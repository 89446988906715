import { Box, Chip, DialogTitle, Stack } from '@mui/material'
import React from 'react'
import { FormContainer, TextFieldElement, useForm } from 'react-hook-form-mui'
import { SelectedFieldOpts } from '../../../providers/HighlightContext'
import { DynamicFormControl } from '../DynamicFormControl'
import { UserFormData } from '../form-control-types'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import LoadingButton from '@mui/lab/LoadingButton'
import { UnifiedSectionField } from './UnifiedSectionField'
import { FieldType } from '../../../types'

interface MappedFieldId{
    id:string;
    mappedField:string;
    page:number;
}

export const UnifiedFieldModal = (
    {formData, pdfId, page, title, fields, submitFn}:
    {
        formData:UserFormData, 
        pdfId:string,
        page:number,
        title:string|undefined, 
        fields: SelectedFieldOpts[] | undefined, 
        submitFn:(form:FormData, field:SelectedFieldOpts)=>Promise<void>|undefined
    }) => 
{
    const unifiedFormContext = useForm()
    const {setValue} = unifiedFormContext
    const [submitting, setSubmitting] = React.useState<boolean>(false);

    const titleMemo = React.useMemo(() => {
        setValue('title', title)
    }, [title])


    const handleSubmit = (data:{[key: string]: any;}) => {
        if(fields){
            const form = new FormData()
            const mappedFields:MappedFieldId[] = []
            const fieldType = fields[0].field.type
            for (let i = 0; i < fields.length; i++) {
                const element = fields[i];
                const item:MappedFieldId = {id:element.field.id, mappedField:element.field.name, page:page}
                mappedFields.push(item)
            }
            form.append('fieldIds', JSON.stringify(mappedFields))
            form.append('pdfId', pdfId)
            form.append('fieldType', fieldType)
            if(fieldType === FieldType.List){
                form.append('options', JSON.stringify(fields[0].field.options))
            }
            formatFormFields(data, form)
            // console.log(form)
            // form.append('mappedField', field?.field.name??'')
            setSubmitting(true)
            submitFn(form,fields[0])
        }
    }

    const formatFormFields = (data:{[key: string]: any;},form:FormData) => {
        for (const key in data) {
          if(data[key]){
            if(typeof data[key] === 'object' && data[key] !== null &&!Array.isArray(data[key])){
              const id:string = data[key]['id']
              form.append(key, id)
            } else {
              form.append(key, data[key])
            }
          }
        }
    }
    

    return (
        <>
            <DialogTitle>Create new unified field</DialogTitle>
            <Box p={2} width={'500px'}>
                <FormContainer formContext={unifiedFormContext} onSuccess={handleSubmit}>
                    <Stack spacing={4}>
                        <Stack direction={'row'} mb={-2} alignItems={'center'} justifyContent={'center'}>
                            {fields &&
                                fields.map((f) => (
                                    <Stack direction={'column'} alignItems={'center'} maxWidth={'50%'} key={f.field.id} px={0.5}>
                                        <Chip sx={{mb:1.5}} label={f.field.name} />
                                        <ArrowDownwardIcon/>
                                    </Stack>
                                ))
                            }
                        </Stack>
                        <TextFieldElement size={'small'} name={'title'} label={'Title'} helperText={'The name of the new unified field'} required={true} disabled={true}/>
                        <UnifiedSectionField formContext={unifiedFormContext}/>
                        {formData.fields.map((f,i) => (
                            <DynamicFormControl key={`unified-form-field-${i}`} {...f} formContext={unifiedFormContext}  />
                        ))}
                        <Box pb={2}>
                            <LoadingButton
                                type={"submit"}
                                loading={submitting}
                                variant={"outlined"}
                            >
                                Create
                            </LoadingButton>
                        </Box>
                    </Stack>
                </FormContainer>
            </Box>
        </>
    )
}
