import { AxiosError } from "axios";
import React from "react";
import { DashboardCtx, useDashboardContext } from "../../../providers/DashboardContext";
import { useModalContext } from "../../../providers/ModalContext";
import { useNotifier } from "../../../providers/NotifyContext";
import { PDFSorting, PdfFolderModel, PdfListPagination } from "../../../types";
import { JobResponse } from "../../unified-editor/types";
import { useInstance } from "../../../utils/axiosConfig";

export function useLoadMore() {

    const { state:{items, sorting}, dispatch } = useDashboardContext()
    const [error, setError] = React.useState<any>();
    const [loading, setLoading] = React.useState<boolean>(false)
    const {instance} = useInstance()

    const defaultSize = 10

    async function loadMoreFiles(){
        setLoading(true)
        setTimeout(() => {
            instance.get(`/api/pdf/list?skip=${items.length}&limit=${defaultSize}&sort=${sorting}`)
            .then((res) => {
                const response:PdfListPagination = res.data
                dispatch({type:DashboardCtx.AddData, payload:response})
            })
            .catch((err)=>{
                console.log(err)
                setError(err)
            })
            .finally(() =>{
                setLoading(false)
            })
        }, 500);
    }

    async function loadMoreFolders(folderId:string){
        setLoading(true)
        setTimeout(() => {
            instance.get(`/api/pdf/list_folder/${folderId}?skip=${items.length}&limit=${defaultSize}&sort=${sorting}`)
            .then((res) => {
                const response:PdfListPagination = res.data
                dispatch({type:DashboardCtx.AddData, payload:response})
            })
            .catch((err)=>{
                console.log(err)
                setError(err)
            })
            .finally(() =>{
                setLoading(false)
            })
        }, 500);
    }

    return { loading, error, loadMoreFiles, loadMoreFolders }
}


export function useEditPdf(){

    const { dispatch } = useDashboardContext()

    const [loading, setLoading] = React.useState<boolean>(false)
    const [disabled, setDisabled] = React.useState<boolean>(false);
    const [error, setError] = React.useState<AxiosError>();
    const {instance} = useInstance()

    const modal = useModalContext();

    const notify = useNotifier();

    async function removeHandler(id:string, name:string){
        setDisabled(true)
        setLoading(true)
        instance.delete(`/api/pdf/remove/${id}`)
        .then((res) => {
            if(res.status === 200){
                dispatch({type:DashboardCtx.RemovePdf, payload:id})
                notify.success(`${name} has been removed from your Library`);
                modal.hide()
            }
        })
        .catch(err => {
            setError(err)
            notify.error('There was an error deleting this mapping')
            console.log(err)
        })
        .finally(() => {
            setDisabled(false)
            setLoading(false)
        })
    }

    function changeNameHandler(editName:{id:string, name:string}) {
        setDisabled(true)
        if(editName.id && editName.name){
            const id = editName.id
            setLoading(true)
            instance.patch(`/api/pdf/form_name/${editName.id}/${editName.name}`)
            .then((res) => {
                setDisabled(false)
                if(res.status === 200){
                    const updatedName = res.data;
                    dispatch({type:DashboardCtx.UpdateName, payload:{id:id, name:updatedName}})
                    notify.success(`The file name has successfully been changed to ${editName.name}`);
                }
            })
            .catch(err => {
                setDisabled(false)
                setError(err)
                console.log(err)
                notify.error('There was an error changing the name')
            })
            .finally(()=>{
                setLoading(false)
            })
        }
    }

    function submitHandler(id:string) {
        setLoading(true)
        instance.post(`/transform/${id}`)
        .then((res) => {
            const data = res.data as JobResponse
            dispatch({type:DashboardCtx.SetLoading, payload:data})
            setLoading(false)
            notify.success(`The form was successfully submitted for processing`)
        })
        .catch(err => {
            setLoading(false)
            console.log(err)
            notify.error('There was an error submitting the form')
            dispatch({type:DashboardCtx.RemoveLoading})
        })
    }

    return { loading, error, disabled, removeHandler, changeNameHandler, submitHandler }
}


export function useEditFiles(){

    const { dispatch } = useDashboardContext()

    const [loading, setLoading] = React.useState<boolean>(false)
    const [error, setError] = React.useState<AxiosError>();
    const {instance} = useInstance()
    
    const notify = useNotifier();

    async function onDrop(folderId:string|undefined, pdfId:string){
        let url = ''
        if(folderId){
            url = `/api/pdf/add_to_folder/${folderId}/${pdfId}`
        } else {
            url = `/api/pdf/remove_from_folder/${pdfId}`
        }
        setLoading(true)
        instance.post(url)
        .then(() => {
            dispatch({type:DashboardCtx.RemovePdf, payload:pdfId})
            notify.success('The file was successfully moved')
        })
        .catch((err) => {
            setError(err)
            console.log(err)
            notify.error('There was an error moving the file')
        })
        .finally(() => {
            setLoading(false)
        })
    }

    return { loading, error, onDrop }
}

export function useEditFolders(){
    const [loading, setLoading] = React.useState<boolean>(false)
    const [error, setError] = React.useState<AxiosError>();
    const {instance} = useInstance()

    const { dispatch } = useDashboardContext()

    const notify = useNotifier();
    const modal = useModalContext();

    const renameFolder = (folderId:string, name:string) => {
        const newName = {"name":name}
        setLoading(true)
        instance.post(`/api/folders/rename_folder/${folderId}`, newName)
        .then((res) => {
            if(res.status === 200){
                dispatch({type:DashboardCtx.UpdateName, payload:{id:folderId, name:name}})
                notify.success(`The folder was successfully renamed to "${name}"`)
            }
        })
        .catch(err => {
            setError(err)
            console.log(err)
            notify.error('There was an error renaming the folder')
        })
        .finally(() => {
            setLoading(false)
        })
    }

    const removeFolder = async (folderId:string) => {
        setLoading(true)
        try {
            const check = await instance.get(`/api/pdf/folder_is_empty/${folderId}`)
            if(check.data as boolean){
                instance.post(`/api/folders/remove_folder/${folderId}`)
                .then((res) => {
                    if(res.status === 200){
                        dispatch({type:DashboardCtx.RemovePdf, payload:folderId})
                        notify.success(`The folder was successfully removed`)
                        modal.hide()
                    }
                })
                .catch((err) => {
                    setError(err)
                    console.log(err)
                    notify.error('There was an error removing the folder')
                })
                .finally(() => {
                    setLoading(false)
                })
            } else {
                setLoading(false)
                notify.warning('This folder contains either pdfs or folders and cannot be deleted, please move or delete to continue')
                modal.hide()
            }
        } catch (error) {
            setError(error as any)
            console.log(error)
            notify.error('There was an error checking the folder status')
        }
    }

    const addFolder = (name:string, folderId?:string) => {
        const newName = {"name":name}
        let addUrl = ''
        if(folderId){
            addUrl = `?q=${folderId}`
        }
        setLoading(true)
        instance.post(`/api/folders/add_folder${addUrl}`, newName)
        .then((res) => {
            const newFolder = res.data as PdfFolderModel 
            dispatch({type:DashboardCtx.AddFolder, payload:newFolder})
            notify.success(`A new folder "${name}" was created`)
            modal.hide()
        })
        .catch(err => {
            setError(err)
            console.log(err)
            notify.error('There was an error creating the folder')
        })
        .finally(() => {
            setLoading(false)
        })

    }

    return { loading, error, renameFolder, removeFolder, addFolder }
}


export function useLoadItems(){
    
    const notify = useNotifier();
    
    const { state:{count, items, sorting}, dispatch } = useDashboardContext()
    const [error, setError] = React.useState<any>(null);
    const [loading, setLoading] = React.useState<boolean>(true);
    const {instance} = useInstance()

    const defaultSize = 10

    const getPdfList = (newSorting?:PDFSorting) => {
        notify.close()
        setLoading(true)
        instance.get(`/api/pdf/list?skip=0&limit=${defaultSize}&sort=${newSorting ? newSorting : sorting ? sorting : PDFSorting.default}`)
        .then((res) => {
            setError(null)
            const response:PdfListPagination = res.data
            dispatch({type:DashboardCtx.SetData, payload:response})
        })
        .catch((err)=>{
            console.log(err)
            setError(true)
            notify.error('There has been an error loading the files',{autoHideDuration:null})
        })
        .finally(() => {
            setLoading(false)
        })
    }

    const getPdfFoldersList = (id:string) => {
        setLoading(true)
        instance.get(`/api/pdf/list_folder/${id}?skip=0&limit=${defaultSize}&sort=${sorting ? sorting :PDFSorting.default}`)
        .then((res) => {
            setError(null)
            const response:PdfListPagination = res.data
            dispatch({type:DashboardCtx.SetData, payload:response})
        })
        .catch((err)=>{
            console.log(err)
            setError(err)
            notify.error('There has been an error loading the folder',{autoHideDuration:null})
        })
        .finally(() => {
            setLoading(false)
        })
    }

    const searchPdfList = (term:string) => {
        setLoading(true)
        notify.close()
        instance.get(`/api/pdf/search/${term}?skip=0&limit=${defaultSize}`)
        .then((res) => {
            setError(null)
            const response:PdfListPagination = res.data
            dispatch({type:DashboardCtx.SetData, payload:response})
        })
        .catch((err)=>{
            console.log(err)
            setError(err)
            notify.error('There has been an error loading the files',{autoHideDuration:null})
        })
        .finally(() => {
            setLoading(false)
        })
    }

    return {count, loading, error, items, getPdfList, getPdfFoldersList, searchPdfList}
}


export function useReloadItems(){
    
    const notify = useNotifier();
    
    const {dispatch} = useDashboardContext()
    const [error, setError] = React.useState<any>(null);
    const [loading, setLoading] = React.useState<boolean>(false);
    const {instance} = useInstance()

    const defaultSize = 10

    const reloadPdfList = (sorting?:PDFSorting) => {
        setLoading(true)
        instance.get(`/api/pdf/list?skip=0&limit=${defaultSize}&sort=${sorting ? sorting : PDFSorting.default}`)
        .then((res) => {
            setError(null)
            const response:PdfListPagination = res.data
            dispatch({type:DashboardCtx.SetData, payload:response})
        })
        .catch((err)=>{
            console.log(err)
            setError(true)
            notify.error('There has been an error loading the files',{autoHideDuration:null})
        })
        .finally(() => {
            setLoading(false)
        })
    }

    const reloadPdfFoldersList = (id:string, sorting?:PDFSorting) => {
        setLoading(true)
        instance.get(`/api/pdf/list_folder/${id}?skip=0&limit=${defaultSize}&sort=${sorting ? sorting :PDFSorting.default}`)
        .then((res) => {
            setError(null)
            const response:PdfListPagination = res.data
            dispatch({type:DashboardCtx.SetData, payload:response})
        })
        .catch((err)=>{
            console.log(err)
            setError(err)
            notify.error('There has been an error loading the folder',{autoHideDuration:null})
        })
        .finally(() => {
            setLoading(false)
        })
    }

    return {reloadPdfList, reloadPdfFoldersList, loading, error}
}