import LoadingButton from '@mui/lab/LoadingButton/LoadingButton'
import React from 'react'
import { useCombinedState } from '../../providers/combinedStateFn'
import useStore from '../../providers/EditFormState'
import { useNotifier } from '../../providers/NotifyContext'
import { PdfCtx, usePdfContext } from '../../providers/PdfContext'
import { FieldModel, GroupHighlightModel, HighlightModel, SubsectionHighlightModel } from '../../types'
import { FormFieldData, SectionListItem } from './form-control-types'
import { useInstance } from '../../utils/axiosConfig'

export const RemoveFieldMapping = ({selectedField}:{selectedField:FieldModel}) => {

    const resetEditFormState = useStore((state) => state.initial)
    const { resetHighlightedFields, initialHighlightClickFn } = useCombinedState()
    const [ loading, setLoading ] = React.useState<boolean>(false)
    const { state:{document:{id}, pageNo}, dispatch } = usePdfContext()
    const {instance} = useInstance()
    const notify = useNotifier();

    const resetPageState = () => {
        resetEditFormState()
        initialHighlightClickFn()
        resetHighlightedFields()
    }

    const handleClick = () => {
        setLoading(true)
        instance.delete(`/api/pdf/remove_mapping/${id}/${selectedField.id}/${pageNo}`)
        .then((res) => {
            const value:FormFieldData = res.data as FormFieldData
            addSectionToDocument(value.section)
            addSubToDocument(value.subsection)
            addGroupToDocument(value.group)
            if(selectedField.completed){
                dispatch({type:PdfCtx.RmvCompleted, payload:selectedField.id})
            }
            resetPageState()
            notify.success(`'${selectedField.name}' was successfully removed`)
        })
        .catch(err => {
            setLoading(false)
            console.log(err)
        })
    }

    const addSectionToDocument = (sections:HighlightModel[]|undefined) => {
        if(sections){
            dispatch({type:PdfCtx.EditSections, payload:sections})
        }
      }

    const addSubToDocument = (subsections:SubsectionHighlightModel[]|undefined) => {
        if(subsections){
            dispatch({type:PdfCtx.EditSubsections, payload:subsections})
        }
    }

    const addGroupToDocument = (groups:GroupHighlightModel[]|undefined) => {
        if(groups){
            const group = groups[0]
            dispatch({type:PdfCtx.EditGroups, payload:group})
        }
    }


  return (
    <LoadingButton onClick={handleClick} fullWidth variant={'outlined'} color={'error'} loading={loading}>
        Remove Mapping
    </LoadingButton>
  )
}
