import React, { useMemo } from 'react'
import { useUnifiedData } from './utils'
import { Box, CircularProgress, DialogContent, DialogContentText, Divider, IconButton, Stack, Tab, Typography } from '@mui/material'
import { AllMatchedUserFields, UIGroup } from './types'
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import LoadingButton from '@mui/lab/LoadingButton';
import { useNotifier } from '../../providers';
import { GroupRelationshipType } from '../../types';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { UserFormData } from '../form/form-control-types';

export const GroupInfo = (
    {group, handleDelete}:
    {group: UIGroup, handleDelete:()=>void}) => 
{

    const {deleteUnifiedGroup} = useUnifiedData()
    const {success, close, error:notifyError, warning} = useNotifier()
    const [value, setValue] = React.useState('1');
    const [loading, setLoading] = React.useState<boolean>(false);
    const {getUnifiedFieldData, loading:dataLoading, error, data, unlinkFieldFromUnified} = useUnifiedData()

    React.useEffect(() => {
        getUnifiedFieldData(group.options[0].id)
    }, [])

    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setValue(newValue);
    };


    const handleDeleteGroup = () => {
        setLoading(true)
        deleteUnifiedGroup(group.id)
        .then((res) => {
            if(res){
                success(`The unified group was successfully deleted`)
                handleDelete()
            } else {
                warning('This unified field still has connected fields, please review and remove them first')
            }
        })
        .catch((err) => {
            notifyError('There was a problem deleting this group, please try again')
            console.log(err)
        })
        .finally(() => {
            setLoading(false)
        })
    }


    return (
        <DialogContent sx={{mb:2, minWidth:'500px', maxHeight:'80vh', width:'100%'}}>
            {dataLoading
                ? <Stack direction={'row'} justifyContent={'center'}><CircularProgress/></Stack>
                : data && 
                <TabContext value={value}>
                    <Box sx={{ width: '100%' }}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <TabList onChange={handleChange} aria-label="Unified Field Info tabs">
                                <Tab label="Edit Field" value={'1'} />
                                <Tab label="Connected Fields" value={'2'} />
                            </TabList>
                        </Box>
                        <TabPanel sx={{p:0}} value={'1'}>
                        <Stack mt={2} spacing={1} direction={'column'} justifyContent={'flex-end'}>
                            <UnifiedFormData 
                                    formData={data.formData}
                                    handleChange={handleChange}
                                    group={group}/>
                            <LoadingButton onClick={handleDeleteGroup} loading={loading} variant={'outlined'} color={'error'} sx={{width:'25%', ml:'auto'}}>
                                Delete
                            </LoadingButton>
                        </Stack>
                                
                        </TabPanel>
                        <TabPanel sx={{py:2, px:0}} value={'2'}>
                            <UnifiedConnectedFields data={data.matchedFields} unlinkField={unlinkFieldFromUnified}/>
                        </TabPanel>
                    </Box>
                </TabContext>
            }
        </DialogContent>
    )
}


const UnifiedConnectedFields = ({data, unlinkField}:{data:AllMatchedUserFields[], unlinkField:(pdfId:string, fieldId:string, page:number)=>void}) => {

    const handleNavigate = (id:string,page:number,fieldId:string) => {
        window.open(`/edit/${id}?page=${page}&id=${fieldId}`)
    }


    return (
        <>
        <DialogContentText pb={2} id="alert-dialog-description">
            The following fields are connected to the unified field:
        </DialogContentText>
        {data.length 
            ? data.map((p,i) => (
            <Stack key={i} my={2} >
                <Divider sx={{mb:2, borderColor:'black'}} />
                <Typography variant={'body2'} fontWeight={'bold'} sx={{wordWrap:'break-word'}} >{p.name}</Typography>
                {p.fields.map((f,index) => (
                    <Stack direction={'row'} mt={1} key={f.id} alignItems={'center'} justifyContent={'space-between'}>
                        <Stack direction={'row'} alignItems={'center'}>
                            <Typography variant={'body2'}>{`Page ${f.page}`}</Typography>
                            <IconButton size={'small'} sx={{ml:0.5}} onClick={() => handleNavigate(p.id, f.page, f.id)}>
                                <OpenInNewIcon color={'primary'} fontSize={'small'}/>
                            </IconButton>
                        </Stack>
                    </Stack>
                ))}
            </Stack>))
            : <Box>
                <Divider sx={{mb:2, borderColor:'black'}} />
                <Typography variant={'button'}>No Mapped Fields</Typography>
            </Box>
        }
        <Divider sx={{mt:2, borderColor:'black'}}/>
        </>
    )
}


const UnifiedFormData = (
    {formData, handleChange, group}:
    {formData:UserFormData|null, handleChange: (event: React.SyntheticEvent, newValue: string) => void, group:UIGroup}
    ) => {
        return (
        <Box>
            {formData 
                ? <>
                    <Stack mb={2}>
                        <Box>
                            <Typography fontWeight={'bold'}>Section</Typography>
                            <Typography>{formData.unifiedData?.unifiedSection.label}</Typography>
                        </Box>
                        {formData.unifiedData?.unifiedSubsection && <Box>
                            <Typography fontWeight={'bold'}>Group Section</Typography>
                            <Typography>{formData.unifiedData?.unifiedSubsection.label}</Typography>
                        </Box>}
                    </Stack>
                </>
                : <Box mt={2} mb={3}>
                <Box component={'span'}>
                    This field is not a unified field and can be edited directly on the form, which can be found in the
                </Box>
                <Box ml={0.5} sx={{cursor:'pointer'}} color={'primary.main'} component={'span'} onClick={(e:React.MouseEvent<HTMLSpanElement, MouseEvent>)=>handleChange(e,'2')}>
                    next tab
                </Box>
            </Box>
            }
            <Typography >Preview</Typography>
            <Box px={2} pt={3} pb={2} mb={3} border={'solid 1px black'} borderRadius={1} position={'relative'} >
                <Stack direction={'row'} flexWrap={'wrap'} >
                    {group.options.map((i) => (
                        <Stack key={i.id} direction={'row'} alignItems={'center'} mr={2} mb={1} position={'relative'} >
                        {group.relationship === GroupRelationshipType.Single
                            ? <RadioButtonUncheckedIcon sx={{mr:0.5}} />
                            : <CheckBoxOutlineBlankIcon sx={{mr:0.5}} />
                        }
                        <Typography>{i.label}</Typography>
                    </Stack>
                    ))}
                </Stack>
            </Box>
        </Box>    
        )
    }