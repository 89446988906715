import { DialogContent, DialogContentText, Typography } from '@mui/material'
import React, { ChangeEvent } from 'react'
import UploadFileIcon from "@mui/icons-material/UploadFile";
import { LoadingButton } from '@mui/lab';
import { useInstance } from '../../utils/axiosConfig';


export const LogoUploadModal = ({id, setLogo}:{id:string, setLogo:(id:string, url:string)=>void}) => {

    const [loading, setLoading] = React.useState<boolean>(false);
    const [error, setError] = React.useState<string|undefined>(undefined);
    const {instance} = useInstance()

    const handleFileUpload = (e: ChangeEvent<HTMLInputElement>) => {
        setError(undefined)
        setLoading(true);
        if (!e.target.files) {
            return;
        }
        const file = e.target.files[0];

        if(file && (file.type !== 'image/png' && file.type !== 'image/jpeg')){
            setError('Incorrect file type')
            return
        }

        if(file && file.size>307200){
            setError('The file is too large (> 300kb)')
            setLoading(false)
            return
        }

        if(file){
            const formData = new FormData();
            formData.append("file", file, file.name);
            instance.patch(`/api/pdf/logo/${id}`, formData)
            .then((res) => 
            {
                setLoading(false);
                const url:string = res.data;
                setLogo(id,url)
            })
            .catch(err => {
                setLoading(false);
                console.log(err);
            });
        }
    }

  return (
    <DialogContent>
        <DialogContentText id="alert-dialog-description" mb={4}>
            Please select an image for the PDF logo, image types accepted are <b>jpg</b> and <b>png</b> and are less than <b>300kb</b>
        </DialogContentText>
        <LoadingButton
            loading={loading}
            size="large"
            component="label"
            variant="outlined"
            startIcon={<UploadFileIcon />}
            sx={{ textTransform: "none" }}
        >
            Add Logo
            <input type="file" accept="image/png, image/jpeg" hidden onChange={handleFileUpload} />
        </LoadingButton>
        {error && <Typography sx={{color:(style)=>style.palette.error.main, fontWeight:'500'}} mt={2} variant={'subtitle1'}>{error}</Typography>}
    </DialogContent>
)
}
