import { useLocalStorage } from "./useLocalStorage";
import { User } from "./auth-types";
import { AuthCtx, useAuthContext } from "../providers/AuthContext";
import { useNotifier } from "../providers";
import React from "react";
import axios, { AxiosError } from "axios";
import { useInstance } from "../utils/axiosConfig";
import { useSessionStorage } from "./useSessionStorage";


export const useAuth = () => {
  const { dispatch } = useAuthContext()
  const { setItem:setSession } = useSessionStorage()
  const { setItem} = useLocalStorage();
  const { error, close } = useNotifier()
  const [loading, setLoading] = React.useState<boolean>(false)
  const {instance} = useInstance()

  const login = (login:FormData, remember:boolean) => {
    close()
    setLoading(true)
    axios.post(`${process.env.REACT_APP_DB_HOST_URI}/login`,login)
    .then((res) => {
      const user = res.data as User
      dispatch({type:AuthCtx.Login, payload:user})
      if(remember){
        setItem("token", JSON.stringify(user.token));
      } else {
        setSession("token", JSON.stringify(user.token))
      }
    })
    .catch((err) => {
      error('There was an error logging in')
      console.log(err)
    })
    .finally(() => {
      setLoading(false)
    })
  };

  const logout = () => {
    close()
    setLoading(true)
    instance.post('/logout')
    .then(() => {
      dispatch({type:AuthCtx.Logout})
      setItem("token", "");
      setSession("token", "")
    })
    .catch((err) => {
      error('There was an error logging out')
      console.log(err)
    })
    .finally(() => {
      setLoading(false)
    })
  };

  return { login, logout, loading };
};


export const useAuthStatus = () => {

  const { getItem } = useLocalStorage();
  const { getItem:getSession } = useSessionStorage()
  const { dispatch } = useAuthContext()
  const { error, close } = useNotifier()
  const { instance } = useInstance()

  const authStatus = () => {
    close()
    const jsonSessionToken = getSession("token")
    const jsonLocalToken = getItem("token")
    if(jsonSessionToken || jsonLocalToken){
      let token = ''
      if(jsonSessionToken){
        token = JSON.parse(jsonSessionToken)
      } else if(jsonLocalToken){
        token = JSON.parse(jsonLocalToken)
      }
      axios.get(`${process.env.REACT_APP_DB_HOST_URI}/user`,{headers:{Authorization: "Bearer "+token}})
      .then((res) => {
        const user = res.data as User
        dispatch({type:AuthCtx.Login, payload:{...user, token:token}})
      })
      .catch((err:AxiosError) => {
        error('There was an error verifying login details')
        dispatch({type:AuthCtx.Logout})
        console.log(err)
      })
    } else {
      dispatch({type:AuthCtx.Logout})
    }
  }

  return { authStatus }
}