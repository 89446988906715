import LoadingButton from '@mui/lab/LoadingButton'
import { Alert, Box, debounce, DialogContent, Divider, FormHelperText, IconButton, Stack, styled, TextField, Typography } from '@mui/material'
import React from 'react'
import { Controller, FormContainer, SelectElement, SubmitHandler, TextFieldElement, useForm } from 'react-hook-form-mui'
import { DynamicFormControl } from '../DynamicFormControl'
import { UserFormData } from '../form-control-types'
import AddIcon from '@mui/icons-material/Add';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import { UnifiedSectionField } from './UnifiedSectionField'
import { useInstance } from '../../../utils/axiosConfig'



export const UnifiedGroupModal = ({formData, handleClose, pdfId}:{formData:UserFormData, handleClose:()=>void, pdfId:string}) => {

    const formContext = useForm()
    const {watch} = formContext
    const [loading, setLoading] = React.useState<boolean>(false);
    const [disabled, setDisabled] = React.useState<boolean>(false);
    const [status, setStatus] = React.useState<boolean|undefined>(undefined)
    const [fieldList, setFieldList] = React.useState<string[]>([])
    const [fieldName, setFieldName] = React.useState('');
    const [fieldError, setFieldError] = React.useState(false);
    const {instance} = useInstance()
    const [checkedGroupName, setCheckedGroupName] = React.useState<string|undefined>(undefined)
    const onSubmit:SubmitHandler<any> = data => handleSubmit(data);
    const watchType = watch('type')

    const checkTitle = async (text:string) => {
        if(text !== ''){
            const searchTerm = {"search":text}
            await instance.post(`/api/unified/group/check`,searchTerm)
            .then((res) => {
                const availability = res.data as boolean
                if(availability){
                    setStatus(true)
                    setCheckedGroupName(text)
                } else {
                    setStatus(false)
                    setDisabled(true)
                    setCheckedGroupName(undefined)
                }
                setLoading(false)
            })
            .catch((err) => console.log(err))
        } else {
            setStatus(undefined)
            setLoading(false)
            setCheckedGroupName(undefined)
        }
    }

    const debouncedSearch = debounce((text:string)=>{
        checkTitle(text)
    }, 1000)

    const onAmountChanged = React.useMemo( 
        ()=> (e: string) => {
            debouncedSearch(e)
            setDisabled(false)
            setLoading(true)
        }, 
        []
    );

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFieldName(event.target.value)
    }

    const addField = () => {
        if(fieldName!==''){
            const existingArr = new Set(fieldList)
            existingArr.add(fieldName)
            const newArr = Array.from(existingArr)
            setFieldList(newArr)
            setFieldName('')
            setFieldError(false)
        }
    }

    const removeField = (f:string) => {
        const filtered = fieldList.filter(elem => elem !== f);
        setFieldList(filtered)
    }

    const handleSubmit = (data:{[key: string]: any;}) => {
        if(fieldList.length<1){
            setFieldError(true)
            return;
        }
        setFieldError(false)
        const form = new FormData()
        form.append('fields', JSON.stringify(fieldList))
        form.append('pdfId', pdfId)
        formatFormFields(data, form)
        // console.log(form)
        setLoading(true);
        instance.post(`/api/unified/group/add`, 
            form,
            {headers: {'content-type': 'multipart/form-data'}}
        ).then((res) => 
            {   
                if(res.status === 200){
                    setLoading(false);
                    handleClose()
                }
            }
        ).catch(err => {
                setLoading(false);
                console.log(err);
            });
    }

    const formatFormFields = (data:{[key: string]: any;},form:FormData) => {
        for (const key in data) {
          if(data[key]){
            if(typeof data[key] === 'object' && data[key] !== null &&!Array.isArray(data[key])){
              const id:string = data[key]['id']
              form.append(key, id)
            } else {
              form.append(key, data[key])
            }
          }
        }
    }

    const TextFieldElementBorder = styled(TextFieldElement)(()=>({
        'width':'60%',
        "& .MuiOutlinedInput-notchedOutline legend": { display: "none" },
        "& .MuiOutlinedInput-notchedOutline" :{ top: 0 }
    }))

    const options= [
        {id:'multi', label:'Multi'},
        {id:'single', label:'Single'}
    ]


    return (
        <DialogContent sx={{maxWidth:'500px'}}>
            <Stack mt={1}>
                <FormContainer formContext={formContext} onSuccess={onSubmit}>
                    <Stack spacing={2}>
                        <UnifiedSectionField formContext={formContext}/>
                        <Box pt={1}>
                            <Controller 
                                render={({ field }) => (
                                    <TextFieldElement
                                        onChange={(e) => {
                                            field.onChange(e);
                                            onAmountChanged(e.target.value)
                                        }} 
                                        name={field.name}
                                        required 
                                        fullWidth 
                                        size='small' 
                                        label='Group Name' 
                                        helperText='What will be the name of this group?'
                                        autoComplete='off'
                                    />
                                )} 
                                name={'name'}                            
                            />
                            {
                                status && <Alert sx={{mt:1, mb:2}} severity={'success'}>Group Name Available</Alert>
                            }
                            {
                                status === false && <Alert sx={{mt:1,mb:2}} severity={'error'}>This group name is already in use, try another</Alert>
                            }
                        </Box>
                        <SelectElement
                            name='type'
                            required
                            fullWidth
                            size='small'
                            label='Type'
                            helperText='What is the relationship between the items in this group, for single only one can be selected, for multi many can be selected'
                            options={options}
                        />
                        {formData.fields.map((f,i) => (
                            <DynamicFormControl key={`unified-form-field-${i}`} {...f} formContext={formContext}  />
                        ))}
                        <Box>
                            <Stack direction={'row'}>
                                <TextField 
                                    fullWidth 
                                    size={'small'} 
                                    label={'Field Name'} 
                                    name={'field-name'} 
                                    value={fieldName} 
                                    onChange={handleChange} 
                                />
                                <IconButton sx={{ml:1}} aria-label="add" onClick={addField}>
                                    <AddIcon />
                                </IconButton>
                            </Stack>
                            {fieldError
                                ? <FormHelperText sx={{mx:1.75}} error>Please Add at least one field to the group</FormHelperText>
                                : <FormHelperText sx={{mx:1.75}}>
                                    Add fields to this group; when you select to unify using this 
                                    group, you will need to map fields that correspond to the ones 
                                    added here
                                </FormHelperText>

                            }
                        </Box>
                        {fieldList.length>0 && 
                            <>
                            <Divider sx={{pt:1}} />
                            <Typography sx={{textDecoration:'underline'}} >Preview</Typography>
                            <Box px={2} pt={3} pb={2} border={'solid 1px black'} borderRadius={1} position={'relative'} >
                                {checkedGroupName && <Typography bgcolor={'white'} px={1} variant={'body2'} position={'absolute'} top={-12} left={8}>{checkedGroupName}</Typography>}
                                <Stack direction={'row'} flexWrap={'wrap'} >
                                    {fieldList.map((f,i) => (
                                            <Stack key={i} direction={'row'} alignItems={'center'} mr={2} mb={1} position={'relative'} >
                                                {
                                                    watchType 
                                                    ? watchType === 'multi' 
                                                        ? <CheckBoxOutlineBlankIcon sx={{mr:0.5}} />
                                                        : <RadioButtonUncheckedIcon sx={{mr:0.5}} />
                                                    : <CheckBoxOutlineBlankIcon sx={{mr:0.5}} />
                                                }
                                                <Typography>{f}</Typography>
                                                <IconButton onClick={()=>removeField(f)} sx={{opacity:0,position:'absolute', left:'-8px', ":hover":{opacity:1}}} ><RemoveCircleIcon/></IconButton>
                                            </Stack>
                                    ))}
                                </Stack>
                            </Box>
                            </>
                        }
                    </Stack>
                    <LoadingButton
                        sx={{mt:4}}
                        type='submit'
                        loading={loading}
                        disabled={disabled}
                        variant="outlined"
                        >
                        Submit
                    </LoadingButton>
                </FormContainer>
            </Stack>
        </DialogContent>
    )
}