import React, { useEffect, useState } from 'react'
import LoadingButton from '@mui/lab/LoadingButton';
import ColorizeIcon from '@mui/icons-material/Colorize';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { Tooltip } from '@mui/material';
import { FieldModel } from '../../types';
import { motion } from 'framer-motion';
import { HighlightCtx, HighlightType, useHighlightContext } from '../../providers/HighlightContext';
import { usePdfContext } from '../../providers/PdfContext';
import useStore from '../../providers/EditFormState';
import { usePdfNotify } from '../../providers/PdfNotifyContext';
import { useCombinedState } from '../../providers/combinedStateFn';
import { useInstance } from '../../utils/axiosConfig';


export const SearchFieldTitle = (selectedField:FieldModel) => {

    const { state:{highlightType}, dispatch } = useHighlightContext();
    const [loading, setLoading] = useState<boolean>(false);
    const { state:{pageNo, document} } = usePdfContext();
    const setFormTitle = useStore((state) => state.setFormTitle);
    const enabled = highlightType === HighlightType.Text;
    const pageNumber = pageNo - 1;
    const notify = usePdfNotify();
    const {removeHighlightedTextFn} = useCombinedState()
    const {instance} = useInstance()

    const handleClick = () => {
        if(!enabled){
            fetchTextFields()
        } else {
            removeHighlightedTextFn()
        }
    }

    useEffect(() => {
        if(enabled){
            useStore.setState({setHighlightFn:(f)=>{
                setFormTitle(f.name)
            }});
        }
    }, [enabled])
    

    const fetchTextFields = () => {
        setLoading(true);
        instance.get(`/api/pdf/text_fields/${document.id}/${pageNumber}/${selectedField.id}`)
        .then((res)=>{
            if((res.data as FieldModel[]).length<1){
                notify.showSnackBar({text:'No nearby fields found'})
            }
            dispatch({type:HighlightCtx.HighlightText, payload:{elements: [...res.data]}})
            setLoading(false)
        })
        .catch(err => {
            console.log(err)
        })
    }


    return (
        <Tooltip arrow title={ enabled 
            ? "Close highlighted fields" 
            : "Click here to find nearby title fields" }>
            <span style={{
                position:"absolute",
                zIndex:5,
                transition: "all 0.25s",
                left: enabled ? "0" :`calc(${selectedField.positionPercentage[0]}% - 50px)`,
                bottom: enabled ? "calc(100% - 32px)" :`calc(${selectedField.positionPercentage[1]}% + 15px)`}}>
                    <motion.div key="search-field" initial={{opacity:0}} animate={{opacity:1}} exit={{opacity:0}} layout >
                        <LoadingButton 
                        variant={"contained"} 
                        sx={{minWidth:"0"}} 
                        loading={loading} 
                        onClick={handleClick} 
                        endIcon={enabled 
                            ? <HighlightOffIcon sx={{marginLeft:"-10px"}} /> 
                            : <ColorizeIcon sx={{marginLeft:"-10px"}} />} 
                        />
                    </motion.div>
            </span>
        </Tooltip>
    )
}
