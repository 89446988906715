import { alpha, Box, ButtonBase, CircularProgress, Fade, FormHelperText, ListItem, Paper, Popper, styled, Typography, ClickAwayListener, Stack, debounce, Accordion, AccordionDetails, AccordionSummaryProps, AccordionSummary, Chip } from '@mui/material'
import React from 'react'
import { Controller, TextFieldElement, UseFormReturn } from 'react-hook-form-mui'
import { usePopupState, anchorRef, bindPopper } from 'material-ui-popup-state/hooks'
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MultipleStopIcon from '@mui/icons-material/MultipleStop';
import useStore from '../../../providers/EditFormState';
import { SelectedFieldOpts, useHighlightContext } from '../../../providers/HighlightContext';
import { FieldModel, FieldParentProperty, FieldType, SearchResponse, SearchResponseData, UnifiedGroupResponse } from '../../../types';
import { PdfCtx, usePdfContext } from '../../../providers/PdfContext'
import { DynamicFieldData, UserFormData } from '../form-control-types';
import { useCombinedState } from '../../../providers/combinedStateFn';
import { FormCtx, useFormContext } from '../../../providers/FormContext';
import { useInstance } from '../../../utils/axiosConfig';
import { useNotifier } from '../../../providers/NotifyContext';

interface SearchState{
    loading:boolean;
    res?:SearchResponse|undefined;
    searchTerm?:string|undefined;
}

export const TitleField = (
    {disabled, defaultTitle, formContext, formData, enableUnifiedGroup,completed}:
    {
        disabled?:boolean|undefined,
        defaultTitle: any,
        formContext: UseFormReturn<{[key: string]: any;}, any>,
        formData: UserFormData,
        enableUnifiedGroup: (unifiedGroup: UnifiedGroupResponse) => void,
        completed?: boolean|null|undefined
    }
) => {
    const [width, setWidth] = React.useState<number|undefined>(394);
    const isEdited = useStore((state) => state.isEdited)
    const fieldTitle = useStore((state) => state.fieldTitle)
    const {state:{selectedField}} = useHighlightContext();
    const {state:{document:{id},pageNo}, dispatch} = usePdfContext()
    const {dispatch:fDispatch} = useFormContext()
    const setIsEdited = useStore((state) => state.setIsEdited)
    const [searchState, setSearchState] = React.useState<SearchState>({loading:false})
    const {instance} = useInstance()
    const popupState = usePopupState({variant:'popper', popupId:'field-popper'})
    const {control, setValue} = formContext;
    const fieldRef = React.useRef<HTMLDivElement>(null)
    const fieldType = React.useMemo(() => selectedField ? selectedField.field.type : FieldType.Text, [])
    const { resetHighlightedFields, initialHighlightClickFn } = useCombinedState()
    const resetEditFormState = useStore((state) => state.initial)
    const notify = useNotifier()

    const checkTitle = async (text:string) => {
        if(text !== '' && text !== defaultTitle){
            try {
                const searchRes = await instance.get(`/api/unified/${id}/search${fieldType}?q=${text}`)
                const searchData = searchRes.data as SearchResponse
                setSearchState({loading:false, res:searchData, searchTerm:text})
            } catch (error) {
                console.log(error)
            }
        }
    }

    const debouncedSearch = debounce((text:string)=>{
        checkTitle(text)
    }, 500)

    const onAmountChanged = React.useMemo( 
        ()=> (e: string) => {
            if(completed)return
            debouncedSearch(e)
            if(!searchState.loading){
                setSearchState({loading:true})
                setValue('unified', false)
                popupState.open()
            }
        }, 
        []
    );
    

    React.useEffect(() => {
        setWidth(document.getElementById('field-box')?.offsetWidth);
    }, [document.getElementById('field-box')?.offsetWidth])


    React.useEffect(() => {
        if(fieldTitle){
          setValue("title", fieldTitle)
          onAmountChanged(fieldTitle)
          if(!isEdited){
            setIsEdited(true)
          }
        }
      }, [fieldTitle])
    

    const handleClose = (event: Event | React.SyntheticEvent) => {
        if(fieldRef.current && fieldRef.current.contains(event.target as HTMLElement)){
            return;
        }
        popupState.close()
    }

    const openPopper = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        if(e.target.value !== '' && e.target.value !== defaultTitle){
            popupState.open()
        }
    }

    const enableUnified = () => {
        if(selectedField){
            dispatch({
                type:PdfCtx.CreateUnified, 
                payload:{showModal:true,title:searchState.searchTerm, formData:formData, fields:[selectedField], pdfId:id}
            })
            resetEditFormState()
            initialHighlightClickFn()
            resetHighlightedFields()
        }
    }


    const createUnifiedPair = async (pairId:string, title:string) => {
        if(selectedField){
            await instance.get(`/api/pdf/field/${id}/${pairId}`)
            .then((res) => {
                const field = res.data as FieldModel
                const data:SelectedFieldOpts = {field:field}
                dispatch({
                    type:PdfCtx.CreateUnified, 
                    payload:{showModal:true, title:title, formData:formData, fields:[selectedField, data], pdfId:id}
                })
                resetEditFormState()
                initialHighlightClickFn()
                resetHighlightedFields()
            })
            .catch((err) => {
                console.log(err)
            })
        }
    }

    const getUnifiedData = async (data:SearchResponseData) => {
        if(data.fieldParentProperty){
            if(data.fieldParentProperty === FieldParentProperty.Group){
                getGroupData(data.id)
            }
        } else {
            getFieldData(data.id)
        }
    }


    const getGroupData = async (groupId:string) => {
        await instance.get(`/api/unified/group/${groupId}`)
        .then((res) => {
            const unifiedGroupData = res.data as UnifiedGroupResponse
            enableUnifiedGroup(unifiedGroupData)
        }).catch((err) => {
            console.log(err)
        })
    }



    const getFieldData = async (unifiedId:string) => {
        await instance.get(`/api/unified/${unifiedId}`)
        .then((res) => {
            const unifiedData = res.data as UserFormData
            if(fieldType === FieldType.List){
                const unifiedOpts = unifiedData.unifiedData?.unifiedOptions
                if(unifiedOpts && selectedField?.field.options){
                    if(JSON.stringify(unifiedOpts.sort()) !== JSON.stringify(selectedField.field.options.sort())){
                        notify.error('The options for this field do not match the options for the unified field')
                        return;
                    }
                }
                if(!unifiedOpts && selectedField?.field.options || unifiedOpts && !selectedField?.field.options){
                    notify.error('The options for this field do not match the options for the unified field')
                        return;
                }
            }
            const defaultValuesItems = unifiedData.fields.filter((v) => v.defaultValue )
            const defaultValuesNested = checkNested(unifiedData.fields)
            const defaultValues = () => {
                const obj:{[key:string]:any} = {};
            
                if(unifiedData.title){
                  obj['title'] = unifiedData.title
                }
            
                if(unifiedData.unifiedData){
                  const {unifiedSection, unifiedSubsection} = unifiedData.unifiedData
                  obj['section'] = unifiedSection
                  
                  if(unifiedSubsection){
                    obj['subsection'] = unifiedSubsection
                  }
                }
            
                for (let index = 0; index < defaultValuesItems.length; index++) {
                  const name = defaultValuesItems[index].fieldName
                  const value = defaultValuesItems[index].defaultValue
                  obj[name] = value;
                }
            
                for (let index = 0; index < defaultValuesNested.length; index++) {
                  const name = defaultValuesNested[index].fieldName;
                  const value = defaultValuesNested[index].defaultValue;
                  obj[name] = value
                }
            
                return obj;
            }
            const values = Object.entries(defaultValues())
            for (let i = 0; i < values.length; i++) {
                const element = values[i];
                setValue(element[0], element[1])
            }
            setValue('unified', unifiedId)
            fDispatch({type:FormCtx.disableFields, payload:true})
            popupState.close()
              
        })
        .catch((err) => {
            console.log(err)
        })
    }

    function checkNested(fields:DynamicFieldData[]):DynamicFieldData[]{
        const defaultValuesNestedItems:DynamicFieldData[] = []
        for (let index = 0; index < fields.length; index++) {
          const element = fields[index];
          if(element.options){
            const y = element.options
            for (let index = 0; index < y.length; index++) {
              const o = y[index];
              if(o.nested){
                const h = o.nested
                if(h.defaultValue){
                  defaultValuesNestedItems.push(h)
                }
              }
            }
          }
        }
        return defaultValuesNestedItems
      } 


    const FieldTitleItem = styled(ButtonBase)(({theme}) => ({
        color:alpha(theme.palette.common.black,0.87),
        justifyContent:'flex-start',
        fontFamily:'Poppins',
        fontSize:'1em',
        fontWeight:'400',
        width:'100%',
        padding:`${theme.spacing(0.75)} ${theme.spacing(2)}`,
        '&:hover':{
            backgroundColor: alpha(theme.palette.common.black, 0.04)
        }
    })) 

    const AccordionSummarySlim = styled((props: AccordionSummaryProps) => (
        <AccordionSummary
            onClick={(e) => e.stopPropagation()}
          expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.6rem' }} />}
          {...props}
        />
      ))(({ theme }) => ({
        padding:0,
        paddingTop:'4px',
        minHeight:'auto',
        ':hover':{
            backgroundColor:'rgba(0,0,0,0.03)',
        },
        flexDirection: 'row-reverse',
        '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
          transform: 'rotate(90deg)',
        },
        '& .MuiAccordionSummary-content': {
          marginLeft: theme.spacing(1),
          marginTop: 0,
          marginBottom: 0,
        },
      }));


    const BoldedText = ({ text, shouldBeBold }:{text:string, shouldBeBold:string}) => {
        const textArray = text.split(shouldBeBold);
        return (
          <Stack direction={'row'} whiteSpace={'nowrap'} overflow={'hidden'} textOverflow={'ellipsis'} >
            {textArray.map((item, index) => (
              <React.Fragment key={`match-${index}`}>
                <Typography variant={'body2'} fontWeight={'300'} overflow={'hidden'} textOverflow={'ellipsis'} >{item}</Typography>
                {index !== textArray.length - 1 && (
                  <Typography variant={'body2'} fontWeight={'500'} overflow={'hidden'} textOverflow={'ellipsis'}>{shouldBeBold}</Typography>
                )}
              </React.Fragment>
            ))}
          </Stack>
      );
    }

    return (
        <>
        <Box>
            <Box id={'field-box'} ref={fieldRef}>
                <Box ref={anchorRef(popupState)} >
                    <Controller 
                        control={control}
                        name={'title'}
                        render={({field}) => (
                            <TextFieldElement
                                onChange={(e) => {
                                    field.onChange(e);
                                    onAmountChanged(e.target.value);
                                }} 
                                onFocus={(e) => {
                                    openPopper(e);
                                }}
                                fullWidth 
                                size={'small'} 
                                label={'Field Title'} 
                                required 
                                name={field.name}
                                autoComplete={'off'}
                                disabled={disabled}
                                InputProps={{
                                    endAdornment: searchState.loading ? <CircularProgress color={'inherit'} size={20}/> : null
                                }} 
                            />
                        )}
                    />
                </Box>
            </Box>
            <FormHelperText sx={{mx:1.75}}>What Title should this field have?</FormHelperText>
            <Popper
                sx={{zIndex:100, maxHeight:'90%', overflow:'scroll', boxShadow:2}}
                {...bindPopper(popupState)}
                transition
                disablePortal
            >
                {({TransitionProps}) => (
                    <Fade {...TransitionProps} timeout={350}>
                        <Paper style={{width:width}}>
                            <ClickAwayListener onClickAway={handleClose}>
                                <Box component={'ul'} py={0.75} sx={{listStyleType:'none', pl:0, mb:0}} >
                                    {searchState.loading && 
                                        <FieldTitleItem sx={{color:(theme) => alpha(theme.palette.common.black,0.6)}}>Loading...</FieldTitleItem>
                                    }
                                    {searchState.res?.unifiedData && 
                                        (
                                            searchState.res.unifiedData.length
                                            ? <>
                                                <ListItem sx={{backgroundColor:(theme)=>alpha(theme.palette.common.black, 0.04), py:0.5}}><Typography variant={'caption'} fontWeight={'500'} textTransform={'uppercase'}>Existing Unified Fields to add to</Typography></ListItem>
                                                {
                                                    searchState.res.unifiedData.map((r) => (
                                                        <ListItem key={r.id} sx={{flexDirection:'column', cursor:'pointer', ":hover":{bgcolor:'rgba(0,0,0,0.03)'}, borderTop:1, borderColor:'rgba(0,0,0,0.1)'}} onClick={()=>getUnifiedData(r)} >
                                                            <Stack direction={'row'} width={'100%'}>
                                                                <BoldedText text={r.title} shouldBeBold={searchState.searchTerm??''}/>
                                                            </Stack>
                                                            {r.description &&
                                                                r.description.map((d,i) => (
                                                                    <Stack key={`${i}-description`} width={'100%'} direction={'row'} justifyContent={'space-between'}>
                                                                        {d.item && d.item.map((item,i) => (
                                                                            <Typography maxWidth={`${100/(d.item?.length??1)}%`} whiteSpace={'nowrap'} overflow={'hidden'} textOverflow={'ellipsis'} variant={'caption'} component={'p'} title={item} key={`${i}-item`}>{item}</Typography>
                                                                        ))}
                                                                    </Stack>
                                                                ))
                                                            }
                                                            {r.itemList &&
                                                                <Accordion elevation={0} disableGutters sx={{width:'100%', mt:0.5, bgcolor:'transparent'}}>
                                                                        <AccordionSummarySlim>
                                                                            <Typography variant={'caption'}>There are additional values</Typography>
                                                                        </AccordionSummarySlim>
                                                                    <AccordionDetails sx={{pb:0.5}}>
                                                                        <Stack direction={'row'} flexWrap={'wrap'} >
                                                                        {
                                                                            r.itemList.map((i) => (
                                                                                <Chip sx={{mr:0.75, mb:1, fontSize:'0.7rem', height:'28px'}} key={i} label={i} color={'primary'} variant={'outlined'}/>
                                                                            ))
                                                                        }
                                                                        </Stack>
                                                                    </AccordionDetails>
                                                                </Accordion>
                                                            }
                                                        </ListItem>
                                                    ))
                                                }
                                                <Box component={'li'} sx={{borderTop:1, borderBottom:1, borderColor:(theme)=>alpha(theme.palette.info.main, 0.1), backgroundColor:(theme) =>alpha(theme.palette.info.main, 0.03) }}>
                                                    <FieldTitleItem onClick={enableUnified}>
                                                        <Stack direction={'row'} flexWrap={'nowrap'} width={'100%'}>
                                                            <MultipleStopIcon sx={{mr:0.5}}/>
                                                            <Typography whiteSpace={'nowrap'}>Or add&nbsp;</Typography>
                                                            <Typography fontWeight={'500'}>&lsquo;</Typography>
                                                            <Typography fontWeight={'500'} whiteSpace={'nowrap'} overflow={'hidden'} textOverflow={'ellipsis'}>
                                                                {searchState.searchTerm}
                                                            </Typography>
                                                            <Typography fontWeight={'500'}>&rsquo;</Typography>
                                                            <Typography whiteSpace={'nowrap'}>&nbsp;to unified instead?</Typography>
                                                        </Stack>
                                                    </FieldTitleItem>
                                                </Box>
                                            </>
                                            : <Box component={'li'}>
                                                <FieldTitleItem onClick={enableUnified}>
                                                    <Stack direction={'row'} flexWrap={'nowrap'} width={'100%'}>
                                                        <Typography whiteSpace={'nowrap'}>No results, add&nbsp;</Typography>
                                                        <Typography fontWeight={'500'}>&lsquo;</Typography>
                                                        <Typography fontWeight={'500'} whiteSpace={'nowrap'} overflow={'hidden'} textOverflow={'ellipsis'}>
                                                            {searchState.searchTerm}
                                                        </Typography>
                                                        <Typography fontWeight={'500'}>&rsquo;</Typography>
                                                        <Typography whiteSpace={'nowrap'}>&nbsp;to unified?</Typography>
                                                    </Stack>
                                                </FieldTitleItem>
                                            </Box>
                                        )
                                    }
                                    {searchState.res?.pairData && searchState.res.pairData.length > 0 &&
                                        <>
                                        <ListItem sx={{backgroundColor:(theme)=>alpha(theme.palette.common.black, 0.04), py:0.5, mt:1}}><Typography variant={'caption'} fontWeight={'500'} textTransform={'uppercase'} >Possible Matching fields you can unify</Typography></ListItem>
                                        { 
                                            searchState.res.pairData.map((r) => (
                                                <ListItem key={r.id} sx={{flexDirection:'column', fontFamily:'Poppins',  borderTop:1, borderColor:'rgba(0,0,0,0.1)'}} onClick={()=>createUnifiedPair(r.id, r.title)} >
                                                    <Stack direction={'row'} width={'100%'}>
                                                        <BoldedText text={r.title} shouldBeBold={searchState.searchTerm??''} />
                                                    </Stack>
                                                    {r.description &&
                                                        r.description.map((d,i) => (
                                                            <Stack key={`${i}-description-pair`} width={'100%'} direction={'row'} justifyContent={'space-between'}>
                                                                {d.item && d.item.map((item,i) => (
                                                                    <Typography maxWidth={`${100/(d.item?.length??1)}%`} whiteSpace={'nowrap'} overflow={'hidden'} textOverflow={'ellipsis'} variant={'caption'} key={`${i}-item-pair`}>{item}</Typography>
                                                                ))}
                                                            </Stack>
                                                        ))
                                                    }
                                                </ListItem>
                                            ))}
                                        </>
                                    }
                                </Box>
                            </ClickAwayListener>
                        </Paper>
                    </Fade>
                )}
            </Popper>
        </Box>
        </>
    )
}