import React from 'react'
import { Alert, Box, Button, IconButton, Paper, Stack, styled, Typography } from '@mui/material'
import { motion } from 'framer-motion'
import { FormCtx, UnifiedGroupProps, useFormContext } from '../../../providers/FormContext';
import { FieldModel, UnifiedGroupFieldData } from '../../../types';
import useStore from '../../../providers/EditFormState';
import { HighlightCtx, useHighlightContext, ZPosition } from '../../../providers/HighlightContext';
import { usePdfContext } from '../../../providers/PdfContext';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { ReactSortable } from 'react-sortablejs';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import EastIcon from '@mui/icons-material/East';
import { useInstance } from '../../../utils/axiosConfig';


interface UnifiedGroupSubmission{
    unifiedId:string;
    fieldId:string;
    mappedFieldId:string;
}

export const UnifiedGroupFieldSelector = (
    {unifiedGroup, markUnifiedGroupFields}:
    {unifiedGroup: UnifiedGroupProps, markUnifiedGroupFields: (fields: FieldModel[]) => void}
) => {

    const setTextHighfieldIcon = useStore((state) => state.setShowTextHighlight);
    const {dispatch} = useFormContext()
    const {dispatch:hDispatch} = useHighlightContext()
    const {state:{document:{pages,id},pageNo}} = usePdfContext()
    const pageIndex = pageNo-1
    
    const {currentSelectedField,response:{groupFields}} = unifiedGroup
    const [matchedFields, setMatchedFields] = React.useState<FieldModel[]>([currentSelectedField])
    const [unifiedFields, setUnifiedFields] = React.useState<UnifiedGroupFieldData[]>(groupFields)
    const {instance} = useInstance()
    const completed = matchedFields.length === unifiedFields.length;


    React.useEffect(() => {
      if(unifiedGroup.matchedFields.length>0){
        const result = unifiedGroup.matchedFields.filter(({ id: id1 }) => !matchedFields.some(({ id: id2 }) => id2 === id1));
        const matchedFieldsCombined:FieldModel[] = [...matchedFields, ...result]
        checkFieldCount(matchedFieldsCombined.length)
        setMatchedFields(matchedFieldsCombined)
      }

    }, [unifiedGroup])

    
    const checkFieldCount = (count:number) => {
        if(count === unifiedGroup.response.groupFields.length){
            hDispatch({type:HighlightCtx.HighlightFields, payload:{elements:[]}})
        }
    }

    const disableUnifiedGroup = () => {
        dispatch({type:FormCtx.CreateUnifiedGroup, payload:undefined})
        const pageFields = pages[pageIndex].fields;
        hDispatch({type:HighlightCtx.HighlightFields, payload:{elements:pageFields}})
        useStore.setState({setHighlightFn:(f)=>{
            hDispatch({type: HighlightCtx.SelectedField, payload: {field:f}})
        }});
        setTextHighfieldIcon(true)
    }

    const submitUnifiedGroup = async () => {
        if(unifiedFields.length === matchedFields.length){
            const submission:UnifiedGroupSubmission[] = []
            for (let i = 0; i < unifiedFields.length; i++) {
                const uniField = unifiedFields[i];
                const field = matchedFields[i]
                const item:UnifiedGroupSubmission = {unifiedId:uniField.id, fieldId:field.id, mappedFieldId:field.name}
                submission.push(item)
            }
            await instance.post(`/api/unified/group_fields/${id}`,submission)
            .then(() => {
                markUnifiedGroupFields(matchedFields)
            })
            .catch(err => console.log(err))
        }
    }

    const removeAdded = (fieldId:string) => {
        if(completed){
            const activeFields = unifiedGroup.selectableFields.map((s) => { 
                if(matchedFields.includes(s)){
                    const updated = s
                    updated.active = true
                    return updated
                }
                return s
             })
            hDispatch({type:HighlightCtx.HighlightFields, payload:{elements:activeFields,position:ZPosition.Above}})
        }
        const newFields = matchedFields.filter((m) => m.id !== fieldId)
        setMatchedFields(newFields)
        hDispatch({type:HighlightCtx.RemoveActive, payload:fieldId})
        dispatch({type:FormCtx.RmvFieldUnifiedGroup, payload:fieldId})
    }


    return (
        <motion.div transition={{type:'just'}} initial={{opacity:0,y:'-50%'}} animate={{opacity:1,y:0}} exit={{opacity:0,y:'-50%'}} >
            <Alert severity={'info'} sx={{my:2}}>
                Please select the matching fields on the right, once selected, they will be added to the list, and
                matched with the unified field, you can rearrage either list using the drag handles
            </Alert>
            {!completed &&
                <Stack direction={'row'} justifyContent={'center'} whiteSpace={'nowrap'}>
                    <Typography variant={'caption'}>Please select the corresponding field for</Typography>
                    <Typography overflow={'hidden'} textOverflow={'ellipsis'} variant={'caption'} ml={0.3} fontWeight={'600'} >{unifiedFields[matchedFields.length].title}</Typography>
                </Stack>
            }
            <Typography component={'p'} mb={1.5} textAlign={'center'} variant={'button'}>{`${matchedFields.length} / ${unifiedFields.length} selected`}</Typography>
            <Stack direction={'row'}>
                <Box width={'45%'}>
                    <ReactSortable chosenClass={'chosen'} handle={'.handle'} list={unifiedFields} setList={setUnifiedFields}>
                        {unifiedFields.map(m => 
                            <Paper key={m.id} sx={{mb:1}}>
                                <Stack height={'50px'} px={0.5} direction={'row'} alignItems={'center'}>
                                    <DragIndicatorIcon className={'handle'} sx={{cursor:'grab'}}/>
                                    <Typography whiteSpace={'nowrap'} overflow={'hidden'} textOverflow={'ellipsis'} variant={'caption'} ml={0.5}>{m.title}</Typography>
                                </Stack>
                            </Paper>    
                        )}
                    </ReactSortable>
                </Box>
                <Box width={'10%'}>
                    {Array.from(Array(matchedFields.length).keys()).map((a) => (
                        <Stack key={a} height={'50px'} alignItems={'center'} justifyContent={'center'} mb={1}>
                            <EastIcon/>
                        </Stack>
                    ))}
                </Box>
                <Box width={'45%'}>
                    <ReactSortable handle={'.handle'} list={matchedFields} setList={setMatchedFields}>
                        {matchedFields.map(m => 
                            <Paper key={m.id} sx={{mb:1, position:'relative'}}>
                                <Stack height={'50px'} px={0.5} direction={'row'} alignItems={'center'}>
                                    <DragIndicatorIcon className={'handle'} sx={{cursor:'grab'}} />
                                    <Stack justifyContent={'center'} overflow={'hidden'} >
                                    {currentSelectedField.id === m.id &&
                                        <Typography ml={0.5} lineHeight={1.1} component={'p'} color={'text.secondary'} variant={'caption'}>{"selected field (blue)"}</Typography>
                                    }
                                        <Typography lineHeight={1.1} whiteSpace={'nowrap'} overflow={'hidden'} textOverflow={'ellipsis'} variant={'caption'} ml={0.5}>{m.name}</Typography>
                                    </Stack>
                                    {currentSelectedField.id !== m.id &&
                                        <IconButton onClick={()=>removeAdded(m.id)}><RemoveCircleOutlineIcon/></IconButton>
                                    }
                                </Stack>
                            </Paper>    
                        )}
                    </ReactSortable>
                </Box>
            </Stack>
            <Stack width={'100%'} spacing={2} mt={3}>
                <Button variant={'outlined'} color={'success'} disabled={!completed} onClick={submitUnifiedGroup}>Submit</Button>
                <Button variant={'outlined'} color={'error'} onClick={disableUnifiedGroup}>Cancel</Button>
            </Stack>
        </motion.div>
    )
}
