import { Box } from '@mui/material';
import React from 'react'
import { PdfCtx, usePdfContext } from '../../providers/PdfContext';


interface BoxPosProps{
    x:number;
    y:number;
}

interface CanvasSize{
    w:number;
    h:number;
}

export interface FullBoxProps{
    x:number;
    y:number;
    w:number;
    h:number;
    canvas: CanvasSize|undefined;
}


export const PdfCanvas = ({width,height}:{width:number, height:number}) => {

    const {state:{editPdfMode}, dispatch} = usePdfContext()
    
    const canvas = React.useRef<HTMLCanvasElement>(null);
    const ctx = React.useRef<CanvasRenderingContext2D|null>(null);
    const [isDrawing, setIsDrawing] = React.useState<boolean>(false)
    const [box, setBox] = React.useState<BoxPosProps|undefined>(undefined)
    const [isDown, setIsDown] = React.useState<boolean|null>(null)

    const dragTarget = React.useRef<BoxPosProps|null>(null);
    const startX = React.useRef<number|null>(null);
    const startY = React.useRef<number|null>(null);
    const rectW = React.useRef<number|null>(null)
    const rectH = React.useRef<number|null>(null)

    const boxPosX = React.useRef<number|null>(null)
    const boxPosY = React.useRef<number|null>(null)

    React.useEffect(() => {
        setupCanvas()
        dispatch({type:PdfCtx.AddEditPdfBox, payload:undefined})
        setBox(undefined)
        setIsDown(false)
        rectH.current = null
        rectW.current = null
        dragTarget.current = null
    },[width, height])


    const setupCanvas = () => {
        if(canvas.current){
            const canvasEle = canvas.current;
            canvasEle.width = width
            canvasEle.height = height
            ctx.current = canvasEle.getContext('2d');
            if(ctx.current){
                ctx.current.strokeStyle = 'rgba(0, 104, 160, 0.75)'
                ctx.current.lineWidth = 1
                ctx.current.fillStyle = 'rgba(94, 199, 255, 0.2)'
            }
        }
    }


    const handleSetBox = (value:BoxPosProps|undefined) => {
        setBox(value)
        if(!value){
            dispatch({type:PdfCtx.AddEditPdfBox, payload:value})
        } else if(value && rectW.current && rectH.current && canvas.current && startX.current && startY.current) {
            const newBox:FullBoxProps = {
                x:value.x, 
                y:value.y, 
                w:rectW.current, 
                h:rectH.current, 
                canvas: {w:canvas.current.width, h:canvas.current.height}
            }
            dispatch({type:PdfCtx.AddEditPdfBox, payload:newBox})
        }
    }
  
  
    // draw rectangle with background
    const drawFillRect = (info:BoxPosProps) => {
      if(ctx.current && rectH.current && rectW.current && canvas.current){
        ctx.current.clearRect(0,0,canvas.current.clientWidth,canvas.current.clientHeight);
        const {x, y} = info;
        ctx.current.beginPath();
        ctx.current.strokeRect(x, y, rectW.current, rectH.current)
        ctx.current.fillRect(x, y, rectW.current, rectH.current);
      }
    };
  
    // identify the click event in the rectangle
    const hitBox = (x:number, y:number) => {
      let isTarget = null;
        if (box && rectW.current && rectH.current){
            if (x >= box.x && x <= box.x + rectW.current && y >= box.y && y <= box.y + rectH.current
              ) {
                dragTarget.current = box;
                isTarget = true;
              }
        }
      return isTarget;
    };
  
    const handleMouseDown = (e:React.MouseEvent) => {
        e.preventDefault()
        e.stopPropagation()
        if(canvas.current && box && rectW.current && rectH.current && ctx.current){
            startX.current = e.nativeEvent.offsetX;
            startY.current = e.nativeEvent.offsetY;
            if (
                startX.current >= box.x 
                && startX.current <= box.x + rectW.current 
                && startY.current >= box.y 
                && startY.current <= box.y + rectH.current
            ) {
                setIsDown(hitBox(e.nativeEvent.offsetX, e.nativeEvent.offsetY))
            } else {
                rectH.current = null
                rectW.current = null
                handleSetBox(undefined)
                ctx.current.clearRect(0,0, canvas.current.width, canvas.current.height)
                setIsDown(false)
                dragTarget.current = null
            }
        }
        if(!box){
                startX.current = e.nativeEvent.offsetX;
                startY.current = e.nativeEvent.offsetY;
                setIsDrawing(true)
            }
    };
    const handleMouseMove = (e:React.MouseEvent) => {

        if(!isDrawing && !box){
            return
        }

        if(box && !isDown){
            return
        }
        e.preventDefault()
        e.stopPropagation()
    
        if(box&&isDown&&canvas.current && dragTarget.current && startX.current && startY.current && ctx.current){
            const mouseX = e.nativeEvent.offsetX - canvas.current.clientLeft;
            const mouseY = e.nativeEvent.offsetY - canvas.current.clientTop;
            const dx = mouseX - startX.current;
            const dy = mouseY - startY.current;
            boxPosX.current = mouseX - box.x
            boxPosY.current = mouseY - box.y
            startX.current = mouseX;
            startY.current = mouseY;
            dragTarget.current.x += dx;
            dragTarget.current.y += dy;
            drawFillRect(box)
        } else if(!isDown && startX.current && startY.current && canvas.current && ctx.current) {
            const newMouseX = e.nativeEvent.offsetX
            const newMouseY = e.nativeEvent.offsetY
            rectW.current = newMouseX - startX.current
            rectH.current = newMouseY - startY.current
            drawFillRect({x:startX.current, y:startY.current})

        }
    };
    const handleMouseUp = (e:React.MouseEvent) => {
        dragTarget.current = null;
        if(isDrawing || isDown){
            if(isDown && startX.current && startY.current && boxPosX.current && boxPosY.current && rectW.current && rectH.current ){
                handleSetBox({x:startX.current - boxPosX.current, y: startY.current - boxPosY.current})
                boxPosX.current = null
                boxPosY.current = null
            }else if(!isDown && startX.current && startY.current && rectH.current && rectW.current){
                handleSetBox({x:startX.current, y: startY.current})
            }
        }
        setIsDrawing(false)
        setIsDown(false);
    };

        return (
        <Box position={'absolute'} top={0} left={0} width={'100%'} height={'100%'}>
            <svg style={{position:'absolute', zIndex:0}} width="100%" height="100%" xmlns="http://www.w3.org/2000/svg">
                <defs>
                    <pattern id="smallGrid" width="10" height="10" patternUnits="userSpaceOnUse">
                    <path d="M 10 0 L 0 0 0 10" fill="none" stroke="rgba(0,0,0,0.2)" strokeWidth="0.5"/>
                    </pattern>
                    <pattern id="grid" width="100" height="100" patternUnits="userSpaceOnUse">
                    <rect width="100" height="100" fill="url(#smallGrid)"/>
                    <path d="M 100 0 L 0 0 0 100" fill="none" stroke="rgba(0,0,0,0.1)" strokeWidth="1"/>
                    </pattern>
                </defs>
                    
                <rect width="100%" height="100%" fill="url(#grid)" />
            </svg>
            <canvas
                style={{zIndex:1, position:'relative'}} 
                ref={canvas}
                onMouseDown={handleMouseDown}
                onMouseMove={handleMouseMove}
                onMouseUp={handleMouseUp}
                onMouseLeave={handleMouseUp}>    
            </canvas>
        </Box>
    )
}
