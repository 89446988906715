import LoadingButton from '@mui/lab/LoadingButton';
import { Backdrop, Box, Button, DialogContent, DialogContentText, FormControl, IconButton, InputAdornment, OutlinedInput, Stack, Typography } from '@mui/material';
import React, { ChangeEvent } from 'react'
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useModalContext } from '../../providers/ModalContext';
import { DocumentModel } from '../../types';
import UploadFileIcon from "@mui/icons-material/UploadFile";
import { LoadingAnimation } from '../shared/LoadingAnimation';
import { DashboardCtx, useDashboardContext } from '../../providers/DashboardContext';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import MergeTypeIcon from '@mui/icons-material/MergeType';
import PublishIcon from '@mui/icons-material/Publish';
import { useInstance } from '../../utils/axiosConfig';

export const Header = () => {
    
    const navigate = useNavigate();
    const modal = useModalContext();
    const [modalLoading, setModalLoading] = React.useState<boolean>(false)
    const [loading, setLoading] = React.useState<boolean>(false);
    const { state:{disabled}, dispatch } = useDashboardContext()
    const [searchTerm, setSearchTerm] = React.useState<string>('')
    const {instance} = useInstance()

    const handleCloseLoading = () => {
        setLoading(false);
    };

    const handleFileUpload = (e: ChangeEvent<HTMLInputElement>) => {
        setLoading(true);
        if (!e.target.files) {
            return;
        }
        const file = e.target.files[0];

        if(file){
            const formData = new FormData();
            formData.append("file", file, file.name);
            instance.post(`/api/pdf/check_name`,{"name":file.name})
            .then((res) => {
                const isAvailable:boolean = res.data
                if (isAvailable){
                    submitFile(file,formData)
                } else {
                    setLoading(false)
                    modal.show({
                        title:'Duplicate PDF?',
                        text: modalContent(),
                        actions: modalAction(file, formData)
                    })
                }
            })
            .catch(err => {
                setLoading(false);
                console.log(err);
            });
        }
    }

    const modalContent = () => (
        <DialogContent>
            <DialogContentText id="alert-dialog-description">
                A pdf with this name already exists, is this a duplcate PDF? You can still upload this pdf, but it will be given a random name
            </DialogContentText>
        </DialogContent>
    )

    const modalAction = (file:File, formData:FormData):JSX.Element => (
        <Stack justifyContent={'space-between'} width={'100%'} direction={'row'} mb={1}>
            <LoadingButton loading={modalLoading} color={'success'} onClick={() => submitFile(file,formData)}>
                Submit Anyway
            </LoadingButton> 
            <Button color={'error'} onClick={cancelFile}>Cancel</Button>
        </Stack>
    )

    const cancelFile = () => {
        setModalLoading(false)
        setLoading(false)
        modal.hide()
    }

    const submitFile = (file:File, formData:FormData) => {
        setModalLoading(true)
        instance.post(`/api/pdf/upload`, formData)
            .then((res) => 
            {
                setLoading(false);
                setModalLoading(false)
                modal.hide()
                const doc:DocumentModel = res.data;
                dispatch({type:DashboardCtx.Initial})
                navigate(`/edit/${doc.id}`, {state: {file: file, res: doc}});
            })
            .catch(err => {
                setLoading(false);
                setModalLoading(false)
                console.log(err);
            });
    }

    const handleSearch = () => {
        if(searchTerm !== ''){
            navigate(`/dashboard/search/${searchTerm}`)
        }
    }

    const clearSearch = () => {
        if(searchTerm !== ''){
            setSearchTerm('')
        }
    }

    const handleKey = (e:React.KeyboardEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        if(e.key === 'Enter'){
            handleSearch()
         }
    }
      

    return (
        <>
        <Stack direction="row" justifyContent="space-between" alignItems="center" mt={2} >
            <Stack flex={1} alignItems={'center'} direction={'row'} >
                <Stack direction={'row'} width={'60%'} minWidth={'200px'} spacing={0.5}>
                    <FormControl variant={'outlined'}>
                        <OutlinedInput onKeyDown={handleKey} size={'small'} value={searchTerm} placeholder={'Search'} onChange={(e) => setSearchTerm(e.target.value)} sx={{
                            '& legend': { display: 'none' },
                            '& fieldset': { top: 0 },
                            pl:0.5,
                            pr:0.5
                        }}
                        startAdornment={ <InputAdornment position={'start'}><IconButton onClick={handleSearch} disabled={searchTerm===''} ><SearchIcon color={searchTerm!=='' ? 'primary':'disabled'}/></IconButton> </InputAdornment> } 
                        endAdornment={ searchTerm !== '' ? <InputAdornment position={'end'}><IconButton onClick={clearSearch}><ClearIcon/></IconButton></InputAdornment> : null } />
                    </FormControl>
                </Stack>
            </Stack>
            <Typography variant='h4' my={3} fontWeight="bold" textAlign="center">PDF Library</Typography>
            <Box flex={1} display="flex" justifyContent="flex-end">
                <LoadingButton component={RouterLink} to={'/unified'} key={'Unified'} variant={disabled ? 'contained' : 'outlined'} loadingPosition={'start'} startIcon={disabled ? <PublishIcon/> : <MergeTypeIcon/>} loading={disabled} sx={{mr:1.5, textTransform:'none'}}>{disabled ? 'In Progress' : 'Unified'}</LoadingButton>
                <Button
                    component="label"
                    variant="outlined"
                    startIcon={<UploadFileIcon />}
                    sx={{ textTransform: "none" }}
                >
                    Add PDF
                    <input type="file" accept=".pdf" hidden onChange={handleFileUpload} />
                </Button>
            </Box>
        </Stack>
        {loading &&
            <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={loading}
            onClick={handleCloseLoading}
        >
            <LoadingAnimation />
        </Backdrop>
        }
        </>
    )
}
