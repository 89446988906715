import React from "react";
import { AppThemeProvider } from "./AppTheme";
import { NotifyProvider } from "./NotifyContext";
import { ProviderRenderer } from "../utils/ProviderRenderer";
import { CssBaseline } from "@mui/material";
import ModalContextProvider from "./ModalContext";
import { HighlightContextProvider } from "./HighlightContext";
import { PdfNotifyProvider } from "./PdfNotifyContext";
import ContextContextProvider from "./ContextContext";
import { AuthContextProvider } from "./AuthContext";

export * from "./AppTheme";
export * from "./NotifyContext";

export function RootProviders({ children }:{children:React.ReactNode}) {
    const providers = [AuthContextProvider, AppThemeProvider, NotifyProvider, ModalContextProvider, ContextContextProvider];

    return (
        <>
        <CssBaseline />
        <ProviderRenderer index={0} list={providers}>
            {children}
        </ProviderRenderer>
        </>
    );
}

export function PdfProviders({children}:{children:React.ReactNode}){
    const providers = [HighlightContextProvider, PdfNotifyProvider];

    return (
        <ProviderRenderer index={0} list={providers}>
            {children}
        </ProviderRenderer>
    )
}