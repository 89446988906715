import { Box, alpha, styled, useTheme } from '@mui/material'
import React, { useMemo, useRef } from 'react'
import { ConnectFieldsOpts } from '../../providers/HighlightContext'
import Xarrow from "react-xarrows"
import { FieldModel, FieldType } from '../../types'
import zIndex from '@mui/material/styles/zIndex'


interface PosLayout {
    bottomMiddle:MarkerFieldModel[];
    rightSide:MarkerFieldModel[];
    leftSide:MarkerFieldModel[];
    topMiddle:MarkerFieldModel[];
}


interface MarkerFieldModel extends FieldModel{
    addMarker?:number[]|undefined;
}


export const ConnectionPoints = ({connections}:{connections:ConnectFieldsOpts}) => {
    const startField = useRef(null)
    const originCenterPoint = useMemo(() => getCenterPoint(connections.parentField), [])
    const quad = useMemo(() => setQuadrants(connections.connectedFields, originCenterPoint), [connections.connectedFields])
    const theme = useTheme()


    function getCenterPoint(field:FieldModel):number[]{
        // center point calculation
        const centerPointX =  (field.positionPercentage[0] + (field.sizePercentage[0]/2))
        const centerPointY = (field.positionPercentage[1] + (field.sizePercentage[1]/2))
        return [centerPointX, centerPointY]
    }

    function setQuadrants(fields:FieldModel[], originPoint:number[]):PosLayout{
        // another thing to check for is if the rectangle crosses the origin point at all, 
        // if it does then we have to change the postition of the anchor point
        const rightSide:MarkerFieldModel[] = []
        const bottomMiddle:MarkerFieldModel[] = []
        const leftSide:MarkerFieldModel[] = []
        const topMiddle:MarkerFieldModel[] =[]

        fields.map((f)=>{
            const fieldCenter = getCenterPoint(f)

            const originX = originPoint[0]
            const originY = originPoint[1]
            const fieldXRight = f.positionPercentage[0] + f.sizePercentage[0]
            const fieldXLeft = f.positionPercentage[0]
            const fieldXCenter = fieldCenter[0]
            const fieldYCenter = fieldCenter[1]

            const compareXRight = originX - fieldXRight
            const compareXCenter = originX - fieldXCenter
            const compareXCenterBottom = fieldXCenter - originX
            const compareXLeft = fieldXLeft - originX

            const compareY = originY - fieldYCenter

            // is this field left of the center point
            // smaller therefore on the negative x axis (left of the origin point)
            if(fieldCenter[0] <= originPoint[0]){
                // if y axis of field is smaller than center point, its in minus y axis
                // smaller therefore bottom part of y axis
                if(fieldCenter[1] <= originPoint[1]){
                    if(compareXRight<compareXCenter){
                        if(compareXRight<10){
                            if(compareY<3 && compareY>-3){
                                // add marker to add an aditional component to help space it out
                                const markerPosX = (originX +fieldXCenter)/2
                                const markerPosY = originY - 3
                                const markerField:MarkerFieldModel = {...f, addMarker:[markerPosX, markerPosY]} 
                                topMiddle.push(markerField)
                            } else {
                                topMiddle.push(f)
                            }
                        }else {
                            rightSide.push(f)
                        }
                    } else {
                        topMiddle.push(f)
                    }
                // else top part of y axis 
                } else {
                    if(compareXRight<compareXCenter){
                        if(compareXRight<10){
                            if(compareY<3 && compareY>-3){
                                // add marker to add an aditional component to help space it out
                                const markerPosX = (originX +fieldXCenter)/2
                                const markerPosY = originY - 3
                                const markerField:MarkerFieldModel = {...f, addMarker:[markerPosX, markerPosY]} 
                                bottomMiddle.push(markerField)
                            } else {
                                bottomMiddle.push(f)
                            }
                        }else {
                            rightSide.push(f)
                        }
                    } else {
                        bottomMiddle.push(f)
                    }
                }
            // positive part (right) of x axis
            } else {
                // negative part of y axis
                if(fieldCenter[1] <= originPoint[1]){
                    if(compareXLeft<compareXCenterBottom){
                        if(compareXLeft<10){
                            if(compareY<3 && compareY>-3){
                                // add marker to add an aditional component to help space it out
                                const markerPosX = (originX +fieldXCenter)/2
                                const markerPosY = originY - 3
                                const markerField:MarkerFieldModel = {...f, addMarker:[markerPosX, markerPosY]} 
                                topMiddle.push(markerField)
                            } else {
                                topMiddle.push(f)
                            }
                        } else {
                            leftSide.push(f)
                        }
                    }
                // positive part of y axis
                } else {
                    // if the x axis of the field is closer to the side, then we will add the arrow
                    // to the side, if its closer to the center, we add the arrow to the center
                    if(compareXLeft<compareXCenterBottom){
                        // if however the left side is too close, the arrow is not really visible
                        // in that case, we will put the arrow at the bottom
                        if(compareXLeft<10){
                            if(compareY<3 && compareY>-3){
                                // add marker to add an aditional component to help space it out
                                const markerPosX = (originX +fieldXCenter)/2
                                const markerPosY = originY - 3
                                const markerField:MarkerFieldModel = {...f, addMarker:[markerPosX, markerPosY]} 
                                bottomMiddle.push(markerField)
                            } else {
                                bottomMiddle.push(f)
                            }
                        } else {
                            leftSide.push(f)
                        }
                    } else {
                        bottomMiddle.push(f)
                    }

                }
            }
        })
        const pos:PosLayout={bottomMiddle:bottomMiddle, leftSide:leftSide, rightSide:rightSide, topMiddle:topMiddle}
        return pos
    }



    const TextBox = styled('span')(({theme}) => ({
        fontFamily: 'Poppins',
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: '#000',
        fontWeight: 'bold',
        fontSize: '8px',
        cursor: 'pointer',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        backgroundColor:alpha(theme.palette.success.light,0.5),
        position: 'relative',
        zIndex: 3,
    }))

  return (
    <>
        {connections.addBackdrop &&
            <Box position={'absolute'} width={'100%'} height={'100%'} top={0} left={0} style={{backgroundColor:"rgba(255,255,255,0.7)"}} />
        }
        <Box ref={startField} style={
            {   
                backgroundColor:'purple',
                position: 'absolute',
                left: `${connections.parentField.positionPercentage[0]}%`, 
                bottom: `${connections.parentField.positionPercentage[1]}%`,
                width: `${connections.parentField.sizePercentage[0]}%`,
                height: `${connections.parentField.sizePercentage[1]}%`
            }
        }  />
        {
            quad.bottomMiddle.map((i,ind) => (
                i.addMarker
                ? <Box key={i.id}>
                    <Box id={`${i.id}_invisible`} position={'absolute'} style={{left:`${i.addMarker[0]}%`, bottom:`${i.addMarker[1]}%`}} />
                    <Box id={i.id} style={
                        {
                            position: 'absolute',
                            left: `${i.positionPercentage[0]}%`, 
                            bottom: `${i.positionPercentage[1]}%`,
                            width: `${i.sizePercentage[0]}%`,
                            height: `${i.sizePercentage[1]}%`
                        }
                    } >
                        <TextBox>{i.name}</TextBox>
                    </Box>
                    <Xarrow
                        zIndex={5}
                        color={theme.palette.success.main}
                        headSize={3}
                        strokeWidth={3}
                        start={startField}
                        end={`${i.id}_invisible`}
                        showHead={false}
                        startAnchor={'bottom'}
                    />
                    <Xarrow
                        zIndex={5}
                        color={theme.palette.success.main}
                        headSize={3}
                        strokeWidth={3}
                        start={`${i.id}_invisible`}
                        end={i.id}
                        endAnchor={[ "bottom"]}
                    />
                </Box>
                : <Box key={i.id}>
                    <Box id={i.id} style={
                        {
                            position: 'absolute',
                            left: `${i.positionPercentage[0]}%`, 
                            bottom: `${i.positionPercentage[1]}%`,
                            width: `${i.sizePercentage[0]}%`,
                            height: `${i.sizePercentage[1]}%`
                        }
                    }>
                        <TextBox>{i.name}</TextBox>
                    </Box>
                    <Xarrow
                        zIndex={5}
                        headSize={3}
                        color={theme.palette.success.main}
                        strokeWidth={3}
                        start={startField}
                        end={i.id}
                        endAnchor={{position:"bottom",offset:{x:-(quad.bottomMiddle.length*5)+(ind*5)}}}
                        animateDrawing
                    />
                </Box>
            ))
            
        }
        {
            quad.rightSide.map((i) => (
                <Box key={i.id}>
                    <Box id={i.id} style={
                        {
                            position: 'absolute',
                            left: `${i.positionPercentage[0]}%`, 
                            bottom: `${i.positionPercentage[1]}%`,
                            width: `${i.sizePercentage[0]}%`,
                            height: `${i.sizePercentage[1]}%`
                        }
                    }>
                        <TextBox>{i.name}</TextBox>
                    </Box>
                    <Xarrow
                        zIndex={5}
                        headSize={3}
                        color={theme.palette.success.main}
                        strokeWidth={3}
                        start={startField}
                        end={i.id}
                        endAnchor={["right"]}
                        animateDrawing
                    />
                </Box>
            ))
        }
        {
            quad.leftSide.map((i)=>(
                <Box key={i.id}>
                    <Box id={i.id} style={
                        {
                            position: 'absolute',
                            left: `${i.positionPercentage[0]}%`, 
                            bottom: `${i.positionPercentage[1]}%`,
                            width: `${i.sizePercentage[0]}%`,
                            height: `${i.sizePercentage[1]}%`
                        }
                    }>
                        <TextBox>{i.name}</TextBox>
                    </Box>
                    <Xarrow
                        zIndex={5}
                        headSize={3}
                        color={theme.palette.success.main}
                        strokeWidth={3}
                        start={startField}
                        end={i.id}
                        endAnchor={["left"]}
                        animateDrawing
                    />
                </Box>
            ))
        }
        {
            quad.topMiddle.map((i,ind) => (
                i.addMarker
                ? <Box key={i.id}>
                    <Box id={`${i.id}_invisible`} position={'absolute'} style={{left:`${i.addMarker[0]}%`, bottom:`${i.addMarker[1]}%`}} />
                    <Box id={i.id} style={
                        {
                            position: 'absolute',
                            left: `${i.positionPercentage[0]}%`, 
                            bottom: `${i.positionPercentage[1]}%`,
                            width: `${i.sizePercentage[0]}%`,
                            height: `${i.sizePercentage[1]}%`
                        }
                    } >
                        <TextBox>{i.name}</TextBox>
                    </Box>
                    <Xarrow
                        zIndex={5}
                        headSize={3}
                        color={theme.palette.success.main}
                        strokeWidth={3}
                        start={startField}
                        end={`${i.id}_invisible`}
                        showHead={false}
                        startAnchor={'bottom'}
                    />
                    <Xarrow
                        zIndex={5}
                        headSize={3}
                        color={theme.palette.success.main}
                        strokeWidth={3}
                        start={`${i.id}_invisible`}
                        end={i.id}
                        endAnchor={[ "bottom"]}
                    />
                </Box>
                : <Box key={i.id}>
                    <Box id={i.id} style={
                        {
                            position: 'absolute',
                            left: `${i.positionPercentage[0]}%`, 
                            bottom: `${i.positionPercentage[1]}%`,
                            width: `${i.sizePercentage[0]}%`,
                            height: `${i.sizePercentage[1]}%`
                        }
                    }>
                        <TextBox>{i.name}</TextBox>
                    </Box>
                    <Xarrow
                        zIndex={5}
                        headSize={3}
                        color={theme.palette.success.main}
                        strokeWidth={3}
                        start={startField}
                        end={i.id}
                        endAnchor={{position:"top",offset:{x:-(quad.topMiddle.length*5)+(ind*5)}}}
                        animateDrawing
                    />
                </Box>
            ))
        }
    </>
  )
}





    // const initial:FieldModel = {
    //     id: "a8e4a5f7-be6c-4aa9-aa95-9db293fb3d32",
    //     type:FieldType.Button,
    //     name: "Check Box1.0.1.0.1.1.0.1.1.0.0",
    //     page: 1,
    //     position: [
    //         325.479,
    //         392.1817,
    //         335.479,
    //         402.1817
    //     ],
    //     positionPercentage: [
    //         53.141,
    //         49.488
    //     ],
    //     sizePercentage: [
    //         1.633,
    //         1.262
    //     ],
    //     isGroup: null,
    //     groupFieldId: null,
    //     groupPage: null,
    //     options: null,
    //     completed: null
    // }

    // const end:FieldModel[] = [
    //     {
    //         "id": "628900c1-65e4-43a0-9a97-f0f4d46df044",
    //         "type": FieldType.Text,
    //         "name": "Company Name",
    //         "page": 1,
    //         "position": [
    //             37.379791,
    //             680.292908,
    //             319.752258,
    //             694.405823
    //         ],
    //         "positionPercentage": [
    //             6.103,
    //             85.844
    //         ],
    //         "sizePercentage": [
    //             46.103,
    //             1.781
    //         ],
    //         "isGroup": null,
    //         "groupFieldId": null,
    //         "groupPage": null,
    //         "options": null,
    //         "completed": true
    //     },
    //     {
    //         "id": "703904fa-84ab-40d8-adab-12eb2ba1b834",
    //         "type": FieldType.Text,
    //         "name": "Street Address",
    //         "page": 1,
    //         "position": [
    //             38.065659,
    //             656.374634,
    //             320.095215,
    //             671.773376
    //         ],
    //         "positionPercentage": [
    //             6.215,
    //             82.825
    //         ],
    //         "sizePercentage": [
    //             46.047,
    //             1.943
    //         ],
    //         "isGroup": null,
    //         "groupFieldId": null,
    //         "groupPage": null,
    //         "options": null,
    //         "completed": true
    //     },
    //     {
    //         "id": "14abf03a-9af4-4422-9bf5-4aea22154b0e",
    //         "type": FieldType.Text,
    //         "name": "Other Full Addresses",
    //         "page": 1,
    //         "position": [
    //             321.094116,
    //             603.642029,
    //             583.649536,
    //             620.785034
    //         ],
    //         "positionPercentage": [
    //             52.425,
    //             76.171
    //         ],
    //         "sizePercentage": [
    //             42.868,
    //             2.163
    //         ],
    //         "isGroup": null,
    //         "groupFieldId": null,
    //         "groupPage": null,
    //         "options": null,
    //         "completed": true
    //     },
    //     {
    //         "id": "ac52b369-4218-47a3-a0fb-a409327900f4",
    //         "type": FieldType.Text,
    //         "name": "Related Controlled",
    //         "page": 1,
    //         "position": [
    //             322.094452,
    //             576.929871,
    //             583.078125,
    //             593.645203
    //         ],
    //         "positionPercentage": [
    //             52.589,
    //             72.801
    //         ],
    //         "sizePercentage": [
    //             42.611,
    //             2.109
    //         ],
    //         "isGroup": null,
    //         "groupFieldId": null,
    //         "groupPage": null,
    //         "options": null,
    //         "completed": true
    //     },
    //     {
    //         "id": "b5e09b8f-aa3a-4605-a789-91d835583ac4",
    //         "type": FieldType.Text,
    //         "name": "BOR",
    //         "page": 1,
    //         "position": [
    //             38.700188,
    //             391.666565,
    //             178.892517,
    //             403.463593
    //         ],
    //         "positionPercentage": [
    //             6.319,
    //             49.423
    //         ],
    //         "sizePercentage": [
    //             22.889,
    //             1.489
    //         ],
    //         "isGroup": null,
    //         "groupFieldId": null,
    //         "groupPage": null,
    //         "options": null,
    //         "completed": true
    //     },
    //     {
    //         "id": "bf7314d4-3bc9-404e-aa89-9887de0c2a26",
    //         "type": FieldType.Text,
    //         "name": "Comp",
    //         "page": 1,
    //         "position": [
    //             181.687164,
    //             391.209076,
    //             318.546265,
    //             403.239319
    //         ],
    //         "positionPercentage": [
    //             29.664,
    //             49.365
    //         ],
    //         "sizePercentage": [
    //             22.345,
    //             1.518
    //         ],
    //         "isGroup": null,
    //         "groupFieldId": null,
    //         "groupPage": null,
    //         "options": null,
    //         "completed": null
    //     },
    //     {
    //         "id": "c0b17b55-519e-48e9-9ca0-b7c754484051",
    //         "type": FieldType.Text,
    //         "name": "Check Box1.0.1.0.1.1.0.1.0.0.0",
    //         "page": 1,
    //         "position": [
    //             361.2744,
    //             392.1817,
    //             371.2744,
    //             402.1817
    //         ],
    //         "positionPercentage": [
    //             58.986,
    //             49.488
    //         ],
    //         "sizePercentage": [
    //             1.633,
    //             1.262
    //         ],
    //         "isGroup": null,
    //         "groupFieldId": null,
    //         "groupPage": null,
    //         "options": null,
    //         "completed": null
    //     },
    //     {
    //         "id": "391e84ed-13ac-4d02-a166-54179e199fda",
    //         "type": FieldType.Text,
    //         "name": "Revenue",
    //         "page": 1,
    //         "position": [
    //             456.956726,
    //             391.666565,
    //             583.649536,
    //             404.52475
    //         ],
    //         "positionPercentage": [
    //             74.608,
    //             49.423
    //         ],
    //         "sizePercentage": [
    //             20.685,
    //             1.623
    //         ],
    //         "isGroup": null,
    //         "groupFieldId": null,
    //         "groupPage": null,
    //         "options": null,
    //         "completed": null
    //     },
    //     {
    //         "id": "36060fc7-3d01-494c-a6a6-a9465e656959",
    //         "type": FieldType.Text,
    //         "name": "Email3",
    //         "page": 1,
    //         "position": [
    //             320.697754,
    //             428.444061,
    //             447.331635,
    //             442.235168
    //         ],
    //         "positionPercentage": [
    //             52.361,
    //             54.064
    //         ],
    //         "sizePercentage": [
    //             20.676,
    //             1.74
    //         ],
    //         "isGroup": null,
    //         "groupFieldId": null,
    //         "groupPage": null,
    //         "options": null,
    //         "completed": null
    //     },
    //     {
    //         "id": "0675f119-819a-41bd-9d43-f74a95fe428a",
    //         "type": FieldType.Text,
    //         "name": "Email4",
    //         "page": 1,
    //         "position": [
    //             319.975311,
    //             414.241547,
    //             447.367554,
    //             428.16095
    //         ],
    //         "positionPercentage": [
    //             52.243,
    //             52.272
    //         ],
    //         "sizePercentage": [
    //             20.799,
    //             1.756
    //         ],
    //         "isGroup": null,
    //         "groupFieldId": null,
    //         "groupPage": null,
    //         "options": null,
    //         "completed": null
    //     },
    //     {
    //         "id": "f9b07a10-09db-4cf3-aeff-c95deb088142",
    //         "type": FieldType.Text,
    //         "name": "Class2 def",
    //         "page": 1,
    //         "position": [
    //             320.779785,
    //             299.059021,
    //             447.098511,
    //             317.677612
    //         ],
    //         "positionPercentage": [
    //             52.374,
    //             37.737
    //         ],
    //         "sizePercentage": [
    //             20.624,
    //             2.349
    //         ],
    //         "isGroup": null,
    //         "groupFieldId": null,
    //         "groupPage": null,
    //         "options": null,
    //         "completed": true
    //     },
    //     {
    //         "id": "93c2bdaf-09a2-49ff-91ef-6825c1ceb73c",
    //         "type": FieldType.Text,
    //         "name": "Class2 hrwk",
    //         "page": 1,
    //         "position": [
    //             321.09491,
    //             282.093231,
    //             447.295715,
    //             299.685394
    //         ],
    //         "positionPercentage": [
    //             52.425,
    //             35.596
    //         ],
    //         "sizePercentage": [
    //             20.605,
    //             2.22
    //         ],
    //         "isGroup": null,
    //         "groupFieldId": null,
    //         "groupPage": null,
    //         "options": null,
    //         "completed": null
    //     },
    //     {
    //         "id": "222f6457-2809-4d77-a5d2-aae6066373b5",
    //         "type": FieldType.Text,
    //         "name": "Class1 def",
    //         "page": 1,
    //         "position": [
    //             181.266968,
    //             299.105804,
    //             321.402985,
    //             317.677643
    //         ],
    //         "positionPercentage": [
    //             29.596,
    //             37.743
    //         ],
    //         "sizePercentage": [
    //             22.88,
    //             2.344
    //         ],
    //         "isGroup": null,
    //         "groupFieldId": null,
    //         "groupPage": null,
    //         "options": null,
    //         "completed": true
    //     },
    //     {
    //         "id": "b1b085af-4328-42f3-a6d6-844637a947fd",
    //         "type": FieldType.Text,
    //         "name": "Plan Name",
    //         "page": 1,
    //         "position": [
    //             92.557594,
    //             139.207382,
    //             323.781036,
    //             152.839722
    //         ],
    //         "positionPercentage": [
    //             15.112,
    //             17.566
    //         ],
    //         "sizePercentage": [
    //             37.752,
    //             1.72
    //         ],
    //         "isGroup": null,
    //         "groupFieldId": null,
    //         "groupPage": null,
    //         "options": null,
    //         "completed": null
    //     },
    //     {
    //         "id": "a9088c2b-7798-48a6-a212-e77aa080fb51",
    //         "type": FieldType.List,
    //         "name": "Dropdown1.2",
    //         "page": 1,
    //         "position": [
    //             474.4391,
    //             77.89871,
    //             583.9042,
    //             91.52479
    //         ],
    //         "positionPercentage": [
    //             77.462,
    //             9.83
    //         ],
    //         "sizePercentage": [
    //             17.872,
    //             1.719
    //         ],
    //         "isGroup": null,
    //         "groupFieldId": null,
    //         "groupPage": null,
    //         "options": [
    //             "Yes",
    //             "No"
    //         ],
    //         "completed": null
    //     },
    //     {
    //         "id": "74e97cf7-1fba-4637-8be0-eb7158e4cb69",
    //         "type": FieldType.Text,
    //         "name": "Class3 def",
    //         "page": 1,
    //         "position": [
    //             447.641022,
    //             300.318665,
    //             583.078125,
    //             318.248993
    //         ],
    //         "positionPercentage": [
    //             73.087,
    //             37.896
    //         ],
    //         "sizePercentage": [
    //             22.113,
    //             2.263
    //         ],
    //         "isGroup": null,
    //         "groupFieldId": null,
    //         "groupPage": null,
    //         "options": null,
    //         "completed": true
    //     }
    // ]