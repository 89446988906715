import { Box, Dialog, IconButton, Stack, styled, Tooltip } from '@mui/material'
import React from 'react'
import { AutocompleteElement, FieldValues, UseFormReturn } from 'react-hook-form-mui'
import { SectionListItem } from '../form-control-types'
import { UnifiedSectionModal } from './UnifiedSectionModal'
import { UnifiedSubsectionModal } from './UnifiedSubsectionModal'
import SubdirectoryArrowLeftIcon from '@mui/icons-material/SubdirectoryArrowLeft';
import AddIcon from '@mui/icons-material/Add';
import { useInstance } from '../../../utils/axiosConfig'

export const UnifiedSectionField = ({formContext}:{formContext: UseFormReturn<FieldValues, any>}) => {

    const [open, setOpen] = React.useState<boolean>(false)
    const [openSub, setOpenSub] = React.useState<boolean>(false)
    const [modalOpen, setModalOpen] = React.useState<boolean>(false)
    const [modalSubOpen, setModalSubOpen] = React.useState<boolean>(false)
    const [sectionList, setSectionList] = React.useState<SectionListItem[]|undefined>(undefined);
    const [subsectionList, setSubsectionList] = React.useState<SectionListItem[]|undefined>(undefined);
    const {instance} = useInstance()
    const {setValue, watch, getValues} = formContext
    const subLoading = openSub && !subsectionList;
    const loading = open && !sectionList;
    const watchSection = watch('section')

    React.useEffect(() => {
        let active = true;
    
        if (!loading) {
          return undefined;
        }
    
        (async () => {
          if (active) {
            setTimeout(() => {
                instance.get(`/api/unified/sections`)
                .then((res) => {
                    // console.log(res.data)
                    setSectionList(res.data);
                })
                .catch((err) => {
                    console.log(err)
                })
            }, 500);
          }
        })();
    
        return () => {
          active = false;
        };
      }, [loading]);
    
    React.useEffect(() => {
    if (!open) {
        setSectionList(undefined);
    }
    }, [open]);

    React.useEffect(() => {
        const section:SectionListItem|undefined = getValues('section')
        if(section){
            let active = true;
        
            if (!subLoading) {
              return undefined;
            }
        
            (async () => {
              if (active) {
                setTimeout(() => {
                    instance.get(`/api/unified/subsections/${section.id}`)
                    .then((res) => {
                        // console.log(res.data)
                        setSubsectionList(res.data);
                    })
                    .catch((err) => {
                        console.log(err)
                    })
                }, 500);
              }
            })();
        
            return () => {
              active = false;
            };
        }
    }, [subLoading]);
    
    React.useEffect(() => {
    if (!openSub) {
        setSubsectionList(undefined);
    }
    }, [openSub]);

    const handleClose = () => {
        setModalOpen(false)
    }

    const handleOpen = () => {
        setModalOpen(true)
    }

    const handleSubClose = () => {
        setModalSubOpen(false)
    }

    const handleSubOpen = () => {
        setOpenSub(false)
        setModalSubOpen(true)
    }

    const addSection = (item:SectionListItem) => {
        setValue('section',item)
        setValue('subsection','')
    }

    const addSubsection = (item:SectionListItem) => {
        setValue('subsection',item)
    }

    const SectionDialog = styled(Dialog)(({ theme }) => ({
        '.MuiDialog-paper': {
            width: '450px'
        }
    }));


    return (
        <>
        <Box>
        <Stack direction={'row'} alignItems={'flex-start'}>
            <AutocompleteElement
                required
                loading={loading}
                autocompleteProps={{
                    size:'small', 
                    fullWidth:true, 
                    open:open,
                    onOpen:()=>{setOpen(true)},
                    onClose:()=>{setOpen(false)},
                    onChange() {
                        setValue('subsection','')
                    },
                }}
                name={'section'}
                label={'Add to Unified Section'}
                options={sectionList??[]}/>
            <Tooltip title={'Click to add a new Section'}>
                <IconButton sx={{ml:1}} onClick={handleOpen}>
                    <AddIcon/>
                </IconButton>
            </Tooltip>
        </Stack>
        { watchSection && 
            <Stack direction={'row'} mt={2}>
                <SubdirectoryArrowLeftIcon sx={{transform:'scaleX(-1)', mt:0.75, ml:1, mr: 0.25}} />
                <AutocompleteElement
                    loading={subLoading}
                    autocompleteProps={{
                        size:'small',
                        fullWidth:true,
                        open:openSub,
                        onOpen:()=>{setOpenSub(true)},
                        onClose:()=>{setOpenSub(false)}
                    }}
                    name={'subsection'}
                    label={'Add to Sub-Section'}
                    options={subsectionList??[]}
                />
                <Tooltip title={'Click to add a new Sub-Section'}>
                    <IconButton sx={{ml:1}} onClick={handleSubOpen}>
                        <AddIcon/>
                    </IconButton>
                </Tooltip>
            </Stack>
        }
        </Box>
        <SectionDialog onClose={handleClose} open={modalOpen}>
            <UnifiedSectionModal addSection={addSection} handleClose={handleClose}/>
        </SectionDialog>
        {watchSection && <SectionDialog onClose={handleSubClose} open={modalSubOpen}>
            <UnifiedSubsectionModal addSubsection={addSubsection} handleClose={handleSubClose} sectionId={getValues('section').id}/>
        </SectionDialog>}
        </>
    )
}
