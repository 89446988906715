import { Divider, DividerProps, styled } from '@mui/material'
import React from 'react'

export const SlimDivider = (props:DividerProps) => {

    const SlimDividerStyled = styled(Divider)(() => ({
        '&.MuiDivider-root':{
            marginTop:'4px',
            marginBottom:'4px'
        }
    }))

  return (
    <SlimDividerStyled {...props}/>
  )
}
