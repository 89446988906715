import React, { createContext, useContext } from "react";
import { UIField, UIForm, UIGroup, UIRow, UISubsection, WidthOption } from "../components/unified-editor/types";
import { GroupRelationshipType } from "../types";

export enum UnifiedCtx {
    EditDocument = 'EDITDOCUMENT',
    AddData = 'ADDDATA',
    MoveSectionUp = 'MOVESECTIONUP',
    MoveSectionDown = 'MOVESECTIONDOWN',
    MoveRowUp = 'MOVEROWUP',
    MoveRowDown = 'MOVEROWDOWN',
    MoveFieldLeft = 'MOVEFIELDLEFT',
    MoveFieldRight = 'MOVEFIELDRIGHT',
    MoveFieldRowUp = 'MOVEFIELDUPROW',
    MoveFieldRowDown = 'MOVEFIELDDOWNROW',
    SetFieldWidth = 'SETFIELDWIDTH',
    EditSectionName = 'EDITSECTIONNAME',
    EditSubsectionName = 'EDITSUBSECTIONNAME',
    EditFieldName = 'EDITFIELDNAME',
    EditGroupName = 'EDITGROUPNAME',
    AddRow = 'ADDROW',
    SetReload = 'SETRELOAD',
    RemoveField = 'REMOVEFIELD',
    RemoveGroup = 'REMOVEGROUP',
}

const MAXLENGTH = 5
const widthOptions:WidthOption[] = [
    {text:'20%', width:20, disabled:false}, 
    {text:'25%', width:25, disabled:false}, 
    {text:'33%', width:33.33, disabled:false}, 
    {text:'50%', width:50, disabled:false}, 
    {text:'66%', width:66.67, disabled:false}, 
    {text:'75%', width:75, disabled:false}, 
    {text:'100%', width:100, disabled:false}
]

function isGroup(e: UIField|UIGroup): e is UIGroup {
    return (e as UIGroup).options !== undefined;
}

function isSubsection(e: UIRow|UISubsection): e is UISubsection {
    return (e as UISubsection).title !== undefined;
}


const getWidthFieldGroup = (fieldGroup:UIField|UIGroup):number => {
    let width = 0
    if(!isGroup(fieldGroup)){
        width += (fieldGroup.width??100)
    } else {
        if(fieldGroup.relationship === GroupRelationshipType.Multi){
            for (let i = 0; i < fieldGroup.options.length; i++) {
                const option = fieldGroup.options[i];
                width += option.width
            }
        } else {
            width += fieldGroup.options[0].width
        }
    }
    return width
}

const getWidthRow = (row:UIRow):number => {
    let rowFreeAmount = 100
    for (let i = 0; i < row.elements.length; i++) {
        const uiFieldGroup = row.elements[i];
        const fieldGroupWidth = getWidthFieldGroup(uiFieldGroup)
        rowFreeAmount = rowFreeAmount - fieldGroupWidth
    }
    return rowFreeAmount
}

const getWidthOptions = (remainderWidth:number):WidthOption[] => {
    const options:WidthOption[] = []
    for (let i = 0; i < widthOptions.length; i++) {
        const option = widthOptions[i];
        if(option.width<=remainderWidth){
            options.push(option)
        } else {
            const newOption:WidthOption = {...option, disabled:true}
            options.push(newOption)
        }
    }
    return options
}

const setFieldOptions = (row:UIRow, freeRowWidth:number):UIRow => {
    const newRow:UIRow = {...row, elements:[], free_row_width:freeRowWidth}
    for (let i = 0; i < row.elements.length; i++) {
        const fieldGroup = row.elements[i];
        if(!isGroup(fieldGroup)){
            const remainderWidth = Math.ceil(freeRowWidth) + (fieldGroup.width??0);
            const widthOptions = getWidthOptions(remainderWidth)
            fieldGroup.width_options = widthOptions
        } else {
            if(fieldGroup.relationship === GroupRelationshipType.Multi){
                for (let i = 0; i < fieldGroup.options.length; i++) {
                    const groupOption = fieldGroup.options[i];
                    const remainderWidth = Math.ceil(freeRowWidth) + groupOption.width;
                    const widthOptions = getWidthOptions(remainderWidth)
                    fieldGroup.options[i].width_options = widthOptions
                }
            } else {
                const remainderWidth = Math.ceil(freeRowWidth) + fieldGroup.options[0].width;
                const widthOptions = getWidthOptions(remainderWidth)
                fieldGroup.options[0].width_options = widthOptions
            }
        }
        newRow.elements.push(fieldGroup)
    }
    return newRow
}


export type UnifiedAction = 
    | { type: UnifiedCtx.AddData, payload:UIForm }
    | { type: UnifiedCtx.MoveSectionUp, payload: {id:string, index:number} }
    | { type: UnifiedCtx.MoveSectionDown, payload: {id:string, index:number} }
    | { type: UnifiedCtx.MoveRowUp, payload: {id:string, index: number, sectionIndex:number, subsectionIndex?:number|undefined} }
    | { type: UnifiedCtx.MoveRowDown, payload: {id:string, index: number, sectionIndex:number, subsectionIndex?:number|undefined} }
    | { type: UnifiedCtx.MoveFieldLeft, payload: 
        {id:string, index: number, sectionIndex:number, subsectionIndex?:number|undefined, rowIndex:number} }
    | { type: UnifiedCtx.MoveFieldRight, payload: 
        {id:string, index: number, sectionIndex:number, subsectionIndex?:number|undefined, rowIndex:number} }
    | { type: UnifiedCtx.MoveFieldRowUp, payload: 
        { id:string, index:number, sectionIndex:number, subsectionIndex?:number|undefined, rowIndex:number } }
    | { type: UnifiedCtx.MoveFieldRowDown, payload: 
        { id:string, index:number, sectionIndex:number, subsectionIndex?:number|undefined, rowIndex:number } }
    | { type: UnifiedCtx.SetFieldWidth, payload: 
        { id:string, index:number, sectionIndex:number, subsectionIndex?:number|undefined, rowIndex:number, width:string } }
    | { type: UnifiedCtx.EditSectionName, payload: {id:string, index:number, title:string} }
    | { type: UnifiedCtx.EditSubsectionName, payload: {id:string, sectionIndex:number, subsectionIndex:number, title:string} }
    | { type: UnifiedCtx.EditFieldName, payload: 
        {id:string, sectionIndex:number, subsectionIndex?:number|undefined, rowIndex:number, index:number, title:string} }
    | { type: UnifiedCtx.EditGroupName, payload: 
        {id:string, sectionIndex:number, subsectionIndex?:number|undefined, rowIndex:number, index:number, title: string} }
    | { type: UnifiedCtx.AddRow, payload: {sectionIndex:number, subsectionIndex?:number|undefined, rowIndex:number} }
    | { type: UnifiedCtx.SetReload, payload: boolean }
    | { type: UnifiedCtx.RemoveField, payload: {id:string, sectionIndex:number, subsectionIndex?:number|undefined, rowIndex:number, index:number} }
    | { type: UnifiedCtx.RemoveGroup, payload: 
        {groupId:string, sectionIndex: number, subsectionIndex?:number|undefined, rowIndex: number, index: number} }

const reducer = (state:UIForm, action:UnifiedAction):UIForm => {
    switch (action.type) {
        case UnifiedCtx.AddData:{
            const sections = action.payload.sections
            const unified_types = action.payload.unified_types
            const selected_type = action.payload.selected_type
            for (let i = 0; i < sections.length; i++) {
                const section = sections[i];
                for (let i = 0; i < section.elements.length; i++) {
                    let uiRowSub = section.elements[i];
                    if(!isSubsection(uiRowSub)){
                        const freeAmount = getWidthRow(uiRowSub)
                        uiRowSub = setFieldOptions(uiRowSub, freeAmount)
                    } else {
                        for (let i = 0; i < uiRowSub.rows.length; i++) {
                            let subRow = uiRowSub.rows[i];
                            const freeAmount = getWidthRow(subRow)
                            subRow = setFieldOptions(subRow, freeAmount)
                            uiRowSub.rows.splice(i, 1, subRow)
                        }
                    }
                    section.elements.splice(i, 1, uiRowSub)
                }
                sections.splice(i, 1, section)
            }
            // console.log(sections, unified_types, selected_type)
            return {sections:sections, unified_types:unified_types, selected_type:selected_type, reload:false, disabled:action.payload.disabled}
        }
        case UnifiedCtx.MoveSectionDown:{
            if(action.payload.id === state.sections[action.payload.index].id){
                const updatedSections = state.sections
                updatedSections.splice(action.payload.index +1, 0, updatedSections.splice(action.payload.index, 1)[0])
                return {...state, sections:updatedSections}
            }
            return state
        }
        case UnifiedCtx.MoveSectionUp:{
            if(action.payload.id === state.sections[action.payload.index].id){
                const updatedSections = state.sections
                updatedSections.splice(action.payload.index - 1, 0, updatedSections.splice(action.payload.index, 1)[0])
                return {...state, sections:updatedSections}
            }
            return state
        }
        case UnifiedCtx.MoveRowDown:{
            const sections = state.sections
            const sI = action.payload.sectionIndex
            const ssI = action.payload.subsectionIndex
            const rI = action.payload.index
            let row:UIRow|undefined = undefined;
            if(ssI!==undefined){
                row = (sections[sI].elements[ssI] as UISubsection).rows[rI]
                if(action.payload.id === row.id){
                    (sections[sI].elements[ssI] as UISubsection)
                    .rows.splice(rI+1, 0, (sections[sI].elements[ssI] as UISubsection).rows.splice(rI, 1)[0])
                }
            } else {
                row = sections[sI].elements[rI] as UIRow
                if(action.payload.id === row.id){
                    sections[sI].elements.splice(rI+1, 0, sections[sI].elements.splice(rI, 1)[0])
                }
            }
            return {...state, sections: sections}
        }
        case UnifiedCtx.MoveRowUp:{
            const sections = state.sections
            const sI = action.payload.sectionIndex
            const ssI = action.payload.subsectionIndex
            const rI = action.payload.index
            let row:UIRow|undefined = undefined
            if(ssI!==undefined){
                row = (sections[sI].elements[ssI] as UISubsection).rows[rI]
                if(action.payload.id === row.id){
                    (sections[sI].elements[ssI] as UISubsection)
                    .rows.splice(rI-1, 0, (sections[sI].elements[ssI] as UISubsection).rows.splice(rI, 1)[0])
                }
            } else {
                row = sections[sI].elements[rI] as UIRow
                if(action.payload.id === row.id){
                    sections[sI].elements.splice(rI-1, 0, sections[sI].elements.splice(rI, 1)[0])
                }
            }
            return {...state, sections: sections}
        }
        case UnifiedCtx.MoveFieldLeft:{
            let field:UIField|UIGroup|undefined = undefined
            const sections = state.sections
            const sI = action.payload.sectionIndex
            const ssI = action.payload.subsectionIndex
            const rI = action.payload.rowIndex
            const I = action.payload.index
            if(ssI !== undefined){
                field = (sections[sI].elements[ssI] as UISubsection).rows[rI].elements[I]
            } else {
                field = (state.sections[sI].elements[rI] as UIRow).elements[I]
            }
            if(field && action.payload.id === field.id){
                if(ssI !== undefined){
                    (sections[sI].elements[ssI] as UISubsection)
                    .rows[rI].elements.splice(I -1, 0, (sections[sI].elements[ssI] as UISubsection).rows[rI].elements.splice(I, 1)[0])
                } else {
                    (sections[sI].elements[rI] as UIRow)
                    .elements.splice(I -1, 0, (sections[sI].elements[rI] as UIRow).elements.splice(I, 1)[0])
                }
                return {...state, sections: sections}
            }
            return state
        }
        case UnifiedCtx.MoveFieldRight:{
            let field:UIField|UIGroup|undefined = undefined
            const sections = state.sections
            const sI = action.payload.sectionIndex
            const ssI = action.payload.subsectionIndex
            const rI = action.payload.rowIndex
            const I = action.payload.index
            if(ssI !== undefined){
                field = (sections[sI].elements[ssI] as UISubsection).rows[action.payload.rowIndex].elements[action.payload.index]
            } else {
                field = (sections[sI].elements[rI] as UIRow).elements[action.payload.index]
            }
            if(field && action.payload.id === field.id){
                if(ssI !== undefined){
                    (sections[sI].elements[ssI] as UISubsection)
                    .rows[rI].elements.splice(I +1, 0, (sections[sI].elements[ssI] as UISubsection).rows[rI].elements.splice(I, 1)[0])
                } else {
                    (sections[sI].elements[rI] as UIRow)
                    .elements.splice(I +1, 0, (sections[sI].elements[rI] as UIRow).elements.splice(I, 1)[0])
                }
                return {...state, sections: sections}
            }
            return state

        }
        case UnifiedCtx.MoveFieldRowUp:{
            const sections = state.sections
            const I = action.payload.index
            const sI = action.payload.sectionIndex
            const ssI = action.payload.subsectionIndex
            const rI = action.payload.rowIndex
            let field:UIField|UIGroup|undefined = undefined;

            
            if(ssI !== undefined){
                field = (sections[sI].elements[ssI] as UISubsection).rows[rI].elements[I]
            } else {
                if(isSubsection((sections[sI].elements[rI-1]))){
                    return state
                }
                field = (sections[sI].elements[rI] as UIRow).elements[I];
            }
            if(field && action.payload.id === field.id){
                // console.log('passes tests')
                const fieldWidth = getWidthFieldGroup(field);

                // first we'll edit the row where the field is being moved from
                if(ssI !== undefined){
                    (sections[sI].elements[ssI] as UISubsection).rows[rI].elements.splice(I, 1);
                    const oldRow = (sections[sI].elements[ssI] as UISubsection).rows[rI]
                    const oldRowWidthFree = ((oldRow.free_row_width)??100) + fieldWidth
                    const oldRowElements:UIField[] = []
                    for (let i = 0; i < oldRow.elements.length; i++) {
                        const field:UIField = oldRow.elements[i] as UIField;
                        const newOptions = getWidthOptions((oldRowWidthFree+(field.width??100)))
                        field.width_options = newOptions
                        oldRowElements.push(field)
                    }
                    const newOldRow:UIRow = {...oldRow, elements:oldRowElements, free_row_width:oldRowWidthFree};
                    (sections[sI].elements[ssI] as UISubsection).rows.splice(rI, 1, newOldRow);

                    (sections[sI].elements[ssI] as UISubsection).rows[rI-1].elements.push(field);
                    const existingRowWidth = (sections[sI].elements[ssI] as UISubsection).rows[rI-1].free_row_width??100;
                    const newRowWidth = existingRowWidth-fieldWidth
                    const updatedRow = (sections[sI].elements[ssI] as UISubsection).rows[rI-1]
                    const updatedRowElements = updatedRow.elements as UIField[]
                    const updatedRowCount = updatedRowElements.length
                    if(newRowWidth<0){
                        if(updatedRowCount <= MAXLENGTH){
                            const newRowElements:UIField[] = [];
                            const updatedRowWidth = Number((100 / updatedRowCount).toFixed(2))
                            for (let i = 0; i < updatedRowElements.length; i++) {
                                const field = updatedRowElements[i];
                                const newOptions = getWidthOptions(updatedRowWidth)
                                field.width = updatedRowWidth
                                field.width_options = newOptions
                                newRowElements.push(field)
                            }
                            const newRow:UIRow = { ...updatedRow, elements:newRowElements, free_row_width:0};
                            (sections[sI].elements[ssI] as UISubsection).rows.splice(rI-1,1,newRow)
                        } else {
                            return state
                        }
                    } 
                    else {
                        const newRowElements:UIField[] = [];
                        for (let i = 0; i < updatedRowElements.length; i++) {
                            const field = updatedRowElements[i];
                            const emptyWidth = newRowWidth + (field.width??100)
                            const newOptions = getWidthOptions(emptyWidth)
                            field.width_options = newOptions
                            newRowElements.push(field)
                        }
                        const newRow:UIRow = {...updatedRow, elements:newRowElements, free_row_width:newRowWidth};
                        (sections[sI].elements[ssI] as UISubsection).rows.splice(rI-1,1,newRow);
                    }
                } 
                else {
                    (sections[sI].elements[rI] as UIRow).elements.splice(I, 1);
                    const oldRow = sections[sI].elements[rI] as UIRow
                    const oldRowWidthFree = ((oldRow.free_row_width)??100) + fieldWidth;
                    const oldRowElements:UIField[] = []
                    for (let i = 0; i < oldRow.elements.length; i++) {
                        const field = oldRow.elements[i] as UIField;
                        const newOptions = getWidthOptions((oldRowWidthFree+(field.width??100)))
                        field.width_options = newOptions
                        oldRowElements.push(field)
                    }
                    const newOldRow:UIRow = {...oldRow, elements:oldRowElements, free_row_width:oldRowWidthFree};
                    sections[sI].elements.splice(rI, 1, newOldRow);
                    

                    // now we will edit the row where the field is being moved to
                    (sections[sI].elements[rI-1] as UIRow).elements.push(field)
                    const existingRowWidth = (sections[sI].elements[rI-1] as UIRow).free_row_width??100
                    const newRowWidth = existingRowWidth-fieldWidth
                    const updatedRow = sections[sI].elements[rI-1] as UIRow
                    const updatedRowElements = updatedRow.elements as UIField[]
                    const updatedRowCount = updatedRowElements.length
                    if(newRowWidth<0){
                        if(updatedRowCount <= MAXLENGTH){
                            const newRowElements:UIField[] = [];
                            const updatedRowWidth = Number((100 / updatedRowCount).toFixed(2))
                            for (let i = 0; i < updatedRowElements.length; i++) {
                                const field = updatedRowElements[i];
                                const newOptions = getWidthOptions(updatedRowWidth)
                                field.width = updatedRowWidth
                                field.width_options = newOptions
                                newRowElements.push(field)
                            }
                            const newRow:UIRow = { ...updatedRow, elements:newRowElements, free_row_width:0};
                            sections[sI].elements.splice(rI-1, 1, newRow);
                        } else {
                            return state
                        }
                    } else {
                        const newRowElements:UIField[] = [];
                        for (let i = 0; i < updatedRowElements.length; i++) {
                            const field = updatedRowElements[i];
                            const emptyWidth = newRowWidth + (field.width??100)
                            const newOptions = getWidthOptions(emptyWidth)
                            field.width_options = newOptions
                            newRowElements.push(field)
                        }
                        const newRow:UIRow = {...updatedRow, elements:newRowElements, free_row_width:newRowWidth}
                        sections[sI].elements.splice(rI-1, 1, newRow);
                    }
                }
            }
            return {...state, sections:sections}
        }
        case UnifiedCtx.MoveFieldRowDown:{
            const sections = state.sections
            const I = action.payload.index
            const sI = action.payload.sectionIndex
            const ssI = action.payload.subsectionIndex
            const rI = action.payload.rowIndex
            let field:UIField|UIGroup|undefined = undefined;
            if(ssI !== undefined){
                field = (sections[sI].elements[ssI] as UISubsection).rows[rI].elements[I]
            } else {
                if(isSubsection((sections[sI].elements[rI+1]))){
                    return state
                }
                field = (sections[sI].elements[rI] as UIRow).elements[I];
            }
            if(field && action.payload.id === field.id){
                const fieldWidth = getWidthFieldGroup(field);
                if(ssI !== undefined){
                    (sections[sI].elements[ssI] as UISubsection).rows[rI].elements.splice(I, 1)
                    const oldRow = (sections[sI].elements[ssI] as UISubsection).rows[rI]
                    const oldRowWidthFree = ((oldRow.free_row_width)??100) + fieldWidth
                    const oldRowElements:UIField[] = []
                    for (let i = 0; i < oldRow.elements.length; i++) {
                        const field:UIField = oldRow.elements[i] as UIField;
                        const newOptions = getWidthOptions((oldRowWidthFree+(field.width??100)))
                        field.width_options = newOptions
                        oldRowElements.push(field)
                    }
                    const newOldRow:UIRow = {...oldRow, elements:oldRowElements, free_row_width:oldRowWidthFree};
                    (sections[sI].elements[ssI] as UISubsection).rows.splice(rI, 1, newOldRow);

                    (sections[sI].elements[ssI] as UISubsection).rows[rI+1].elements.push(field);
                    const existingRowWidth = (sections[sI].elements[ssI] as UISubsection).rows[rI+1].free_row_width??100;
                    const newRowWidth = existingRowWidth-fieldWidth
                    const updatedRow = (sections[sI].elements[ssI] as UISubsection).rows[rI+1]
                    const updatedRowElements = updatedRow.elements as UIField[]
                    const updatedRowCount = updatedRowElements.length
                    if(newRowWidth<0){
                        if(updatedRowCount <= MAXLENGTH){
                            const newRowElements:UIField[] = [];
                            const updatedRowWidth = Number((100 / updatedRowCount).toFixed(2))
                            for (let i = 0; i < updatedRowElements.length; i++) {
                                const field = updatedRowElements[i];
                                const newOptions = getWidthOptions(updatedRowWidth)
                                field.width = updatedRowWidth
                                field.width_options = newOptions
                                newRowElements.push(field)
                            }
                            const newRow:UIRow = { ...updatedRow, elements:newRowElements, free_row_width:0};
                            (sections[sI].elements[ssI] as UISubsection).rows.splice(rI+1,1,newRow)
                        } else {
                            return state
                        }
                    } else {
                        const newRowElements:UIField[] = [];
                        for (let i = 0; i < updatedRowElements.length; i++) {
                            const field = updatedRowElements[i];
                            const emptyWidth = newRowWidth + (field.width??100)
                            const newOptions = getWidthOptions(emptyWidth)
                            field.width_options = newOptions
                            newRowElements.push(field)
                        }
                        const newRow:UIRow = {...updatedRow, elements:newRowElements, free_row_width:newRowWidth};
                        (sections[sI].elements[ssI] as UISubsection).rows.splice(rI+1,1,newRow);
                    }
                } else {
                    // first we'll edit the row where the field is being moved from
                    (sections[sI].elements[rI] as UIRow).elements.splice(I, 1);
                    const oldRow = sections[sI].elements[rI] as UIRow
                    const oldRowWidthFree = ((oldRow.free_row_width)??100) + fieldWidth;
                    const oldRowElements:UIField[] = []
                    for (let i = 0; i < oldRow.elements.length; i++) {
                        const field = oldRow.elements[i] as UIField;
                        const newOptions = getWidthOptions((oldRowWidthFree+(field.width??100)))
                        field.width_options = newOptions
                        oldRowElements.push(field)
                    }
                    const newOldRow:UIRow = {...oldRow, elements:oldRowElements, free_row_width:oldRowWidthFree};
                    sections[sI].elements.splice(rI, 1, newOldRow);
                    

                    // now we will edit the row where the field is being moved to
                    (sections[sI].elements[rI+1] as UIRow).elements.push(field)
                    const existingRowWidth = (sections[sI].elements[rI+1] as UIRow).free_row_width??100
                    const newRowWidth = existingRowWidth-fieldWidth
                    const updatedRow = sections[sI].elements[rI+1] as UIRow
                    const updatedRowElements = updatedRow.elements as UIField[]
                    const updatedRowCount = updatedRowElements.length
                    if(newRowWidth<0){
                        if(updatedRowCount <= MAXLENGTH){
                            const newRowElements:UIField[] = [];
                            const updatedRowWidth = Number((100 / updatedRowCount).toFixed(2))
                            for (let i = 0; i < updatedRowElements.length; i++) {
                                const field = updatedRowElements[i];
                                const newOptions = getWidthOptions(updatedRowWidth)
                                field.width = updatedRowWidth
                                field.width_options = newOptions
                                newRowElements.push(field)
                            }
                            const newRow:UIRow = { ...updatedRow, elements:newRowElements, free_row_width:0};
                            sections[sI].elements.splice(rI+1, 1, newRow);
                        } else {
                            return state
                        }
                    } else {
                        const newRowElements:UIField[] = [];
                        for (let i = 0; i < updatedRowElements.length; i++) {
                            const field = updatedRowElements[i];
                            const emptyWidth = newRowWidth + (field.width??100)
                            const newOptions = getWidthOptions(emptyWidth)
                            field.width_options = newOptions
                            newRowElements.push(field)
                        }
                        const newRow:UIRow = {...updatedRow, elements:newRowElements, free_row_width:newRowWidth}
                        sections[sI].elements.splice(rI+1, 1, newRow);
                    }
                    // console.log(sections)
                }
            }
            return {...state, sections:sections}
        }
        case UnifiedCtx.SetFieldWidth:{
            const sections = state.sections
            const I = action.payload.index
            const sI = action.payload.sectionIndex
            const sSI = action.payload.subsectionIndex
            const rI = action.payload.rowIndex

            if(sSI !== undefined) {
                const fieldGroup = (sections[sI].elements[sSI] as UISubsection).rows[rI].elements[I]
                if(fieldGroup !== undefined && action.payload.id === fieldGroup.id){
                    if(!isGroup(fieldGroup)){
                        
                            const difference = (fieldGroup.width??100) - (Number(action.payload.width));
                            const newRowWidth = ((sections[sI].elements[sSI] as UISubsection).rows[rI].free_row_width??100) + difference
                            fieldGroup.width = Number(action.payload.width);
                            (sections[sI].elements[sSI] as UISubsection).rows[rI].elements.splice(I, 1, fieldGroup);
                            const updatedRowRow = (sections[sI].elements[sSI] as UISubsection).rows[rI];
                            const updatedRow = setFieldOptions( updatedRowRow , newRowWidth );

                            (sections[sI].elements[sSI] as UISubsection).rows.splice(rI, 1, updatedRow)
                        
                    } else {
                        if(fieldGroup.relationship === GroupRelationshipType.Single){
                                const difference = fieldGroup.options[0].width - Number(action.payload.width)
                                const newRowWidth = ((sections[sI].elements[sSI] as UISubsection).rows[rI].free_row_width??100) + difference;

                                fieldGroup.options[0].width = Number(action.payload.width);
                                (sections[sI].elements[sSI] as UISubsection).rows[rI].elements.splice(I, 1, fieldGroup)

                                const updatedRowRow = (sections[sI].elements[sSI] as UISubsection).rows[rI];
                                const updatedRow = setFieldOptions(updatedRowRow, newRowWidth);

                                (sections[sI].elements[sSI] as UISubsection).rows.splice(rI, 1, updatedRow)
                            
                        }
                    }
                }
            } else {
                const fieldGroup = (sections[sI].elements[rI] as UIRow).elements[I];
                if(fieldGroup!==undefined && action.payload.id === fieldGroup.id){
                    if(!isGroup(fieldGroup)){
                        if(action.payload.id === fieldGroup.id) {
                            const difference = (fieldGroup.width??100) - (Number(action.payload.width));
                            const newRowWidth = ((sections[sI].elements[rI] as UIRow).free_row_width??100) + difference
                            fieldGroup.width = Number(action.payload.width);
                            (sections[sI].elements[rI] as UIRow).elements.splice(I, 1, fieldGroup);
                            const updatedRow = setFieldOptions((sections[sI].elements[rI] as UIRow), newRowWidth)
                            sections[sI].elements.splice(rI, 1, updatedRow)
                        }
                    } else {
                        if(fieldGroup.relationship === GroupRelationshipType.Single){
                            if(action.payload.id===fieldGroup.id){
                                const difference = fieldGroup.options[0].width - Number(action.payload.width)
                                const newRowWidth = ((sections[sI].elements[rI] as UIRow).free_row_width??100) + difference
                                fieldGroup.options[0].width = Number(action.payload.width);
                                (sections[sI].elements[rI] as UIRow).elements.splice(I, 1, fieldGroup)
                                const updatedRow = setFieldOptions((sections[sI].elements[rI] as UIRow), newRowWidth)
                                sections[sI].elements.splice(rI, 1, updatedRow)
                            }
                        } 
                    }
                }
            }
            return {...state, sections:sections}
        }
        case UnifiedCtx.EditSectionName:{
            const sections = state.sections
            const sectionIndex = action.payload.index
            const section = sections[sectionIndex]
            if(section.id === action.payload.id && section.title !== action.payload.title){
                section.title = action.payload.title
                sections.splice(sectionIndex, 1, section)
            }
            return {...state, sections:sections}
        }
        case UnifiedCtx.EditSubsectionName:{
            const sections = state.sections
            const subsection = sections[action.payload.sectionIndex].elements[action.payload.subsectionIndex] as UISubsection
            if(subsection.id === action.payload.id && subsection.title !== action.payload.title){
                (sections[action.payload.sectionIndex].elements[action.payload.subsectionIndex] as UISubsection).title = action.payload.title
            }
            return {...state, sections:sections}
        }
        case UnifiedCtx.EditFieldName:{
            const sections = state.sections
            const sI = action.payload.sectionIndex
            const ssI = action.payload.subsectionIndex
            const rI = action.payload.rowIndex
            const fI = action.payload.index
            if(ssI !== undefined){
                if(((sections[sI].elements[ssI] as UISubsection).rows[rI].elements[fI] as UIField).title !== action.payload.title){
                    ((sections[sI].elements[ssI] as UISubsection).rows[rI].elements[fI] as UIField).title = action.payload.title
                }
            } else if(((sections[sI].elements[rI] as UIRow).elements[fI] as UIField).title !== action.payload.title) {
                ((sections[sI].elements[rI] as UIRow).elements[fI] as UIField).title = action.payload.title
            }
            return {...state, sections:sections}
        }
        case UnifiedCtx.EditGroupName:{
            const sections = state.sections
            const sI = action.payload.sectionIndex
            const ssI = action.payload.subsectionIndex
            const rI = action.payload.rowIndex
            const gI = action.payload.index
            if(ssI !== undefined){
                if(((sections[sI].elements[ssI] as UISubsection).rows[rI].elements[gI] as UIGroup).name !== action.payload.title){
                    ((sections[sI].elements[ssI] as UISubsection).rows[rI].elements[gI] as UIGroup).name = action.payload.title
                }
            } else if(((sections[sI].elements[rI] as UIRow).elements[gI] as UIGroup).name !== action.payload.title) {
                ((sections[sI].elements[rI] as UIRow).elements[gI] as UIGroup).name = action.payload.title
            }
            return {...state, sections:sections}
        }
        case UnifiedCtx.AddRow:{
            const sections = state.sections
            const sI = action.payload.sectionIndex
            const ssI = action.payload.subsectionIndex
            const rI = action.payload.rowIndex
            if(ssI !== undefined){
                const rowSubIndexLength = (sections[sI].elements[ssI] as UISubsection).rows.length
                if(rowSubIndexLength-1 === rI){
                    const rowId = new Date().valueOf();
                    const emptyRow:UIRow = {id:rowId.toString(), elements:[], free_row_width:100};
                    (sections[sI].elements[ssI] as UISubsection).rows.push(emptyRow)
                }
            } else {
                const rowIndexLength = sections[sI].elements.length
                if(rowIndexLength-1 === rI){
                    const rowId = new Date().valueOf();
                    const emptyRow:UIRow = {id:rowId.toString(), elements:[], free_row_width:100}
                    sections[sI].elements.push(emptyRow)
                }
            }
            return {...state, sections:sections}
        }
        case UnifiedCtx.SetReload:
            return {...state, reload:action.payload}
        case UnifiedCtx.RemoveField:{
            const sections = state.sections
            const sI = action.payload.sectionIndex
            const ssI = action.payload.subsectionIndex
            const rI = action.payload.rowIndex
            const I = action.payload.index
            if(ssI !== undefined){
                const field = (sections[sI].elements[ssI] as UISubsection).rows[rI].elements[I];
                if(field !== undefined && field.id === action.payload.id){
                    (sections[sI].elements[ssI] as UISubsection).rows[rI].elements.splice(I, 1)
                }
            } else {
                const field = (sections[sI].elements[rI] as UIRow).elements[I];
                if(field !== undefined && field.id === action.payload.id){
                    (sections[sI].elements[rI] as UIRow).elements.splice(I, 1)
                }
            }
            return {...state, sections:sections}
        }
        case UnifiedCtx.RemoveGroup:{
            const sections = state.sections
            const sI = action.payload.sectionIndex
            const ssI = action.payload.subsectionIndex
            const rI = action.payload.rowIndex
            const I = action.payload.index
            if(ssI !== undefined){
                const group = (sections[sI].elements[ssI] as UISubsection).rows[rI].elements[I];
                if(group !== undefined && group.id === action.payload.groupId){
                    (sections[sI].elements[ssI] as UISubsection).rows[rI].elements.splice(I, 1)
                }
            } else {
                const group = (sections[sI].elements[rI] as UIRow).elements[I]
                if(group !== undefined && group.id === action.payload.groupId){
                    (sections[sI].elements[rI] as UIRow).elements.splice(I,1)
                }
            }
            return {...state, sections:sections}
        }
        default:
            return state
    }
}

const initialState:UIForm = {
    sections:[],
    unified_types:[],
    selected_type:'',
    reload:false,
    disabled:false
} 

const UnifiedContext = createContext<{
    state: UIForm ;
    dispatch: React.Dispatch<UnifiedAction>;
}>({
    state: initialState,
    dispatch: () => null
});

const useUnifiedContext = () => {
    const unifiedContext = useContext(UnifiedContext)
    if(!UnifiedContext){
        throw new Error('No UnifiedContext.Provider found when calling usePdfContext')
    }
    return unifiedContext
}

const UnifiedContextProvider = ({value, children}:{value:UIForm, children:React.ReactNode}) => {
    const [state, dispatch] = React.useReducer(reducer, value)
    return (
        <UnifiedContext.Provider value={{state,dispatch}}>
            {children}
        </UnifiedContext.Provider>
    )
}


export {useUnifiedContext, UnifiedContextProvider}