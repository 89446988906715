import React from 'react'
import { UnifiedCtx, useUnifiedContext } from '../../../providers/UnifiedContext'
import { Backdrop, Box, CircularProgress, Fab, Stack } from '@mui/material'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { useLoadForm, useSubmitForm } from './utils';
import { Section } from './Section';

export const PdfForm = ({pdfId, scrollToTop}:{pdfId:string, scrollToTop:()=>void}) => {

    const {state:{sections}, dispatch} = useUnifiedContext()
    const { loading, error, submitFields } = useSubmitForm()

    const moveSectionDown = React.useCallback((id:string,atIndex:number) => {
        dispatch({type: UnifiedCtx.MoveSectionDown, payload:{id:id, index:atIndex}})
    },[sections])

    const moveSectionUp = React.useCallback((id:string,atIndex:number) => {
        dispatch({type: UnifiedCtx.MoveSectionUp, payload:{id:id, index:atIndex}})
    },[sections])

    const handleSubmit = () => {
        window.scrollTo(0, 0)
        submitFields(pdfId)
    }

    const handleScrollTop = () => {
        // window.scrollTo(0, 0)
        scrollToTop()
    }

  return (
    <>
    {(loading) &&
        <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={true}
        >
            <CircularProgress color="inherit" />
        </Backdrop>
    }
    <Stack flexWrap={'wrap'} mt={7} mb={4} p={0.5} >
        {sections.map((s,i) => (
            <Section 
                key={s.id} 
                index={i} 
                section={s} 
                isFirst={i==0}
                isLast={i==sections.length-1}
                moveSectionDown={moveSectionDown} 
                moveSectionUp={moveSectionUp} />
        ))}
    </Stack>
    {/* <Stack position={'sticky'} zIndex={0} bottom={'36px'} direction={'row'} justifyContent={'space-between'} mx={4} alignItems={'center'}> */}
        <Box role="presentation" position={'sticky'} display={'inline'} bottom={'38px'} ml={2} >
            <Fab size={'small'} onClick={handleScrollTop} color="primary" aria-label="Scroll back to top">
                <KeyboardArrowUpIcon/>
            </Fab>
        </Box>
        <Box role="presentation" position={'sticky'} display={'inline'} bottom={'24px'} sx={{float:'right'}} mr={2} >
            <Fab variant={'extended'} onClick={handleSubmit} color="primary" aria-label="Submit form">
                Submit
            </Fab>
        </Box>
    {/* </Stack> */}
    </>
  )
}