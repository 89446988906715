import { ThemeProvider } from "@mui/material/styles";
import React, { createContext } from "react";
import { appTheme } from "./styles";

export const AppThemeContext = createContext(null);

export function AppThemeProvider({ children }:{children:React.ReactNode}) {
  return (
    <ThemeProvider theme={appTheme}>
      {children}
    </ThemeProvider>
  );
}
