import React from 'react'
import { Box, Stack } from '@mui/material'
import { motion } from 'framer-motion'
import { UnifiedContextProvider } from '../../../providers/UnifiedContext'
import { UIForm } from '../../unified-editor/types'
import { useLoadForm } from './utils'
import LoadingButton from '@mui/lab/LoadingButton'
import { LoadingAnimation } from '../../shared/LoadingAnimation'
import ReplayIcon from '@mui/icons-material/Replay';
import { PdfForm } from './PdfForm'
import { useEffectOnce } from '../../../hooks/useEffectOnce'
export const EditPdfForm = ({pdfId}:{pdfId:string}) => {

    const initial:UIForm = {sections:[], unified_types:[],selected_type:'', disabled:false} 

    const divRef = React.useRef<HTMLDivElement>(null);

    const scrollToTop = () => {
        if (divRef.current) {
            divRef.current.scroll({
                top: 0,
                behavior: "smooth"
            });
        }
    };

    return (
        <Box width={'100%'} position={'absolute'} sx={{overflow:'hidden'}} height={'100%'} top={0} left={0} zIndex={1} overflow={'hidden'}>
            <motion.div 
                ref={divRef}
                layout 
                transition={{type:"just"}} 
                initial={{x:"100%"}} 
                animate={{x:0}} 
                exit={{x:"100%"}} 
                style={{width:'100%', height:'100%', backgroundColor:"rgb(239, 239, 239)", overflow:'scroll'}}>
                    <UnifiedContextProvider value={initial}>
                        <FormLoadData pdfId={pdfId} scrollToTop={scrollToTop}/>
                    </UnifiedContextProvider>
            </motion.div>
        </Box>
    )
}


const FormLoadData = ({pdfId, scrollToTop}:{pdfId:string, scrollToTop:()=>void}) => {

    const { loading, error, loadData } = useLoadForm()

    useEffectOnce(() => {
        setTimeout(() => {
            loadData(pdfId)
        }, 1000);
    })

    return (
        <>
        {error
        ? <Stack mt={5} width={'100%'} height={'100%'} justifyContent={'center'} alignItems={'center'} direction={'row'}>
            <LoadingButton loading={error&&loading} size={'large'} variant={'outlined'} startIcon={ <ReplayIcon/> } onClick={()=>loadData(pdfId)}>Reload</LoadingButton>
        </Stack>
        : loading
            ? <LoadingAnimation height={'100%'}/>
            : <PdfForm pdfId={pdfId} scrollToTop={scrollToTop} />
        }
        </>
    )
}