import { Box, Button, Grid, IconButton, ListItemIcon, ListItemText, MenuItem, Stack, TextField, Tooltip, Typography } from '@mui/material';
import React, { forwardRef } from 'react'
import FolderIcon from '@mui/icons-material/Folder';
import { useParentSize, UseParentSizeOptions } from '@cutting/use-get-parent-size';
import { ItemTypes, PdfFolderModel } from '../../types';
import { useDrop } from 'react-dnd';
import { useContextContext } from '../../providers/ContextContext';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import { useEditFolders } from './utils';
import { LoadingButton } from '@mui/lab';

interface PdfFolderProps{
    folder: PdfFolderModel;
    handleOpenFolder: (folderId: string) => void;
    handleRemoveFolder: (folder: PdfFolderModel) => void
    // handleRenameFolder: (folder:PdfFolderModel) => void
}

export const PdfFolder = forwardRef((props:PdfFolderProps, ref: React.ForwardedRef<unknown>) => {

    const {folder, handleOpenFolder, handleRemoveFolder} = props
    const {loading, renameFolder} = useEditFolders()
    const [enableEdit, setEnableEdit] = React.useState<boolean>(false);
    const [editName, setEditName] = React.useState<string>(folder.name)
    const iconRef = React.useRef<HTMLDivElement>(null);
    const { width } = useParentSize(iconRef);
    const {show, hide} = useContextContext()
    const textRef = React.useRef<any>(null)

    const [{canDrop, isOver}, drop] = useDrop(() => ({
        accept: ItemTypes.FILE,
        drop: () => ({ id: folder.id}),
        collect: (monitor) => ({
            isOver: monitor.isOver(),
            canDrop: monitor.canDrop(),
        }),
    }))

    const isActive = canDrop && isOver
    let backgroundColor = '#e3e3e3'
    let border = 'dashed 3px transparent'
    if(isActive){
        backgroundColor = '#c8f7ec'
    } else if(canDrop){
        backgroundColor = '#c8e5f7'
        border = 'dashed 3px #c8e5f7'
    }

    React.useEffect(() => {
        if (enableEdit && textRef.current) {
          textRef.current.select()
        }
      }, [enableEdit]);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEditName(event.target.value)
    };

    const handleRenameFolder = () => {
        setEnableEdit(false)
        renameFolder(folder.id, editName)
    }

    const editFolderName = () => {
        hide()
        setEnableEdit(true)
    }

    const removeFolder = () => {
        hide()
        handleRemoveFolder(folder)
    }

    const Menu = () => {
        return (
            <>
            <MenuItem dense onClick={editFolderName}>
                <ListItemIcon>
                    <DriveFileRenameOutlineIcon />
                </ListItemIcon>
                <ListItemText>Rename</ListItemText>
            </MenuItem>
            <MenuItem dense onClick={removeFolder} >
                <ListItemIcon>
                    <DeleteOutlineIcon/>
                </ListItemIcon>
                <ListItemText>Delete</ListItemText>
            </MenuItem>
            </>
        )
    }

    const handleContextMenu = (e: React.MouseEvent<HTMLButtonElement|HTMLDivElement>) => {
        show({
            menu: <Menu/>,
            element: e
        })
    }

    return (
        <Grid item xs={12} sm={2.4} mb={4}>
            <Stack ref={iconRef} direction={'column'} alignItems={'center'} justifyContent={'center'} height={'100%'} width={'100%'}>
                <Button ref={drop} onDoubleClick={() => handleOpenFolder(folder.id)} sx={{border:border}} onContextMenu={handleContextMenu} >
                    <FolderIcon style={{fontSize:`${width*0.7}px`}} htmlColor={backgroundColor} />
                </Button>
                <Box position={'relative'}>
                {enableEdit
                        ? <TextField
                            inputRef={textRef}
                            multiline 
                            value={editName}
                            disabled={loading}
                            size={"small"} 
                            onChange={handleChange}
                            InputProps={{disableUnderline:true}} 
                            inputProps={{
                                style:{textAlign:'center', 
                                fontWeight:500, 
                                lineHeight:1.43, 
                                padding:"0 5px", 
                                wordBreak:"break-word", 
                                fontSize:'0.875rem'}
                            }} 
                            variant={'standard'}/>
                        : <Tooltip title={'Double-click here to edit name'} arrow>
                            <Typography
                            sx={{wordBreak:'break-word'}}
                            padding={'1px 5px 5px'}
                            fontSize={'0.875rem'}
                            textAlign={'center'} 
                            fontWeight={500} 
                            lineHeight={1.43} 
                            onDoubleClick={()=>{if(!loading){setEnableEdit(true)}}} >
                                {editName}
                            </Typography>
                        </Tooltip>
                    }
                {loading
                        ? <Stack direction='row' sx={{position:"absolute", width:"100%", justifyContent:"center"}} >
                            <LoadingButton loading={true} sx={{minWidth:0, p:2}}/>
                        </Stack>
                        : <>
                            {enableEdit && 
                                <Stack direction='row' sx={{position:"absolute", width:"100%", justifyContent:"center"}} >
                                    {editName !== folder.name &&
                                        <IconButton onClick={handleRenameFolder}>
                                            <CheckCircleOutlinedIcon color='success' sx={{opacity: loading ? "0" : "1" }} />
                                        </IconButton>
                                    }
                                    <IconButton onClick={()=>{setEnableEdit(false);setEditName(folder.name)}}><CancelOutlinedIcon color='error' /></IconButton>
                                </Stack>
                            }
                        </>
                    }
                </Box>
            </Stack>
        </Grid>
    )
});

PdfFolder.displayName = "PdfFolder";
