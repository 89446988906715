import * as React from 'react';
import ErrorCard from '../components/ErrorCard';

const ErrorFallback = () => (
    <div>
        <ErrorCard error={new Error('Unknown')}/>
    </div>
);

export default ErrorFallback;
