import React from 'react'
import GridViewIcon from '@mui/icons-material/GridView';
import { Box, Button, Stack, styled, Tooltip, Typography } from '@mui/material';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { FormCtx, useFormContext } from '../../../providers/FormContext';
import AddIcon from '@mui/icons-material/Add';
import { AnimatePresence } from 'framer-motion';
import { HighlightCtx, useHighlightContext } from '../../../providers/HighlightContext';
import { FieldModel, GroupHighlightModel,  } from '../../../types';
import { usePdfContext } from '../../../providers/PdfContext';
import useStore from '../../../providers/EditFormState';
import { GroupInfo } from './GroupInfo';
import InfoIcon from '@mui/icons-material/Info';
import TouchAppIcon from '@mui/icons-material/TouchApp';
import PanToolAltIcon from '@mui/icons-material/PanToolAlt';
import RemoveIcon from '@mui/icons-material/Remove';
import { useCombinedState } from '../../../providers/combinedStateFn';

export const AddToGroup = ({group, disabled}:{group: GroupHighlightModel | undefined, disabled?:boolean|undefined}) => {

    const setTextHighfieldIcon = useStore((state) => state.setShowTextHighlight);
    const { state:{createButtonGroup}, dispatch } = useFormContext()
    const { state:{selectedField}, dispatch:hDispatch } = useHighlightContext()
    const combined = useCombinedState()

    const handleClick = () => {
        dispatch({type:FormCtx.createButtonGroup, payload:!createButtonGroup})
        if(createButtonGroup){
            setTextHighfieldIcon(true)
            combined.removeHighlightedTextFn()
        } else {
            setTextHighfieldIcon(false)
            if(selectedField){
                hDispatch({type:HighlightCtx.SelectedOnly, payload:selectedField})
            } else {
                hDispatch({type:HighlightCtx.HighlightFields, payload:{elements:[]}})
            }
        }
        
    }

    const GroupButton = styled(Button)(({ theme }) => ({
        borderTop: createButtonGroup ? 'solid 1px' : 'solid 1px rgba(0,0,0,0)',
        borderLeft: createButtonGroup ? 'solid 1px' : 'solid 1px rgba(0,0,0,0)',
        borderRight: createButtonGroup ? 'solid 1px' : 'solid 1px rgba(0,0,0,0)',
        borderBottomRightRadius: createButtonGroup ? '0' : '4px',
        borderBottomLeftRadius: createButtonGroup ? '0' : '4px',
        '.MuiButton-startIcon':{
            marginRight: '1rem',
            marginLeft:'0.25rem'
        },
        '.MuiButton-endIcon':{
            flex: 1,
            marginRight:'0.25rem'
        },
    }));


  return (
    <Box mt={2}>
        <GroupButton disabled={disabled} fullWidth onClick={handleClick} startIcon={<GridViewIcon/>} endIcon={
            <Box component={'span'} sx={{marginLeft:'auto'}}>
                {createButtonGroup && 
                    <Tooltip title={
                        <Stack>
                            <Typography variant='caption'>How to use:</Typography>
                            <Stack direction={'row'} alignItems={'center'}><TouchAppIcon fontSize={'small'} sx={{mr:0.5, mt:0.5}} /><Typography variant='caption'>Click button to edit</Typography></Stack>
                            <Stack direction={'row'} alignItems={'center'}><PanToolAltIcon fontSize={'small'} sx={{mr:0.5, mt:0.5}} /><Typography variant='caption'>Hover button to view fields</Typography></Stack>
                            <Stack direction={'row'} alignItems={'center'}><RemoveIcon fontSize={'small'} sx={{mr:0.5, mt:0.5}} /><Typography variant='caption'>Click to remove from group</Typography></Stack>
                            <Stack direction={'row'} alignItems={'center'}><AddIcon fontSize={'small'} sx={{mr:0.5, mt:0.5}} /><Typography variant='caption'>Click to add to group</Typography></Stack>
                        </Stack>
                    }>
                        <InfoIcon sx={{mr:1}} />
                    </Tooltip>
                }
                {createButtonGroup 
                    ? <KeyboardArrowUpIcon/> 
                    : <Tooltip title={'Create a group of buttons to act like a collective, either one of those buttons can be selected (radio) or multiple can be selected (checkbox) while filling out the form'}>
                        <AddIcon/>
                    </Tooltip>
                }
            </Box> 
        }>
            {group ? 'Change Group' : 'Add to Group'}
        </GroupButton>
        <AnimatePresence>
            {createButtonGroup && <GroupInfo />}
        </AnimatePresence>
    </Box>
  )
}
