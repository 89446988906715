import { Skeleton, Stack } from '@mui/material'
import React from 'react'

export const SkeletonLoadingFields = ({repeatN=1, btn=false, form=false}:{repeatN?:number, btn?:boolean, form?:boolean}) => {
    return (
        <Stack mt={3}>
            <Skeleton animation='wave' variant='text' sx={{fontSize:"1.4em"}}/>
            {form && 
                <>
                <Stack direction='row' justifyContent='space-between' my={0.5}>
                    <Skeleton animation='wave' variant='rounded' sx={{width:"48%"}} height={40}/>
                    <Skeleton animation='wave' variant='rounded' sx={{width:"48%"}} height={40}/>
                </Stack>
                <Skeleton animation='wave' variant='text' sx={{ mb:1.5 }}/>
                </>
            }
        {
            [...Array(repeatN)].map((v,i)=>(
                <Stack my={2} key={i}>
                    <Skeleton animation='wave' variant='rounded' height={40} />
                    <Skeleton animation='wave' variant='text' sx={{ mt:0.5 }}/>
                </Stack>
            ))
        }
            {btn && <Skeleton animation='wave' variant='rounded' height={50} sx={{width:"70%", mx:"auto", mt:3}} />}
        </Stack>
    )
}
