import { Visibility, VisibilityOff } from '@mui/icons-material';
import { Box, Button, Checkbox, Container, FormControl, FormControlLabel, IconButton, InputAdornment, InputLabel, Link, OutlinedInput, Stack, TextField, Typography } from '@mui/material'
import React from 'react'
import { useAuth } from '../auth/useAuth';
import { LoadingButton } from '@mui/lab';

export const Login = () => {

    const [showPassword, setShowPassword] = React.useState(false);
    const {login, loading} = useAuth()

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        const username = data.get('username')
        const password = data.get('password')
        const remember = data.get('remember')
        let rememberBool = false
        if(remember){
            rememberBool = true
        }
        if(username && password){
            login(data, rememberBool)
        }

    }

    return (
        <Container component={'main'} maxWidth={'sm'}>
            <Stack mt={16} justifyContent={'center'}>
                <Stack m={'auto'} direction={'row'} alignItems={'center'}>
                    <Box mr={1} ml={-1}><img style={{"height":"96px"}} src='/login-128x128.png' /></Box> 
                    <Typography fontWeight={'500'} variant={'h4'}>Form Builder</Typography>
                </Stack>
                <Typography px={6} mt={6} mb={1.5} variant={'h6'}>Login</Typography>
                <Stack px={6} width={'100%'} component={'form'} onSubmit={handleSubmit} noValidate>
                    <TextField sx={{mb:3}} disabled={loading} fullWidth required name={'username'} label={'Email Address'} autoComplete={'email'} autoFocus />
                    <FormControl sx={{mb:1}} disabled={loading} fullWidth required variant={"outlined"}>
                        <InputLabel htmlFor={"outlined-adornment-password"}>Password</InputLabel>
                        <OutlinedInput
                            id={"outlined-adornment-password"}
                            type={showPassword ? 'text' : 'password'}
                            endAdornment={
                            <InputAdornment position={"end"}>
                                <IconButton
                                aria-label={"toggle password visibility"}
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                                edge={"end"}
                                >
                                {showPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment>
                            }
                            autoComplete={'current-password'}
                            label={"Password"}
                            name={'password'}
                        />
                    </FormControl>
                    <FormControlLabel
                        disabled={loading}
                        sx={{mb:5}}
                        name="remember"
                        label="Remember me"
                        control={
                            <Checkbox/>
                        }/>
                    <Box/>
                    <LoadingButton loading={loading} variant={'contained'} type={'submit'}>Sign in</LoadingButton>
                </Stack>
                <Stack mt={2} px={6} direction={'row'} width={'100%'} justifyContent={'space-between'}>
                    <Link variant={'body2'} href={'/'}>Forgot password?</Link>
                    <Link variant={'body2'} href={'/'}>No account? Sign Up</Link>
                </Stack>
            </Stack>
        </Container>
    )
}
