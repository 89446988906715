import React, { useState } from 'react'
import { LoadingButton } from '@mui/lab';
import { Alert, debounce, DialogContent, Stack } from '@mui/material';
import { FormContainer, TextFieldElement, Controller, SubmitHandler, useForm } from 'react-hook-form-mui';
import { HighlightModel } from '../../../types';
import { useInstance } from '../../../utils/axiosConfig';

type Inputs = {
    name:string;
}

export const SectionModalFields = (
    {id,handleAddSection}:{
        id:string,
        handleAddSection: (group: HighlightModel) => void
    }):JSX.Element => {

    const [loading, setLoading] = useState<boolean>(false);
    const [disabled, setDisabled] = React.useState<boolean>(false);
    const [status, setStatus] = React.useState<boolean|undefined>(undefined)
    const {instance} = useInstance()
    const formContext = useForm<Inputs>();
    const onSubmit:SubmitHandler<Inputs> = data => handleSubmit(data);

    const handleSubmit = (e:Inputs) => {
        setLoading(true);
        instance.post(`/api/sections/add/${id}`, e)
        .then((res) => 
        {   
            setLoading(false);
            const sectionData:HighlightModel = res.data as HighlightModel
            handleAddSection(sectionData)
        })
        .catch(err => {
            setLoading(false);
            console.log(err);
        });
    }


    const checkTitle = async (text:string) => {
        if(text !== ''){
            const searchTerm = {"search":text}
            await instance.post(`/api/sections/check/${id}`,searchTerm)
            .then((res) => {
                const availability = res.data as boolean
                if(availability){
                    setStatus(true)
                } else {
                    setStatus(false)
                    setDisabled(true)
                }
                setLoading(false)
            })
            .catch((err) => console.log(err))
        } else {
            setStatus(undefined)
            setLoading(false)
        }
    }

    const debouncedSearch = debounce((text:string)=>{
        checkTitle(text)
    }, 1000)


    const onAmountChanged = React.useMemo( 
        ()=> (e: string) => {
            debouncedSearch(e)
            setDisabled(false)
            setLoading(true)
        }, 
        []
    );


        return (
            <DialogContent>
                <Stack mt={1}>
                    <FormContainer formContext={formContext} onSuccess={onSubmit}>
                        <Stack spacing={2}>
                            <Controller 
                                render={({ field }) => (
                                    <TextFieldElement
                                        onChange={(e) => {
                                            field.onChange(e);
                                            onAmountChanged(e.target.value)
                                        }} 
                                        name={field.name}
                                        required 
                                        fullWidth 
                                        size='small' 
                                        label='Section Name' 
                                        helperText='What will be the name of this section?'
                                        autoComplete='off'
                                    />
                                )} 
                                name={'name'}                            
                            />
                        </Stack>
                        {
                        status && <Alert sx={{mt:2}} severity={'success'}>Section Name Available</Alert>
                        }
                        {
                            status === false && <Alert sx={{mt:2}} severity={'error'}>This section name is already in use, try another</Alert>
                        }
                        <LoadingButton
                            disabled={disabled}
                            sx={{mt:4}}
                            type='submit'
                            loading={loading}
                            variant="outlined"
                            >
                            Submit
                        </LoadingButton>
                    </FormContainer>
                </Stack>
            </DialogContent>
        )
    }
