import { styled, Tooltip, tooltipClasses, TooltipProps } from '@mui/material';
import React from 'react'

interface TooltipPropsWithWidth extends TooltipProps {
    tooltipwidth?:number
}

export const TooltipWithWidth = ({...props}:TooltipPropsWithWidth) => {

    const {tooltipwidth} = props

    const CustomWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
        <Tooltip {...props} classes={{ popper: className }} />
      ))({
        [`& .${tooltipClasses.tooltip}`]: {
          maxWidth: tooltipwidth ? tooltipwidth : 300,
          textAlign:'center'
        },
      });

  return (
    <CustomWidthTooltip {...props} />
  )
}
