import React, { useMemo } from 'react'
import { IconButton, ListItemText, Tooltip } from '@mui/material'
import { FieldModel, GroupHighlightModel } from '../../../types'
import { GroupItemStyled } from './GroupItemStyled'
import AddIcon from '@mui/icons-material/Add'
import RemoveIcon from '@mui/icons-material/Remove';
import { SelectedFieldOpts } from '../../../providers/HighlightContext'
import classnames from 'classnames'
import LinearProgressStyled from './LinearProgressStyled'

interface GroupItemProps {
    group: GroupHighlightModel;
    selectedField: SelectedFieldOpts | undefined;
    groupFields: GroupHighlightModel | undefined;
    handleTextClick: (group: GroupHighlightModel) => void;
    handleOnHover: (group: GroupHighlightModel) => void;
    handleOnExit: () => void;
    handleAddRemove: (i: GroupHighlightModel, isGroupField: boolean | undefined) => void;
}

export const GroupItem = (
    {
        group, 
        selectedField, 
        groupFields, 
        handleTextClick,
        handleOnHover,
        handleOnExit,
        handleAddRemove,
    }:GroupItemProps) => {   
        

    const enabled = groupFields && groupFields.id === group.id;
    const hasHighlights = useMemo(()=> group.box || (group.fields && group.fields.length>0), [])
    const isGroupField = useMemo(() => group.fields?.some(checkId), [])
    const [progress, setProgress] = React.useState(enabled ? 100 : 0);

    function checkId(value:FieldModel){
        return value.id === selectedField?.field.id
    }

    const onClick = () => {
        handleTextClick(group)
    }

    const onMouseEnter = () => {
        if(hasHighlights){
            if(!enabled){
                setProgress(100)
            }
            handleOnHover(group)
        }
    }

    const onMouseLeave = () => {
        if(hasHighlights){
            setProgress(0)
            handleOnExit() 
        }
    }

    const onAddRemove = () => {
        handleAddRemove(group, isGroupField)
    }


    return (
        <GroupItemStyled 
            className={classnames('group_item', {enabled: !hasHighlights && enabled , selected: isGroupField})}
            disablePadding>
            <ListItemText
                onClick={onClick}
                sx={{width:'100%', mr:'10px', pt:'7px', pb: hasHighlights ? '10px' : '7px', pl:'15px', my:'0'}}
                primary={group.name} 
                primaryTypographyProps={{style:{whiteSpace: "nowrap",textOverflow:'ellipsis',overflow:'hidden', fontSize:'0.875rem'}}} 
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
            />
            <Tooltip arrow title={isGroupField ? "Remove this field from this group" : "Add this field to this group"}>
                <IconButton 
                    color='info'
                    sx={{marginRight:'7px', padding:'5.5px'}} 
                    onClick={onAddRemove}>
                    { isGroupField ? <RemoveIcon/> : <AddIcon/>}
                </IconButton>
            </Tooltip> 
            {hasHighlights && <LinearProgressStyled sx={{position:'absolute', bottom:0, left:0, width:'100%'}} variant="determinate" value={progress} />}
        </GroupItemStyled>
    )
}
