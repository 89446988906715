import React from 'react'
import { RegisterOptions, TextFieldElement } from 'react-hook-form-mui'

interface TextFieldProps {
    name: string;
    label?: string;
    helperText?: string;
    required?: boolean;
    config?:RegisterOptions;
    disabled?:boolean;
}

export const TextField = (props:TextFieldProps) => {

    const { name, label, helperText, required, config, disabled } = props;

    return (
        <TextFieldElement 
            disabled={disabled}
            required={required}
            validation={config}
            fullWidth
            size="small" 
            name={name}
            label={label}
            helperText={helperText}/>
    )
}
