import { Backdrop, Stack, Typography } from '@mui/material'
import React from 'react'
import { Dropzone } from '.'
import { LoadingAnimation } from '../shared/LoadingAnimation'

export const EmptyLibrary = () => {

    const [loading, setLoading] = React.useState<boolean>(false);

    return (
        <>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}>
                <LoadingAnimation />
            </Backdrop>
            <Stack width="100%" sx={{pt:10}} alignItems="center" position="relative">
                <Typography variant="h6">This Library is Currently Empty</Typography>
                <Dropzone setLoading={setLoading} />
            </Stack>
        </>
    )
}
