import { alpha, Box, Button, ButtonGroup, Chip, FormControl, FormHelperText, IconButton, InputLabel, MenuItem, Select, SelectChangeEvent, Stack, styled, Tooltip, Typography, useTheme } from '@mui/material';
import { AnimatePresence, AnimationProps, motion } from 'framer-motion';
import React, { useMemo, useState } from 'react'
import { useCombinedState } from '../../../providers/combinedStateFn';
import useStore from '../../../providers/EditFormState';
import { FormCtx, useFormContext } from '../../../providers/FormContext';
import { HighlightCtx, useHighlightContext, ZPosition } from '../../../providers/HighlightContext';
import MultipleStopIcon from '@mui/icons-material/MultipleStop';
import PreviewIcon from '@mui/icons-material/Preview';
import { usePdfContext } from '../../../providers/PdfContext';
import { FieldModel, FieldType } from '../../../types'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { usePdfNotify } from '../../../providers/PdfNotifyContext';
import { useNotifier } from '../../../providers';
import DoneIcon from '@mui/icons-material/Done';
import ClearIcon from '@mui/icons-material/Clear';
import { UserFormData } from '../../form/form-control-types';
import EastIcon from '@mui/icons-material/East';
import { UseFormSetValue } from 'react-hook-form-mui';

export const AddToConditional = (
    {disabled, form, fieldType, setValue}:
    {disabled:boolean, form:UserFormData, fieldType:FieldType, setValue: UseFormSetValue<{[key: string]: any;}>}
) => {

    const setTextHighfieldIcon = useStore((state) => state.setShowTextHighlight);
    const { state:{createButtonConditional, conditionals}, dispatch } = useFormContext()
    const { state:{selectedField, showConditionals}, dispatch:hDispatch } = useHighlightContext()
    const { state:{document:{pages}, pageNo} } = usePdfContext();
    const setIsEdited = useStore((state) => state.setIsEdited)
    const [conditionalEdited, setConditionalEdited] = useState<boolean>(false)
    const [savedConditionals, setSavedConditionals] = useState<FieldModel[]>([])
    const {removeHighlightedTextFn} = useCombinedState()
    const {showSnackBar, handleClose} = usePdfNotify()
    const {success, warning} = useNotifier()
    const [option, setOption] = useState<string>('');
    const [optionError, setOptionError] = useState<boolean>(false);

    const theme = useTheme();
    
    const formConditionalFields = form.conditional?.fields;
    const existingConditionalIds = useMemo(() => formConditionalFields?.map((i) => i.id), [])
    // const availableFields:FieldModel[] = useMemo(() => pages[pageNo-1].fields.filter((i) => !i.conditional), [])
    const availableFields = useMemo(() =>  pages[pageNo-1].fields.filter((i) => {
        if(!existingConditionalIds?.includes(i.id)){
            if(!i.conditional){return i}
        } else {
            return i
        }
    }), [])

    const addConditional = (f:FieldModel) => {
        dispatch({type:FormCtx.addConditional, payload: f})
        hDispatch({type:HighlightCtx.AddConnectField, payload:{parentField:selectedField?.field,field:f}})
        success(`Added '${f.name}' to '${selectedField?.field.name}' conditional`, {autoHideDuration:2000})
        setConditionalEdited(true)
    }

    const removeConditional = (f:FieldModel) => {
        dispatch({type:FormCtx.rmvConditional, payload: f})
        hDispatch({type:HighlightCtx.RmvConnectField, payload:f})
        warning(`Removed '${f.name}' from '${selectedField?.field.name}' conditional`, {autoHideDuration:2000, persist:false})
        setConditionalEdited(true)
    }

    const handleCancel = () => {
        if(savedConditionals.length>0){
            dispatch({type:FormCtx.addConditionals, payload:savedConditionals})
        } else {
            dispatch({type:FormCtx.addConditional, payload: undefined})
        }
        dispatch({type:FormCtx.createButtonConditional, payload:false})
        handleClose()
        setTextHighfieldIcon(true)
        removeHighlightedTextFn()
        setConditionalEdited(false)
    }

    const handleClick = () => {
        dispatch({type:FormCtx.createButtonConditional, payload:true})
        if(form.conditional?.option)setOption(form.conditional.option)
        setSavedConditionals([])
        if(selectedField){
            if(conditionals){
                setSavedConditionals(conditionals)
                hDispatch({type:HighlightCtx.ShowConnectedFields, payload:{parentField:selectedField.field, connectedFields:conditionals}})
            }
            showSnackBar({text:'Click on any field to add it as a conditional', autoHideDuration:null})
            setTextHighfieldIcon(false)
            hDispatch(
                {
                    type:HighlightCtx.HighlightFields, 
                    payload:{position:ZPosition.Above,elements:availableFields, color:alpha(theme.palette.warning.main,0.3)}
                }
            )
            useStore.setState({setHighlightFn:(f)=>{addConditional(f)}});
        }
    }

    const showPreview = () => {
        if(showConditionals){
            setTextHighfieldIcon(true)
            removeHighlightedTextFn()
        } else {
            if(selectedField && conditionals){
                setTextHighfieldIcon(false)
                hDispatch({type:HighlightCtx.HighlightFields, payload:{elements:[]}})
                hDispatch({type:HighlightCtx.ShowConnectedFields, payload:{parentField:selectedField.field, connectedFields:conditionals}})
            }
        }
    }

    const handleSave = () => {
        if(createButtonConditional){
            if(fieldType === FieldType.List){
                if(option === ''){
                    setOptionError(true)
                    return
                }
                setValue('conditionalOption', option)
            }
            dispatch({type:FormCtx.createButtonConditional, payload:!createButtonConditional})
            setIsEdited(true)
            handleClose()
            setTextHighfieldIcon(true)
            removeHighlightedTextFn()
        }
    }

    
    const handleChange = (event: SelectChangeEvent) => {
        setOption(event.target.value);
        setConditionalEdited(true)
        setOptionError(false)
    };


    const CondtionalButton = styled(Button)(()=>({
        borderBottomLeftRadius: '0',
        borderBottomRightRadius: '0',
    }))


    const variants:AnimationProps['variants'] = {
        open: { opacity: 0, height:'0'},
        closed: { opacity: 1, height: '100%' },
    }

    const conditionalVariants:AnimationProps['variants'] = {
        open: { height: '200px'},
        closed: { height: 0}
    }

    const containerVariants:AnimationProps['variants'] = {
        open: { height: '0' },
        closed: { height: '16px' }
    }

  return (
    <Box>
        <AnimatePresence>
            {createButtonConditional
                ?<ButtonGroup fullWidth>
                    <CondtionalButton 
                        color={'success'}
                        onClick={handleSave} 
                        style={{
                            borderBottom:'solid 1px rgba(0,0,0,0)', 
                            borderLeftColor:alpha(theme.palette.primary.main,0.5), 
                            borderTopColor:alpha(theme.palette.primary.main, 0.5)
                            }}
                        sx={{borderRight:'solid 1px rgba(0,0,0,0)'}} 
                        disabled={!conditionalEdited} 
                        startIcon={ <DoneIcon/> }>
                        Save
                    </CondtionalButton>
                    <CondtionalButton 
                        style={{borderBottom:'solid 1px rgba(0,0,0,0)'}} 
                        sx={{borderLeft:'solid 1px rgba(0,0,0,0)'}} 
                        onClick={handleCancel}
                        startIcon={ <ClearIcon/> }>
                        Cancel
                    </CondtionalButton>
                </ButtonGroup>
                : <motion.div initial={{opacity:0}} animate={{opacity:1}} exit={{opacity:0}} >
                    <Button disabled={disabled||showConditionals} variant={'outlined'} onClick={handleClick} fullWidth color={'success'} startIcon={ <MultipleStopIcon/> }>
                        {conditionals ? 'Edit Conditional' : 'Create Conditional Field'}
                    </Button>
                </motion.div>
            }
        </AnimatePresence>
        <motion.div 
            variants={conditionalVariants}
            style={{marginBottom:'0em',width:'100%'}} 
            transition={{type:'just'}} 
            animate={createButtonConditional ? "open" : "closed" }
            >
                <Box width={'100%'} px={2} mb={2} height={'100%'} overflow={'scroll'} sx={
                    {
                        borderBottomColor:(theme)=>theme.palette.success.light, 
                        borderBottomWidth: '1px',
                        borderBottomStyle: createButtonConditional ? 'solid' : 'none',
                        borderLeftColor: (theme) => theme.palette.success.light ,
                        borderLeftWidth: '1px',
                        borderLeftStyle: 'solid',
                        borderRightColor: (theme) => theme.palette.success.light ,
                        borderRightWidth: '1px',
                        borderRightStyle: 'solid',
                        borderBottomRightRadius: '4px',
                        borderBottomLeftRadius: '4px',
                    }
                } >
                {createButtonConditional &&
                    <>
                    <Stack direction={'row'} justifyContent={'center'} mt={1} alignItems={'center'}>
                        <Chip color={'primary'} variant={'outlined'} label={selectedField?.field.name}/>
                        {fieldType === FieldType.List && selectedField?.field.options &&
                            <>
                            <Box mx={1}><EastIcon/></Box>
                            <FormControl size={'small'} style={{marginLeft:'0.5em'}} fullWidth required>
                                <InputLabel id="conditional-option">Option</InputLabel>
                                <Select
                                    error={optionError}
                                    labelId="conditional-option"
                                    id="conditional-option-select"
                                    value={option}
                                    label="Option"
                                    onChange={handleChange}>
                                    {selectedField.field.options.map((o,i) => (
                                            <MenuItem key={`${i}_${o}`} value={o}>{o}</MenuItem>
                                        ))
                                    }
                                </Select>
                                <FormHelperText>select trigger option</FormHelperText>
                            </FormControl>
                            </>
                        }
                    </Stack>
                    <Stack justifyContent={'center'} direction={'row'} ><KeyboardArrowDownIcon/></Stack>
                    {conditionals && conditionals.length > 0
                        ? <Stack direction={'row'} flexWrap={'wrap'} justifyContent={'center'}>
                                {
                                    conditionals.map((i) => (
                                        <Chip onDelete={()=>removeConditional(i)} sx={{mt:1, mr:1}} key={i.id} label={i.name} />
                                    ))
                                }
                            </Stack>
                        : <Typography mt={1} sx={{color:theme.palette.grey[600]}} textAlign={'center'} variant={'subtitle2'}>
                            To get started, click on any of the fields to your right, this will add them to a list here, 
                            and create the first conditional field connection
                        </Typography>
                    }
                    </>
                }
                </Box>
        </motion.div>        
        {!conditionals && 
            <motion.div style={{pointerEvents:'none'}} transition={{type:'just'}} variants={variants} animate={createButtonConditional ? "open" : "closed" }  >
                <FormHelperText sx={{mx:1.75}} >You can enable fields to show conditionally when a user enables this button</FormHelperText>
            </motion.div>
        }
        <AnimatePresence>
            {conditionals && !createButtonConditional &&
            <motion.div initial={{x:'-100%'}} animate={{x:0}} transition={{type:'just', delay:0.2}} >
                <Stack direction={'row'} alignItems={'center'}>
                    <Stack direction={'row'} overflow={'scroll'} mt={0} alignItems={'center'}>
                        {conditionals.map((i) => (
                            <Box key={i.id} pt={1} pb={2} mr={1}>
                                <Chip label={i.name}/>
                            </Box>
                        ))}
                    </Stack>
                        <Box ml={'auto'} px={0.5} mb={1}>
                            <IconButton onClick={showPreview} disabled={disabled} color={showConditionals ? 'primary' :'default'}>
                                <PreviewIcon/>
                            </IconButton>
                        </Box>
                </Stack>
            </motion.div>}
        </AnimatePresence>
        <motion.div variants={containerVariants} animate={createButtonConditional ? "open" : "closed" } />
    </Box>
  )
}
