import { Menu } from "@mui/material";
import React, { MouseEventHandler, useContext } from "react";


type UseContextShowReturnType = {
    show: boolean;
    setShow: (value:boolean) => void;
    close: () => void;
}

const useContextShow = ():UseContextShowReturnType => {
    const [show, setShow] = React.useState<boolean>(false)
    const handleOnClose = () => {
        setShow(false)
    }

    return { 
        show, 
        setShow, 
        close:handleOnClose
    }
}

type ContextContextType = {
    show:({menu,element}:{menu:JSX.Element,element:React.MouseEvent<HTMLButtonElement|HTMLDivElement>})=>Promise<boolean>
    hide: () => void
}


const ContextContext = React.createContext<ContextContextType>({} as ContextContextType)

function ContextContextProvider({children}:{children:React.ReactNode}){
    const {setShow, show, close} = useContextShow()
    const resolver = React.useRef<any>();
    const [content, setContent] = React.useState<{menu:JSX.Element}|null>()
    const [anchorElContext, setAnchorElContext] = React.useState<null | HTMLElement>(null);
    const [points, setPoints] = React.useState<{x:number,y:number}|null>(null)

    const handleShow = ({menu,element}:{menu:JSX.Element,element:React.MouseEvent<HTMLButtonElement|HTMLDivElement>}):Promise<boolean> => {
        element.preventDefault()
        setAnchorElContext(element.currentTarget)
        setPoints({x:element.clientX, y:element.clientY})
        setContent({menu})
        setShow(true)
        return new Promise(function(resolve){
            resolver.current = resolve
        })
    }

    const handleCloseContext = () => {
        setShow(false)
        setPoints(null)
        setAnchorElContext(null);
        setContent(null)
        resolver.current && resolver.current(false);
        close()
    };

    const contextContext:ContextContextType = {
        show:handleShow,
        hide:handleCloseContext
    }


    return (
        <ContextContext.Provider value={contextContext}>
            {children}
            {content && points &&
                <div style={{width:'100%',height:'100%', position:'absolute', top:0, left:0, zIndex:-1}}>
                    <Menu
                        id='context-menu'
                        anchorEl={anchorElContext}
                        open={show}
                        onClose={handleCloseContext}
                        MenuListProps={{
                            'aria-labelledby': 'context-button'
                        }}
                        anchorReference={'anchorPosition'}
                        anchorPosition={{top:points.y, left:points.x}}
                    >
                        {content.menu}
                    </Menu>
                </div>
            }
        </ContextContext.Provider>
    )
}

const useContextContext = ():ContextContextType => useContext(ContextContext);

export { useContextContext, useContextShow }

export default ContextContextProvider