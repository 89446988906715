import React, { useEffect, useMemo, useState } from 'react'
import { AutocompleteChangeDetails, AutocompleteChangeReason, Button, DialogContent, FormHelperText, IconButton, Skeleton, Stack } from '@mui/material'
import { AutocompleteElement, FormContainer, SubmitHandler, TextFieldElement, useForm } from 'react-hook-form-mui'
import AddIcon from '@mui/icons-material/Add';
import { SectionListItem, SubsectionListItem } from '../../form/form-control-types';
import useStore from '../../../providers/EditFormState';
import { HighlightModel } from '../../../types';
import EditIcon from '@mui/icons-material/Edit';
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';
import DeleteIcon from '@mui/icons-material/Delete';
import CheckIcon from '@mui/icons-material/Check';


export const EditSectionSubModal = ({section, subsection, handleSubmit, editSection, deleteSection, handleAdd, handleAddSub}:{
    section:HighlightModel,
    subsection:HighlightModel|undefined,
    handleAdd: () => void,
    handleAddSub: () => void,
    handleSubmit: SubmitHandler<{[key: string]: any;}> | undefined,
    editSection: (n:string, i:string) => Promise<boolean|undefined>,
    deleteSection: (id: string) => Promise<void>
}) => {

    const formValues = useStore((state) => state.res)
    const formData = useStore((state) => state.setFormData)
    const [sectionList, setSectionList] = useState<SectionListItem[]>([]);
    const [subList, setSubList] = useState<SectionListItem[]>([]);
    const sectionData = formValues?.sectionList
    const subData = formValues?.subsectionList
    const [sectionLoading, setSectionLoading] = useState<boolean>(false)
    const [subsectionLoading, setSubsectionLoading] = useState<boolean>(false)
    const [openSectionEdit, setOpenSectionEdit] = useState<boolean>(false)
    const [openSubsectionEdit, setOpenSubsectionEdit] = useState<boolean>(false)
    
    const defaultValues = useMemo(() => {
        const obj:{[key:string]:any} = {};
        obj['section'] = {id:section.id, label:section.name}
        if(subsection){
            obj['subsection'] = {id:subsection.id, label:subsection.name}
        }
        return obj;
    }, [])



    const deleteSectionFn = async () => {
        setSectionLoading(true)
        try {
            const sectionId = getValues('section').id
            await deleteSection(sectionId)
            const rmvItemFilter = sectionList.filter((v) => v.id !== sectionId)
            setSectionList(rmvItemFilter)
            setValue('section', '')
            if(formValues)formData({...formValues, sectionList:rmvItemFilter})
            setOpenSectionEdit(false)
        } catch (error) {
            console.log(error)
        }
        setSectionLoading(false)
    }

    const editSectionFn = async () => {
        const name = getValues('section-edit')
        const sectionId = getValues('section').id
        setSectionLoading(true)
        try {
            const response = await editSection(name,sectionId)
            if(response){
                const newValue = {id:sectionId, label: name}
                const rmvItemFilter = sectionList.filter((v) => v.id !== newValue.id)
                const newList:SectionListItem[] = [...rmvItemFilter, newValue]
                setSectionList(newList)
                setValue('section', newValue)
                if(formValues)formData({...formValues, sectionList:newList})
                setOpenSectionEdit(false)
            }
        } catch (error) {
            console.log(error)
        }
        setSectionLoading(false)
    }

    const handleEditSubOpen = () => {
        if(getValues('subsection')){
            setOpenSectionEdit(false)
            setOpenSubsectionEdit(!openSubsectionEdit)
            const subsectionValue = getValues('subsection').label
            setValue('subsection-edit', subsectionValue)
        }
    }

    const handleEditOpen = () => {
        if(getValues('section')){
            setOpenSubsectionEdit(false)
            setOpenSectionEdit(!openSectionEdit)
            const sectionValue = getValues('section').label
            setValue('section-edit', sectionValue)
        }
    }

    const formContext = useForm({defaultValues:defaultValues});
    const {watch, formState:{isDirty}, getValues, setValue, getFieldState} = formContext;
    const watchSection = watch("section");
    const { isDirty:editSectionDirty } = getFieldState('section-edit', formContext.formState)
    const { isDirty:editSubsectionDirty } = getFieldState('subsection-edit', formContext.formState)
    
    useEffect(() => {
        const existingSectionList:SectionListItem[] = sectionData ?? []
        setSectionList(existingSectionList)
        if(existingSectionList.length){
            const initialSection:SectionListItem = defaultValues['section']
            updateSubsectionFormValues(initialSection)
        }
    }, [])

    const updateSubsectionFormValues = (section:SectionListItem) => {
        if(subData){
            const subList:SectionListItem[] = []
            for (let i = 0; i < subData.length; i++) {
                const subItem = subData[i];
                if(subItem.sectionId === section.id){
                    const subListItem:SectionListItem = {id:subItem.id, label:subItem.label}
                    subList.push(subListItem)
                }
            }
            setSubList(subList)
        }
    }

    const changedSection = (_: any, value: any,) => {
        const section:SectionListItem = value
        updateSubsectionFormValues(section)
    }

    const SubsectionTextField = () => (
        <>
        <Stack direction={'row'} mt={4}>
            <AutocompleteElement
                autocompleteProps={{size:'small', fullWidth:true, disabled:openSectionEdit || openSubsectionEdit}} 
                name={'subsection'} 
                label={'Add to Sub-Section'} 
                options={subList}
            />
            <IconButton sx={{ml:1}} onClick={handleEditSubOpen} disabled={openSectionEdit} >
                <EditIcon/>
            </IconButton>
            <IconButton onClick={handleAddSub} disabled={openSectionEdit || openSubsectionEdit}>
                <AddIcon/>
            </IconButton>
        </Stack>
        <FormHelperText sx={{ml:1.75, mr:3}}>If you wish to further sectionlize the field into a smaller group</FormHelperText>
        {openSubsectionEdit && 
            <>
            <Stack direction={'row'} sx={{mt:1.5}} alignItems={'center'} >
                <PublishedWithChangesIcon sx={{ ml:2, mr: 1}} />
                {!subsectionLoading
                    ? <TextFieldElement
                        name='subsection-edit'
                        required
                        fullWidth
                        size='small'
                        label='Sub-Section Name'
                    />
                    : <Skeleton animation={'wave'} height={70}/>}
                <IconButton sx={{ml:1}} disabled={!editSubsectionDirty} >
                    <CheckIcon/>
                </IconButton>
                <IconButton >
                    <DeleteIcon/>
                </IconButton>
            </Stack>
            <FormHelperText sx={{ml:8}}>Edit the name, or delete this section</FormHelperText>
            </>
        }
        </>
    )

    return (
    <DialogContent>
        <FormContainer onSuccess={handleSubmit} formContext={formContext}>
            <Stack direction={'row'} mt={1}>
                <AutocompleteElement
                    autocompleteProps={{size:'small', fullWidth:true, disabled:openSectionEdit||openSubsectionEdit, onChange:changedSection}} 
                    name={'section'} 
                    label={'Add to Section'} 
                    options={sectionList} />
                <IconButton sx={{ml:1}} onClick={handleEditOpen} disabled={openSubsectionEdit}>
                    <EditIcon/>
                </IconButton>
                <IconButton onClick={handleAdd} disabled={openSectionEdit || openSubsectionEdit} >
                    <AddIcon/>
                </IconButton>
            </Stack>
            <FormHelperText sx={{ml:1.75, mr:3}}>Include this field in a larger section to seperate which fields appear together</FormHelperText>
            {openSectionEdit && 
                <>
                <Stack direction={'row'} sx={{mt:1.5}} alignItems={'center'} >
                    <PublishedWithChangesIcon sx={{ ml:2, mr: 1}} />
                    {!sectionLoading
                        ? <TextFieldElement
                            name='section-edit'
                            required
                            fullWidth
                            size='small'
                            label='Section Name'
                        />
                        : <Skeleton animation={'wave'} height={70}/>}
                    <IconButton sx={{ml:1}} onClick={editSectionFn} disabled={!editSectionDirty}>
                        <CheckIcon/>
                    </IconButton>
                    <IconButton onClick={deleteSectionFn} disabled={sectionLoading}>
                        <DeleteIcon/>
                    </IconButton>
                </Stack>
                <FormHelperText sx={{ml:8}}>Edit the name, or delete this section</FormHelperText>
                </>
            }
            {
                watchSection && <SubsectionTextField />
            }
            <Button
                disabled={!isDirty}
                sx={{mt:4}}
                type='submit'
                variant="outlined"
                >
                Edit
            </Button>
        </FormContainer>
    </DialogContent>
    )
}
