import { LoadingButton } from '@mui/lab';
import { Alert, Box, debounce, DialogContent, Stack } from '@mui/material';
import React, { useState } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { FormContainer, SelectElement, TextFieldElement } from 'react-hook-form-mui';
import { GroupHighlightModel } from '../../../types';
import { useInstance } from '../../../utils/axiosConfig';

type Inputs = {
    group_name:string;
    group_type:string;
}

export const GroupModalFields = (
    {id,handleAddGroup}:
    {id:string,handleAddGroup: (group: GroupHighlightModel) => void}):JSX.Element => {

    const [loading, setLoading] = useState<boolean>(false);
    const formContext = useForm<Inputs>();
    const {formState:{isDirty}} = formContext
    const [disabled, setDisabled] = React.useState<boolean>(false);
    const [status, setStatus] = React.useState<boolean|undefined>(undefined)
    const {instance} = useInstance()
    const onSubmit:SubmitHandler<Inputs> = data => handleSubmit(data);


    const handleSubmit = (e:Inputs) => {
        setLoading(true);
        instance.post(`/api/groups/add/${id}`, e)
        .then((res) => 
        {   
            setLoading(false);
            handleAddGroup(res.data as GroupHighlightModel)
        })
        .catch(err => {
            setLoading(false);
            console.log(err);
        });
    }


    const checkTitle = async (text:string) => {
        if(text !== ''){
            const searchTerm = {"search":text}
            await instance.post(`/api/groups/check/${id}`,searchTerm)
            .then((res) => {
                const availability = res.data as boolean
                if(availability){
                    setStatus(true)
                } else {
                    setStatus(false)
                    setDisabled(true)
                }
                setLoading(false)
            })
            .catch((err) => console.log(err))
        } else {
            setStatus(undefined)
            setLoading(false)
        }
    }


    const debouncedSearch = debounce((text:string)=>{
        checkTitle(text)
    }, 1000)


    const onAmountChanged = React.useMemo( 
        ()=> (e: string) => {
            debouncedSearch(e)
            setDisabled(false)
            setLoading(true)
        }, 
        []
    );


    const options= [
        {id:'multi', label:'Multi'},
        {id:'single', label:'Single'}
    ]


    return (
        <DialogContent sx={{maxWidth:'500px'}}>
            <Stack mt={1}>
                <FormContainer formContext={formContext} onSuccess={onSubmit}>
                    <Stack spacing={2}>
                        <Box>
                            <Controller 
                                render={({ field }) => (
                                    <TextFieldElement
                                        onChange={(e) => {
                                            field.onChange(e);
                                            onAmountChanged(e.target.value)
                                        }} 
                                        name={field.name}
                                        required 
                                        fullWidth 
                                        size='small' 
                                        label='Group Name' 
                                        helperText='What will be the name of this group?'
                                        autoComplete='off'
                                    />
                                )} 
                                name={'group_name'}                            
                            />
                            {
                                status && <Alert sx={{mt:1, mb:3}} severity={'success'}>Group Name Available</Alert>
                            }
                            {
                                status === false && <Alert sx={{mt:1, mb:3}} severity={'error'}>This group name is already in use, try another</Alert>
                            }
                        </Box>
                        <SelectElement
                            name='group_type'
                            required
                            fullWidth
                            size='small'
                            label='Type'
                            helperText='What is the relationship between the items in this group, for single only one can be selected, for multi many can be selected'
                            options={options}
                        />
                    </Stack>
                    <LoadingButton
                        disabled={!isDirty||disabled}
                        sx={{mt:4}}
                        type='submit'
                        loading={loading}
                        variant="outlined"
                        >
                        Submit
                    </LoadingButton>
                </FormContainer>
            </Stack>
        </DialogContent>
    )
}
