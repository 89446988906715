import { Alert, Snackbar } from '@mui/material'
import React from 'react'
import { usePdfNotify } from '../../providers/PdfNotifyContext';

export const PDFSnackbar = () => {

    const notify = usePdfNotify();

  return (
    <Snackbar
        style={{top:0, position:'absolute'}}
        open={notify.state.open}
        autoHideDuration={notify.state.autoHideDuration}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        onClose={notify.handleClose}>
        <Alert sx={{boxShadow:'0 0.3em 1em rgba(0,0,0,0.2)'}} onClose={notify.handleClose} severity={notify.state.typeColor}>
            {notify.state.message}
        </Alert>
    </Snackbar>
  )
}
