import { Box, CircularProgress, FormControlLabel, Switch } from '@mui/material';
import React from 'react'


export const LoadingSwitch = ({loadingFn, showLabel, hideLabel, disabled}:
  {
    loadingFn:(event: React.ChangeEvent<HTMLInputElement>) => Promise<void>, 
    showLabel:string, 
    hideLabel:string,
    disabled?:boolean|undefined,
  }) => {

    const [switchValue, setSwitchValue] = React.useState(false);
    const [loading, setLoading] = React.useState(false);

    const handleChange = async (e:React.ChangeEvent<HTMLInputElement>) => {
        setSwitchValue(!switchValue);
        setLoading(true);
        try {
            await loadingFn(e)
            setLoading(false)
        } catch (error) {
            setLoading(false)
        }
      };


      const Icon = () => (
        <Box
            className={switchValue && !loading ? 'active' : ''}
            sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: 20,
                height: 20,
                borderRadius: "50%",
                backgroundColor: (theme) => theme.palette.background.default,
                boxShadow: (theme) => theme.shadows[1],
                '&.active':{
                    backgroundColor:(theme)=> theme.palette.primary.main  
                }
            }}
        >
          {loading && (
            <CircularProgress size={14} thickness={8} />
          )}
        </Box>
      );

    return (
      <FormControlLabel control={
        <Switch
            checkedIcon={<Icon />}
            icon={<Icon />}
            disabled={loading||disabled}
            checked={switchValue}
            onChange={handleChange}/>}
          label={switchValue ? <b>{hideLabel}</b> : <b>{showLabel}</b>}
        />
      );
}