import React, { Suspense } from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { EditPdf } from './views/EditPdf';
import { Dashboard } from './views/Dashboard';
import { UnifiedEditor } from './views/UnifiedEditor';
import { Files } from './views/Files';
import { Folders } from './views/Folders';
import { Search } from './views/Search';
import { Layout } from './views/Layout';
import { Login } from './views/Login';
import { useAuthContext } from './providers/AuthContext';
import { LoadingAnimation } from './components/shared/LoadingAnimation';
import { useAuthStatus } from './auth/useAuth';
import { AxiosInterceptor } from './utils/axiosConfig';


const App = () => {

  const {state:{user:isAuthenticated, loading}} = useAuthContext()
  const {authStatus} = useAuthStatus()

  React.useEffect(() => {
    authStatus()
  }, [])
  

  return (
    <>
    {loading
      ? <LoadingAnimation/>
      : <BrowserRouter>
        <Suspense fallback={<div>Loading...</div>}>
          <AxiosInterceptor>
            {isAuthenticated
              ? <PrivateRoutes/>
              : <PublicRoutes/>
            }
          </AxiosInterceptor>
        </Suspense>
    </BrowserRouter>
    }
    </>
  )
}

const PrivateRoutes = () => {
  return (
    <Routes>
      <Route path='/' element={<Layout />} >
        <Route index element={ <Navigate to="/dashboard" /> } />
        <Route path='/edit/:id' element={<EditPdf />} />
        <Route path='/dashboard' element={ <Dashboard/> }>
          <Route index element={ <Navigate to="/dashboard/files" /> } />
          <Route path='files' element={ <Files/> } />
          <Route path='folders/:id' element={ <Folders/> } />
          <Route path='search/:term' element={ <Search/> } />
        </Route>
        <Route path={'/unified'} element={ <UnifiedEditor/> } />
      </Route>
      <Route path='/login' element={ <Navigate to="/"/> } />
    </Routes>
  )
}

const PublicRoutes = () => {
  return (
    <Routes>
      <Route index element={ <Navigate to="/login" /> } />
      <Route path={'/login'} element={<Login/>} />
      <Route path='/*' element={<Navigate to='/login' replace />} />
    </Routes>
  )
}

export default App;
