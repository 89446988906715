import React, { useEffect, useState } from 'react'
import { Check } from '@mui/icons-material';
import { Box, IconButton, Stack, Step, StepConnector, stepConnectorClasses, StepIconProps, StepLabel, stepLabelClasses, Stepper, styled, Tooltip } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { HighlightCtx, useHighlightContext } from '../../../providers/HighlightContext';
import { HighlightModel, SubsectionHighlightModel, SubsectionSectionHighlightModel } from '../../../types';
import { useCombinedState } from '../../../providers/combinedStateFn';
import useStore from '../../../providers/EditFormState';
import { useModalContext } from '../../../providers/ModalContext';
import { EditSectionSubModal } from './EditSectionSubModal';
import { FormCtx, useFormContext } from '../../../providers/FormContext';
import { UseFormSetValue } from 'react-hook-form-mui';
import { PdfCtx, usePdfContext } from '../../../providers/PdfContext';
import { useNotifier } from '../../../providers';
import { SectionListItem, SubsectionListItem } from '../../form/form-control-types';
import { SectionModalFields } from './SectionModalFields';
import { SubsectionModalFields } from '../create-subsection/SubsectionModalFields';
import { useInstance } from '../../../utils/axiosConfig';

export const CompletedSectionHeader = ({section,setValue, setSectionList, disabled}:{
    section:HighlightModel,
    setValue: UseFormSetValue<{[key: string]: any;}>
    setSectionList: React.Dispatch<React.SetStateAction<SectionListItem[]>>
    disabled?: boolean | undefined
}) => {

    const {dispatch} = useHighlightContext()
    const {removeHighlightedTextFn} = useCombinedState()
    const {state:{subsection}, dispatch:fDispatch} = useFormContext()
    const {state:{document:{id}, pageNo, currentSelectedSection}, dispatch:pDispatch} = usePdfContext()
    const setTextHighfieldIcon = useStore((state) => state.setShowTextHighlight);
    const setIsEdited = useStore((state) => state.setIsEdited)
    const formData = useStore((state) => state.setFormData)
    const formValues = useStore((state) => state.res)
    const [sectionModel, setSectionModel] = useState<HighlightModel>(section)
    const modal = useModalContext();
    const [enablePreview, setEnablePreview] = useState<boolean>(false)
    const [steps, setSteps] = useState<SectionListItem[]>([])
    const step = subsection ? 2 : 1
    const notify = useNotifier()
    const {instance} = useInstance()

    useEffect(() => {
        const sec:SectionListItem = {id:section.id, label: section.name}
        const sub:SectionListItem = {id:subsection?.id ?? '0',label: subsection?.name??'No Subsection'}
        const s:SectionListItem[] = [sec,sub]
        setSteps(s)
    }, [])
    

    const QontoConnector = styled(StepConnector)(({ theme }) => ({
        [`&.${stepConnectorClasses.alternativeLabel}`]: {
            top: 15,
            left: 'calc(-50% + 16px)',
            right: 'calc(50% + 16px)',
        },
        [`&.${stepConnectorClasses.active}`]: {
            [`& .${stepConnectorClasses.line}`]: {
            borderColor: theme.palette.grey[400],
            },
        },
        [`&.${stepConnectorClasses.completed}`]: {
            [`& .${stepConnectorClasses.line}`]: {
            borderColor: theme.palette.success.main,
            },
        },
        [`& .${stepConnectorClasses.line}`]: {
            borderColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
            borderTopWidth: 3,
            borderRadius: 1,
        }
    }));

    const QontoStepIconRoot = styled('div')<{ ownerState: { active?: boolean } }>(
        ({ theme, ownerState }) => ({
            color: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#eaeaf0',
            display: 'flex',
            height: 32,
            alignItems: 'center',
            ...(ownerState.active && {
            color: theme.palette.grey[400]
            }),
            '& .QontoStepIcon-completedIcon': {
            color: theme.palette.success.light,
            zIndex: 1,
            fontSize: 18,
            },
            '& .QontoStepIcon-circle': {
            width: 8,
            height: 8,
            borderRadius: '50%',
            backgroundColor: 'currentColor',
            },
        }),
    );

    const QontoStepLabel = styled(StepLabel)(({theme})=>({
        [`& .${stepLabelClasses.labelContainer}`]: {
            [`& .${stepLabelClasses.label}`]: {
                marginTop: '0'
            },
        },
        [`& .${stepLabelClasses.active}`]: {
            fontWeight: 'normal'
        }
    }))

    function QontoStepIcon(props: StepIconProps) {
        const { active, completed, className, icon } = props;
        
        return (
            <QontoStepIconRoot ownerState={{ active }} className={className}>
            {completed ? (
                // <Check className="QontoStepIcon-completedIcon" />
                <IconButton  onClick={()=>handlePreview(icon)} disabled={disabled}>
                    <Check className="QontoStepIcon-completedIcon" />
                </IconButton>
            ) : (
                <div className="QontoStepIcon-circle" />
            )}
            </QontoStepIconRoot>
        );
    }


    const previewOff = () => {
        setEnablePreview(false)
        setTextHighfieldIcon(true)
        removeHighlightedTextFn()
    }

    const handlePreview = (e:React.ReactNode) => {
        if(enablePreview){
            previewOff()
        } else {
            const pos:number = e as number
            setEnablePreview(true);
            setTextHighfieldIcon(false)
            if(pos === 1){
                dispatch({type:HighlightCtx.HighlightSections, payload:[sectionModel]})
            } else if(pos === 2 && subsection){
                const item:SubsectionSectionHighlightModel = {...subsection, section:section}
                dispatch({type:HighlightCtx.HighlightSubsections, payload:item})
            }
        }
    }

    const handleSubmit = (data:{[key: string]: any;}) => {
        fDispatch({type:FormCtx.section, payload:undefined})
        if(data['section'] && data['section'].id !== sectionModel.id){
            const item = {id:data['section'].id, label:data['section'].label}
            setValue('section', item)
            setIsEdited(true)
        } else if(data['section'] === null) {
            setValue('section','')
            setIsEdited(true)
        }
        if(data['subsection'] && data['subsection'].id !== subsection?.id){
            const item:SectionListItem = {id:data['subsection'].id, label:data['subsection'].label}
            setValue('subsection', item)
            setIsEdited(true)
        } else if(data['subsection'] === null) {
            setValue('subsection','')
            setIsEdited(true)
        }
        modal.hide()
    }

    const editSection = async (name:string, sectionId:string) => {
        const form = new FormData()
        form.append('name', name)
        const edit = new Promise(function(resolve:(value:boolean)=>void, reject){
            instance.patch(`/api/sections/edit/${id}/${pageNo}/${sectionId}`, 
                form, 
                {headers: {'content-type': 'multipart/form-data'}}
                )
            .then((res) => {
                const value:HighlightModel = res.data as HighlightModel
                pDispatch({type:PdfCtx.EditSections, payload:[value]})
                const newValue = {id:value.id, label:value.name}
                const oldSecId = steps[0].id
                if(oldSecId === value.id){
                    setSteps((prev) => {
                        const remove = prev.filter((i) => i.id !== value.id)
                        const newSteps:SectionListItem[] = [newValue, remove[0]]
                        return newSteps
                    })
                }
                setSectionList((prev) => {
                    const item:SectionListItem = newValue
                    const rmvItemFilter = prev.filter((v) => v.id !== item.id)
                    return [...rmvItemFilter, item]
                })
                setValue('section', newValue)
                setSectionModel(value)
                resolve(true)
                })
            .catch(err => {
                console.log(err)
                reject(err)
            })
        })
        try {
            const value = await edit;
            notify.success('Section name was changed')
            return value
        } catch (error) {
            console.log(error)
            notify.error('There was an error')
        }
    }

    const deleteSection = async (sectionId:string) => {
        const deleteSection = new Promise<void>(function(resolve, reject) {
            instance.delete(`/api/sections/remove/${id}/${sectionId}`)
                .then((res) => {
                    const value:string = res.data as string
                    pDispatch({type:PdfCtx.DeleteSection, payload:value})
                    setIsEdited(true)
                    setSectionList((prev) => {
                        const rmvItemFilter = prev.filter((v) => v.id !== value)
                        return rmvItemFilter
                    })
                    const oldSecId = steps[0].id
                    if(oldSecId === value){
                        setValue('section','')
                        fDispatch({type:FormCtx.section, payload:undefined})
                    }
                    if(currentSelectedSection?.id === value){
                        pDispatch({type:PdfCtx.SelectedSection, payload:undefined})
                    }
                    resolve()
                })
            .catch(err => {
                console.log(err)
                reject(err)
            })
        })
        try {
            await deleteSection
            notify.success('Section was deleted')
        } catch (error) {
            console.log(error)
            notify.error('There was an error')
        }
    }

    const handleAddSection = (section:HighlightModel) => {
        pDispatch({type:PdfCtx.EditSections, payload:[section]})
        const sectionItem:SectionListItem = {id: section.id, label: section.name}
        const exitingSectionList:SectionListItem[] = formValues?.sectionList ?? []
        if(formValues)formData({...formValues, sectionList:[...exitingSectionList, sectionItem] })
        setValue('section', {id:section.id, label:section.name})
        setIsEdited(true)
        fDispatch({type:FormCtx.section, payload:undefined})
        modal.hide()
        notify.success(`Successfully created a new section '${section.name}'`)
    }


    const handleAdd = () => {
        modal.show({
            title:'Create new section',
            text: <SectionModalFields id={id} handleAddSection={handleAddSection}/>,
        })
    }

    const handleAddSubsection = (subsection:SubsectionHighlightModel) => {
        pDispatch({type:PdfCtx.EditSubsections, payload:[subsection]})
        const subsectionItem:SubsectionListItem = {id: subsection.id, label: subsection.name, sectionId: subsection.sectionId }
        const exitingSubsectionList:SubsectionListItem[] = formValues?.subsectionList ?? []
        if(formValues)formData({...formValues, subsectionList:[...exitingSubsectionList, subsectionItem] })
        setValue('subsection', {id:subsection.id, label:subsection.name})
        setIsEdited(true)
        modal.hide()
        notify.success(`Successfully created a new sub-section '${subsection.name}'`)
    }

    const handleAddSub = () => {
        const sectionId = section.id
        modal.show({
            title:'Create new Sub-Section',
            text: <SubsectionModalFields id={id} sectionId={sectionId} handleAddSub={handleAddSubsection}/>,
        })
        
    }


    const handleEdit = () => {
        modal.show({
            title:'Edit Section/Sub-Section',
            text: <EditSectionSubModal 
                    section={sectionModel} 
                    subsection={subsection}
                    handleSubmit={handleSubmit} 
                    editSection={editSection} 
                    deleteSection={deleteSection}
                    handleAdd={handleAdd}
                    handleAddSub={handleAddSub} />,
        })
    }


    return (
        <Stack direction={'row'} alignItems={'center'}>
            {/* <Box sx={{p:0.5, borderRadius:2,backgroundColor:(theme) => theme.palette.grey[300]}}>
                <TitleIcon/>
            </Box> */}
            <Box width={'100%'}>
                <Stepper alternativeLabel activeStep={step} connector={<QontoConnector />}>
                    {steps.map((item) => (
                        <Step key={item.id}>
                            <QontoStepLabel StepIconComponent={QontoStepIcon} data-id={item.id}>
                                {item.label}
                            </QontoStepLabel>
                        </Step>
                    ))}
                </Stepper>
            </Box>
            <Tooltip title={'Edit Sections and Sub-Sections'}>
                <Box component={'span'}>
                    <IconButton onClick={handleEdit} disabled={disabled}>
                        <EditIcon/>
                    </IconButton>
                </Box>
            </Tooltip>
        </Stack>
    )
}
