import { LoadingButton } from '@mui/lab'
import { Box, DialogContent, DialogContentText, Divider, LinearProgress, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material'
import React, { ChangeEvent } from 'react'
import { useNotifier } from '../../../providers'
import { FieldModel, PdfEditSubmission } from '../../../types'
import { useInstance } from '../../../utils/axiosConfig'

export const ReplacementPdfModal = ({id, handleSubmission}:{id:string, handleSubmission: (fields: FieldModel[]) => void}) => {

    const [loading, setLoading] = React.useState<boolean>(false)
    const [submissionResponse, setSubmissionResponse] = React.useState<PdfEditSubmission|undefined>(undefined)
    const [pdfFile, setPdfFile] = React.useState<File|undefined>(undefined)
    const {instance} = useInstance()
    const notify = useNotifier()


    const handleFileUpload = (e: ChangeEvent<HTMLInputElement>) => {
        setLoading(true);
        if (!e.target.files) {
            return;
        }
        const file = e.target.files[0];

        if(file){
            const formData = new FormData();
            formData.append("file", file, file.name);
            instance.post(`/api/pdf/replace_existing_pdf/${id}`,formData)
            .then((res) => {
                const data = res.data as PdfEditSubmission
                setSubmissionResponse(data)
                setLoading(false)
                setPdfFile(file)
            })
            .catch(err => {
                setLoading(false);
                console.log(err);
                notify.error('There was an error analyzing the PDF')
            });   
        }
    }

    const handleFileSubmit = () => {
        setLoading(true)
        if(!pdfFile){
            return
        }
        const formData = new FormData();
        formData.append("file", pdfFile, pdfFile.name);
        instance.post(`/api/pdf/replace_existing_pdf_submission/${id}`,formData)
            .then((res) => {
                setLoading(false)
                const data = res.data as FieldModel[]
                handleSubmission(data)
            })
            .catch(err => {
                setLoading(false);
                console.log(err);
                notify.error('There was an error submitting the PDF')
            }); 
    }


    return (
        <DialogContent>
            <DialogContentText>
                Add a pdf here that matches the existing pdf, it can contain extra fields, 
                but not have existing fields removed
            </DialogContentText>
            {loading && !submissionResponse &&
                <Box sx={{ width: '100%' }}><LinearProgress /></Box>
            }
            {submissionResponse && !submissionResponse.error && submissionResponse.fields && submissionResponse.fields.length>0
                ? <LoadingButton onClick={handleFileSubmit} loading={loading} sx={{mt:2}} variant={'contained'} color={'success'} component={'label'}>
                    Submit
                </LoadingButton>
                : <LoadingButton loading={loading} sx={{mt:2}} variant={'contained'} color={'info'} component={'label'}>
                    Select
                    <input type="file" accept=".pdf" hidden onChange={handleFileUpload} />
                </LoadingButton>

            }
            {submissionResponse &&
                <>
                    <Divider sx={{my:2}}/>
                    <Typography variant={'h6'}>Submission Results</Typography>
                    <Typography>{submissionResponse.message}</Typography>
                    {
                        submissionResponse.fields && 
                        <Box mt={2}>
                            <Typography variant={'caption'}>{`New Fields to Add (${submissionResponse.fields.length})`}</Typography>
                            <TableContainer sx={{mb:2, mt:1}} component={Paper}>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Name</TableCell>
                                            <TableCell>Type</TableCell>
                                            <TableCell>Page</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {
                                            submissionResponse.fields.map((f) => (
                                                <TableRow key={f.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                    <TableCell component={'th'}>{f.name}</TableCell>
                                                    <TableCell>{f.type}</TableCell>
                                                    <TableCell>{f.page}</TableCell>
                                                </TableRow>
                                            ))
                                        }
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Box>
                    }
                </>
            }
        </DialogContent>
    )
}
