import React, { useContext } from 'react'
import { PDFSorting, PdfFolderModel, PdfImageModel, PdfImageStatus, PdfListPagination, PdfListPaginationClient } from '../types';
import { createCtx } from '../utils/createCtx'
import { JobResponse } from '../components/unified-editor/types';


const getStatus = (response:JobResponse):PdfImageStatus => {
    if(response.isSuccess){
        if(response.isOver){
            return PdfImageStatus.complete
        } else {
            return PdfImageStatus.loading
        }
    }
    return PdfImageStatus.fail
}

export interface LogoData {
    id: string;
    url: string;
}

export interface UpdateName {
    id: string;
    name: string;
}

export enum DashboardCtx {
    AddData = 'ADDDATA',
    SetData = 'SETDATA',
    Initial = 'INITIAL',
    RemovePdf = 'REMOVEPDF',
    SetLogo = 'SETLOGO',
    UpdateName = 'UPDATENAME',
    AddFolder = 'ADDFOLDER',
    SetSorting = 'SETSORTING',
    SetStatus = 'SETSTATUS',
    SetLoading = 'SETLOADING',
    RemoveLoading = 'REMOVELOADING'
}

export type DashboardAction =
    | {type: DashboardCtx.AddData, payload:PdfListPagination}
    | {type: DashboardCtx.SetData, payload: PdfListPagination}
    | {type: DashboardCtx.Initial}
    | {type: DashboardCtx.RemovePdf, payload: string}
    | {type: DashboardCtx.SetLogo, payload: LogoData}
    | {type: DashboardCtx.UpdateName, payload: UpdateName}
    | {type: DashboardCtx.AddFolder, payload: PdfFolderModel}
    | {type: DashboardCtx.SetStatus, payload: JobResponse}
    | {type: DashboardCtx.SetLoading, payload: JobResponse}
    | {type: DashboardCtx.RemoveLoading}


const initialState:PdfListPaginationClient = {
    items: [],
    count: 0,
    folder: null,
    hasNextPage: false,
    sorting: PDFSorting.default,
    disabled: false
};

type AppState = typeof initialState

const reducer = (state:PdfListPaginationClient,action:DashboardAction):AppState => {
    switch (action.type) {
        case DashboardCtx.AddData:{
            const updatedState = state
            const updatedList:PdfImageModel|PdfFolderModel[] = [...updatedState.items, ...action.payload.items]
            // if(updatedList.some((i) => i.status === PdfImageStatus.loading)){
            //     updatedState.disabled = true
            // }
            if(action.payload.job){
                updatedState.disabled = true
            }
            return {...updatedState, items:updatedList, hasNextPage:action.payload.hasNextPage, job:action.payload.job}
        }
        case DashboardCtx.SetData:{
            const data:PdfListPaginationClient = {...action.payload, disabled:false}
            // if(data.items.some((i) => i.status === PdfImageStatus.loading)){
            //     data.disabled = true
            // }
            if(action.payload.job){
                data.disabled = true
            }
            return data
        }
        case DashboardCtx.Initial:
            return initialState
        case DashboardCtx.RemovePdf:{
            let newCount = state.count
            const newData = state.items.filter(function(element){
                if(action.payload !== element.id){
                    return element;
                }
            })
            if(newData.length !== state.items.length){
                newCount = newCount - 1
            }
            return {...state, items:newData, count:newCount}
        }
        case DashboardCtx.SetLogo:{
            const updatedData = state.items.map((v) => {
                if(v.id === action.payload.id){
                    return {...v, logo:action.payload.url}
                }
                return v;
            })
            return {...state, items:updatedData}
        }
        case DashboardCtx.UpdateName:{
            const updatedData = state.items.map((v) => {
                if(v.id === action.payload.id){
                    return {...v, name:action.payload.name}
                }
                return v;
            })
            return {...state, items:updatedData}
        }
        case DashboardCtx.AddFolder:{
            if(!state.items.includes(action.payload)){
                const updatedData = [...state.items, action.payload]
                const updatedCount = state.count + 1
                return {...state, items:updatedData, count:updatedCount}
            }
            return state
        }
        case DashboardCtx.SetStatus:{
            const updatedState = state
            const updatedItems = updatedState.items
            for (let i = 0; i < updatedItems.length; i++) {
                const item = updatedItems[i];
                if(item.id === action.payload.id){
                    const status = getStatus(action.payload)
                    const updatedItem:PdfImageModel|PdfFolderModel = {...item, status:status}
                    updatedItems.splice(i, 1, updatedItem)
                }
            }
            if(getStatus(action.payload) === PdfImageStatus.complete){
                updatedState.disabled = false
                updatedState.job = undefined
            }
            // if(updatedItems.some((i) => i.status === PdfImageStatus.loading)){
            //     updatedState.disabled = true
            // } else {
            //     updatedState.disabled = false
            // }
            return {...updatedState, items:[...updatedItems]}
        }
        case DashboardCtx.SetLoading:{
            const updatedItems = state.items
            for (let i = 0; i < updatedItems.length; i++) {
                const item = updatedItems[i];
                if(item.id === action.payload.id){
                    const updatedItem:PdfImageModel|PdfFolderModel = {...item, status:PdfImageStatus.loading}
                    updatedItems.splice(i, 1, updatedItem)
                }
            }
            return {...state, items:[...updatedItems], disabled:true, job:action.payload}
        }
        case DashboardCtx.RemoveLoading:{
            const updatedItems = state.items
            for (let i = 0; i < updatedItems.length; i++) {
                const item = updatedItems[i];
                if(item.status === PdfImageStatus.loading){
                    const updatedItem:PdfFolderModel|PdfFolderModel = {...item, status:PdfImageStatus.default}
                    updatedItems.splice(i, 1, updatedItem)
                }
            }
            return {...state, items:[...updatedItems], disabled:false}
        }
        default:
            return state
    }
}

const [ ctx, DashboardContextProvider ] = createCtx(reducer, initialState)
const DashboardContext = ctx;
const useDashboardContext = () => {
    const dashboardContext = useContext(DashboardContext)
    if(!dashboardContext){
        throw new Error('No DashboardContext.Provider found when calling useHighlightContext');
    }
    return dashboardContext;
} 

export { useDashboardContext, DashboardContextProvider }