import React, { useEffect, useRef, useState } from 'react'
import { Alert, Backdrop, Box, Button, Dialog, Skeleton, Snackbar, Stack } from '@mui/material';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import { AnimatePresence, motion } from 'framer-motion';
import { Sidebar } from '.';
import { EditBar } from '../editor';
import { HighlightPdfField } from './HighlightPdfField';
import { PdfCtx, usePdfContext } from '../../providers/PdfContext';
import { FieldModel } from '../../types';
import useStore from '../../providers/EditFormState';
import { SearchFieldTitle } from '../editor/SearchFieldTitle';
import { HighlightCtx, useHighlightContext } from '../../providers/HighlightContext';
import './PDFViewer.css';
import { usePdfNotify } from '../../providers/PdfNotifyContext';
import { useModalContext } from '../../providers/ModalContext';
import { FormContextProvider } from '../../providers/FormContext';
import { HighlightSelectedField } from './HighlightSelectedField';
import { OutlineBox } from './OutlineBox';
import { ConnectionPoints } from './ConnectionPoints';
import { UnifiedModal } from '../form/unified/UnifiedModal';
import { FullBoxProps, PdfCanvas } from '../editor/PdfCanvas';
import { EditPdfToolbar } from '../editor/edit-pdf/EditPdfToolbar';
import { useSearchParams } from 'react-router-dom';
import { PDFSnackbar } from './PDFSnackbar';
import { EditPdfForm } from '../editor/edit-pdf/EditPdfForm';


export const PDFViewer = ({file}:{file:File|string}) => {

    const {state:{document, pageNo, editPdfMode, editFormMode}, dispatch:pdfDispatch} = usePdfContext()
    const {state:{highlightedElements, selectedField, highlightType, outlineBox, connectFields}, dispatch} = useHighlightContext()
    const textHighlightSearch = useStore((state) => state.showTextHighlightSearch)
    const isEdited = useStore((state) => state.isEdited)
    const loading = useStore((state) => state.loading);
    const resetEditFormState = useStore((state) => state.initial)
    const setHighlightFn = useStore((state) => state.setHighlightFn)

    const [isRendered, setIsRendered] = useState<boolean>();
    const [numPages, setNumPages] = useState<number|null>(null);
    const [renderedPageNo, setRenderedPageNo] = useState<number|null>(null);
    const [scale, setScale] = useState(1);
    const [renderedScale, setRenderedScale] = useState<number|undefined>(undefined);
    const [pdfLoading, setPdfLoading] = useState<boolean>(true);
    const [showFieldLabels, setShowFieldLabels] = useState<boolean>(false);

    const [searchParams, setSearchParams] = useSearchParams();
    
    const notify = usePdfNotify();
    const modal = useModalContext();
    const canvas = useRef<HTMLCanvasElement>(null);    
    const isLoading = renderedPageNo !== pageNo || renderedScale !== scale;    

    useEffect(() => {
        if(renderedPageNo !== pageNo){
            const pageFields = document.pages[pageNo-1].fields;
            dispatch({type:HighlightCtx.HighlightFields, payload:{elements:pageFields}})
            setTimeout(() => {
                notify.showSnackBar({text:`You're on page ${pageNo}`, autoHideDuration:1500})
            }, 500);
        }
    }, [isLoading])


    useEffect(()=> {
        // set initial highlight button function on page load
        initialHighlightClickFn();
    }, [])    

    const options = {
        cMapUrl: 'cmaps/',
        cMapPacked: true,
        standardFontDataUrl: 'standard_fonts/',
    };

    function onRenderSuccess() {
        setIsRendered(true);
        setRenderedPageNo(pageNo);
        setRenderedScale(scale);
    }

    function onDocumentLoadSuccess({ numPages: nextNumPages }:{ numPages: any }) {
        setNumPages(nextNumPages);
        setTimeout(() => {
            setPdfLoading(false)
            const page = searchParams.get("page")
            const field = searchParams.get("id")
            if(page){
                pdfDispatch({type:PdfCtx.Page,payload: Number(page)})
                if(field){
                    const fieldData = document.pages[Number(page)-1].fields.find((f) => f.id === field)
                    if(fieldData){
                        handleHighlightClick(fieldData)
                    }
                }
            } else {
                setSearchParams([['page','1']])
            }
        }, 1000);
    }

    const handleCloseModal = () => {
        modal.hide()
    }

    const handleConfirmForm = () => {
        clearEditbar()
    }

    const clearEditbar = () => {
        const pageFields = document.pages[pageNo-1].fields;
        dispatch({type:HighlightCtx.Initial, payload:pageFields});
        initialHighlightClickFn();
        resetEditFormState();
        handleCloseModal();
        setSearchParams([['page',`${pageNo}`]])
    }

    const modalActions:JSX.Element = 
        <>
            <Button onClick={handleCloseModal}>Cancel</Button>
            <Button onClick={handleConfirmForm} autoFocus>Yes</Button>
        </>;

    const handleCloseEditbar = () => {
        if(!loading){
            if(isEdited){
                modal.show({
                    title: "Discard form data?",
                    text:`You've edited some values on this field, are you sure you 
                        would like to discard your edits, your changes won't be saved`,
                    actions: modalActions
                })
            } else {
                clearEditbar()
            }
        }
    }

    const initialHighlightClickFn = () => {
        useStore.setState({setHighlightFn:(f)=>{
            dispatch({type: HighlightCtx.SelectedField, payload: {field:f}})
        }});
    }

    const handleHighlightClick = (f:FieldModel) => {
        dispatch({type:HighlightCtx.HideBox})
        if(setHighlightFn){
            setHighlightFn(f)
            setSearchParams([['page',`${pageNo}`],['id',`${f.id}`]])
        }
    }
    
    return (
        <>
        <Box sx={{height:'calc(100vh - 64px)'}}>
            <Backdrop
                sx={{ color: '#fff', zIndex: 2, backgroundColor: "rgba(0,0,0,0.25)"}}
                open={!!selectedField}
                onClick={handleCloseEditbar}/>
            <Stack height='100%' m='auto' minWidth='1100px' minHeight='700px' sx={{bgcolor:'grey.400'}} position={'relative'} >
                <Stack direction='row' width='100%' height='100%' >
                    <EditPdfToolbar page={pageNo}/>
                    <Stack direction='row' position='relative' overflow='hidden' width={'35%'} sx={{bgcolor:'grey.100'}} >
                        <Sidebar 
                            scale={scale} 
                            numPages={numPages} 
                            setScale={setScale} 
                            isRendered={isRendered}
                            setShowFieldLabels={setShowFieldLabels}
                            showFieldLabels={showFieldLabels} />
                        <AnimatePresence>
                            {selectedField && 
                                <FormContextProvider>
                                    <EditBar field={selectedField.field} handleClose={handleCloseEditbar}/>
                                </FormContextProvider>}
                        </AnimatePresence>
                    </Stack>
                    <Stack direction='row' width={'65%'} position='relative' overflow='scroll' p="1em" >
                        <PDFSnackbar/>
                        <AnimatePresence>
                            {editFormMode && <EditPdfForm pdfId={document.id}/>}
                        </AnimatePresence>
                        <Box m='auto'>
                            {pdfLoading && 
                                <Skeleton animation='wave' width={612} height={792} sx={{transform:'translate(-50%, -50%)', position:'absolute', top:'50%', left:'50%'}} />
                            }
                            <Document file={file} onLoadSuccess={onDocumentLoadSuccess} options={options} loading={<div/>}>
                                <Box component='span' style={{position: 'relative', opacity: !isLoading ? '1' : "0", transition: '0.5s ease-in-out'}} >
                                    <AnimatePresence>
                                        {selectedField && textHighlightSearch && (
                                            <SearchFieldTitle {...selectedField.field} />
                                        )}
                                    </AnimatePresence>
                                    {isLoading && renderedPageNo && renderedScale && (
                                        <Page
                                            key={renderedPageNo + "@" + renderedScale}
                                            className="prevPage"
                                            pageNumber={renderedPageNo}
                                            scale={renderedScale}/>
                                    )}
                                    <Page
                                        canvasRef={canvas}
                                        key={pageNo + "@" + scale}
                                        pageNumber={pageNo}
                                        onRenderSuccess={onRenderSuccess}
                                        scale={scale}/>
                                    {!isLoading && isRendered && (
                                        highlightedElements.elements.map((f,i) => (
                                            <motion.div key={i} initial={{opacity:0}} animate={{opacity:1}}>
                                                <HighlightPdfField
                                                    selectedFieldId={selectedField?.field.id}
                                                    showFieldLabels={showFieldLabels}
                                                    fieldData={f}
                                                    highlightType={highlightType}
                                                    highlightColor={highlightedElements.color}
                                                    handleClick={handleHighlightClick}
                                                    highlightZPos={highlightedElements.position}/>
                                            </motion.div>
                                        ))
                                    )}
                                    {!isLoading && isRendered && selectedField && (
                                        <HighlightSelectedField selectedField={selectedField} highlightType={highlightType}/>
                                    )}
                                    {outlineBox && outlineBox.map((item, i) => (
                                        <OutlineBox key={i} item={item}  />
                                    ) ) }
                                    {connectFields && 
                                        <ConnectionPoints connections={connectFields}  />
                                    }
                                    {canvas.current && editPdfMode && editPdfMode.enabled &&
                                        <PdfCanvas 
                                            height={canvas.current.clientHeight} 
                                            width={canvas.current.clientWidth}/>
                                    }
                                </Box>
                            </Document>
                        </Box>
                    </Stack>
                </Stack>
            </Stack>
        </Box>
        <UnifiedModal/>
        </>
    )
}

