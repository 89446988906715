import React from "react";
import { UnifiedCtx, useUnifiedContext } from "../../../../providers/UnifiedContext";
import { SubmittedFieldData, SubmittedFullData, SubmittedSectionData, UIField, UIForm, UIGroup, UIRow, UISubsection } from "../../../unified-editor/types";
import { useNotifier } from "../../../../providers/NotifyContext";
import { useInstance } from "../../../../utils/axiosConfig";

function isGroup(e: UIField|UIGroup): e is UIGroup {
    return (e as UIGroup).options !== undefined;
}

function isSubsection(e: UIRow|UISubsection): e is UISubsection {
    return (e as UISubsection).title !== undefined;
}

export const useLoadForm = () => {
    const { dispatch } = useUnifiedContext()
    const [loading, setLoading] = React.useState<boolean>(true)
    const [error, setError] = React.useState<any|null>(null)
    const {close, error:notifyError} = useNotifier()
    const {instance} = useInstance()

    const loadData = (pdfId:string) => {
        setLoading(true)
        setError(null)
        close()
        instance.get(`/api/pdf/get_pdf_form/${pdfId}`)
        .then((res) => {
            // console.log(res.data)
            const data = res.data as UIForm
            dispatch({type:UnifiedCtx.AddData, payload:data})
        })
        .catch(err => {
            console.log(err)
            setError(err)
            notifyError('There was an error loading the unified data, please try again')
        })
        .finally(() => {
            setLoading(false)
        })
    }

    return { loading, error, loadData }
}

export const useSubmitForm = () => {
    const {state:{sections}} = useUnifiedContext()
    const [loading, setLoading] = React.useState<boolean>(false)
    const [error, setError] = React.useState<any|null>(null)
    const {close, error:notifyError, success} = useNotifier()
    const {instance} = useInstance()

    const submitFields = (pdfId:string) => {
        setLoading(true)
        const sanitizedData = () => {
            const submitSections:SubmittedSectionData[] = []
            const submitFields:SubmittedFieldData[] = []
            let row = 1
            for (let i = 0; i < sections.length; i++) {
                const section = sections[i];
                const addSection:SubmittedSectionData = {id:section.id, order:i+1}
                submitSections.push(addSection)
                const rowsSubs = section.elements
                for (let i = 0; i < rowsSubs.length; i++) {
                    const rowSub = rowsSubs[i];
                    if(!isSubsection(rowSub)){
                        let itemCount = 0
                        for (let i = 0; i < rowSub.elements.length; i++) {
                            const item = rowSub.elements[i];
                            if(!isGroup(item)){
                                const addField:SubmittedFieldData={id:item.id, row:row, width:item.width??100, order:i+1}
                                submitFields.push(addField)
                                itemCount++
                            } else {
                                    for (let index = 0; index < item.options.length; index++) {
                                        const option = item.options[index];
                                        const addField:SubmittedFieldData={id:option.id, row:row, width:option.width, order:i+1}
                                        submitFields.push(addField)
                                        itemCount++
                                    }
                            }
                        }
                        if(itemCount>0){
                            row++
                        }
                    } else {
                        for (let i = 0; i < rowSub.rows.length; i++) {
                            const subRow = rowSub.rows[i];
                            let itemCount = 0
                            for (let i = 0; i < subRow.elements.length; i++) {
                                const item = subRow.elements[i];
                                if(!isGroup(item)){
                                    const addField:SubmittedFieldData={id:item.id, row:row, width:item.width??100, order:i+1}
                                    submitFields.push(addField)
                                    itemCount++
                                } else {
                                        for (let index = 0; index < item.options.length; index++) {
                                            const option = item.options[index];
                                            const addField:SubmittedFieldData={id:option.id, row:row, width:option.width, order:i+1}
                                            submitFields.push(addField)
                                            itemCount++
                                        }
                                }
                            }
                            if(itemCount>0){
                                row++
                            }
                        }
                    }
                }
            }
            const submitData:SubmittedFullData = { sections:submitSections, fields: submitFields}
            return submitData
        }
        const submitData = sanitizedData()
        close()
        instance.post(`/api/pdf/edit_pdf_positions/${pdfId}`, submitData)
        .then(() => {
            success('PDF positioning submitted successfully')
        })
        .catch((err) => {
            console.log(err)
            notifyError('There was an error in submission, please try again')
        })
        .finally(() => {
            setLoading(false)
        })
    }

    return { submitFields, loading, error }
}