import React from "react";
import { UnifiedCtx, useUnifiedContext } from "../../../providers/UnifiedContext";
import { AllMatchedUserFields, MatchedUserField, SubmittedFieldData, SubmittedFullData, SubmittedSectionData, UIField, UIForm, UIGroup, UIRow, UISubsection, UnifiedFieldData } from "../types";
import { useNotifier } from "../../../providers";
import { GroupRelationshipType } from "../../../types";
import { UserFormData } from "../../form/form-control-types";
import { useModalContext } from "../../../providers/ModalContext";
import { useInstance } from "../../../utils/axiosConfig";


function isGroup(e: UIField|UIGroup): e is UIGroup {
    return (e as UIGroup).options !== undefined;
}

function isSubsection(e: UIRow|UISubsection): e is UISubsection {
    return (e as UISubsection).title !== undefined;
}


export const useLoadUnified = () => {

    const { dispatch } = useUnifiedContext()
    const [loading, setLoading] = React.useState<boolean>(true)
    const [error, setError] = React.useState<any|null>(null)
    const {instance} = useInstance()
    const {close, error:notifyError} = useNotifier()



    const deleteUnifiedField = (fieldId:string) => {
        return new Promise(function(resolve:(value:boolean)=>void, reject){
            instance.delete(`/api/unified/delete_field/${fieldId}`)
            .then((res) => {
                resolve(res.data as boolean)
            })
            .catch((err) => {
                reject(err)
            })
        })
    }



    const loadData = (type?:string|undefined) => {
        setLoading(true)
        setError(null)
        close()
        return new Promise(function(resolve:(value:UIForm)=>void, reject){
            instance.get(type ? `/api/unified/get_fields?type=${type}` : `/api/unified/get_fields` )
            .then((res) => {
                const data = res.data as UIForm
                // console.log(data)
                dispatch({type:UnifiedCtx.AddData, payload:data})
                resolve(data)
                // success('The unfied data was loaded')
            })
            .catch(err => {
                console.log(err)
                setError(err)
                notifyError('There was an error loading the unified data, please try again')
                reject(err)
            })
            .finally(() => {
                setLoading(false)
            })
        })
    }

    return { loading, error, loadData }
}


export const useGetUnified = () => {
    const { dispatch } = useUnifiedContext()
    const [loading, setLoading] = React.useState<boolean>(false)
    const [error, setError] = React.useState<any|null>(null)
    const {close, error:notifyError} = useNotifier()
    const {instance} = useInstance()

    const getData = (type:string) => {
        setLoading(true)
        setError(null)
        close()
        instance.get(`/api/unified/get_fields?type=${type}`)
        .then((res) => {
            const data = res.data as UIForm
            dispatch({type:UnifiedCtx.AddData, payload:data})
            // success('The unfied data was loaded')
        })
        .catch(err => {
            console.log(err)
            setError(err)
            notifyError('There was an error loading the unified data, please try again')
        })
        .finally(() => {
            setLoading(false)
        })
    }

    return { loading, error, getData }
}


export const useEditSection = () => {
    const { dispatch } = useUnifiedContext()
    const [loading, setLoading] = React.useState<boolean>(false)
    const [error, setError] = React.useState<any|null>(null)
    const {success, error:notifyError, close} = useNotifier()
    const [edit, setEdit] = React.useState<boolean>(false)
    const {instance} = useInstance()

    const editSectionTitle = (sectionId:string, sectionIndex:number, title:string) => {
        setLoading(true)
        setError(null)
        close()
        instance.patch(`/api/unified/edit_section_title/${sectionId}`, {"title":title})
        .then((res) => {
            if(res.data){
                setEdit(false)
                dispatch({ type:UnifiedCtx.EditSectionName, payload:{id:sectionId, index:sectionIndex, title:title} })
                success('The section name was successfully edited')
            } else {
                notifyError('There was an error editing the section name, please try again')
            }
        })
        .catch(err => {
            console.log(err)
            setError(err)
            notifyError('There was an error editing the section name, please try again')
        })
        .finally(() => {
            setLoading(false)
        })
    }

    return { loading, error, editSectionTitle, setEdit, edit }
}



export const useEditSubsection = () => {
    const { dispatch } = useUnifiedContext()
    const [loading, setLoading] = React.useState<boolean>(false)
    const [error, setError] = React.useState<any|null>(null)
    const {success, error:notifyError, close} = useNotifier()
    const [edit, setEdit] = React.useState<boolean>(false)
    const {instance} = useInstance()

    const editSubsectionTitle = (subsectionId:string ,sectionIndex:number, subsectionIndex:number, title:string) => {
        setLoading(true)
        setError(null)
        close()
        instance.patch(`/api/unified/edit_subsection_title/${subsectionId}`, {"title":title})
        .then((res) => {
            if(res.data){
                setEdit(false)
                dispatch({ type:UnifiedCtx.EditSubsectionName, 
                    payload:{
                        id:subsectionId, 
                        sectionIndex:sectionIndex, 
                        subsectionIndex:subsectionIndex,
                        title:title
                    }})
                success('The sub-section name was successfully edited')
            } else {
                notifyError('There was an error editing the sub-section name, please try again')
            }
        })
        .catch(err => {
            console.log(err)
            setError(err)
            notifyError('There was an error editing the sub-section name, please try again')
        })
        .finally(() => {
            setLoading(false)
        })
    }

    return { loading, error, editSubsectionTitle, setEdit, edit }
}



export const useEditField = () => {
    const { dispatch } = useUnifiedContext()
    const [loading, setLoading] = React.useState<boolean>(false)
    const [error, setError] = React.useState<any|null>(null)
    const {success, error:notifyError, close} = useNotifier()
    const [edit, setEdit] = React.useState<boolean>(false)
    const {instance} = useInstance()

    const editFieldTitle = (
        {fieldId, sectionIndex, subsectionIndex, rowIndex, fieldIndex, title}:
        {fieldId:string, sectionIndex:number, subsectionIndex?:number|undefined, rowIndex:number, fieldIndex:number, title:string}
    ) => {
        setLoading(true)
        setError(null)
        close()
        instance.patch(`/api/unified/edit_field_title/${fieldId}`, {"title":title})
        .then((res) => {
            if(res.data){
                setEdit(false)
                dispatch({ type:UnifiedCtx.EditFieldName,
                    payload:{
                        id:fieldId, 
                        sectionIndex:sectionIndex, 
                        subsectionIndex:subsectionIndex,
                        rowIndex:rowIndex,
                        index:fieldIndex,
                        title:title
                    }})
                success('The field name was successfully edited')
            } else {
                notifyError('There was an error editing the field name, please try again')
            }
        })
        .catch(err => {
            console.log(err)
            setError(err)
            notifyError('There was an error editing the field name, please try again')
        })
        .finally(() => {
            setLoading(false)
        })
    }

    return { loading, error, editFieldTitle, setEdit, edit }
}


export const useEditGroup = () => {
    const { dispatch } = useUnifiedContext()
    const [loading, setLoading] = React.useState<boolean>(false)
    const [error, setError] = React.useState<any|null>(null)
    const {success, error:notifyError, close} = useNotifier()
    const [edit, setEdit] = React.useState<boolean>(false)
    const {instance} = useInstance()

    const editGroupTitle = (
        {groupId, sectionIndex, subsectionIndex, rowIndex, fieldIndex, title}:
        {groupId:string, sectionIndex:number, subsectionIndex?:number|undefined, rowIndex:number, fieldIndex:number, title:string}
    ) => {
        setLoading(true)
        setError(null)
        close()
        instance.patch(`/api/unified/edit_group_title/${groupId}`, {"title":title})
        .then((res) => {
            if(res.data){
                setEdit(false)
                dispatch({ type:UnifiedCtx.EditGroupName,
                    payload:{
                        id:groupId, 
                        sectionIndex:sectionIndex, 
                        subsectionIndex:subsectionIndex,
                        rowIndex:rowIndex,
                        index:fieldIndex,
                        title:title
                    }})
                success('The group name was successfully edited')
            } else {
                notifyError('There was an error editing the group name, please try again')
            }
        })
        .catch(err => {
            console.log(err)
            setError(err)
            notifyError('There was an error editing the group name, please try again')
        })
        .finally(() => {
            setLoading(false)
        })
    }

    return { loading, error, editGroupTitle, setEdit, edit }
}



export const useSubmitUnified = () => {
    const {state:{sections}} = useUnifiedContext()
    const [loading, setLoading] = React.useState<boolean>(false)
    const [error, setError] = React.useState<any|null>(null)
    const {success, error:notifyError, close} = useNotifier()
    const {instance} = useInstance()

    const submitUnified = (selected_type?: string | null | undefined) => {
        setLoading(true)
        const sanitizedData = () => {
            const submitSections:SubmittedSectionData[] = []
            const submitFields:SubmittedFieldData[] = []
            let row = 1
            for (let i = 0; i < sections.length; i++) {
                const section = sections[i];
                const addSection:SubmittedSectionData = {id:section.id, order:i+1}
                submitSections.push(addSection)
                const rowsSubs = section.elements
                for (let i = 0; i < rowsSubs.length; i++) {
                    const rowSub = rowsSubs[i];
                    if(!isSubsection(rowSub)){
                        let itemCount = 0
                        for (let i = 0; i < rowSub.elements.length; i++) {
                            const item = rowSub.elements[i];
                            if(!isGroup(item)){
                                const addField:SubmittedFieldData={id:item.id, row:row, width:item.width??100, order:i+1}
                                submitFields.push(addField)
                                itemCount++
                            } else {
                                // if(item.relationship === GroupRelationshipType.Single){
                                //     const option = item.options[0];
                                //     const addField:SubmittedFieldData={id:item.id, row:row, width:option.width, order:i+1}
                                //     submitFields.push(addField)
                                //     itemCount++
                                // } else if(item.relationship === GroupRelationshipType.Multi){
                                    for (let index = 0; index < item.options.length; index++) {
                                        const option = item.options[index];
                                        const addField:SubmittedFieldData={id:option.id, row:row, width:option.width, order:i+1}
                                        submitFields.push(addField)
                                        itemCount++
                                    }
                                // }
                                
                            }
                        }
                        if(itemCount>0){
                            row++
                        }
                    } else {
                        for (let i = 0; i < rowSub.rows.length; i++) {
                            const subRow = rowSub.rows[i];
                            let itemCount = 0
                            for (let i = 0; i < subRow.elements.length; i++) {
                                const item = subRow.elements[i];
                                if(!isGroup(item)){
                                    const addField:SubmittedFieldData={id:item.id, row:row, width:item.width??100, order:i+1}
                                    submitFields.push(addField)
                                    itemCount++
                                } else {
                                    // if(item.relationship === GroupRelationshipType.Single){
                                    //     const option = item.options[0];
                                    //     const addField:SubmittedFieldData={id:item.id, row:row, width:option.width, order:i+1}
                                    //     submitFields.push(addField)
                                    //     itemCount++
                                    // } else if(item.relationship === GroupRelationshipType.Multi){
                                        for (let index = 0; index < item.options.length; index++) {
                                            const option = item.options[index];
                                            const addField:SubmittedFieldData={id:option.id, row:row, width:option.width, order:i+1}
                                            submitFields.push(addField)
                                            itemCount++
                                        }
                                //     }
                                }
                            }
                            if(itemCount>0){
                                row++
                            }
                        }
                    }
                }
            }

            const submitData:SubmittedFullData = { sections:submitSections, fields: submitFields, selected_type:selected_type}
            return submitData
        }
        const submitData = sanitizedData()
        // console.log(submitData)
        close()
        instance.post(`/api/unified/edit_fields`, submitData)
        .then(() => {
            success('Unified Fields positioning submitted successfully')
        })
        .catch((err) => {
            console.log(err)
            notifyError('There was an error in submission, please try again')
        })
        .finally(() => {
            setLoading(false)
        })

    }

    return { submitUnified, loading, error }
}



export const useUnifiedData = () => {

    const [loading, setLoading] = React.useState<boolean>(true)
    const [submitLoading, setSubmitLoading] = React.useState<boolean>(false)
    const [error, setError] = React.useState<any|null>(null)
    const [data, setData] = React.useState<UnifiedFieldData|null>(null)
    const {success, close, error:notifyError} = useNotifier()
    const {instance} = useInstance()
    const modal = useModalContext();

    const getUnifiedFieldData = (unifiedId:string) => {
        setError(null)
        setLoading(true)
        instance.get(`/api/unified/get_field_data/${unifiedId}`)
        .then((res) => {
            const unifiedData = res.data as UnifiedFieldData
            setData(unifiedData)
        })
        .catch((err) => {
            console.log(err)
            setError(err)
        })
        .finally(() => {
            setLoading(false)
        })
    }

    const unlinkFieldFromUnified = (pdfId:string, fieldId:string, pageNo:number) => {
        setLoading(true)
        instance.delete(`/api/pdf/remove_mapping/${pdfId}/${fieldId}/${pageNo}`)
        .then(() => {
            success(`The field was successfully unlinked`)
        })
        .catch(err => {
            setError(err)
            notifyError('There was a problem unlinking the field, please try again')
            console.log(err)
        })
        .finally(() => {
            setLoading(false)
        })
    }

    const submitUnifiedData = (form:FormData) => {
        setError(null)
        setSubmitLoading(true)
        close()
        instance.patch(
            `/api/unified/edit_field_data`, 
            form, 
            {headers: {'content-type': 'multipart/form-data'}}
            )
            .then(() => {
                success(`The unified field was successfully edited`)
                modal.hide()
            })
            .catch((err) => {
                notifyError("Unfortuately there was an error updating the field")
                setError(err)
                console.log(err)
            })
            .finally(() => {
                setSubmitLoading(false)
            })
    }

    const deleteUnifiedField = (fieldId:string) => {
        return new Promise(function(resolve:(value:boolean)=>void, reject){
            instance.delete(`/api/unified/delete_field/${fieldId}`)
            .then((res) => {
                resolve(res.data as boolean)
            })
            .catch((err) => {
                reject(err)
            })
        })
    }

    const deleteUnifiedGroup = (groupId:string) => {
        return new Promise(function(resolve:(value:boolean) => void, reject){
            instance.delete(`/api/unified/delete_group/${groupId}`)
            .then((res) => {
                resolve(res.data as boolean)
            })
            .catch((err) => {
                reject(err)
            })
        })
    }


    return { 
        loading, 
        submitLoading, 
        error, 
        data, 
        getUnifiedFieldData, 
        submitUnifiedData, 
        deleteUnifiedField, 
        deleteUnifiedGroup, 
        unlinkFieldFromUnified 
    }
}