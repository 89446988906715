import React from 'react'
import { RegisterOptions, SelectElement } from 'react-hook-form-mui';
import { SelectOption } from '../form-control-types';

interface SelectFieldProps{
    name: string;
    label?: string;
    helperText?: string;
    required?: boolean;
    options: SelectOption[];
    config?:RegisterOptions;
    disabled?:boolean|undefined;
}

export const SelectField = (props: SelectFieldProps) => {

    const { name, label, helperText, required, options, config, disabled } = props;

    return (
        <SelectElement 
            fullWidth
            size="small"
            disabled={disabled}
            validation={config}
            name={name}
            label={label}
            helperText={helperText}
            required={required}
            options={options}/>
    )
}
