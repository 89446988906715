import React from 'react'
import { Stack, Tooltip } from '@mui/material';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import FormatColorTextIcon from '@mui/icons-material/FormatColorText';
import CreateSharpIcon from '@mui/icons-material/CreateSharp';
import ArrowDropDownSharpIcon from '@mui/icons-material/ArrowDropDownSharp';
import FormatListBulletedSharpIcon from '@mui/icons-material/FormatListBulletedSharp';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { FieldType } from '../../types'

export const FieldOption = ({field}:{field:FieldType}) => {

    const RenderType = () => {
        switch (field) {
            case FieldType.Button:
                return <Tooltip title="Type is Button" arrow><Stack direction="row" py={0.75} px={1.25} borderRadius="15px" sx={{backgroundColor:'primary.light'}} >
                        <CheckBoxIcon sx={{color:"primary.contrastText", fontSize: "1.2em", marginRight:"0.1em"}}/> 
                        <RadioButtonCheckedIcon sx={{color:"primary.contrastText", fontSize: "1.2em"}}/>
                    </Stack></Tooltip>
            case FieldType.List:
                return <Tooltip title="Type is List" arrow><Stack direction="row" py={0.75} px={1.25} borderRadius="15px" sx={{backgroundColor:'primary.light'}} >
                        <FormatListBulletedSharpIcon sx={{color:"primary.contrastText", fontSize: "1.2em", marginRight:"0.1em"}}/> 
                        <ArrowDropDownSharpIcon sx={{color:"primary.contrastText", fontSize: "1.2em"}}/>
                    </Stack></Tooltip>
            case FieldType.Text:
                return <Tooltip title="Type is Text Field" arrow>
                    <Stack direction="row" py={0.75} px={1.25} borderRadius="15px" sx={{backgroundColor:'primary.light'}} >
                        <FormatColorTextIcon sx={{color:"primary.contrastText", fontSize: "1.2em"}}/> 
                    </Stack></Tooltip>
            case FieldType.Signature:
                return <Tooltip title="Type is Signature Field" arrow>
                    <Stack direction="row" py={0.75} px={1.25} borderRadius="15px" sx={{backgroundColor:'primary.light'}} >
                        <CreateSharpIcon sx={{color:"primary.contrastText", fontSize: "1.2em"}}/> 
                    </Stack></Tooltip>
            default:
                return <Tooltip title="Unknown Field Type" arrow>
                    <Stack direction="row" py={0.75} px={1.25} borderRadius="15px" sx={{backgroundColor:'primary.light'}} >
                        <ErrorOutlineIcon sx={{color:"primary.contrastText", fontSize: "1.2em"}}/> 
                    </Stack></Tooltip>
        }
    }

    return (
        <RenderType />
    )
}
