import { Box, Checkbox, FormControlLabel, FormGroup, IconButton, ListItemIcon, ListItemText, MenuItem, Stack, Typography, styled } from '@mui/material'
import React from 'react'
import { GroupRelationshipType } from '../../types'
import { Dropdown } from './Dropdown'
import { UIGroup, UIGroupOption } from './types'
import { UnifiedCtx, useUnifiedContext } from '../../providers/UnifiedContext'
import { useContextContext } from '../../providers/ContextContext'
import MoreVertIcon from '@mui/icons-material/MoreVert';
import HeightIcon from '@mui/icons-material/Height';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import classNames from 'classnames'
import { useModalContext } from '../../providers/ModalContext'
import { GroupInfo } from './GroupInfo'
import HelpIcon from '@mui/icons-material/Help';
import { useNavigate } from 'react-router-dom'

export const Group = (
    {group, index, isFirst, isLast, sectionIndex, subsectionIndex, rowIndex, isRowFirst, isRowLast}:
    {
        group:UIGroup, 
        index:number, 
        isFirst:boolean, 
        isLast:boolean, 
        sectionIndex:number, 
        subsectionIndex?:number, 
        rowIndex:number, 
        isRowFirst:boolean, 
        isRowLast:boolean
    }) => {

    const {dispatch} = useUnifiedContext()
    const {show, hide} = useContextContext()
    const modal = useModalContext();
    const navigate = useNavigate()


    const handleResizeGroup = (width:number) => {
        dispatch({
            type:UnifiedCtx.SetFieldWidth, 
            payload:{
                id:group.id, 
                index:index, 
                sectionIndex:sectionIndex, 
                subsectionIndex:subsectionIndex,
                rowIndex: rowIndex,
                width:width.toString()
            }})
        hide()
    }

    const moveFieldLeft = () => {
        dispatch(
            {
                type:UnifiedCtx.MoveFieldLeft, 
                payload:{id:group.id, index: index, sectionIndex:sectionIndex, subsectionIndex:subsectionIndex, rowIndex:rowIndex}
            }
        )
        hide()
    }

    const moveFieldRight = () => {
        dispatch(
            {
                type:UnifiedCtx.MoveFieldRight, 
                payload:{id:group.id, index: index, sectionIndex:sectionIndex, subsectionIndex:subsectionIndex, rowIndex:rowIndex}
            }
        )
        hide()
    }
    
    const moveFieldRowUp = () => {
        dispatch(
            {
                type: UnifiedCtx.MoveFieldRowUp, 
                payload:{id:group.id, index:index, sectionIndex:sectionIndex, subsectionIndex:subsectionIndex, rowIndex:rowIndex}
            })
        hide()
    }

    const moveFieldRowDown = () => {
        dispatch(
            {
                type: UnifiedCtx.MoveFieldRowDown, 
                payload:{id:group.id, index:index, sectionIndex:sectionIndex, subsectionIndex:subsectionIndex, rowIndex:rowIndex}
            })
        hide()
    } 

    const handleDeleteGroup = () => {
        if(group.relationship === GroupRelationshipType.Multi){
            navigate(0)
        } else {
            dispatch(
                {
                    type:UnifiedCtx.RemoveGroup, 
                    payload:{groupId:group.id, sectionIndex:sectionIndex, subsectionIndex:subsectionIndex, rowIndex:rowIndex, index:index}
                })
            modal.hide()
        }
    }

    const handleGroupInfo = () => {
        modal.show({
            title: group.name,
            text: <GroupInfo group={group} handleDelete={handleDeleteGroup}/>
        })
    }


    const FormLabel = styled(FormControlLabel)(({theme}) => ({
        wordBreak:'break-word',
        '&.MuiFormControlLabel-root':{
            marginRight:0
        },
        '& .MuiFormControlLabel-label':{
            fontSize:'0.9rem'
        }
    })) 


    const Menu = () => {
        return (
            <>
            {group.options[0].width_options && group.options[0].width_options.map((o) => (
                <MenuItem disabled={o.disabled} key={o.width} dense onClick={() => handleResizeGroup(o.width)}>
                    <ListItemIcon>
                        <HeightIcon sx={{transform:'rotate(90deg)'}} />
                    </ListItemIcon>
                    <ListItemText>{o.text}</ListItemText>
                </MenuItem>
            ))}

            {!isFirst && <MenuItem dense onClick={moveFieldLeft}>
                <ListItemIcon>
                    <KeyboardArrowLeftIcon/>
                </ListItemIcon>
                <ListItemText>Move Left</ListItemText>
            </MenuItem>}

            {!isLast && <MenuItem dense onClick={moveFieldRight}>
                <ListItemIcon>
                    <KeyboardArrowRightIcon/>
                </ListItemIcon>
                <ListItemText>Move Right</ListItemText>
            </MenuItem>}

            {!isRowFirst && <MenuItem dense onClick={moveFieldRowUp}>
                <ListItemIcon>
                    <KeyboardArrowUpIcon/>
                </ListItemIcon>
                <ListItemText>Move Up</ListItemText>
            </MenuItem>}

            {!isRowLast && <MenuItem dense onClick={moveFieldRowDown}>
                <ListItemIcon>
                    <KeyboardArrowDownIcon/>
                </ListItemIcon>
                <ListItemText>Move Down</ListItemText>
            </MenuItem>}

            {/* {!isFirst && 
                <IconButton className={'field-item__left'} size={'small'} onClick={moveFieldLeft}> <KeyboardArrowLeftIcon/> </IconButton> }
            {!isLast && 
                <IconButton className={'field-item__right'} size={'small'} onClick={moveFieldRight} > <KeyboardArrowRightIcon/> </IconButton> }
            {!isRowFirst && 
                <IconButton className={'field-item__up'} size={'small'} onClick={moveFieldRowUp} ><KeyboardArrowUpIcon/></IconButton> }
            {!isRowLast && 
                <IconButton className={'field-item__down'} size={'small'} onClick={moveFieldRowDown} ><KeyboardArrowDownIcon/></IconButton> } */}

            </>
        )
    }

    const handleContextMenu = (e: React.MouseEvent<HTMLButtonElement|HTMLDivElement>) => {
        show({
            menu: <Menu/>, 
            element: e
        })
    }

    const StyledCheckbox = styled(Checkbox)(({theme}) => ({
        '&.conditional path':{
            fill:theme.palette.info.main
        }
    }))

  return (
    <>
        {
            group.relationship === GroupRelationshipType.Single
            ? <Stack className={'group-item__single'} width={`${group.options[0].width}%`} mt={'auto'} mb={1.5} direction={'row'} alignItems={'center'}>
                <Dropdown 
                    groupId={group.id}
                    conditionalId={group.conditionalId}
                    options={group.options} 
                    label={group.name} 
                    placeholder={`${group.options[0].width} - ${group.options[0].row}`} 
                    sectionIndex={sectionIndex} 
                    subsectionIndex={subsectionIndex} 
                    rowIndex={rowIndex} 
                    index={index}
                    handleGroupInfo={handleGroupInfo} />
                <IconButton sx={{mt:'auto'}} onClick={(e)=>handleContextMenu(e)}> <MoreVertIcon/> </IconButton>
            </Stack> 
            : <Box>
                <Typography variant={"subtitle2"} color={"text.secondary"} className={'group-item__title'}>{group.name}</Typography>
                {group.options.map((g,i) => (
                    <Stack className={'group-item__multi'} key={`${g.id}_${i}`} width={`${g.width}%`} px={1} direction={'row'} alignItems={'center'}>
                        <FormGroup sx={{overflow:'hidden', justifyContent:'center'}}>
                            <FormLabel 
                                control={<StyledCheckbox className={classNames({conditional:group.conditionalId})} />} 
                                label={
                                    <Typography sx={{color:(theme) => group.conditionalId && theme.palette.info.main}}>
                                        {g.label}
                                    </Typography>} />
                        </FormGroup>
                        <Box>
                            <IconButton onClick={(e) => handleContextMenu(e)}><MoreVertIcon/></IconButton>
                        </Box>
                        <IconButton onClick={handleGroupInfo} className={'group-item__multi-title-info'} sx={{ml:0.5}} size={'small'}><HelpIcon fontSize={'small'}/></IconButton>
                    </Stack>
                ))
                }
            </Box>
        }
    </>
  )
}
