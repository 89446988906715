import { createContext, useContext, useReducer } from "react";
import { User } from "../auth/auth-types";
import { createCtx } from "../utils/createCtx";

interface AuthState {
    loading: boolean
    user?: User|undefined
}

export enum AuthCtx {
    Login = 'LOGIN',
    Logout = 'LOGOUT'
}

export type AuthAction = 
    | { type: AuthCtx.Login, payload: User }
    | { type: AuthCtx.Logout }

const reducer = (state:AuthState, action:AuthAction):AuthState => {
    switch(action.type){
        case AuthCtx.Login:
            return {loading:false, user:action.payload}
        case AuthCtx.Logout:
            return {loading:false, user:undefined}
        default:
            return state
    }
}

const initialState:AuthState = {loading:true}

const [ctx, AuthContextProvider] = createCtx(reducer, initialState)
const AuthContext = ctx
const useAuthContext = () => {
    const authContext = useContext(AuthContext)
    if(!authContext){
        throw new Error('No AuthContext.Provider found when calling useAuthContext')
    }
    return authContext
}

export { AuthContextProvider, useAuthContext }