import React from 'react'
import { IconButton } from '@mui/material';
import { SnackbarKey, useSnackbar } from 'notistack';
import CloseIcon from '@mui/icons-material/Close';

/**
 * Adds a close icon button to the snackbars, and from the passed key can close the selected snackbar.
 * @param {SnackbarKey} snackbarKey the key of the snackbar to close.
 */
export const CloseSnackbarButton = ({snackbarKey}:{snackbarKey:SnackbarKey}) => {
    const { closeSnackbar } = useSnackbar();
    return (
        <IconButton key="dismiss" color="inherit" onClick={() => closeSnackbar(snackbarKey)}>
            <CloseIcon sx={{fontSize:20}} />
        </IconButton>
    );
}
