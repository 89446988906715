import { Box, IconButton, SelectChangeEvent, Stack, Typography } from '@mui/material';
import React, { useEffect } from 'react'
import { Field } from './Field';
import { Group } from './Group';
import { UIField, UIGroup, UIRow, WidthOption } from './types'
import { useParentSize } from '@cutting/use-get-parent-size';
import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDown';
import ArrowCircleUpIcon from '@mui/icons-material/ArrowCircleUp';
import { UnifiedCtx, useUnifiedContext } from '../../providers/UnifiedContext';
import { FieldTest } from './FieldTest';


export const Row = (
    {row, index, isFirst, isLast, sectionIndex, subsectionIndex}:
    {
        row:UIRow, 
        index:number, 
        isFirst: boolean, 
        isLast: boolean, 
        sectionIndex:number,
        subsectionIndex?:number|undefined
    }) => {

    function isGroup(e: UIField|UIGroup): e is UIGroup {
        return (e as UIGroup).options !== undefined;
    }

    
    // const rowRef = React.useRef<HTMLDivElement>(null);
    // const { width } = useParentSize(rowRef);
    const id = row.id
    const { dispatch } = useUnifiedContext()
    


    const moveRowDown = () => {
        dispatch({type: UnifiedCtx.MoveRowDown, payload:{id:id, index: index, sectionIndex:sectionIndex, subsectionIndex:subsectionIndex}})
    }

    const moveRowUp = () => {
        dispatch({type: UnifiedCtx.MoveRowUp, payload:{id:id, index:index, sectionIndex:sectionIndex, subsectionIndex:subsectionIndex}})
    }


    return (
        <Box className={'row-item'} style={{backgroundColor:'#fff'}} p={0.5} border={'solid 1px'} borderColor={'rgba(0,0,0,0.14)'} mb={4} borderRadius={1} position={'relative'}>
            <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                {/* <Typography className={'row-item__number'} variant={'caption'} textTransform={'uppercase'} px={1} fontWeight={'500'}>row {row.row_number}</Typography> */}
                {/* <IconButton><DragIndicatorIcon/></IconButton> */}
                <Box/>
                <Box>
                    {!isFirst && <IconButton className={'row-item__up'} onClick={moveRowUp} ><ArrowCircleUpIcon/></IconButton> }
                    {!isLast && <IconButton className={'row-item__down'} onClick={moveRowDown} ><ArrowCircleDownIcon/></IconButton>}
                </Box>
            </Stack>
            <Stack direction={'row'} overflow={'hidden'}>
            {row.elements.map((r,i) => (
                isGroup(r)
                ? <Group 
                    key={`group_${r.id}_${i}`} 
                    group={r} 
                    index={i} 
                    isFirst={ i === 0 } 
                    isLast={ i===row.elements.length-1 } 
                    sectionIndex ={sectionIndex} 
                    subsectionIndex={subsectionIndex} 
                    rowIndex={index} 
                    isRowFirst={isFirst} 
                    isRowLast={isLast} />
                : <FieldTest 
                    key={r.id} 
                    field={r} 
                    index={i} 
                    // rowWidth={width-1} 
                    isFirst={i === 0} 
                    isLast={i === row.elements.length-1}
                    sectionIndex={sectionIndex}
                    subsectionIndex={subsectionIndex}
                    rowIndex={index}
                    isRowFirst={isFirst}
                    isRowLast={isLast}
                    />
            ))
            }
            </Stack>
        </Box>
    )
}
