import { JobResponse } from "../components/unified-editor/types";

export enum FieldType {
    Text = "/Tx", 
    Button = "/Btn", 
    List = "/Ch", 
    Signature = "/Sig"
}

export enum GroupRelationshipType {
    Single = "single",
    Multi = "multi"
}

export enum FieldParentProperty {
    Conditional = 'conditional',
    Group = 'group'
}

export interface FieldModel {
    id: string;
    type: FieldType;
    name: string;
    page:number;
    position: number[];
    positionPercentage: number[];
    sizePercentage: number[];
    isGroup?: boolean | null;
    groupFieldId?: string | null;
    groupPage?: number | null;
    options?: string[] | null;
    completed?: boolean | null;
    conditional?: boolean | null;
    active?:boolean | undefined;
    customLabel?: string | undefined;
}


export interface PageModel {
    size: number[];
    fields: FieldModel[];
    groups?: GroupHighlightModel[];
    sections?: HighlightModel[];
    subsections?: SubsectionHighlightModel[];
}

export interface DocumentModel {
    id: string;
    name: string;
    pages: PageModel[];
    total_fields: number;
    completed_fields: number;
}

export enum PdfImageStatus {
    loading = "loading",
    fail = "fail",
    complete = "complete",
    default = "default"
}

export interface PdfImageModel {
    id: string;
    url: string;
    name: string;
    total_fields: number;
    completed_fields: number;
    status: PdfImageStatus;
    logo?: string|undefined; 
}

export interface PdfFolderModel {
    id: string
    name: string
    folder?: string|undefined
    status?: PdfImageStatus|undefined
}

export interface PdfListPagination {
    count: number;
    // pdf_list: PdfImageModel[];
    items: Array<PdfImageModel|PdfFolderModel>;
    folder: PdfFolderModel | null;
    hasNextPage: boolean;
    sorting: PDFSorting;
    job?: JobResponse|undefined;
}

export interface PdfListPaginationClient extends PdfListPagination{
    disabled:boolean
}

export enum PDFSorting {
    default = "default",
    alpha_asc = "alpha_asc",
    alpha_des = "alpha_des"
}

export interface PdfTextField {
    id: string;
    name: string;
    position: number[];
    positionPercentage: number[];
    sizePercentage: number[];
    original?:boolean;
}


export interface BoxModel {
    position:number[];
    positionPercentage:number[];
    sizePercentage:number[];
}


export interface BoxItemModel {
    id: string;
    name: string;
    box: BoxModel;
}

export interface HighlightModel{
    id:string;
    name:string;
    fields?:FieldModel[]|undefined;
    box?:BoxModel|undefined
}

export interface GroupHighlightModel extends HighlightModel{
    relationship: GroupRelationshipType;
}

export interface SubsectionHighlightModel extends HighlightModel{
    sectionId: string;
}

export interface SubsectionSectionHighlightModel extends HighlightModel{
    section?: HighlightModel|undefined
}

export interface SearchResponseData{
    id:string
    title:string
    description?:SearchResponseDescription[]|undefined
    itemList?:string[]|undefined
    fieldParentProperty?:FieldParentProperty|undefined
}

export interface SearchResponseDescription{
    item?:string[]|undefined
}

export interface SearchResponse{
    pairData?:SearchResponseData[]|undefined
    unifiedData?:SearchResponseData[]|undefined
}

export interface UnifiedGroupFieldData{
    id:string,
    title:string
}

export interface UnifiedGroupPair {
    unifiedFieldData:UnifiedGroupFieldData;
    matchedFieldData:FieldModel;
}

export interface UnifiedGroupResponse{
    groupFields:UnifiedGroupFieldData[],
    fieldType:FieldType
}

export interface PdfEditSubmission{
    error:boolean,
    message:string,
    fields?:FieldModel[]|undefined
}

export const ItemTypes = {
    FILE: 'file'
}

export const DropTypes = {
    Section: 'Section',
    Subsection: 'Subsection',
    Row: 'Row',
    Field: 'Field'
}

export enum ResultType {
    Search = "Search",
    Files = "Files",
    Folders = "Folders"
}

export interface EditNameState{
    id?: string;
    name?: string;
    loading: boolean;
}