import { LoadingButton } from '@mui/lab';
import { Stack } from '@mui/material';
import React from 'react'
import ReplayIcon from '@mui/icons-material/Replay';
import { PDFSorting, ResultType } from '../types';
import { useLoadItems } from '../components/dashboard/utils';
import { LoadingNoText } from '../components/shared/LoadingAnimation';
import { ListNoPagination } from '../components/dashboard/ListNoPagination';
import { EmptyLibrary } from '../components/dashboard';

export const Files = () => {

    const { loading, error, count, getPdfList } = useLoadItems()

    React.useEffect(() => {
        const sort = localStorage.getItem('sort')
        if(sort){
            const parsedValue = JSON.parse(sort) as PDFSorting
            getPdfList(parsedValue)
        } else {
            getPdfList()
        }
    }, [])

    

    return (
        <>
        {error  
            ?<Stack mt={5} width={'100%'} height={'100%'} justifyContent={'center'} alignItems={'center'} direction={'row'}>
                <LoadingButton loading={error&&loading} size={'large'} variant={'outlined'} startIcon={ <ReplayIcon/> } onClick={()=>getPdfList()}>Reload</LoadingButton>
            </Stack>
            : loading
                ? <LoadingNoText/>
                : <>
                    {count > 0
                        ? <ListNoPagination type={ResultType.Files} />
                        : <EmptyLibrary/>
                    }
                </>
        }
        </>
    )

}