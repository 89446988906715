import React, { useEffect, useState } from 'react'
import { useParams, useLocation } from 'react-router-dom';
import { DocumentModel } from '../types';
import { AxiosError } from 'axios';
import { LoadingNoText } from '../components/shared/LoadingAnimation';
import { PDFViewer } from '../components/viewer';
import { PdfContextProvider } from '../providers/PdfContext';
import { PdfProviders } from '../providers';
import { useInstance } from '../utils/axiosConfig';

export interface PdfStateProps {
    file: File|string;
    res: DocumentModel|undefined;
    loading: boolean;
    error: AxiosError<any, any> | null;
}

interface PdfLocationProps {
    res: DocumentModel;
    file: File|string;
}

export const EditPdf = () => {
    const initialState:PdfStateProps = {
        file: "",
        res: undefined,
        loading: true,
        error: null
    }
    const {id} = useParams();
    const location = useLocation().state as PdfLocationProps;
    const [state, setState] = useState<PdfStateProps>(initialState);
    const {instance} = useInstance()


    useEffect(() => {
        if(location !== null){
            const doc:DocumentModel = location.res;
            const file:File|string = location.file;
            setState({...state, res: doc, file: file, loading:false});
        } else {
            instance.get(`/api/pdf/${id}`)
            .then(async (res) => 
            {
                const id = res.data.id
                const doc:DocumentModel = {...res.data.doc, id}
                const pdfUrl:string = res.data.pdf;
                setState({...state, file:pdfUrl, res: doc, loading: false})

            })
            .catch(err => {
                console.log(err);
                setState({...state, loading: false, error: err})
            });
        }
    }, [])
    
    return (
        <>
        {state.loading && (<LoadingNoText />)}
        {state.error && (<div>error - {state.error.message}</div>)}
        {!state.loading && !state.error && state.res && (
            <PdfContextProvider value={{document:state.res, pageNo: 1, editFormMode:false}}>
                <PdfProviders>
                    <PDFViewer file={state.file}/>
                </PdfProviders>
            </PdfContextProvider>
        )}
        </>
    );
}