import { LoadingButton } from '@mui/lab';
import { DialogContent, DialogContentText } from '@mui/material'
import React from 'react'
import { PdfFolderModel } from '../../types';

export const RemoveFolderModal = ({folder, removeFolder}:{folder:PdfFolderModel, removeFolder:(id:string)=>void}) => {

    const [loading, setLoading] = React.useState<boolean>(false);

    const handleRemoveFolder = () => {
        setLoading(true)
        removeFolder(folder.id)
    }

  return (
    <DialogContent>
        <DialogContentText id="alert-dialog-description">
            Are you sure you would like to Delete <b>{folder.name}</b>
        </DialogContentText>
        <LoadingButton loading={loading} color="error" sx={{mt:1}} onClick={handleRemoveFolder}>
            Delete
        </LoadingButton> 
    </DialogContent>
)
}
