import { OptionsObject, SnackbarKey, SnackbarMessage, SnackbarProvider, useSnackbar, withSnackbar } from "notistack";
import React, { createContext } from "react";
import { CloseSnackbarButton } from '../utils/CloseSnackbarButton';


interface NotifierContextProps {
    close: (key: SnackbarKey) => void;
    error: (message: SnackbarMessage, options: OptionsObject) => SnackbarKey;
    info: (message: SnackbarMessage, options: OptionsObject) => SnackbarKey;
    log: (message: SnackbarMessage, options: OptionsObject) => SnackbarKey;
    success: (message: SnackbarMessage, options: OptionsObject) => SnackbarKey;
    warning: (message: SnackbarMessage, options: OptionsObject) => SnackbarKey;
}


/**
 * The React context for the `Notify` model.
 */
export const NotifyContext = createContext<NotifierContextProps|null>(null);


const NotifierWrapper = withSnackbar(({ children }:any) => {
    const { close, error, info, log, success, warning } = useNotifier();
    return (
        <NotifyContext.Provider value={{close,error,info,log,success,warning}}>
            {children}
        </NotifyContext.Provider>
    );
});

/**
 * The React context provider component for the `Notify` model.
 */
export function NotifyProvider({ children }:{children:React.ReactNode}) {

    return (
        <SnackbarProvider
            action={snackbarKey => <CloseSnackbarButton snackbarKey={snackbarKey}/>}
            preventDuplicate
            maxSnack={4}>
            <NotifierWrapper>{children}</NotifierWrapper>
        </SnackbarProvider>
    );
}

/**
 * Global notification system that displays a Snackbar component which is controlled 
 * by [notistack]. Convenience wrappers for [notistack] variants are made accessible
 * through the available methods `close`, `error`, `info`, `log`, `success`, and `warning`.
 */
export function useNotifier() {

    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    
    /**
     * Close the `Snackbar` with the given key.
     * @param {Number} key The ID of the `Snackbar` to hide.
     */
    const close = (key?:SnackbarKey|undefined) => {
        closeSnackbar(key);
    };

    /**
     * Adds a new `error` `Snackbar` to the queue to be presented. The `autoHideDuration`
     * value is defaulted to `7000ms`.
     * @param {String} message the text of the notification.
     * @param {Object} options The options to pass to.
     */
    const error = (message:SnackbarMessage, options?:OptionsObject) => {
        return enqueueSnackbar(message, {
        autoHideDuration: 5000,
        persist: true,
        ...(options || {}),
        variant: "error"
        });
    };

    /**
     * Adds a new `info` `Snackbar` to the queue to be presented.
     * @param {String} message the text of the notification.
     * @param {Object} options The options to pass to.
     */
    const info = (message:SnackbarMessage, options?:OptionsObject) => {
        return enqueueSnackbar(message, { ...(options || {}), variant: "info" });
    };

    /**
     * Adds a new `default` `Snackbar` to the queue to be presented.
     * @param {String} message the text of the notification.
     * @param {Object} options The options to pass to.
     */
    const log = (message:SnackbarMessage, options?:OptionsObject) => {
        return enqueueSnackbar(message, { ...(options || {}), variant: "default" });
    };

    /**
     * Adds a new `success` `Snackbar` to the queue to be presented.
     * @param {String} message the text of the notification.
     * @param {Object} options The options to pass to.
     */
    const success = (message:SnackbarMessage, options?:OptionsObject) => {
        return enqueueSnackbar(message, { ...(options || {}), variant: "success" });
    };

    /**
     * Adds a new `warning` `Snackbar` to the queue to be presented. The `autoHideDuration`
     * value is defaulted to `6000ms`.
     * @param {String} message the text of the notification.
     * @param {Object} options The options to pass to.
     */
    const warning = (message:SnackbarMessage, options?:OptionsObject) => {
        return enqueueSnackbar(message, {
        persist: true,
        ...(options || {}),
        variant: "warning"
        });
    };

    return {
        close,
        error,
        info,
        log,
        success,
        warning
    };
}
