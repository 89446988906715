import { Stack, Typography } from '@mui/material'
import React from 'react'
import ClearAllIcon from '@mui/icons-material/ClearAll';

export const NoResults = () => {
    return (
        <Stack width="100%" sx={{mt:8}} alignItems="center" position="relative">
            <ClearAllIcon fontSize={'large'} />
            <Typography mt={1} variant="h6">There are no results</Typography>
        </Stack>
    )
}
