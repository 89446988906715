import { LoadingButton } from '@mui/lab'
import { Alert, Box, debounce, DialogContent, TextField } from '@mui/material'
import React from 'react'
import { FieldValues, FormContainer, SubmitHandler, useForm } from 'react-hook-form-mui'
import { FieldModel, FieldType } from '../../../types'
import { FullBoxProps } from '../PdfCanvas'
import { useInstance } from '../../../utils/axiosConfig'

export const AddTextFieldModal = ({box, id, page, addField}:{box:FullBoxProps, id:string, page:number, addField:(field:FieldModel) => void}) => {

    const formContext = useForm()
    const {register, formState:{errors}} = formContext
    const name = register('name', {
        required:'This is a required field', 
        pattern: {
            value: /^[a-zA-Z0-9]{0,15}$/, 
            message: 'Please make sure there are no special characters (*!@#$%^*()_-) a maximum of 15 characters, and no whitespaces'
        },
    })
    const [loading, setLoading] = React.useState<boolean>(false)
    const [disabled, setDisabled] = React.useState<boolean>(false);
    const [status, setStatus] = React.useState<boolean|undefined>(undefined)
    const {instance} = useInstance()
    const onSubmit:SubmitHandler<FieldValues> = data => handleSubmit(data);

    const handleSubmit = (e:FieldValues) => {
        if(box.canvas){
            setLoading(true);
            const positionJson = JSON.stringify({x:box.x, y:box.y, w:box.w, h: box.h})
            const canvasJson = JSON.stringify({w:box.canvas.w, h:box.canvas.h})
            const fieldType = JSON.stringify(FieldType.Text)
            const data = {"name":e.name,"position":positionJson, "canvas":canvasJson, "type":fieldType}
            instance.post(`/api/pdf/submit_new_field/${id}/${page}`, data)
            .then((res) => 
            {   
                const fieldData = res.data as FieldModel
                addField(fieldData)
                setLoading(false);
            })
            .catch(err => {
                setLoading(false);
                console.log(err);
            });
        }
    }

    const checkTitle = async (text:string) => {
        if(text !== ''){
            const searchTerm = {"name":text}
            await instance.post(`/api/pdf/check_field_names/${id}`,searchTerm)
            .then((res) => {
                const availability = res.data as boolean
                if(availability){
                    setStatus(true)
                } else {
                    setStatus(false)
                    setDisabled(true)
                }
                setLoading(false)
            })
            .catch((err) => console.log(err))
        } else {
            setStatus(undefined)
            setLoading(false)
        }
    }

    const debouncedSearch = debounce((text:string)=>{
        checkTitle(text)
    }, 1000)



    const onAmountChanged = React.useMemo( 
        ()=> (e: string) => {
            debouncedSearch(e)
            setDisabled(false)
            setLoading(true)
        }, 
        []
    );
    

    const handleInput = (e: { target: any; type?: any; }) => {
        name.onChange(e)
        if(!errors['name']){
            onAmountChanged(e.target.value)
        }
    }

    
  return (
    <DialogContent sx={{maxWidth:'500px'}}>
            <FormContainer formContext={formContext} onSuccess={onSubmit}>
                <Box py={1}>
                    <TextField
                        error={errors['name'] ? true : false}
                        name={name.name}
                        onChange={handleInput}
                        inputRef={name.ref}
                        onBlur={name.onBlur}
                        fullWidth
                        size={'small'}
                        label={'Field Name'}
                        helperText={errors['name'] ? errors['name'].message?.toString() : 'The name of the new field'}
                        autoComplete={'off'}
                    />
                </Box>
                {
                    status && <Alert sx={{mt:1}} severity={'success'}>Section Name Available</Alert>
                }
                {
                    status === false && <Alert sx={{mt:1}} severity={'error'}>This section name is already in use, try another</Alert>
                }
                <LoadingButton sx={{mt:2}} loading={loading} disabled={!!errors['name'] && disabled} type={'submit'}>Submit</LoadingButton>
            </FormContainer>
    </DialogContent>
  )
}
