import { createFilterOptions } from '@mui/material';
import React from 'react'
import { AutocompleteElement, RegisterOptions, UseFormSetValue } from 'react-hook-form-mui';
import { SelectOption } from '../form-control-types';

interface SelectCustomProps{
    name: string;
    label?: string;
    helperText?: string;
    required?: boolean;
    options: SelectOption[];
    setValue: UseFormSetValue<any>;
    optionLabel?: string;
    config?:RegisterOptions;
    disabled?:boolean|undefined;
}

interface OptionType {
    id?: string;
    label: string;
    inputValue: string;
}

export const SelectCustom = (props:SelectCustomProps) => {

    const { name, label, helperText, required, options, setValue, disabled, optionLabel = 'Add' } = props;

    const filter = createFilterOptions<OptionType>();

    return (
            <AutocompleteElement
                required={required}
                name={name}
                label={label}
                options={options}
                textFieldProps={{
                    helperText:helperText,
                    disabled:disabled
                }}
                autocompleteProps={{
                    size: "small",
                    onChange: (event, newValue) => {
                        if (typeof newValue === 'string') {
                            setValue(name, newValue);
                        } else if (newValue && newValue.inputValue) {
                            setValue(name, newValue.inputValue)
                        } else {
                            setValue(name, newValue);
                        }
                    },
                    filterOptions: (options, params) => {
                        const filtered = filter(options, params);
                        const { inputValue } = params;
                        const isExisting = options.some((option) => inputValue === option.label);
                        if (inputValue !== '' && !isExisting) {
                            filtered.push({
                                id: inputValue.toLowerCase(),
                                inputValue: inputValue,
                                label: `${optionLabel} "${inputValue}"`,
                            });
                        }
                        return filtered;
                    },
                    isOptionEqualToValue: (option, value) => {
                        return true
                    }
                }}/>
    )
}
