import { LoadingButton } from '@mui/lab'
import { DialogContent, Stack, Typography } from '@mui/material'
import React, { useState } from 'react'
import { FormContainer, SelectElement, SubmitHandler, TextFieldElement, useForm } from 'react-hook-form-mui'
import { GroupHighlightModel } from '../../../types'
import { useInstance } from '../../../utils/axiosConfig'

type Inputs = {
    group_name:string;
    group_type:string;
}

export const EditGroupModal = (
    {id, group, handleRemoveGroup, handleEditGroup, pageNo}:{
        id:string,
        group:GroupHighlightModel, 
        pageNo:number,
        handleRemoveGroup: (group: GroupHighlightModel) => void,
        handleEditGroup: (group: GroupHighlightModel) => void
    }) => {

    const [loading, setLoading] = useState<boolean>(false);
    const formContext = useForm<Inputs>({defaultValues:{group_name:group.name, group_type:group.relationship}});
    const {formState:{isDirty}} = formContext
    const onSubmit:SubmitHandler<Inputs> = data => handleSubmit(data);
    const {instance} = useInstance()

    const handleSubmit = (e:Inputs) => {
        setLoading(true);
        instance.patch(`/api/groups/edit/${id}/${pageNo}/${group.id}`, e)
        .then((res) => 
        {   
            setLoading(false);
            handleEditGroup(res.data as GroupHighlightModel)
        })
        .catch(err => {
            setLoading(false);
            console.log(err);
        });
    }
    
    const handleRemove = () => {
        setLoading(true)
        handleRemoveGroup(group)
    }

    const options= [
        {id:'multi', label:'Multi'},
        {id:'single', label:'Single'}
    ]

    return (
        <DialogContent>
            <Stack mt={1}>
                <FormContainer formContext={formContext} onSuccess={onSubmit}>
                    <Stack spacing={2}>
                        <TextFieldElement
                            name='group_name' 
                            required 
                            fullWidth 
                            size='small' 
                            label='Group Name' 
                            helperText='What will be the name of this group?' 
                        />
                        <SelectElement
                            name='group_type'
                            required
                            fullWidth
                            size='small'
                            label='Type'
                            helperText='What is the relationship between the items in this group, for single only one can be selected, for multi many can be selected'
                            options={options}
                        />
                    </Stack>
                    <Stack direction='row' justifyContent='space-between' >
                        <LoadingButton
                            sx={{mt:4}}
                            type='submit'
                            loading={loading}
                            variant="outlined"
                            disabled={!isDirty}
                            >
                            Edit
                        </LoadingButton>
                        <LoadingButton
                            onClick={handleRemove}
                            sx={{mt:4}}
                            loading={loading}
                            variant="outlined"
                            color="error"
                            >
                            Delete
                        </LoadingButton>
                    </Stack>
                </FormContainer>
            </Stack>
        </DialogContent>
    )
}
