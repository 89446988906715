import { FieldModel } from "../types";
import useStore from "./EditFormState";
import { HighlightCtx, useHighlightContext } from "./HighlightContext";
import { usePdfContext } from "./PdfContext";

export function useCombinedState() {
    const { state:{pageNo, document} } = usePdfContext()
    const { dispatch } = useHighlightContext()
    const pageIndex = pageNo - 1;

    const resetHighlightedFields = () => {
        const fields:FieldModel[] = document.pages[pageIndex].fields;
        dispatch({type:HighlightCtx.Initial, payload:fields})
    }

    const initialHighlightClickFn = () => {
        useStore.setState({setHighlightFn:(f)=>{
            dispatch({type: HighlightCtx.SelectedField, payload: {field:f}})
        }});
    }

    const removeHighlightedTextFn = () => {
        const fields:FieldModel[] = document.pages[pageIndex].fields;
        dispatch({type:HighlightCtx.RmTextHighlights, payload:fields})
    }

    return {
        resetHighlightedFields,
        initialHighlightClickFn,
        removeHighlightedTextFn
    }
}
