import { Backdrop, Box, Button, CircularProgress, Grid, ListItemIcon, ListItemText, Menu, MenuItem, Stack, Typography } from '@mui/material';
import { AnimatePresence, motion } from 'framer-motion';
import React from 'react'
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import useInfiniteScroll from 'react-infinite-scroll-hook';
import { useNavigate } from 'react-router-dom';
import { useContextContext } from '../../providers/ContextContext';
import { DashboardCtx, useDashboardContext } from '../../providers/DashboardContext';
import { useModalContext } from '../../providers/ModalContext';
import { PDFSorting, PdfFolderModel, PdfImageModel, ResultType } from '../../types';
import { LoadingAnimation } from '../shared/LoadingAnimation';
import { LogoUploadModal } from './LogoUploadModal';
import { MoveFileModal } from './MoveFileModal';
import { PdfFolder } from './PdfFolder';
import { PdfItem } from './PdfItem';
import { UpFolder } from './UpFolder';
import { useEditFiles, useEditFolders, useEditPdf, useLoadMore, useReloadItems } from './utils';
import CreateNewFolderIcon from '@mui/icons-material/CreateNewFolder';
import { NewFolderModal } from './NewFolderModal';
import { RemoveFolderModal } from './RemoveFolderModal';
import { DeletePdfModal } from './DeletePdfModal';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import SortIcon from '@mui/icons-material/Sort';
import { JobResponse } from '../unified-editor/types';
import { useNotifier } from '../../providers';
import polyfilledEventSource from '@sanity/eventsource/browser'
import { useAuthContext } from '../../providers/AuthContext';

interface SortOption {
    type: PDFSorting
    name: string
}

export const ListNoPagination = ({type}:{type:ResultType}) => {
        
    const { state:{items, folder, hasNextPage, count, sorting, disabled, job}, dispatch } = useDashboardContext()
    const { loading, error, loadMoreFiles, loadMoreFolders } = useLoadMore()
    const { reloadPdfList, reloadPdfFoldersList, loading:getPdfLoading } = useReloadItems()
    const { removeHandler } = useEditPdf()
    const { loading:dropLoading, onDrop } = useEditFiles()
    const { removeFolder, addFolder } = useEditFolders()
    const {show, hide} = useContextContext()
    const {success} = useNotifier()
    const {state:{user}} = useAuthContext()
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const url = `${process.env.REACT_APP_DB_HOST_URI}${process.env.REACT_APP_DB_STREAM_URI}`
    let eventSource:EventSource|undefined = undefined;

    React.useEffect(() => {
        if(job){
            // eventSource = new EventSource(`${url}/${job.id}`)
            eventSource = new polyfilledEventSource(`${url}/${job.id}`, {headers:{Authorization:'Bearer '+user?.token}})
            eventSource.addEventListener("message", function(event){
                const jsonData = JSON.parse(event.data)
                const data = jsonData as JobResponse
                setStatus(data)
                if(data.isSuccess && data.isOver && data.id === job.id){
                    eventSource?.close()
                    success('All submissions have been processed')
                }
            })
        }
        return () => {
            eventSource?.close()
        }
    }, [job])
    

    const setStatus = (data:JobResponse) => {
        dispatch({type:DashboardCtx.SetStatus, payload:data})
    }


    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const [infiniteRef] = useInfiniteScroll({
        loading,
        hasNextPage,
        onLoadMore: folder ? ()=>loadMoreFolders(folder.id) : loadMoreFiles,
        disabled: !!error,
        rootMargin: '0px 0px 00px 0px',
      });

    const navigate = useNavigate();
    const modal = useModalContext();


    function isFile(e: PdfImageModel|PdfFolderModel): e is PdfImageModel {
        return (e as PdfImageModel).url !== undefined;
    }

    const folders = React.useMemo(() => {
        const f:PdfFolderModel[] = [];
        items.forEach((i) => {
            if(!isFile(i)){
                f.push(i)
            }
        })
        return f
    }, [items])

    const files = React.useMemo(() => {
        const f:PdfImageModel[] = [];
        items.forEach((i) => {
            if(isFile(i)){
                f.push(i)
            }
        })
        return f
    }, [items])


    const setOpenId = (id:string, name:string) => {
        modal.show({
            title:'Are you sure you would like to delete this PDF?',
            text: <DeletePdfModal id={id} name={name} removePdf={removeHandler} />
        })
    }


    const addLogo = (id:string) => {
        modal.show({
            title:'Select a PDF Logo',
            text: <LogoUploadModal id={id} setLogo={setLogo} />
        })
    }


    const setLogo = (id:string, url:string) => {
        modal.hide()
        dispatch({type:DashboardCtx.SetLogo, payload:{id:id, url:url}})
    }

    const moveTo = (id:string) => {
        modal.show({
            title:'Move File',
            text: <MoveFileModal folders={folders} folder={folder} handleMove={(fId)=>{modal.hide();handleDrop(fId,id)}} />
        })
    }

    const clickHandler = (id:string) => {
        dispatch({type:DashboardCtx.Initial})
        navigate(`/edit/${id}`);
    }
    
    const handleOpenFolder = (folderId:string) => {
        navigate(`/dashboard/folders/${folderId}`)
    }

    const goToFiles = () => {
        navigate('/dashboard/files')
    }

    const goToFolder = (folderId:string) => {
        navigate(`/dashboard/folders/${folderId}`)
    }

    const handleDrop = (folderId:string|undefined, pdfId:string) => {
        onDrop(folderId, pdfId)
    }


    const handleRemoveFolder = (folder:PdfFolderModel) => {
        modal.show({
            title:'Remove Folder',
            text: <RemoveFolderModal folder={folder} removeFolder={removeFolder}/>
        })
    }


    const handleSubmitFolder = (folderId?:string) => {
        modal.show({
            title:'Add Folder',
            text: <NewFolderModal addFolder={addFolder} folderId={folderId} />
        })
    }

    const FolderMenu = () => {
        return (
            <>
            <MenuItem dense onClick={()=>{hide();handleSubmitFolder(folder?.id)}}>
                <ListItemIcon>
                    <CreateNewFolderIcon />
                </ListItemIcon>
                <ListItemText>Add New Folder</ListItemText>
            </MenuItem>
            </>
        )
    }

    const handleContextMenu = (e: React.MouseEvent<HTMLButtonElement|HTMLDivElement>) => {
        show({
            menu: <FolderMenu/>,
            element: e
        })
    }

    const options:SortOption[] = [
        {type: PDFSorting.default, name: 'Created'},
        {type: PDFSorting.alpha_asc, name: 'A - Z'},
        {type: PDFSorting.alpha_des, name: 'Z - A'}
    ]

    const selectedOption = (opt:PDFSorting) => {
        return options.find((o) => o.type === opt)?.name
    }

    const handleSort = (sort:PDFSorting) => {
        localStorage.setItem('sort',JSON.stringify(sort))
        handleClose()
        if(folder){
            reloadPdfFoldersList(folder.id, sort)
        } else{
            reloadPdfList(sort)
        }
    }


    return (
        <>
        <DndProvider backend={HTML5Backend}>
            {(dropLoading||getPdfLoading) &&
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={dropLoading||getPdfLoading}
                >
                    <LoadingAnimation />
                </Backdrop>
            }
            {error
                ? <Stack width={'100%'} height={'100%'} justifyContent={'center'} alignItems={'center'} direction={'row'}>
                    <Button onClick={goToFiles}>Go Back</Button>
                </Stack>
                : <>
                {type !== ResultType.Search && <AnimatePresence mode="popLayout">
                    <motion.div style={{marginBottom:'-32px', marginTop:'24px'}} layout initial={{opacity:0}} animate={{opacity: 1}} exit={{opacity: 0}} transition={{ type: "keyframes" }}>
                        <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                            <Typography ml={1} color={'text.disabled'} fontSize={'0.875rem'} fontWeight={'500'}>{`${count} results`}</Typography>
                            <Button onClick={handleClick} startIcon={<SortIcon/>} endIcon={<ArrowDropDownIcon/>}>
                                {sorting ? selectedOption(sorting) : 'Default'}
                            </Button>
                            <Menu
                                id="basic-menu"
                                anchorEl={anchorEl}
                                open={open}
                                onClose={handleClose}
                                MenuListProps={{
                                'aria-labelledby': 'basic-button',
                                }}
                            >
                                {options.map((o,i) => (
                                    <MenuItem key={i} onClick={() => handleSort(o.type)}>{o.name}</MenuItem>
                                ))}
                            </Menu>
                        </Stack>
                    </motion.div>
                </AnimatePresence>}
                <Grid container spacing={4} mt={2.5} mb={6} position={'relative'}>
                    {type !== ResultType.Search  && <Box onContextMenu={handleContextMenu} top={0} left={0} width={'100%'} height={'100%'} position={'absolute'} />}
                    <AnimatePresence mode="popLayout">
                        {folder && <UpFolder goToFiles={goToFiles} goToFolder={goToFolder} folder={folder}/> }
                        {folders.map((folder:PdfFolderModel) => (
                            <PdfFolder
                                key={folder.id}
                                folder={folder}
                                handleOpenFolder={handleOpenFolder}
                                handleRemoveFolder={handleRemoveFolder}
                            />
                            ))}
                        {files.map((img:PdfImageModel, i:number) => (
                            <PdfItem 
                                key={`${img.id}_${i}`} 
                                img={img} 
                                clickHandler={clickHandler} 
                                setOpenId={setOpenId}
                                addLogoHandler={addLogo}
                                handleDrop={handleDrop}
                                moveTo={moveTo}
                                disabled={disabled}
                            />
                            ))}
                    </AnimatePresence>
                </Grid>
                {hasNextPage  
                    && <Stack pb={4} pt={2} justifyContent={'center'} direction={'row'} ref={infiniteRef}>
                            <CircularProgress/>
                    </Stack>
                }
                </>
            }
        </DndProvider>
        </>
    )
}
