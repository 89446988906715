import LoadingButton from '@mui/lab/LoadingButton'
import { Button } from '@mui/material'
import React from 'react'
import { useCombinedState } from '../../../providers/combinedStateFn'
import useStore from '../../../providers/EditFormState'
import { useNotifier } from '../../../providers/NotifyContext'
import { PdfCtx, usePdfContext } from '../../../providers/PdfContext'
import { FieldModel } from '../../../types'
import { UnifiedData } from '../form-control-types'
import { useInstance } from '../../../utils/axiosConfig'

export const RemoveUnifiedMapping = ({selectedField, unifiedData}:{selectedField:FieldModel,unifiedData: UnifiedData}) => {

    const resetEditFormState = useStore((state) => state.initial)
    const { resetHighlightedFields, initialHighlightClickFn } = useCombinedState()
    const { state:{document:{id}}, dispatch } = usePdfContext()
    const [ loading, setLoading ] = React.useState<boolean>(false)
    const {instance} = useInstance()
    const notify = useNotifier();

    const resetPageState = () => {
        resetEditFormState()
        initialHighlightClickFn()
        resetHighlightedFields()
      }

    const handleClick = () => {
        setLoading(true)
        if(unifiedData.unifiedGroup){
          removeUnifiedGroup(unifiedData.unifiedGroup.fields, unifiedData.unifiedGroup.id)
        } else {
          removeUnifiedField()
        }
        
    }

    const removeUnifiedField = () => {
      instance.post(`/api/unified/remove_mapping/${id}/${selectedField.id}`)
        .then(() => {
            if(selectedField.completed){
                dispatch({type:PdfCtx.RmvCompleted, payload:selectedField.id})
            }
            resetPageState()
            notify.success(`'${selectedField.name}' was successfully removed`)
        })
        .catch(err => {
            setLoading(false)
            console.log(err)
        })
    }

    const removeUnifiedGroup = (fieldIds:string[], groupId:string) => {
      instance.post(`/api/unified/remove_group_mapping/${id}/${groupId}`)
        .then(() => {
            if(selectedField.completed){
                dispatch({type:PdfCtx.RmvCompletedGroup, payload:fieldIds})
            }
            resetPageState()
            notify.success(`'${selectedField.name}' was successfully removed`)
        })
        .catch(err => {
            setLoading(false)
            console.log(err)
        })
    }

  return (
    <LoadingButton onClick={handleClick} fullWidth variant={'outlined'} color={'error'} loading={loading}>
        Remove Mapping
    </LoadingButton>
  )
}
