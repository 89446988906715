import React from 'react'
import { Box, Checkbox, FormControlLabel, FormGroup, IconButton, ListItemIcon, ListItemText, MenuItem, Select, SelectChangeEvent, Stack, TextField, Typography, alpha, styled } from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import SubdirectoryArrowRightIcon from '@mui/icons-material/SubdirectoryArrowRight';
import HelpIcon from '@mui/icons-material/Help';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import HeightIcon from '@mui/icons-material/Height';
import classNames from 'classnames'
import { UIField } from '../../unified-editor/types';
import { UnifiedCtx, useUnifiedContext } from '../../../providers/UnifiedContext';
import { useEditField } from '../../unified-editor/utils';
import { useContextContext } from '../../../providers/ContextContext';
import { useModalContext } from '../../../providers/ModalContext';
import { FieldModel, FieldType } from '../../../types';
import { usePdfContext } from '../../../providers/PdfContext';
import useStore from '../../../providers/EditFormState';
import { useSearchParams } from 'react-router-dom';
import { HighlightCtx, useHighlightContext } from '../../../providers/HighlightContext';

export const FieldTest = (
    {field, index, isFirst, isLast, sectionIndex, subsectionIndex, rowIndex, isRowFirst, isRowLast}:
    {
        field:UIField, 
        index:number, 
        isFirst:boolean, 
        isLast:boolean,
        sectionIndex: number,
        subsectionIndex?:number|undefined,
        rowIndex:number,
        isRowFirst:boolean,
        isRowLast:boolean,
    }
) => {
    const {dispatch} = useUnifiedContext()
    const {dispatch:hDispatch} = useHighlightContext()
    const {state:{document}} = usePdfContext()
    const {show, hide} = useContextContext()
    const [_, setSearchParams] = useSearchParams();
    const setHighlightFn = useStore((state) => state.setHighlightFn)
    const required = React.useMemo(() => {
        const hasRequired = (field.fieldData as any)["required"] as string|undefined
        if(hasRequired){
            if(hasRequired === "yes"){
                return true
            } else {
                return false
            }
        } else {
            return undefined
        }
    }, [])

    const StyledBox = styled(Stack)(({theme}) => ({
        height:'40px',
        border: 'solid 1px rgba(0,0,0,0.3)',
        borderRadius: '4px',
        backgroundColor: '#fafafa',
        '&.conditional':{
            borderColor:theme.palette.info.main
        }
    })) 

    const moveFieldLeft = () => {
        dispatch(
            {
                type:UnifiedCtx.MoveFieldLeft, 
                payload:{id:field.id, index: index, sectionIndex:sectionIndex, subsectionIndex:subsectionIndex, rowIndex:rowIndex}
            }
        )
        hide()
    }

    const moveFieldRight = () => {
        dispatch(
            {
                type:UnifiedCtx.MoveFieldRight, 
                payload:{id:field.id, index: index, sectionIndex:sectionIndex, subsectionIndex:subsectionIndex, rowIndex:rowIndex}
            }
        )
        hide()
    }
    
    const moveFieldRowUp = () => {
        dispatch(
            {
                type: UnifiedCtx.MoveFieldRowUp, 
                payload:{id:field.id, index:index, sectionIndex:sectionIndex, subsectionIndex:subsectionIndex, rowIndex:rowIndex}
            })
        hide()
    }

    const moveFieldRowDown = () => {
        dispatch(
            {
                type: UnifiedCtx.MoveFieldRowDown, 
                payload:{id:field.id, index:index, sectionIndex:sectionIndex, subsectionIndex:subsectionIndex, rowIndex:rowIndex}
            })
        hide()
    }   

    const handleSetFieldWidth = (event: SelectChangeEvent) => {
        dispatch(
            {
                type:UnifiedCtx.SetFieldWidth, 
                payload:{
                    id:field.id, 
                    index:index, 
                    sectionIndex:sectionIndex, 
                    subsectionIndex:subsectionIndex, 
                    rowIndex:rowIndex, 
                    width:event.target.value
                }
            }
        )
        hide()
    }

    const handleSetButtonWidth = (width: number) => {
        dispatch(
            {
                type:UnifiedCtx.SetFieldWidth, 
                payload:{
                    id:field.id, 
                    index:index, 
                    sectionIndex:sectionIndex, 
                    subsectionIndex:subsectionIndex, 
                    rowIndex:rowIndex, 
                    width:width.toString()
                }
            }
        )
        hide()
    }

    const handleOpenField = () => {
        let page:number|undefined = undefined
        let field_data:FieldModel|undefined = undefined
        for (let i = 0; i < document.pages.length; i++) {
            const p = document.pages[i];
            for (let index = 0; index < p.fields.length; index++) {
                const f = p.fields[index];
                if(f.id === field.id){
                    field_data = f
                    page = i + 1
                }
            }
        }
        if(field_data && page){
            // if(setHighlightFn){
            //     setHighlightFn(field_data)
            //     setSearchParams([['page',`${page}`],['id',`${field_data.id}`]])
            // }
            // hDispatch({type:HighlightCtx.SetSelectedFormField, payload:field_data})
            // setSearchParams([['page',`${page}`],['id',`${field_data.id}`]])
        }
    }

    // const handleEditField = () => {
    //     editFieldTitle(
    //         {fieldId:field.id, sectionIndex:sectionIndex, subsectionIndex:subsectionIndex, rowIndex:rowIndex, fieldIndex:index, title:title}
    //     )
    // }

    // const handleFieldInfo = () => {
    //     modal.show({
    //         title: field.title,
    //         text: <FieldInfo fieldId={field.id} fieldName={field.title} handleDelete={handleDeleteField}/>
    //     })
    // }

    // const handleDeleteField = () => {
    //     dispatch(
    //         {
    //             type:UnifiedCtx.RemoveField, 
    //             payload:{id:field.id, sectionIndex:sectionIndex, subsectionIndex:subsectionIndex, rowIndex:rowIndex, index:index}
    //         })
    //     modal.hide()
    // }

    const Menu = () => {
        return (
            <>
            {field.width_options && field.width_options.map((o) => (
                <MenuItem disabled={o.disabled} key={o.width} dense onClick={() => handleSetButtonWidth(o.width)}>
                    <ListItemIcon>
                        <HeightIcon sx={{transform:'rotate(90deg)'}} />
                    </ListItemIcon>
                    <ListItemText>{o.text}</ListItemText>
                </MenuItem>
            ))}

            {!isFirst && <MenuItem dense onClick={moveFieldLeft}>
                <ListItemIcon>
                    <KeyboardArrowLeftIcon/>
                </ListItemIcon>
                <ListItemText>Move Left</ListItemText>
            </MenuItem>}

            {!isLast && <MenuItem dense onClick={moveFieldRight}>
                <ListItemIcon>
                    <KeyboardArrowRightIcon/>
                </ListItemIcon>
                <ListItemText>Move Right</ListItemText>
            </MenuItem>}

            {!isRowFirst && <MenuItem dense onClick={moveFieldRowUp}>
                <ListItemIcon>
                    <KeyboardArrowUpIcon/>
                </ListItemIcon>
                <ListItemText>Move Up</ListItemText>
            </MenuItem>}

            {!isRowLast && <MenuItem dense onClick={moveFieldRowDown}>
                <ListItemIcon>
                    <KeyboardArrowDownIcon/>
                </ListItemIcon>
                <ListItemText>Move Down</ListItemText>
            </MenuItem>}
            </>
        )
    }

    const FormLabel = styled(FormControlLabel)(({theme}) => ({
        wordBreak:'break-word',
        '&.MuiFormControlLabel-root':{
            marginRight:0
        },
        '& .MuiFormControlLabel-label':{
            fontSize:'0.9rem'
        }
    })) 

    const StyledCheckbox = styled(Checkbox)(({theme}) => ({
        '&.conditional path':{
            fill:theme.palette.info.main
        }
    }))

    const handleContextMenu = (e: React.MouseEvent<HTMLButtonElement|HTMLDivElement>) => {
        show({
            menu: <Menu/>, 
            element: e
        })
    }

    const StyledStack = styled(Stack)(({theme}) => ({
        flexDirection:'row',
        marginTop:'auto',
        alignItems:'center',
        ':hover':{
            cursor:'pointer',
            backgroundColor:alpha(theme.palette.primary.light, 0.1)
        }
    }))


    return (
        <>
        {field.fieldType !== FieldType.Button
        ? <Stack className={'field-item'} width={`${field.width}%`} px={1} mb={1.5} position={'relative'}>
            {
                <Stack mb={0.5} direction={'row'} mt={'auto'} alignItems={'center'}>
                    {field.conditionalId && <SubdirectoryArrowRightIcon fontSize={'small'} color={'info'} sx={{mr:0.25}} />}
                    <Box>
                        <Typography sx={{color:(theme) => field.conditionalId && theme.palette.info.main}} component={'span'}>{field.title}</Typography>
                        {required && required && <Box ml={0.5} fontWeight={'bold'} fontSize={'1.1rem'} color={'error.main'} component={'span'}>*</Box>}
                    </Box>
                    {/* <IconButton onClick={handleFieldInfo} className={'field-item__title-info'} sx={{ml:0.5}} size={'small'}><HelpIcon fontSize={'small'}/></IconButton> */}
                </Stack>
            } 
            <StyledBox width={'100%'} className={classNames({conditional:field.conditionalId})} position={'relative'} direction={'row'} justifyContent={'space-between'} alignItems={'center'} pl={1}>
                {field.width_options && <Select
                    onChange={handleSetFieldWidth}
                    sx={{
                        '& legend': { display: 'none' },
                        '& fieldset': { top: 0, border:'none' },
                        '& .MuiSelect-select':{ padding:0, paddingTop:'8px', paddingBottom: '8px' }
                    }}
                    value={`${field.width}`}>
                    {field.width_options.map((w,i) => (
                        <MenuItem disabled={w.disabled} key={i} value={w.width}>{w.text}</MenuItem>
                    ))}
                </Select>}
                    <Stack direction={'row'}>
                        {!isFirst && <IconButton className={'field-item__left'} size={'small'} onClick={moveFieldLeft}> <KeyboardArrowLeftIcon/> </IconButton> }
                        {!isLast && <IconButton className={'field-item__right'} size={'small'} onClick={moveFieldRight} > <KeyboardArrowRightIcon/> </IconButton> }
                        {!isRowFirst && <IconButton className={'field-item__up'} size={'small'} onClick={moveFieldRowUp} ><KeyboardArrowUpIcon/></IconButton> }
                        {!isRowLast && <IconButton className={'field-item__down'} size={'small'} onClick={moveFieldRowDown} ><KeyboardArrowDownIcon/></IconButton> }
                    </Stack>
            </StyledBox>
        </Stack>
        : <Stack className={'box-item__multi'} key={field.id} width={`${field.width}%`} px={1} direction={'row'} alignItems={'center'}>
            <FormGroup sx={{overflow:'hidden', justifyContent:'center'}}>
                <FormLabel 
                    control={<StyledCheckbox className={classNames({conditional:field.conditionalId})} />} 
                    label={
                        <Typography sx={{color:(theme) => field.conditionalId && theme.palette.info.main}}>
                            {field.title}
                        </Typography>} />
            </FormGroup>
            <Box>
                <IconButton onClick={(e) => handleContextMenu(e)}><MoreVertIcon/></IconButton>
            </Box>
        </Stack>
        }
        </>
    )
}
