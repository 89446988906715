import { AlertColor, AlertProps } from '@mui/material';
import React, { createContext, useContext } from 'react';

type PdfNotifyContextProps = {
    open: boolean;
    message: string;
    typeColor: AlertColor | undefined;
    autoHideDuration: number | null | undefined;
}

type PdfNotifyContextActions = {
    state: PdfNotifyContextProps;
    showSnackBar: (
        {text,typeColor, autoHideDuration}:
        {
            text: string, 
            typeColor?: AlertProps["severity"], 
            autoHideDuration?:number|null|undefined
        }) => void;
    handleClose: () => void;
};


const PdfNotifyContext = createContext({} as PdfNotifyContextActions);


function PdfNotifyProvider({children}:{children:React.ReactNode}) {
    const [open, setOpen] = React.useState<boolean>(false);
    const [message, setMessage] = React.useState<string>('');
    const [typeColor, setTypeColor] = React.useState<AlertProps["severity"]>('info');
    const [autoHideDuration, setAutoHideDuration] = React.useState<number|null|undefined>(5000);

    const state:PdfNotifyContextProps = {open, message, typeColor, autoHideDuration}

    const showSnackBar = (
            {text,typeColor='info',autoHideDuration=5000}:
            {
                text: string, 
                typeColor?: AlertProps["severity"]|undefined, 
                autoHideDuration?:number|null|undefined
            }) => {
        setMessage(text);
        setTypeColor(typeColor);
        setOpen(true);
        setAutoHideDuration(autoHideDuration)
    };

    const handleClose = () => {
        setOpen(false);
        setTypeColor('info');
    };

    return (
        <PdfNotifyContext.Provider value={{ state, showSnackBar, handleClose }}>
            {children}
        </PdfNotifyContext.Provider>
    );
}

const usePdfNotify = (): PdfNotifyContextActions => {
    const context = useContext(PdfNotifyContext);

    if (!context) {
        throw new Error('useSnackBar must be used within an SnackBarProvider');
    }

    return context;
};

export { PdfNotifyProvider, usePdfNotify };