import { LoadingButton } from '@mui/lab'
import { DialogContent, DialogContentText } from '@mui/material'
import React from 'react'

export const DeletePdfModal = ({id,name,removePdf}:{id:string,name:string,removePdf:(id:string,name:string)=>void}) => {

    const [loading, setLoading] = React.useState<boolean>(false);

    const handleRemovePdf = () => {
        setLoading(true)
        removePdf(id,name)
    }

  return (
    <DialogContent>
        <DialogContentText id="alert-dialog-description">
            Any existing mappings will be lost for <b>{name}</b>
        </DialogContentText>
        <LoadingButton loading={loading} color="error" sx={{mt:1}} onClick={handleRemovePdf}>
            Delete
        </LoadingButton> 
    </DialogContent>
  )
}
