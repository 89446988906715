import LoadingButton from '@mui/lab/LoadingButton'
import { Alert, debounce, DialogContent, DialogTitle, Stack } from '@mui/material'
import React from 'react'
import { Controller,  FormContainer, SubmitHandler, TextFieldElement, useForm } from 'react-hook-form-mui'
import { SectionListItem } from '../form-control-types'
import { useInstance } from '../../../utils/axiosConfig'

type Inputs = {
    name:string;
}

export const UnifiedSectionModal = ({addSection, handleClose}:{addSection: (item: SectionListItem) => void, handleClose:()=>void}) => {

    const formContext = useForm<Inputs>()
    const [loading, setLoading] = React.useState<boolean>(false);
    const [disabled, setDisabled] = React.useState<boolean>(false);
    const [status, setStatus] = React.useState<boolean|undefined>(undefined)
    const {instance} = useInstance()
    const onSubmit:SubmitHandler<Inputs> = data => handleSubmit(data);

    const checkTitle = async (text:string) => {
        if(text !== ''){
            const searchTerm = {"search":text}
            await instance.post(`/api/unified/section/check`,searchTerm)
            .then((res) => {
                const availability = res.data as boolean
                if(availability){
                    setStatus(true)
                } else {
                    setStatus(false)
                    setDisabled(true)
                }
                setLoading(false)
            })
            .catch((err) => console.log(err))
        } else {
            setStatus(undefined)
            setLoading(false)
        }
    }

    const debouncedSearch = debounce((text:string)=>{
        checkTitle(text)
    }, 1000)

    const onAmountChanged = React.useMemo( 
        ()=> (e: string) => {
            debouncedSearch(e)
            setDisabled(false)
            setLoading(true)
        }, 
        []
    );

    const handleSubmit = (e:Inputs) => {
        setLoading(true);
        instance.post(`/api/unified/section/add`,e)
        .then((res) => 
        {   
            const newItemId = res.data as string
            const newItem:SectionListItem = {id:newItemId, label:e.name}
            addSection(newItem)
            setLoading(false);
            handleClose()
        })
        .catch(err => {
            setLoading(false);
            console.log(err);
        });
    }

    return (
        <>
        <DialogTitle>Create Section</DialogTitle>
        <DialogContent>
            <Stack mt={1}>
                <FormContainer formContext={formContext} onSuccess={onSubmit}>
                    <Stack spacing={2}>
                        <Controller 
                            render={({ field }) => (
                                <TextFieldElement
                                    onChange={(e) => {
                                        field.onChange(e);
                                        onAmountChanged(e.target.value)
                                    }} 
                                    name={field.name}
                                    required 
                                    fullWidth 
                                    size='small' 
                                    label='Section Name' 
                                    helperText='What will be the name of this section?'
                                    autoComplete='off'
                                />
                            )} 
                            name={'name'}                            
                        />
                    </Stack>
                    {
                        status && <Alert sx={{mt:2}} severity={'success'}>Section Name Available</Alert>
                    }
                    {
                        status === false && <Alert sx={{mt:2}} severity={'error'}>This section name is already in use, try another</Alert>
                    }
                    <LoadingButton
                        sx={{mt:4}}
                        type='submit'
                        loading={loading}
                        disabled={disabled}
                        variant="outlined"
                        >
                        Submit
                    </LoadingButton>
                </FormContainer>
            </Stack>
        </DialogContent>
        </>
    )
}
