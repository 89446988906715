import { LoadingButton } from '@mui/lab'
import { Button, DialogContent, DialogContentText, TextField } from '@mui/material'
import React from 'react'
import { PdfFolderModel } from '../../types'
import { useEditFolders } from './utils'

export const NewFolderModal = ({addFolder, folderId}:{addFolder:(name:string, folderId?:string)=>void, folderId?:string}) => {

    const [name, setName] = React.useState<string>('')
    const [loading, setLoading] = React.useState<boolean>(false);

    const handleAddFolder = () => {
      if(name!==''){
        setLoading(true)
        addFolder(name, folderId)
      }
    }

    const handleKey = (e: React.KeyboardEvent<HTMLDivElement>) => {
      if(e.key === 'Enter'){
        handleAddFolder()
      }
    }


  return (
    <DialogContent>
        <DialogContentText id="alert-dialog-description" mb={1}>
            What would you like to name the new folder?
        </DialogContentText>
        <TextField sx={{
                '& legend': { display: 'none' },
                '& fieldset': { top: 0 },
                }} onKeyDown={handleKey} fullWidth placeholder={'Folder Name'} size={'small'} disabled={loading} value={name} onChange={(e) => setName(e.target.value)} />
        <LoadingButton sx={{mt:2}} loading={loading} onClick={handleAddFolder}>
            Submit
        </LoadingButton>
    </DialogContent>
  )
}
