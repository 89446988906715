import React from 'react'
import { Box, IconButton, Stack, TextField, Typography } from '@mui/material'
import { Row } from './Row'
import { UISubsection } from './types'
import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDown';
import ArrowCircleUpIcon from '@mui/icons-material/ArrowCircleUp';
import { UnifiedCtx, useUnifiedContext } from '../../providers/UnifiedContext';
import { useEditSubsection } from './utils';
import { LoadingButton } from '@mui/lab';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

export const Subsection = (
  {subsection, sectionIndex, index, isFirst, isLast}:
  {subsection:UISubsection, sectionIndex:number, index:number, isFirst:boolean, isLast:boolean}) => {

    const id = subsection.id
    const { dispatch } = useUnifiedContext()
    const [title, setTitle] = React.useState<string>(subsection.title)
    const { edit, setEdit, editSubsectionTitle, loading, error } = useEditSubsection()


    const moveRowDown = () => {
        dispatch({type: UnifiedCtx.MoveRowDown, payload:{id:id, index: index, sectionIndex:sectionIndex}})
    }

    const moveRowUp = () => {
        dispatch({type: UnifiedCtx.MoveRowUp, payload:{id:id, index:index, sectionIndex:sectionIndex}})
    }

    const handleEditSubsection = () => {
      editSubsectionTitle(subsection.id, sectionIndex, index, title)
    }

    const handleAddRow = () => {
      dispatch({type:UnifiedCtx.AddRow, payload:{sectionIndex:sectionIndex, subsectionIndex:index, rowIndex:subsection.rows.length-1}})
    }

  return (
    <Box className={'subsection-item'} style={{backgroundColor:'#fff'}} px={2} py={1} border={'solid 1px'} borderColor={'rgba(0,0,0,0.14)'} mb={4} borderRadius={1} position={'relative'}>
      <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
        <Box></Box>
        <Box>
          {!isFirst && <IconButton className={'subsection-item__up'} onClick={moveRowUp} ><ArrowCircleUpIcon/></IconButton> }
          {!isLast && <IconButton className={'subsection-item__down'} onClick={moveRowDown} ><ArrowCircleDownIcon/></IconButton>}
        </Box>
      </Stack>

      {edit
        ? <Stack direction={'row'} mb={1.5} alignItems={'center'}>
          {loading
            ? <LoadingButton loading={true} sx={{minWidth:0, mx:1}}/>
            : <Box>
              <IconButton disabled={title===subsection.title} onClick={handleEditSubsection} size={'small'}>
                <CheckCircleOutlinedIcon color={title===subsection.title ? 'disabled' : 'success'} />
              </IconButton>
            </Box>
          }
          <Box>
            <IconButton disabled={loading} onClick={()=>{setEdit(false);setTitle(subsection.title)}} size={'small'} sx={{mr:1}}>
              <CancelOutlinedIcon color={loading?'disabled':'error'} />
            </IconButton>
          </Box>
          <TextField fullWidth multiline size={'small'} sx={{
            '& legend': { display: 'none' },
            '& fieldset': { top: 0 },
            }} value={title} onChange={(event) => {setTitle(event.target.value)}}/>
        </Stack>
        : <Typography variant={'h6'} className={'subsection-item__title'} mb={2.5} onDoubleClick={()=>{if(!loading)setEdit(true)}}>{title}</Typography>
      }
      { subsection.rows.map((r,i) => (
        <Row key={`row-sub-${i}`} row={r} index={i} isFirst={i==0} isLast={i==subsection.rows.length-1} sectionIndex={sectionIndex} subsectionIndex={index}/>
      ))}
      <Stack direction={'row'} justifyContent={'flex-end'}>
        <IconButton className={'subsection-item__add-row'} disabled={loading} onClick={handleAddRow}><AddCircleOutlineIcon color={loading?'disabled':'inherit'}/> </IconButton>
      </Stack>
    </Box>

  )
}
