import { Box, IconButton, Paper, Stack, TextField, Typography } from '@mui/material'
import React from 'react'
import { Subsection } from './Subsection';
import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDown';
import ArrowCircleUpIcon from '@mui/icons-material/ArrowCircleUp';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import { LoadingButton } from '@mui/lab';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { UIRow, UISection, UISubsection } from '../../unified-editor/types';
import { useEditSection } from '../../unified-editor/utils';
import { UnifiedCtx, useUnifiedContext } from '../../../providers/UnifiedContext';
import { Row } from './Row';


export const Section = (
  {section, index, moveSectionDown, moveSectionUp, isFirst, isLast}:
  {
    section:UISection, 
    index:number,
    moveSectionDown: (id:string,atIndex: number) => void,
    moveSectionUp: (id:string,atIndex: number) => void,
    isFirst: boolean,
    isLast: boolean
  }
) => {

  // const [title, setTitle] = React.useState<string>(section.title)
  // const {loading, error, editSectionTitle, edit, setEdit} = useEditSection()
  const {dispatch} = useUnifiedContext()

  function isSubsection(e: UIRow|UISubsection): e is UISubsection {
    return (e as UISubsection).title !== undefined;
  }
  

  // const handleEditSection = () => {
  //   editSectionTitle(section.id, index, title)
  // }

  const handleAddRow = () => {
    dispatch({type:UnifiedCtx.AddRow, payload:{sectionIndex:index, rowIndex:section.elements.length-1}})
  }

  const id:string = section.id

  return (
    <Box width={'100%'} mb={4} className={'section'} >
        <Paper elevation={8} sx={{p:2}} >
          <Stack justifyContent={'space-between'} direction={'row'}>
            {
            // edit
            // ? <Stack direction={'row'} mb={1.5}>
            //     {loading 
            //       ? <LoadingButton loading={true} sx={{minWidth:0, ml:1, mr:1}}/>
            //       : <IconButton disabled={title===section.title} onClick={handleEditSection} size={'small'} >
            //         <CheckCircleOutlinedIcon color={title===section.title ? 'disabled' : 'success'}/>
            //       </IconButton>
            //     }
            //     <IconButton disabled={loading} onClick={()=>{setEdit(false);setTitle(section.title)}} size={'small'} sx={{mr:1}} >
            //       <CancelOutlinedIcon color={loading?'disabled':'error'} />
            //     </IconButton>
            //     <TextField size={'small'} sx={{
            //       '& legend': { display: 'none' },
            //       '& fieldset': { top: 0 },
            //       }} value={title} onChange={(event) => {setTitle(event.target.value)}}/>
            //   </Stack>
            //   : 
              <Typography my={1.25} variant={'h5'}>{section.title}</Typography>
            }
            <Stack direction={'row'} alignItems={'center'}>
              {!isFirst && <IconButton className={'section__up'} onClick={()=>moveSectionUp(id,index)} ><ArrowCircleUpIcon/></IconButton> }
              {!isLast && <IconButton className={'section__down'} onClick={()=>moveSectionDown(id,index)} ><ArrowCircleDownIcon/></IconButton>}
            </Stack>
          </Stack>
              {section.elements.map((e,i) => (
                isSubsection(e) 
                ? <Subsection key={e.title} subsection={e} sectionIndex={index} index={i} isFirst={i===0} isLast={i===section.elements.length-1} />
                : <Row key={`row-${i}`} row={e} index={i} isFirst={i===0} isLast={i==section.elements.length-1} sectionIndex={index} />
                ))}
                <Stack direction={'row'} justifyContent={'flex-end'}>
                  <IconButton className={'section__add-row'} onClick={handleAddRow}><AddCircleOutlineIcon/> </IconButton>
                </Stack>
        </Paper>
    </Box>
  )
}
