import React, { createContext, useContext, useReducer } from "react";
import { FullBoxProps } from "../components/editor/PdfCanvas";
import { SectionListItem, UserFormData } from "../components/form/form-control-types";
import { DocumentModel, FieldModel, GroupHighlightModel, HighlightModel, PageModel, SubsectionHighlightModel } from "../types";
import { CustomLabelProps, SelectedFieldOpts } from "./HighlightContext";

export interface PdfContextProps {
    document: DocumentModel;
    pageNo: number;
    currentSelectedSection?: SectionListItem|undefined;
    unifiedForm?: UnifiedFormProps|undefined;
    editPdfMode?: EditPdfProps|undefined;
    editFormMode: boolean
}

export interface EditPdfProps {
    box?: FullBoxProps|undefined;
    enabled?: boolean|undefined;
}

export interface UnifiedFormProps {
    showModal: boolean;
    formData: UserFormData;
    pdfId:string;
    title?: string | undefined;
    fields?: SelectedFieldOpts[]|undefined
}

export enum PdfCtx {
    Page = 'PAGE',
    AddCompleted = 'ADD',
    AddCompletedGroup = 'ADDCOMPLETEDGROUP',
    RmvCompleted = 'RMVCOMPLETED',
    RmvCompletedGroup = 'RMVCOMPLETEDGROUP',
    AddConditional = 'ADDCONDITIONAL',
    EditGroups = 'EDITGROUPS',
    EditSections = 'EDITSECTIONS',
    DeleteSection = 'DELETESECTION',
    EditSubsections = 'EDITSUBSECTIONS',
    SelectedSection = 'SELECTEDSECTION',
    CreateUnified = 'CREATEUNIFIED',
    EditLabel = 'EDITLABEL',
    EditPdfMode = 'EDITPDF',
    AddEditPdfBox = 'EDITPDFBOX',
    AddPdfField = 'ADDPDFFIELD',
    AddPdfFields = 'ADDPDFFIELDS',
    TogglePdfForm = 'TOGGLEPDFFORM'
}

export type PdfAction = 
    | { type: PdfCtx.Page, payload: number }
    | { type: PdfCtx.AddCompleted, payload: string }
    | { type: PdfCtx.AddCompletedGroup, payload: FieldModel[] }
    | { type: PdfCtx.RmvCompleted, payload: string }
    | { type: PdfCtx.RmvCompletedGroup, payload: string[] }
    | { type: PdfCtx.EditGroups, payload: GroupHighlightModel }
    | { type: PdfCtx.EditSections, payload: HighlightModel[] }
    | { type: PdfCtx.DeleteSection, payload: string }
    | { type: PdfCtx.EditSubsections, payload: SubsectionHighlightModel[] }
    | { type: PdfCtx.SelectedSection, payload: SectionListItem|undefined }
    | { type: PdfCtx.AddConditional, payload: string[] }
    | { type: PdfCtx.CreateUnified, payload: UnifiedFormProps|undefined }
    | { type: PdfCtx.EditLabel, payload: CustomLabelProps[]|undefined }
    | { type: PdfCtx.EditPdfMode, payload: boolean }
    | { type: PdfCtx.AddEditPdfBox, payload: FullBoxProps|undefined }
    | { type: PdfCtx.AddPdfField, payload: FieldModel }
    | { type: PdfCtx.AddPdfFields, payload: FieldModel[] }
    | { type: PdfCtx.TogglePdfForm, payload: boolean }


const initialState:PdfContextProps = {
    document:{id:'',name:'',pages:[],total_fields:0,completed_fields:0},
    pageNo: 1,
    editFormMode: false
}

const reducer = (state:PdfContextProps, action:PdfAction):PdfContextProps => {
    switch (action.type) {
        case PdfCtx.Page:
            return {
                ...state,
                pageNo: action.payload
            }
        case PdfCtx.AddCompleted:{
            const updatedState = state
            const currentPage = updatedState.pageNo - 1
            const fieldIndex = updatedState.document.pages[currentPage].fields.findIndex((v => v.id === action.payload));
            const completedCount = updatedState.document.completed_fields
            const field = updatedState.document.pages[currentPage].fields[fieldIndex]
            if(!field.completed){
                updatedState.document.pages[currentPage].fields[fieldIndex].completed = true;
                updatedState.document.completed_fields = completedCount + 1
            }
            return updatedState
        }
        case PdfCtx.AddCompletedGroup:{
            const updatedState = state
            const currentPage = updatedState.pageNo - 1
            const toUpdateFields = action.payload
            let count = 0
            for (let i = 0; i < toUpdateFields.length; i++) {
                const element = toUpdateFields[i];
                const fieldIndex = updatedState.document.pages[currentPage].fields.findIndex((v => v.id === element.id));
                const field = updatedState.document.pages[currentPage].fields[fieldIndex]
                if(!field.completed){
                    updatedState.document.pages[currentPage].fields[fieldIndex].completed = true;
                    count++
                }
            }
            const completedCount = updatedState.document.completed_fields
            updatedState.document.completed_fields = completedCount + count
            return updatedState
        }
        case PdfCtx.RmvCompleted:{
            const updatedState = state
            const currentPage = updatedState.pageNo - 1
            const fieldIndex = updatedState.document.pages[currentPage].fields.findIndex((v => v.id === action.payload));
            const completedCount = updatedState.document.completed_fields
            const field = updatedState.document.pages[currentPage].fields[fieldIndex]
            if(field.completed){
                updatedState.document.pages[currentPage].fields[fieldIndex].completed = false;
                updatedState.document.completed_fields = completedCount - 1
            }
            return updatedState
        }
        case PdfCtx.RmvCompletedGroup:{
            const updatedState = state
            const currentPage = updatedState.pageNo - 1
            const toUpdateFields = action.payload
            let count = 0
            for (let i = 0; i < toUpdateFields.length; i++) {
                const element = toUpdateFields[i];
                const fieldIndex = updatedState.document.pages[currentPage].fields.findIndex((v => v.id === element));
                const field = updatedState.document.pages[currentPage].fields[fieldIndex]
                if(field.completed){
                    updatedState.document.pages[currentPage].fields[fieldIndex].completed = false;
                    count--
                }
            }
            const completedCount = updatedState.document.completed_fields
            updatedState.document.completed_fields = completedCount + count
            return updatedState
        }
        case PdfCtx.EditGroups:{
            const updatedState = state;
            const page = state.pageNo
            const group = action.payload
            let current = state.document.pages[page-1].groups
            if(current){
                let newCurrent:GroupHighlightModel[]|undefined = undefined;
                for (let index = 0; index < current.length; index++) {
                    const element = current[index];
                    if(element.id === group.id){
                        current[index] = group;
                        newCurrent = current;
                        break;
                    }
                }
                if (!newCurrent){
                    current.push(group)
                } 
            } else {
                current = [group]
            }
            updatedState.document.pages[page-1].groups = current
            return updatedState
        }
        case PdfCtx.EditSections:{
            const updatedState = state;
            const page = state.pageNo-1;
            const sections = action.payload
            let currentSections = state.document.pages[page].sections
            if(currentSections){
                for (let index = 0; index < sections.length; index++) {
                    const section = sections[index];
                    let addSection:HighlightModel[]|undefined = undefined;
                    for (let i = 0; i< currentSections.length; i++) {
                        const currentSection = currentSections[i];
                        if(currentSection.id === section.id){
                            currentSections[i] = section
                            addSection = currentSections
                            break;
                        }
                    }
                    if (!addSection){
                        currentSections.push(section)
                    }
                }
            } else {
                currentSections = sections
            }
            updatedState.document.pages[page].sections = currentSections
            return updatedState
        }
        case PdfCtx.DeleteSection:{
            const updatedState = state;
            const page = state.pageNo
            const current = state.document.pages[page-1].sections
            if(current){
                const newSections:HighlightModel[] = current.filter((s) => s.id !== action.payload)
                updatedState.document.pages[page-1].sections = newSections
            }
            return updatedState
        }
        case PdfCtx.EditSubsections:{
            const updatedState = state;
            const page = state.pageNo-1
            const subsections = action.payload
            let currentSubs = state.document.pages[page].subsections
            if(currentSubs){
                for (let index = 0; index < subsections.length; index++) {
                    const sub = subsections[index];
                    let addSub:SubsectionHighlightModel[]|undefined = undefined
                    for (let i = 0; i < currentSubs.length; i++) {
                        const currentSub = currentSubs[i];
                        if(currentSub.id === sub.id){
                            currentSubs[index] = sub;
                            addSub = currentSubs;
                            break;
                        }
                    }
                    if (!addSub){
                        currentSubs.push(sub)
                    } 
                }
            } else {
                currentSubs = subsections
            }
            updatedState.document.pages[page].subsections = currentSubs
            return updatedState
        }
        case PdfCtx.SelectedSection:
            return {...state, currentSelectedSection: action.payload}
        case PdfCtx.AddConditional:{
            const updatedState = state;
            const page = state.pageNo
            for (let i = 0; i < updatedState.document.pages[page-1].fields.length; i++) {
                const f = updatedState.document.pages[page-1].fields[i];
                if(action.payload.includes(f.id)){
                    updatedState.document.pages[page-1].fields[i].conditional = true
                }
            }
            return updatedState
        }
        case PdfCtx.CreateUnified:
            return {...state, unifiedForm:action.payload}
        case PdfCtx.EditLabel:{
            if(action.payload){
                const customLabel = action.payload
                const currentDocument = state.document
                const updatedPages: PageModel[] = currentDocument.pages.map((p) => {
                    const updatedPage = p
                    for (let i = 0; i < p.fields.length; i++) {
                        const element = p.fields[i];
                        for (let index = 0; index < customLabel.length; index++) {
                            const label = customLabel[index];
                            if(label.id === element.id){
                                updatedPage.fields[i].customLabel = label.title
                            }
                        }
                    }
                    return updatedPage
                })
                currentDocument.pages = updatedPages
                return {...state, document:currentDocument}
            } else {
                const currentDocument = state.document
                const updatedPages: PageModel[] = currentDocument.pages.map((p) => {
                    const updatedPage = p
                    for (let i = 0; i < p.fields.length; i++) {
                        const element = p.fields[i];
                        if (element.customLabel){
                            updatedPage.fields[i].customLabel = undefined
                        }
                    }
                    return updatedPage
                })
                currentDocument.pages = updatedPages
                return {...state, document:currentDocument}
            }
        }
        case PdfCtx.EditPdfMode:{
            if(action.payload){
                return {...state, editPdfMode:{...state.editPdfMode, enabled:true}}
            } else {
                return {...state, editPdfMode:undefined}
            }
        }
        
        case PdfCtx.AddEditPdfBox:
            return {...state, editPdfMode:{...state.editPdfMode, box: action.payload}}
        case PdfCtx.AddPdfField:{
            const fieldPageIndex = action.payload.page - 1
            const updatedDocument = state.document
            const index = updatedDocument.pages[fieldPageIndex].fields.indexOf(action.payload)
            if(index === -1){
                updatedDocument.pages[fieldPageIndex].fields.push(action.payload)
            }
            return {...state, document:updatedDocument}
        }
        case PdfCtx.AddPdfFields:{
            const fields = action.payload
            const updatedDocument = state.document
            for (let i = 0; i < fields.length; i++) {
                const element = fields[i];
                const fieldPageIndex = element.page - 1
                const index = updatedDocument.pages[fieldPageIndex].fields.indexOf(element)
                if(index === -1){
                    updatedDocument.pages[fieldPageIndex].fields.push(element)
                }
            }
            return {...state, document:updatedDocument}
        }
        case PdfCtx.TogglePdfForm:
            return {...state, editFormMode: action.payload}
        
        default:
            return state;
    }
}


const PdfContext = createContext<{
    state: PdfContextProps ;
    dispatch: React.Dispatch<PdfAction>;
}>({
    state: initialState,
    dispatch: () => null
});

const usePdfContext = () => {
    const pdfContext = useContext(PdfContext)
    if(!pdfContext){
        throw new Error('No PdfContext.Provider found when calling usePdfContext')
    }
    return pdfContext
}

const PdfContextProvider = ({value, children}:{value:PdfContextProps, children:React.ReactNode}) => {
    const [state, dispatch] = useReducer(reducer, value)
    return (
        <PdfContext.Provider value={{state,dispatch}}>
            {children}
        </PdfContext.Provider>
    )
}

export { PdfContextProvider, usePdfContext }