import { Box, Button, FormHelperText, IconButton, Stack, styled } from '@mui/material';
import React, { useEffect, useState } from 'react'
import SubjectIcon from '@mui/icons-material/Subject';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import AddIcon from '@mui/icons-material/Add';
import { AnimatePresence, motion } from 'framer-motion';
import { AutocompleteElement, UseFormGetValues, UseFormSetValue } from 'react-hook-form-mui';
import useStore from '../../../providers/EditFormState';
import { SectionListItem, SubsectionListItem } from '../../form/form-control-types';
import SubdirectoryArrowLeftIcon from '@mui/icons-material/SubdirectoryArrowLeft';
import { useModalContext } from '../../../providers/ModalContext';
import { SubsectionModalFields } from './SubsectionModalFields';
import { HighlightModel, SubsectionHighlightModel } from '../../../types';
import { PdfCtx, usePdfContext } from '../../../providers/PdfContext';
import { useNotifier } from '../../../providers/NotifyContext';

export const SubsectionField = ({sectionData, setValue, getValues, disabled}:{
    sectionData:any, 
    disabled?:boolean|undefined,
    setValue: UseFormSetValue<{[key: string]: any;}>,
    getValues: UseFormGetValues<{[key: string]: any;}>
}) => {
    const formValues = useStore((state) => state.res)
    const formData = useStore((state) => state.setFormData)
    const {state:{document:{id}}} = usePdfContext()
    const subData = formValues?.subsectionList
    const [subList, setSubList] = useState<SectionListItem[]>([]);
    const setIsEdited = useStore((state) => state.setIsEdited)
    const modal = useModalContext()
    const notify = useNotifier()
    const {dispatch} = usePdfContext()

    useEffect(() => {
        const sectionItem:SectionListItem = sectionData
        updateSubsectionFormValues(sectionItem)
    }, [])

    const updateSubsectionFormValues = (section:SectionListItem) => {
        if(subData){
            const subDefault:SectionListItem|undefined = getValues('subsection')
            const subList:SectionListItem[] = []
            let matchedDefault:SubsectionListItem|undefined = undefined;
            for (let i = 0; i < subData.length; i++) {
                const subItem = subData[i];
                if(subDefault && subDefault.id === subItem.id){
                    matchedDefault = subItem
                }
                if(subItem.sectionId === section.id){
                    const subListItem:SectionListItem = {id:subItem.id, label:subItem.label}
                    subList.push(subListItem)
                }
            }
            if(matchedDefault?.sectionId !== section.id){
                setValue('subsection', '')
            }
            setSubList(subList)
        }
    }

    const handleAddSub = (subsection:SubsectionHighlightModel) => {
        dispatch({type:PdfCtx.EditSubsections, payload:[subsection]})
        const subsectionItem:SubsectionListItem = {id: subsection.id, label: subsection.name, sectionId: subsection.sectionId }
        const exitingSubsectionList:SubsectionListItem[] = formValues?.subsectionList ?? []
        if(formValues)formData({...formValues, subsectionList:[...exitingSubsectionList, subsectionItem] })
        setSubList((existing) => ([...existing, subsectionItem]))
        setValue('subsection', {id:subsection.id, label:subsection.name})
        setIsEdited(true)
        modal.hide()
        notify.success(`Successfully created a new sub-section '${subsection.name}'`)
    }

    const handleAdd = () => {
        if(sectionData){
            const sectionId = sectionData.id
            modal.show({
                title:'Create new Sub-Section',
                text: <SubsectionModalFields id={id} sectionId={sectionId} handleAddSub={handleAddSub}/>,
            })
        }
    }

    return (
    <>
        <Stack direction={'row'} mt={1}>
            <SubdirectoryArrowLeftIcon sx={{transform:'scaleX(-1)', mt:0.75, ml:2, mr: 0.25}} />
            <AutocompleteElement
                autocompleteProps={{size:'small', fullWidth:true, disabled:disabled}} 
                name={'subsection'} 
                label={'Add to Sub-Section'} 
                options={subList}
            />
            <IconButton sx={{ml:1}} onClick={handleAdd} disabled={disabled} >
                <AddIcon/>
            </IconButton>
        </Stack>
        <Stack direction={'row'}>
            <SubdirectoryArrowLeftIcon sx={{visibility:'hidden', mt:0.75, ml:2, mr: 0.25}} />
            <FormHelperText sx={{ml:1.75, mr:3}}>If you wish to further sectionlize the field into a smaller group</FormHelperText>
        </Stack>
    </>
    )
}

