import React, { useContext } from "react";
import { FieldModel, BoxModel, BoxItemModel, HighlightModel, SubsectionSectionHighlightModel } from "../types";
import { createCtx } from "../utils/createCtx";

export enum ZPosition {
    Above, Below
}

export enum HighlightType {
    Field, Text, Btn
}

export interface SelectedFieldOpts {
    field: FieldModel,
    position?: ZPosition
}

export interface HighlightedElementsOpts {
    elements: FieldModel[],
    position?: ZPosition,
    color?: string | undefined
}

export interface BtnBoxOpts {
    btnFields: FieldModel[],
    btnBox?:BoxModel,
}

export interface ConnectFieldsOpts{
    parentField: FieldModel;
    connectedFields: FieldModel[];
    addBackdrop?: boolean|undefined;
}

export interface AddConnectFieldOpts{
    parentField?: FieldModel | undefined;
    field: FieldModel;
}

export interface CustomLabelProps{
    id: string;
    title?:string
}


export interface HighlightContextProps {
    highlightType: HighlightType;
    highlightedElements: HighlightedElementsOpts;
    selectedField?: SelectedFieldOpts;
    outlineBox?: BoxItemModel[] | undefined;
    connectFields?: ConnectFieldsOpts | undefined;
    showGroups: boolean;
    showSections: boolean;
    showConditionals: boolean;
    showTitles: boolean;
    showPosition: boolean;
}

export enum HighlightCtx {
    SelectedField = 'SELECTED',
    HighlightFields = 'HIGHLIGHTFIELDS',
    Initial = 'INITIAL',
    RmTextHighlights = 'RMTEXT',
    HighlightText = 'HIGHLIGHTTEXT',
    HighlightBtn = 'HIGHLIGHTBTN',
    SelectedOnly = 'SELECTEDONLY',
    OutlineBox = 'OUTLINEBOX',
    OnlyBox = 'ONLYBOX',
    HideBox = 'HIDEBOX',
    HighlightSections = 'HIGHLIGHTSECTIONS',
    HighlightSubsections = 'HIGHLIGHTSUBSECTIONS',
    ConnectFields = 'CONNECTFIELDS',
    AddConnectField = 'ADDCONNECTFIELD',
    RmvConnectField = 'RMVCONNECTFIELD',
    ShowConnectedFields = 'ALLCONNECTEDFIELDS',
    ChangeToActive = 'CHANGETOACTIVE',
    RemoveActive = 'REMOVEACTIVE',
    EditLabel = 'EDITLABEL',
    ToggleTitle = 'TOGGLETITLE',
    TogglePosition = 'TOGGLEPOSITION'
}

export type HighlightAction = 
    | { type: HighlightCtx.SelectedField, payload: SelectedFieldOpts }
    | { type: HighlightCtx.HighlightFields, payload: HighlightedElementsOpts }
    | { type: HighlightCtx.HighlightText, payload: HighlightedElementsOpts }
    | { type: HighlightCtx.Initial, payload: FieldModel[] }
    | { type: HighlightCtx.RmTextHighlights, payload: FieldModel[] }
    | { type: HighlightCtx.HighlightBtn, payload: FieldModel }
    | { type: HighlightCtx.SelectedOnly, payload: SelectedFieldOpts }
    | { type: HighlightCtx.OutlineBox, payload: HighlightModel[] }
    | { type: HighlightCtx.OnlyBox, payload:HighlightModel[] }
    | { type: HighlightCtx.HideBox }
    | { type: HighlightCtx.HighlightSections, payload: HighlightModel[]}
    | { type: HighlightCtx.HighlightSubsections, payload: SubsectionSectionHighlightModel }
    | { type: HighlightCtx.ConnectFields, payload: ConnectFieldsOpts|undefined }
    | { type: HighlightCtx.AddConnectField, payload: AddConnectFieldOpts }
    | { type: HighlightCtx.RmvConnectField, payload: FieldModel }
    | { type: HighlightCtx.ShowConnectedFields, payload: ConnectFieldsOpts }
    | { type: HighlightCtx.ChangeToActive, payload: FieldModel }
    | { type: HighlightCtx.RemoveActive, payload: string }
    | { type: HighlightCtx.EditLabel, payload: CustomLabelProps[]|undefined }
    | { type: HighlightCtx.ToggleTitle, payload: boolean }
    | { type: HighlightCtx.TogglePosition, payload: boolean }

const initialState:HighlightContextProps = {
    showGroups: false,
    showSections: false,
    showConditionals: false,
    showTitles: false,
    showPosition: false,
    highlightType:HighlightType.Field,
    highlightedElements:{position:ZPosition.Below, elements:[]}
}

type AppState = typeof initialState


const reducer = (state:HighlightContextProps, action: HighlightAction):AppState => {

    switch (action.type) {
        case HighlightCtx.HighlightFields:
            return {
                ...state, 
                highlightType: HighlightType.Field,
                outlineBox:undefined,
                showGroups: false,
                showSections: false,
                showConditionals: false,
                highlightedElements: action.payload}
        case HighlightCtx.HighlightText:
            return {
                ...state, 
                highlightType: HighlightType.Text,
                outlineBox:undefined,
                showGroups: false,
                showSections: false,
                showConditionals: false,
                connectFields: undefined,
                selectedField: {position:ZPosition.Below, field:state.selectedField!.field},
                highlightedElements: {position:ZPosition.Above, ...action.payload}}
        case HighlightCtx.SelectedField:
            return {...state, selectedField: {position:ZPosition.Above, ...action.payload}}
        case HighlightCtx.Initial:{
            return {
                ...state, 
                highlightType: HighlightType.Field,
                selectedField: undefined, 
                outlineBox:undefined,
                showGroups: false,
                showSections: false,
                showConditionals: false,
                connectFields: undefined,
                highlightedElements:{position:ZPosition.Below, elements:action.payload} }
            }
        case HighlightCtx.RmTextHighlights:
            return {
                ...state, 
                highlightType: HighlightType.Field,
                outlineBox:undefined, 
                showGroups: false,
                showSections: false,
                showConditionals: false,
                connectFields: undefined,
                selectedField: {position:ZPosition.Above,field:state.selectedField!.field},
                highlightedElements:{position:ZPosition.Below, elements:action.payload}}
        case HighlightCtx.HighlightBtn:{
            const newArr:FieldModel[] = state.highlightedElements.elements.map(obj => {
                if (obj.id === action.payload.id && state.selectedField) {
                    if(obj.isGroup){
                        return {...obj, isGroup:false,groupFieldId:null}
                    }
                    return {...obj, isGroup:true, groupFieldId: state.selectedField.field.id}
                }
                return obj;
                });
            return {
                ...state,
                highlightedElements:{position: state.highlightedElements.position, elements:newArr}
            }
        }
        case HighlightCtx.SelectedOnly:
            return {...state, highlightedElements:{elements:[]}, selectedField:action.payload}
        case HighlightCtx.OutlineBox:{
            const boxes:BoxItemModel[] = []
            const boxUn = action.payload.map((g) => {return{id:g.id, name:g.name, box:g.box}})
            boxUn.forEach((b)=>{
                if(b.box){
                    const t = b.box
                    const e:BoxItemModel = {...b, box:t}
                    boxes.push(e)
                }
            })

            const fields:FieldModel[] = []
            const fieldsUn = action.payload.map((g) => g.fields)
            fieldsUn.forEach((f) => {if(f)fields.push(...f)})

            return {...state, outlineBox: boxes, highlightedElements:{elements:fields}, showGroups: true }
        }
        case HighlightCtx.OnlyBox:{
            const boxes:BoxItemModel[] = []
            const boxUn = action.payload.map((g) => {return{id:g.id, name:g.name, box:g.box}})
            boxUn.forEach((b)=>{
                if(b.box){
                    const t = b.box
                    const e:BoxItemModel = {...b, box:t}
                    boxes.push(e)
                }
            })
            return {...state, outlineBox: boxes, showGroups:true}
        }
        case HighlightCtx.HideBox:
            return {...state, outlineBox: undefined, showGroups:false, showSections: false}
        case HighlightCtx.HighlightSections:{
            const boxes:BoxItemModel[] = []
            const fields:FieldModel[] = []
            action.payload.forEach((i) => {
                if(i.box){
                    const item:BoxItemModel = {id:i.id, name:i.name, box:i.box}
                    boxes.push(item)
                }
                if(i.fields){
                    fields.push(...i.fields)
                }
            })
            return {...state, showSections:true, outlineBox: boxes, highlightedElements: {elements:fields}}
        }
        case HighlightCtx.HighlightSubsections:{
            const boxes:BoxItemModel[] = []
            const fields:FieldModel[] = []
            const i = action.payload
            if(i.box){
                const item:BoxItemModel = {id:i.id, name:i.name, box:i.box}
                boxes.push(item)
            }
            if(i.section && i.section.box){
                const existingBox = i.section.box
                const newBox:BoxModel = {
                    ...existingBox,
                    positionPercentage:[existingBox.positionPercentage[0]-1, existingBox.positionPercentage[1]-1],
                    sizePercentage: [existingBox.sizePercentage[0] +2, existingBox.sizePercentage[1]+2]
                } 
                const item:BoxItemModel = {id:i.section.id, name:i.section.name, box:newBox}
                boxes.push(item)
            }
            if(i.fields){
                fields.push(...i.fields)
            }
            return {...state, showSections:true, outlineBox: boxes, highlightedElements: {elements:fields}}
        }
        case HighlightCtx.ConnectFields:
            return {...state, connectFields:action.payload}
        case HighlightCtx.AddConnectField:{
            if(state.connectFields?.parentField){
                const newSet:Set<FieldModel> = new Set(state.connectFields.connectedFields)
                newSet.add(action.payload.field)
                return {...state, connectFields:{parentField:state.connectFields.parentField, connectedFields:Array.from(newSet)}}
            } else {
                if(action.payload.parentField){
                    return {
                        ...state, 
                        connectFields:{
                            parentField:action.payload.parentField, 
                            connectedFields:[action.payload.field]
                        }, 
                        showConditionals: true
                    }
                } else {
                    return state
                }
            }
        }
        case HighlightCtx.RmvConnectField:{
            if(state.connectFields?.parentField){
                const newConnectedFields = state.connectFields.connectedFields.filter((i) => i.id !== action.payload.id)
                return {...state, connectFields:{parentField:state.connectFields.parentField, connectedFields: newConnectedFields}}
            } else {
                return state
            }
        }
        case HighlightCtx.ShowConnectedFields:
            return {...state, connectFields:action.payload, showConditionals: true}
        case HighlightCtx.ChangeToActive:{
            const currentHighlightedFields = state.highlightedElements
            const updatedFields:FieldModel[] = currentHighlightedFields.elements.map((c) => {
                if(c.id === action.payload.id){
                    const newField:FieldModel = {...c, active:true}
                    return newField 
                }
                return c
            })
            currentHighlightedFields.elements = updatedFields
            return {...state, highlightedElements:currentHighlightedFields}
        }
        case HighlightCtx.RemoveActive:{
            const currentHighlightedFields = state.highlightedElements
            const updatedFields:FieldModel[] = currentHighlightedFields.elements.map((c) => {
                if(c.id === action.payload){
                    const newField:FieldModel = {...c, active:false}
                    return newField 
                }
                return c
            })
            currentHighlightedFields.elements = updatedFields
            return {...state, highlightedElements:currentHighlightedFields}
        }
        case HighlightCtx.EditLabel:{
            if(action.payload){
                const customLabel = action.payload
                const currentHighlightedFields = state.highlightedElements
                const updatedFields:FieldModel[] = currentHighlightedFields.elements.map((e) => {
                    const index = customLabel.findIndex((c) => c.id == e.id)
                    if(index != -1){
                        const updatedField = e
                        updatedField.customLabel = customLabel[index].title ?? ''
                        return updatedField
                    }
                    const emptyLabel = e
                    emptyLabel.customLabel = ''
                    return emptyLabel
                })
                currentHighlightedFields.elements = updatedFields
                return {...state, highlightedElements:currentHighlightedFields}
            } else {
                const currentHighlightedFields = state.highlightedElements
                const updatedFields:FieldModel[] = currentHighlightedFields.elements.map((e) => {
                    const undefinedLabel = e
                    undefinedLabel.customLabel = undefined
                    return undefinedLabel
                })
                currentHighlightedFields.elements = updatedFields
                return {...state, highlightedElements:currentHighlightedFields}
            }
        }
        case HighlightCtx.ToggleTitle:
            return {...state, showTitles: action.payload}
        case HighlightCtx.TogglePosition:
            return {...state, showPosition: action.payload}
        default:
            return state
    }
}


const [ctx, HighlightContextProvider] = createCtx(reducer, initialState)
const HighlightContext = ctx;
const useHighlightContext = () => {
    const highlightContext = useContext(HighlightContext)
    if(!highlightContext){
        throw new Error('No HighlightContext.Provider found when calling useHighlightContext');
    }
    return highlightContext;
} 


export { useHighlightContext, HighlightContextProvider }