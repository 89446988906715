import { Box, Stack } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react'
import { RegisterOptions, UseFormReturn, UseFormWatch } from 'react-hook-form-mui';
import { DynamicFieldData, SelectOption } from '../form-control-types';
import SubdirectoryArrowLeftIcon from '@mui/icons-material/SubdirectoryArrowLeft';
import { SelectField } from './SelectField';
import { DynamicFormControl } from '../DynamicFormControl'

interface SelectNestedFieldProps{
    name: string;
    label?: string;
    helperText?: string;
    required?: boolean;
    options: SelectOption[];
    config?:RegisterOptions;
    formContext: UseFormReturn<{[key: string]: any;}, any>;
    disabled?:boolean|undefined;
}

export const SelectNested = (props:SelectNestedFieldProps) => {

    const { options, name, label, config, helperText, required, disabled, formContext } = props;
    const { watch } = formContext

    const nestedOptions = useMemo(() => (options.filter((o) => o.nested !== null)), [])
    const watchFields = useMemo(() => (nestedOptions.map((n) => n.id)), [])
    const watchNested = watch(name)
    const [nestedField, setNestedField] = useState<DynamicFieldData|undefined>(undefined)

    useEffect(() => {
        if(watchFields.includes(watchNested)){
            const field = nestedOptions.find((n) => n.id === watchNested)
            if(field && field.nested){
                const nestedDynamic = field.nested
                setNestedField(nestedDynamic)
            }
        } else if(nestedField) {
            setNestedField(undefined)
        }
    }, [watchNested])
    

    return (
        <Box>
            <SelectField
                disabled={disabled}
                name={name}
                label={label}
                config={config}
                helperText={helperText}
                required={required}
                options={options}
            />
            {
                nestedField && 
                <Stack direction={'row'} mt={1.5}>
                    <SubdirectoryArrowLeftIcon sx={{transform:'scaleX(-1)', mt:0.75, ml:2, mr: 0.25}}/>
                    <DynamicFormControl formContext={formContext} {...nestedField} disabled={disabled} />
                </Stack>
            }
        </Box>
    )
}
