import React, { useContext } from "react";
import { FieldValues, UseFormReturn } from "react-hook-form-mui";
import { BoxModel, FieldModel, HighlightModel, UnifiedGroupResponse } from "../types";
import { createCtx } from "../utils/createCtx";

export interface FormGroupInfoProps {
    id:string;
    name:string;
    box?: BoxModel;
}

export interface UnifiedGroupProps {
    response: UnifiedGroupResponse;
    matchedFields: FieldModel[];
    currentSelectedField: FieldModel;
    selectableFields: FieldModel[];
}

export interface FormContextProps {
    createButtonGroup: boolean;
    createButtonConditional: boolean;
    showUnifiedFields: boolean;
    disableFields: boolean;
    formData?: Record<string, unknown> | undefined;
    formContext?: UseFormReturn<FieldValues, any> | undefined;
    groupInfo?: FormGroupInfoProps | undefined;
    section?: HighlightModel | undefined;
    subsection?: HighlightModel | undefined;
    conditionals?: FieldModel[] | undefined;
    unifiedGroup?: UnifiedGroupProps|undefined;
}

const initialState:FormContextProps = {
    createButtonGroup: false,
    createButtonConditional: false,
    showUnifiedFields: false,
    disableFields: false,
}

export enum FormCtx {
    createButtonGroup = 'BUTTONGROUP',
    createButtonConditional = 'BUTTONCONDTIONAL',
    showUnifiedFields = 'SHOWUNIFIEDFIELDS',
    formData = 'FORMDATA',
    formContext = 'FORMCONTEXT',
    groupInfo = 'GROUPINFO',
    section = 'SECTION',
    subsection = 'SUBSECTION',
    addConditional = 'ADDCONDITIONAL',
    rmvConditional = 'RMVCONDITIONAL',
    addConditionals = 'ADDCONDITIONALS',
    disableFields = 'DISABLEFIELDS',
    CreateUnifiedGroup = 'MATCHUNIFIEDGROUP',
    AddFieldUnifiedGroup = 'ADDFIELDGROUP',
    RmvFieldUnifiedGroup = 'REMOVEFIELDUNIFIEDGROUP'
    // selectedSection = 'SELECTEDSECTION'
}

export type FormAction =
    | {type: FormCtx.createButtonConditional, payload:boolean}
    | {type: FormCtx.createButtonGroup, payload: boolean}
    | {type: FormCtx.showUnifiedFields, payload: boolean}
    | {type: FormCtx.formData, payload: Record<string, unknown>}
    | {type: FormCtx.formContext, payload: UseFormReturn<FieldValues, any>}
    | {type: FormCtx.groupInfo, payload: FormGroupInfoProps|undefined}
    | {type: FormCtx.section, payload:HighlightModel|undefined}
    | {type: FormCtx.subsection, payload:HighlightModel|undefined}
    | {type: FormCtx.addConditional, payload: FieldModel|undefined}
    | {type: FormCtx.rmvConditional, payload: FieldModel}
    | {type: FormCtx.addConditionals, payload: FieldModel[]}
    | {type: FormCtx.disableFields, payload: boolean}
    | {type: FormCtx.CreateUnifiedGroup, payload: UnifiedGroupProps|undefined}
    | {type: FormCtx.AddFieldUnifiedGroup, payload:FieldModel}
    | {type: FormCtx.RmvFieldUnifiedGroup, payload: string}
    // | {type: FormCtx.selectedSection, payload: SectionListItem }

type AppState = typeof initialState

const reducer = (state:FormContextProps, action:FormAction):AppState => {
    switch (action.type) {
        case FormCtx.createButtonConditional:
            return {...state, createButtonConditional: action.payload}
        case FormCtx.createButtonGroup:
            return {...state, createButtonGroup: action.payload}
        case FormCtx.showUnifiedFields:
            return {...state, showUnifiedFields: action.payload}
        case FormCtx.formData:
            return {...state, formData: action.payload}
        case FormCtx.formContext:
            return {...state, formContext: action.payload}
        case FormCtx.groupInfo:
            return {...state, groupInfo: action.payload}
        case FormCtx.section:
            return {...state, section: action.payload}
        case FormCtx.subsection:
            return {...state, subsection: action.payload}
        case FormCtx.addConditional:{
            if(action.payload){
                let newGroup:Set<FieldModel> = new Set()
                if(state.conditionals){
                    newGroup = new Set(state.conditionals)
                }
                newGroup.add(action.payload)
                const b = Array.from(newGroup)
                return {...state, conditionals: b}
            } else {
                return {...state, conditionals: undefined}
            }
        }
        case FormCtx.rmvConditional:{
            if(state.conditionals){
                const newConditionalsArr = state.conditionals.filter((i) => i.id !== action.payload.id)
                return {...state, conditionals: newConditionalsArr}
            } else {
                return state
            }
        }
        case FormCtx.addConditionals:
            return {...state, conditionals: action.payload}
        case FormCtx.disableFields:
            return {...state, disableFields:action.payload}
        case FormCtx.CreateUnifiedGroup:
            return {...state, unifiedGroup:action.payload}
        case FormCtx.AddFieldUnifiedGroup:{
            const f = action.payload
            if(state.unifiedGroup){
                const newGroupArr:FieldModel[] = [...state.unifiedGroup.matchedFields, f];
                return {
                    ...state, 
                    unifiedGroup:{
                        ...state.unifiedGroup, 
                        matchedFields:newGroupArr, 
                    }
                }
            }
            return state
        }  
        case FormCtx.RmvFieldUnifiedGroup:{
            const removeId:string = action.payload
            if(state.unifiedGroup){
                const newGroupArr:FieldModel[] = state.unifiedGroup.matchedFields.filter((m) => m.id !== removeId)
                return {...state, unifiedGroup:{...state.unifiedGroup, matchedFields:newGroupArr}}
            }
            return state
        }
        default:
            return {...state}
    }
}

const [ctx, FormContextProvider] = createCtx(reducer, initialState);
const FormContext = ctx;
const useFormContext = () => {
    const formContext = useContext(FormContext)
    if(!formContext){
        throw new Error('No FormContext.Provider found when calling useFormContext');
    }
    return formContext;
} 

export { useFormContext, FormContextProvider }