import React from 'react'
import { DashboardContextProvider } from '../providers/DashboardContext'
import { Header } from '../components/dashboard/Header'
import { Outlet } from 'react-router-dom'

export const Dashboard = () => {
  return (
    <DashboardContextProvider>
        <Header/>
        <Outlet/>
    </DashboardContextProvider>
  )
}
