import { Box, Button, DialogContent, DialogContentText, IconButton, Stack, Tooltip, Typography } from '@mui/material'
import React from 'react'
import EditIcon from '@mui/icons-material/Edit';
import FormatColorTextIcon from '@mui/icons-material/FormatColorText';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import DoneIcon from '@mui/icons-material/Done';
import { motion } from 'framer-motion';
import { usePdfNotify } from '../../../providers/PdfNotifyContext';
import { FullBoxProps } from '../PdfCanvas';
import { useModalContext } from '../../../providers/ModalContext';
import { AddTextFieldModal } from './AddTextFieldModal';
import { PdfCtx, usePdfContext } from '../../../providers/PdfContext';
import UploadIcon from '@mui/icons-material/Upload';
import { FieldModel } from '../../../types';
import { ReplacementPdfModal } from './ReplacementPdfModal';
import { useHighlightContext } from '../../../providers/HighlightContext';
import CloseIcon from '@mui/icons-material/Close';

export const EditPdfToolbar = ({page}:{ page:number}) => {

    const [isEnabled, setIsEnabled] = React.useState<boolean>(false)
    const [isActive, setIsActive] = React.useState<boolean>(false)
    const notify = usePdfNotify();
    const modal = useModalContext();
    const {state:{editPdfMode, editFormMode, document:{id}}, dispatch} = usePdfContext()
    const {state:{selectedField}} = useHighlightContext()

    const variants = {
        open: { x: 0 },
        closed: { y: "-50px" },
    }

    const handleToggleEditState = () => {
        setIsEnabled(isEnabled=>!isEnabled)
        if(isActive){
            setIsActive(false)
            notify.handleClose()
            dispatch({type:PdfCtx.EditPdfMode, payload:false})
        }
    }
    

    const handleAddTextField = () => {
        if(isEnabled){
            if(isActive){
                dispatch({type:PdfCtx.EditPdfMode, payload:false})
                notify.handleClose()
                setIsActive(false)
            }else{
                dispatch({type:PdfCtx.EditPdfMode, payload:true})
                notify.showSnackBar({text:'EDIT MODE - Click and drag a box around the area where you would like to add a field', autoHideDuration:null})
                setIsActive(true)
            }
        }
    }

    const handleAddFieldToDocument = (field:FieldModel) => {
        dispatch({type:PdfCtx.AddPdfField, payload:field})
        dispatch({type:PdfCtx.EditPdfMode, payload:false})
        setIsActive(false)
        setIsEnabled(false)
        modal.hide()
        notify.showSnackBar({text:`Succefully added a new field '${field.name}' to the PDF`, typeColor:'success'})
    }

    const handleSubmitBox = () => {
        if(editPdfMode?.box){
            modal.show({
                title:'Create a new PDF Text Field',
                text: <AddTextFieldModal box={editPdfMode.box} id={id} page={page} addField={handleAddFieldToDocument}/>
            })
        }
    }
    

    const handleUploadNewPdf = () => {
        modal.show({
            title: 'Upload a replacement PDF',
            text: <ReplacementPdfModal id={id} handleSubmission={handleAddFieldsToDocument}/>
        })
    }

    const handleAddFieldsToDocument = (fields:FieldModel[]) => {
        dispatch({type: PdfCtx.AddPdfFields, payload: fields})
        setIsEnabled(false)
        modal.hide()
        notify.showSnackBar({text:'Successfully added the newly mapped fields to the PDF', typeColor:'success'})
    }

    const handleFormToggle = () => {
        dispatch({type: PdfCtx.TogglePdfForm, payload:!editFormMode})
    }



    return (
        <motion.div animate={isEnabled ? "open" : "closed"} variants={variants} transition={{type:'just'}} style={{zIndex:2, position:'absolute', right:0, width:'65%'}}>
            <Stack right={0} width={'100%'} position={'absolute'} alignItems={'flex-end'}>
                <Stack px={2} width={'100%'} bgcolor={'#eaeaea'} direction={'row'} height={'50px'} alignItems={'center'} justifyContent={'space-between'}>
                    <Stack direction={'row'} spacing={2} alignItems={'center'}>
                        {
                            editPdfMode 
                                ? editPdfMode.box 
                                    ? <Button size={'small'} variant={'contained'} color={'success'} onClick={handleSubmitBox} startIcon={<DoneIcon/>}>Create</Button> 
                                    : null
                                : <Button disabled={!!selectedField} size={'small'} variant={'contained'} color={'info'} onClick={handleUploadNewPdf} startIcon={<UploadIcon/>}>Upload</Button>
                        }
                        <Button startIcon={editFormMode ? <CloseIcon/> : <EditIcon/>} variant={'contained'} size={'small'} color={editFormMode ? 'error' : 'info'} disabled={!!selectedField} onClick={handleFormToggle}>
                            {editFormMode ? 'Disable Editing' : 'Edit Form'}
                        </Button>
                    </Stack>
                    <Box ml={'auto'}>
                        <Tooltip title={'Add a Text Field'} placement={'top'}>
                            <Box component={'span'}>
                                <IconButton disabled={true} color={isActive ? 'primary' : 'default'} onClick={handleAddTextField}>
                                    <FormatColorTextIcon/>
                                </IconButton>
                            </Box>
                        </Tooltip>
                    </Box>
                </Stack>
                <Stack sx={{cursor:'pointer'}} onClick={handleToggleEditState} px={3} pb={0.5} borderRadius={'0 0 5px 5px'} boxShadow={'0 5px 5px 0 rgba(0,0,0,0.1)'} direction={'row'} justifyContent={'center'} mx={2} width={'30px'} bgcolor={'#eaeaea'}>
                    {isEnabled 
                        ? <KeyboardArrowUpIcon/>
                        : <EditIcon/>
                    }
                </Stack>
            </Stack>
        </motion.div>
    )
}
