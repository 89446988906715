import React, { useEffect, useMemo, useState } from 'react'
import { Alert, Box, Stack } from '@mui/material'
import { AnimatePresence, motion } from 'framer-motion'
import { FormContainer, useForm } from 'react-hook-form-mui'
import useStore from '../../providers/EditFormState'
import { FormCtx, UnifiedGroupProps, useFormContext } from '../../providers/FormContext'
import { PdfCtx, usePdfContext } from '../../providers/PdfContext'
import { FieldModel, FieldType, HighlightModel, SubsectionHighlightModel, UnifiedGroupResponse } from '../../types'
import { AddToGroup } from '../editor/create-group'
import { GroupChip } from '../editor/create-group/GroupChip'
import { DynamicFormControl } from './DynamicFormControl'
import { DynamicFieldData, FormFieldData, SectionListItem, UserFormData } from './form-control-types'
import { useNotifier } from '../../providers'
import { SectionField } from '../editor/create-section/SectionField'
import { useCombinedState } from '../../providers/combinedStateFn'
import { DropdownOptions } from '../editor/DropdownOptions'
import { AddToConditional } from '../editor/create-conditional/AddToConditional'
import { HighlightCtx, useHighlightContext, ZPosition } from '../../providers/HighlightContext'
import { TitleField } from './fields/TitleField'
import { RemoveUnifiedMapping } from './unified/RemoveUnifiedMapping'
import { UnifiedGroupFieldSelector } from './unified/UnifiedGroupFieldSelector'
import { RemoveFieldMapping } from './RemoveFieldMapping'
import { LoadingButton } from '@mui/lab'
import { usePdfNotify } from '../../providers/PdfNotifyContext'
import { useInstance } from '../../utils/axiosConfig'

interface FormProps {
  formData: UserFormData;
  selectedField: FieldModel;
}

export const EditBarForm = ({formData, selectedField}:FormProps) => {

  const isEdited = useStore((state) => state.isEdited)
  const resetEditFormState = useStore((state) => state.initial)
  const setIsEdited = useStore((state) => state.setIsEdited)
  const setTextHighfieldIcon = useStore((state) => state.setShowTextHighlight);
  const { 
    state:{createButtonGroup, createButtonConditional, conditionals, showUnifiedFields, disableFields, unifiedGroup},
    dispatch:fDispatch 
  } = useFormContext()
  const { state:{document, pageNo, currentSelectedSection}, dispatch } = usePdfContext()
  const { state:{showGroups, showConditionals},dispatch:hDispatch } = useHighlightContext()
  const [ pos, setPos ] = useState<number|string>(0)
  const notify = useNotifier();
  const pdfNotify = usePdfNotify()
  const { resetHighlightedFields, initialHighlightClickFn } = useCombinedState()
  const combinedDisabled = createButtonConditional||showConditionals||showGroups||showUnifiedFields
  const pageIndex = pageNo-1
  const isConditional = useMemo(() => formData.conditional?.fields[0].id === selectedField.id, [])
  const [loading, setLoading] = React.useState<boolean>(false);
  const {instance} = useInstance()

  useEffect(() => {
    if(createButtonGroup){
      setPos('200%')
    }
  }, [createButtonGroup])
  
  
  let existingSection = useMemo(()=>{
    if(selectedField.completed){
      const t = document.pages[pageIndex].sections
      const r = t?.find((p) => {
        return p.fields?.some((h) => {
          return h.id === selectedField.id
        })
      })
      return r
    }
  },[])
  let existingSub = useMemo(() => {
    if(existingSection){
      const t = document.pages[pageIndex].subsections
      const r = t?.find((p) => {
        return p.fields?.some((h) => {
          return h.id === selectedField.id
        })
      })
      return r
    }
  }, [])
  const defaultValuesItems = useMemo(() => formData.fields.filter((v) => v.defaultValue ), [])
  const defaultValuesNested = useMemo(() => checkNested(formData.fields),[])


  const defaultValues = useMemo(() => {
    const obj:{[key:string]:any} = {};

    if(formData.title){
      obj['title'] = formData.title
    }

    if(currentSelectedSection && !existingSection){
      obj['section'] = {id:currentSelectedSection.id, label:currentSelectedSection.label}
    }

    if(existingSection){
      obj['section'] = {id:existingSection.id, label:existingSection.name}
    }

    if(existingSub){
      obj['subsection'] = {id:existingSub.id, label:existingSub.name}
    }

    if(formData.unifiedData){
      const {unifiedSection, unifiedSubsection} = formData.unifiedData
      obj['section'] = unifiedSection
      existingSection = {id:unifiedSection.id, name:unifiedSection.label} 
      
      if(unifiedSubsection){
        obj['subsection'] = unifiedSubsection
        existingSub = {id:unifiedSubsection.id, name:unifiedSubsection.label, sectionId:unifiedSection.id}
      }
    }

    for (let index = 0; index < defaultValuesItems.length; index++) {
      const name = defaultValuesItems[index].fieldName
      const value = defaultValuesItems[index].defaultValue
      obj[name] = value;
    }

    for (let index = 0; index < defaultValuesNested.length; index++) {
      const name = defaultValuesNested[index].fieldName;
      const value = defaultValuesNested[index].defaultValue;
      obj[name] = value
    }

    return obj;
  }, [])

  
  const formContext = useForm({defaultValues:defaultValues});
  const {setValue, formState:{isDirty}, watch, getValues} = formContext;
  const watchSection = watch("section");

  useEffect(() => {
    if(isDirty && !isEdited){
      setIsEdited(true)
    }
  }, [isDirty])

  useEffect(() => {
    fDispatch({type:FormCtx.section, payload:existingSection})
    fDispatch({type:FormCtx.subsection, payload:existingSub})
    if(formData.conditional){
      fDispatch({type:FormCtx.addConditionals, payload:formData.conditional.fields})
    }
    if(formData.unifiedData){
      fDispatch({type:FormCtx.showUnifiedFields, payload:true})
      setTextHighfieldIcon(false)
    }
  }, [])
  

  const handleSubmit = (data:{[key: string]: any;}) => {
    const form = new FormData()
    addRequiredFieldData(form)
    formatFormFields(data, form)
    // check whether the field is being updated or adding data for the first time
    selectedField.completed
    ? updateFieldData(form, data)
    : createFieldData(form, data)
  }


  const updateFieldData = (form:FormData, data:{[key:string]:any}) => {
    setLoading(true)
    instance.patch(`/api/pdf/form_data`, form, {headers: {'content-type': 'multipart/form-data'}})
    .then((res) => {
      const value:FormFieldData = res.data as FormFieldData
      checkIfSection(data)
      resetPageState()
      addSectionToDocument(value.section)
      addSubToDocument(value.subsection)
      notify.success(`'${selectedField.name}' was successfully edited`)
      setLoading(false)
    })
    .catch((err) => {
      notify.error("Unfortuately there was an error updating the field")
      console.log(err)
      setLoading(false)
    })
  }

  const createFieldData = (form:FormData, data:{[key:string]:any}) => {
    setLoading(true)
    instance.post(`/api/pdf/form_data`, form, {headers: {'content-type': 'multipart/form-data'}}
    )
    .then((res) => {
      const value:FormFieldData = res.data as FormFieldData
      checkIfSection(data)
      markFieldAsCompleted()
      resetPageState()
      addSectionToDocument(value.section)
      addSubToDocument(value.subsection)
      notify.success(`'${selectedField.name}' was successfully mapped`)
      setLoading(false)
    })
    .catch((err) => {
      notify.error("Unfortuately there was an error submitting field data")
      console.log(err)
      setLoading(false)
    })
  }

  const addRequiredFieldData = (form:FormData) => {
    form.append('pdfId', document.id)
    form.append('fieldId',selectedField.id)
    form.append('mappedField', selectedField.name)
    form.append('page', pageNo.toString())
    if(conditionals){
      const conditionalIds = conditionals.map((i) => i.id)
      const conditionalIdsJson = JSON.stringify(conditionalIds)
      form.append('conditional', conditionalIdsJson)
    }
  }

  const formatFormFields = (data:{[key: string]: any;},form:FormData) => {
    for (const key in data) {
      if(data[key]){
        if(typeof data[key] === 'object' && data[key] !== null &&!Array.isArray(data[key])){
          const id:string = data[key]['id']
          form.append(key, id)
        } else {
          form.append(key, data[key])
        }
      }
    }
  }

  const checkIfSection = (data:{[key:string]:any;}) => {
    if(data['section']){
      const sectionItem:SectionListItem = data['section']
      dispatch({type:PdfCtx.SelectedSection, payload:sectionItem})
    }
  }

  const resetPageState = () => {
    resetEditFormState()
    initialHighlightClickFn()
    resetHighlightedFields()
  }

  const markFieldAsCompleted = () => {
    if(!selectedField.completed){
      dispatch({type:PdfCtx.AddCompleted, payload:selectedField.id})
    }
    if(conditionals){
      const conditionalIds = conditionals.map((c) => c.id)
      const conditionalArr = [...conditionalIds, selectedField.id]
      dispatch({type:PdfCtx.AddConditional, payload: conditionalArr})
    }
  }

  const addSectionToDocument = (sections:HighlightModel[]|undefined) => {
    if(sections){
      dispatch({type:PdfCtx.EditSections, payload:sections})
    }
  }

  const addSubToDocument = (subsections:SubsectionHighlightModel[]|undefined) => {
    if(subsections){
      dispatch({type:PdfCtx.EditSubsections, payload:subsections})
    }
  }

  const enableUnifiedGroup = (unifiedGroup:UnifiedGroupResponse) => {
    const btnFields = document.pages[pageIndex].fields.filter((f) => f.type === unifiedGroup.fieldType)
    const btnCompleted = btnFields.filter((f) => f.completed !== true)
    const newUnifiedGroupState:UnifiedGroupProps = {
      response:unifiedGroup,
      matchedFields:[],
      currentSelectedField:selectedField,
      selectableFields:btnCompleted,
    } 
    fDispatch({type:FormCtx.CreateUnifiedGroup, payload:newUnifiedGroupState})
    hDispatch({type:HighlightCtx.HighlightFields, payload:{elements:btnCompleted,position:ZPosition.Above}})
    setTextHighfieldIcon(false)
    useStore.setState({setHighlightFn:(f)=>{addMatchedField(f)}});
  }
  
  const addMatchedField = (f:FieldModel) => {
    fDispatch({type:FormCtx.AddFieldUnifiedGroup, payload:f})
    hDispatch({type:HighlightCtx.ChangeToActive, payload:f})
  }

  const markUnifiedGroupFields = (fields:FieldModel[]) => {
    dispatch({type:PdfCtx.AddCompletedGroup, payload:fields})
    resetPageState()
    notify.success(`'${selectedField.name}' was successfully mapped`)
  }


  function checkNested(fields:DynamicFieldData[]):DynamicFieldData[]{
    const defaultValuesNestedItems:DynamicFieldData[] = []
    for (let index = 0; index < fields.length; index++) {
      const element = fields[index];
      if(element.options){
        const y = element.options
        for (let index = 0; index < y.length; index++) {
          const o = y[index];
          if(o.nested){
            const h = o.nested
            if(h.defaultValue){
              defaultValuesNestedItems.push(h)
            }
          }
        }
      }
    }
    return defaultValuesNestedItems
  } 

  return (
    <Box style={{overflowY:'scroll', overflowX:'hidden'}} my={1.5} px={2} height={'100%'}>
      <motion.div initial={{opacity:0}} animate={{opacity:1}} >
      {!selectedField.completed && formData.group &&
        <Alert sx={{mt:2,mb:1}} severity={'error'}>This button is part of a group but is still missing field data</Alert>
      }
      {formData.unifiedData &&
        <>
          <Alert sx={{mt:1, mb:2}} severity={'info'}>This field is part of a unified field and cannot be edited</Alert>
          {
            formData.unifiedData.unifiedGroup &&
              <Alert sx={{mt:-1, mb:2}} severity={'warning'}>
                {`Please note, if you remove this field, this field is part of a group, and contains ${formData.unifiedData.unifiedGroup.fields.length-1} 
                other fields, the mapping for those fields will also be removed`}
              </Alert>
          }
        </>
      }
      {isConditional &&
        <Alert sx={{mt:1, mb:2}} severity={'info'}>This field is part of a conditional group and will inherit the Section and Sub-Section of its parent field</Alert>
      }
      {selectedField.type === FieldType.Button && !unifiedGroup && 
        <AddToGroup group={formData.group} disabled={combinedDisabled} />
      }
      </motion.div>
      <AnimatePresence>
        {unifiedGroup && 
          <UnifiedGroupFieldSelector unifiedGroup={unifiedGroup} markUnifiedGroupFields={markUnifiedGroupFields} />
        }
      </AnimatePresence>
      <AnimatePresence>
      {!createButtonGroup && !unifiedGroup &&
        <motion.div initial={{opacity:0, y:pos}} animate={{opacity:1, y:0}} exit={{opacity:0, y:'50%'}} transition={{type:'just'}} >
          {formData.group &&
            <GroupChip group={formData.group} disabled={createButtonConditional||showConditionals} />
          }
          <FormContainer onSuccess={handleSubmit} formContext={formContext}>
            {selectedField.type === FieldType.List && selectedField.options && (
              <DropdownOptions list={selectedField.options} />
            )}
            {!isConditional &&
              <SectionField
                disabled={combinedDisabled||disableFields}
                setValue={setValue} 
                getValues={getValues}
                watchSection={watchSection}/>
            }
            <Stack spacing={4} mt={4} position={'relative'}>
              <TitleField 
                formContext={formContext} 
                formData={formData} 
                defaultTitle={defaultValues['title']} 
                disabled={combinedDisabled||disableFields} 
                enableUnifiedGroup={enableUnifiedGroup}
                completed={selectedField.completed}
              />
              {formData.fields.map((d, i) => (
                <DynamicFormControl key={i} {...d} formContext={formContext} disabled={combinedDisabled||disableFields} />
              ))}
              {(selectedField.type === FieldType.Button || selectedField.type === FieldType.List) && !isConditional &&
                <AddToConditional disabled={showGroups||showUnifiedFields} form={formData} fieldType={selectedField.type} setValue={setValue} />
              }
              <Box pb={2}>
                <LoadingButton loading={loading} fullWidth disabled={!isEdited||combinedDisabled} variant='outlined' type='submit'>
                  Submit
                </LoadingButton>
              </Box>
            </Stack>
          </FormContainer>
          {selectedField.completed &&
            <>
            {formData.unifiedData 
              ? <RemoveUnifiedMapping selectedField={selectedField} unifiedData={formData.unifiedData}/>
              : <RemoveFieldMapping selectedField={selectedField}/>
            }
            </>
          }
        </motion.div>
      }
      </AnimatePresence>
    </Box>
  )

}
