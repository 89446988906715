import { Card, Typography } from '@mui/material';
import React from 'react';

const ErrorCard = (props: any) => {
    const message = !props.error.error ? '' : props.error.error;
    return (
        <Card>
            <Typography variant='h3'>Something Went Wrong - {message}</Typography>
        </Card>
    );
};

export default ErrorCard;
