import { Breadcrumbs, Stack, Typography } from '@mui/material';
import React from 'react'
import { Link, useParams } from 'react-router-dom';
import ReplayIcon from '@mui/icons-material/Replay';
import HomeIcon from '@mui/icons-material/Home';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import { LoadingButton } from '@mui/lab';
import { useLoadItems } from '../components/dashboard/utils';
import { LoadingNoText } from '../components/shared/LoadingAnimation';
import { ListNoPagination } from '../components/dashboard/ListNoPagination';
import { NoResults } from '../components/dashboard/NoResults';
import { ResultType } from '../types';

export const Search = () => {
    const { loading, error, count, searchPdfList } = useLoadItems()
    const {term} = useParams();

    React.useEffect(() => {
        searchPdfList(term??'')
    }, [term])


    const reloadData = () => {
        searchPdfList(term??'')
    }

    const BreactcrumbsComponent = () => {
        return (
            <Breadcrumbs sx={{mb:-3, ml:1, mt:2}} aria-label="breadcrumb">
                <Link
                    style={{display:'flex', alignItems:'center'}}
                    to={'/dashboard'}
                >
                    <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                    Dashboard
                </Link>
                <Stack direction={'row'} alignItems={'center'} >
                    <ManageSearchIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                    <Typography color="text.primary">
                        results for
                    </Typography>
                    <Typography ml={0.5} color={'text.primary'} fontWeight={'500'} >{`"${term}"`}</Typography>
                    {!loading && !error && <Typography ml={0.5} color={'text.primary'} >{`(${count})`}</Typography>}
                </Stack>
            </Breadcrumbs>
        )
    }

    return (
        <>
        <BreactcrumbsComponent/>
        {error  
            ? <Stack mt={5} width={'100%'} height={'100%'} justifyContent={'center'} alignItems={'center'} direction={'row'}>
                <LoadingButton loading={error&&loading} size={'large'} variant={'outlined'} startIcon={ <ReplayIcon/> } onClick={reloadData}>Reload</LoadingButton>
            </Stack>
            : loading
                ? <LoadingNoText/>
                : <>
                    {count > 0
                        ? <ListNoPagination type={ResultType.Search} />
                        : <NoResults/>
                    }
                </>
        }
        </>
    )
    
}
