import { AppBar, Avatar, Backdrop, Box, Button, CircularProgress, Container, Divider, IconButton, ListItemIcon, Menu, MenuItem, Stack, SvgIconTypeMap, Toolbar, Tooltip, Typography } from '@mui/material'
import React from 'react'
import { Link as RouterLink } from 'react-router-dom';
import DashboardIcon from '@mui/icons-material/Dashboard';
import { useAuthContext } from '../../providers/AuthContext';
import { Logout } from '@mui/icons-material';
import { useAuth } from '../../auth/useAuth';

interface PageProps {
    link: string;
    name: string;
    icon: React.ReactElement
}

const TopNav = () => {

    const pages: PageProps[] = [
        { link: '/dashboard/files', name: 'Dashboard', icon: <DashboardIcon/> }
    ]

    const {state:{user}} = useAuthContext()
    const {logout, loading} = useAuth()
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleLogout = () => {
        logout()
    }

    function stringToColor(string: string) {
        let hash = 0;
        let i;
      
        /* eslint-disable no-bitwise */
        for (i = 0; i < string.length; i += 1) {
          hash = string.charCodeAt(i) + ((hash << 5) - hash);
        }
      
        let color = '#';
      
        for (i = 0; i < 3; i += 1) {
          const value = (hash >> (i * 8)) & 0xff;
          color += `00${value.toString(16)}`.slice(-2);
        }
        /* eslint-enable no-bitwise */
      
        return color;
      }
      
      function stringAvatar(name: string) {
        return {
          sx: {
            textTransform:'uppercase',
            bgcolor: stringToColor(name),
            width: 32, 
            height: 32 
          },
        //   children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
        children: `${name[0]}`
        };
      }


    return (
        <>
        <AppBar position={'sticky'} elevation={0} sx={{backgroundColor:"#fff", boxShadow: "0 0 20px rgba(0,0,0,0.2)", top:0, zIndex:5}} >
            <Container maxWidth='lg'>
                <Toolbar disableGutters>
                    <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' }, alignItems: 'center' }}>
                        <Box sx={{flexGrow: 1}}><img style={{"height":"40px"}} src='/blockforms-logo-256x256.png' /></Box> 
                        {pages.map((page) => (
                            <Button component={RouterLink} key={page.name} startIcon={page.icon} to={page.link} variant={'outlined'} sx={{ textTransform: 'none', px: 2}} >
                                {page.name}
                            </Button>
                        ))}
                        {user &&
                            <>
                                <Tooltip title="Account settings">
                                    <IconButton
                                        onClick={handleClick}
                                        size="small"
                                        sx={{ ml: 2 }}
                                        aria-controls={open ? 'account-menu' : undefined}
                                        aria-haspopup="true"
                                        aria-expanded={open ? 'true' : undefined}
                                    >
                                        <Avatar {...stringAvatar(user.username)}/>
                                    </IconButton>
                                </Tooltip>
                                <Menu
                                    anchorEl={anchorEl}
                                    id="account-menu"
                                    open={open}
                                    onClose={handleClose}
                                    onClick={handleClose}
                                    PaperProps={{
                                        elevation: 0,
                                        sx: {
                                            maxWidth:'250px',
                                            overflow: 'visible',
                                            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                            mt: 1.5,
                                            '& .MuiAvatar-root': {
                                                width: 32,
                                                height: 32,
                                                ml: -0.5,
                                                mr: 1,
                                            },
                                            '&:before': {
                                                content: '""',
                                                display: 'block',
                                                position: 'absolute',
                                                top: 0,
                                                right: 14,
                                                width: 10,
                                                height: 10,
                                                bgcolor: 'background.paper',
                                                transform: 'translateY(-50%) rotate(45deg)',
                                                zIndex: 0,
                                            },
                                        },
                                    }}
                                    transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                                    anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                                >
                                    <MenuItem disabled>
                                        <Avatar/>
                                        <Typography variant={'body2'} noWrap>{user.username}</Typography>
                                    </MenuItem>
                                    <Divider/>
                                    <MenuItem onClick={handleLogout}>
                                        <ListItemIcon>
                                            <Logout fontSize="small" />
                                        </ListItemIcon>
                                        <Stack direction={'row'} justifyContent={'space-between'}>
                                            <Typography variant={'body2'}>Logout</Typography>
                                        </Stack>
                                    </MenuItem>
                                </Menu>
                            </>
                        }
                    </Box>
                </Toolbar>
            </Container>
        </AppBar>
        <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={loading}
        >
            <CircularProgress color="inherit" />
        </Backdrop>
        </>
    )
}

export default TopNav