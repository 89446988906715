import React, { ReactNode } from "react";
import PropTypes from "prop-types";

export const propTypes = {
    /** The children to render at the bottom of the render tree. */
    children: PropTypes.any,
    /** A zero-based index at which to begin extraction from the `list` prop. */
    index: PropTypes.number,
    /** The list of components to render recursively. */
    list: PropTypes.arrayOf(PropTypes.any)
};


export function ProviderRenderer(
    { children, index = 0, list = [] }:
    {
        children:React.ReactNode, 
        index?:number, 
        list:(({ children }: { children: ReactNode; }) => JSX.Element)[]
    }) {
    if (!list.length) {
        return <>{children}</>;
    }
    const Component = list[index]
    const next = index + 1;
    return (
        <Component>
        {list[next] ? (
            <ProviderRenderer index={next} list={list}>
            {children}
            </ProviderRenderer>
        ) : (
            children
        )}
        </Component>
    );
}

ProviderRenderer.propTypes = propTypes;
