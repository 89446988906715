import { ToggleButtonProps } from '@mui/material';
import React, { ReactNode } from 'react'
import { RegisterOptions, ToggleButtonGroupElement } from 'react-hook-form-mui'
import { SelectOption } from '../form-control-types';

interface ButtonGroupProps{
    name: string;
    label?: string;
    helperText?: string;
    required?: boolean;
    options: SelectOption[];
    config?:RegisterOptions;
    disabled?:boolean|undefined;
}

export const ButtonGroup = (props:ButtonGroupProps) => {

    const { helperText, required, label, name, options, config, disabled } = props;

    return (
        <ToggleButtonGroupElement
            disabled={disabled}
            exclusive
            fullWidth
            size="small"
            name={name}
            label={label}
            helperText={helperText}
            required={required}
            options={options}
            validation={config}/>
    )
}
