import React, { useState } from 'react'
import AddIcon from '@mui/icons-material/Add'
import { Box, Button, List, Stack, styled, Typography } from '@mui/material'
import { motion } from 'framer-motion'
import { useEffectOnce } from '../../../hooks/useEffectOnce'
import { GroupHighlightModel } from '../../../types'
import { SkeletonLoadingFields } from '../../shared/SkeletonLoadingFields'
import { HighlightCtx, useHighlightContext } from '../../../providers/HighlightContext'
import { useModalContext } from '../../../providers/ModalContext'
import { GroupModalFields } from './GroupModalFields'
import { useNotifier } from '../../../providers'
import { FormCtx, useFormContext } from '../../../providers/FormContext'
import useStore from '../../../providers/EditFormState'
import { PdfCtx, usePdfContext } from '../../../providers/PdfContext'
import { AxiosError } from 'axios';
import { EditGroupModal } from './EditGroupModal';
import { GroupItem } from './GroupItem';
import {useDebouncedCallback} from 'use-debounce'
import { useCombinedState } from '../../../providers/combinedStateFn'
import { UnifiedGroupModal } from '../../form/unified/UnifiedGroupModal'
import { useInstance } from '../../../utils/axiosConfig'

interface GroupInfoStateProps{
    loading:boolean;
    error?:AxiosError;
    data?:GroupHighlightModel[]|undefined;
}

export const GroupInfo = () => {

    const initialState:GroupInfoStateProps = {
        loading:true
    }

    const { state:{selectedField, showGroups}, dispatch } = useHighlightContext();
    const { dispatch:fDispatch } = useFormContext();
    const {instance} = useInstance()
    const formValues = useStore((state) => state.res)
    const formData = useStore((state) => state.setFormData)
    const [state, setState] = useState<GroupInfoStateProps>(initialState);
    const [groupFields, setGroupFields] = useState<GroupHighlightModel|undefined>(undefined);
    const setTextHighfieldIcon = useStore((state) => state.setShowTextHighlight);
    const notify = useNotifier();
    const modal = useModalContext();
    const { state:{pageNo, document:{id}}, dispatch:pDispatch } = usePdfContext();
    const combined = useCombinedState()
    const debounced = useDebouncedCallback((value:GroupHighlightModel) => {
        if(!groupFields && value.fields && value.fields.length){
            setGroupFields(value);
            dispatch({type:HighlightCtx.OutlineBox, payload:[value]})
        } 
    }, 1000);
    


    useEffectOnce(() => {
        instance.get(`/api/groups/${id}/${pageNo}`)
            .then((res) => 
            {
                setState({loading:false, data:res.data as GroupHighlightModel[]})
            })
            .catch(err => {
                console.log(err);
                setState({loading:false, error:err})
            });
    })
    
    
    const GroupBox = styled(Box)(({theme})=>({
        padding: '1em',
        width: '100%',
        height:'100%',
        borderBottom: `solid 1px ${theme.palette.primary.main}`,
        borderLeft: `solid 1px ${theme.palette.primary.main}`,
        borderRight: `solid 1px ${theme.palette.primary.main}`,
        borderBottomLeftRadius:'4px',
        borderBottomRightRadius:'4px'
    }))

    
    const handleOnHover = (group:GroupHighlightModel) => {
        debounced(group)
    }

    const handleAddGroup = (group:GroupHighlightModel) => {
        if(state.data){
            setState({...state, data:[...state.data, group]})
        } else {
            setState({...state, data:[group]})
        }
        modal.hide()
        notify.success(`Group '${group.name}' added`)
    }

    const removePdfFilter = (group:GroupHighlightModel, id:string) => {
        if(id !== group.id){
            return group;
        }
        
    }

    const handleRemoveGroup = (group:GroupHighlightModel) => {
        instance.delete(`/api/groups/remove/${id}/${group.id}`)
        .then((res) => {
            if(res.data === group.id && state.data){
                const newArr = state.data.filter(function(element){
                    return removePdfFilter(element,group.id)
                });
                setState({...state, data:newArr})
                modal.hide()
                notify.success(`Group '${group.name}' has been removed`)
            }
        })
        .catch(err => {
            console.log(err)
            modal.hide()
            notify.error(`There was an error deleting '${group.name}', please try again`)
        })
    }

    
    const handleEditGroup = (group:GroupHighlightModel) => {
        if(state.data){
            const updatedGroup = state.data.map((item) => {
                if(item.id === group.id){
                    return group
                }
                return item
            })
            setState({...state, data:updatedGroup})
            modal.hide()
            notify.success(`Group '${group.name}' was successfully edited`)
        }
    }


    const handleOnExit = () => {
        debounced.cancel()
        if(groupFields){
            setGroupFields(undefined)
            dispatch({type:HighlightCtx.HighlightFields, payload:{elements:[]}})
        }
    }

    const handleTextClick = (group:GroupHighlightModel) => {
        handleOnExit()
        modal.show({
            title:'Edit Group',
            text: <EditGroupModal 
                    id={id} 
                    pageNo={pageNo}
                    group={group} 
                    handleEditGroup={handleEditGroup} 
                    handleRemoveGroup={handleRemoveGroup} 
                    />
        })
    }

    const handleClick = () => {
        modal.show({
            title:'Create new group',
            text: <GroupModalFields id={id} handleAddGroup={handleAddGroup}/>,
        })
    }

    const handleAddRemove = (i:GroupHighlightModel, isGroupField:boolean|undefined) => {
        if(isGroupField) {
            handleRemoveClick(i)
            return;
        }
        
        const isAdded = checkIfAdded()
        if(isAdded){
            notify.error(`Please remove from any existing group and try again`)
            return;
        }

        handleAddClick(i)
    }

    const checkIfAdded = () => {
        let isAdded = false;
        const fieldArr = [];
        if(state.data){
            for (let i = 0; i < state.data.length; i++) {
                const fields = state.data[i].fields
                if(fields){
                    for (let index = 0; index < fields.length; index++) {
                        fieldArr.push(fields[index].id)
                    }
                }
            }
        }
        if(selectedField) isAdded = fieldArr.includes(selectedField.field.id)
        return isAdded
    }


    const handleAddClick = (i:GroupHighlightModel) => {
        if(selectedField === undefined)return;
        instance.patch(`/api/groups/${id}/${pageNo}/${i.id}/add_field/${selectedField.field.id}`)
        .then((res) => {
            const currentGroups = state.data
            const newGroup = res.data as GroupHighlightModel
            if(currentGroups){
                const index = currentGroups.findIndex(e => e.id === newGroup.id)
                if(index>-1) currentGroups[index] = newGroup
                else currentGroups.push(newGroup)
            }
            setState({...state, data:currentGroups})
            if(formValues)formData({...formValues, group:{...newGroup}})
            fDispatch({type:FormCtx.createButtonGroup, payload:false})
            setTextHighfieldIcon(true)
            combined.removeHighlightedTextFn()
            pDispatch({type:PdfCtx.EditGroups, payload:newGroup})
            notify.success(`'${selectedField.field.name}' was successfully added to the group '${newGroup.name}'`)
        })
        .catch(err => {
            console.log(err);
        });
    }

    const closeUnified = () => {
        modal.hide()
        notify.success('The unified group was added, to add this field to the unified group, type a field name that was in the unified group in the title bar')
    }

    const handleUnified = () => {
        if(formValues){
            modal.show({
                title:'Create Group Modal',
                text: <UnifiedGroupModal formData={formValues} handleClose={closeUnified} pdfId={id}/>
            })
        }
    }


    const handleRemoveClick = (i:GroupHighlightModel) => {
        if(selectedField === undefined)return;
        instance.delete(`/api/groups/${id}/${pageNo}/${i.id}/remove_field/${selectedField.field.id}`)
        .then((res) => {
            const newGroup = res.data as GroupHighlightModel
            const currentGroups = state.data
            if(currentGroups){
                const index = currentGroups.findIndex(x => x.id == newGroup.id);
                currentGroups[index] = newGroup
            }
            setState({...state, data:currentGroups})
            pDispatch({type:PdfCtx.EditGroups, payload:newGroup})
            if(formValues)formData({...formValues, group:undefined})
            notify.success(`'${selectedField.field.name}' was successfully removed from the group '${newGroup.name}'`)
        })
        .catch(err => {
            console.log(err)
        })
    }

    return (
        <>
        <motion.div transition={{type:'just'}} initial={{height:0, opacity:0}} animate={{height:530, opacity:1}} exit={{height:0, opacity:0}} >
            <GroupBox>
                {state.loading && <SkeletonLoadingFields repeatN={3}/>}
                    {state.data &&
                        <Stack height={'100%'}>
                            <List sx={{ py:0, my:1, flexGrow:1, overflow:'scroll'}}>
                                {state.data.map((i) => (
                                    <GroupItem 
                                        key={i.id}
                                        group={i}
                                        selectedField={selectedField}
                                        groupFields={groupFields}
                                        handleTextClick={handleTextClick}
                                        handleOnHover={handleOnHover}
                                        handleOnExit={handleOnExit}
                                        handleAddRemove={handleAddRemove}
                                    />
                                ))}
                            </List>
                            <Stack width='100%' direction='column' justifyContent={'center'} alignItems={'center'}>
                                <Button variant={'contained'} disabled={showGroups} startIcon={<AddIcon/>} onClick={handleClick} >Or Create New Group</Button>
                                <Button sx={{mt:1.5}} variant={'contained'} disabled={showGroups} startIcon={<AddIcon/>} onClick={handleUnified}>Or Create Unified Group</Button>
                            </Stack>
                        </Stack>
                    }
                {state.error &&
                    <Typography>{state.error.message}</Typography>
                }
            </GroupBox>
        </motion.div>
        </>
    )
}
