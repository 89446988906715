import { Dialog } from '@mui/material'
import React from 'react'
import { SelectedFieldOpts } from '../../../providers/HighlightContext'
import { useModalContext } from '../../../providers/ModalContext'
import { useNotifier } from '../../../providers/NotifyContext'
import { PdfCtx, usePdfContext } from '../../../providers/PdfContext'
import { HighlightModel } from '../../../types'
import { FormFieldData } from '../form-control-types'
import { UnifiedFieldModal } from './UnifiedFieldModal'
import { useInstance } from '../../../utils/axiosConfig'

export const UnifiedModal = () => {

    const {state:{unifiedForm,pageNo}, dispatch} = usePdfContext()
    const {instance} = useInstance()
    const notify = useNotifier()
    const modal = useModalContext();

    const onCloseUnified = () => {
        dispatch({type:PdfCtx.CreateUnified, payload:undefined})
    }

    const addSectionToDocument = (section:HighlightModel[]|undefined) => {
        if(section){
            dispatch({type:PdfCtx.EditSections, payload:section})
        }
    }

    const submitUnifiedFn = async (form:FormData, field:SelectedFieldOpts) => {
        await instance.post(
            `/api/unified/form_data`, 
            form, 
            {headers: {'content-type': 'multipart/form-data'}}
        )
        .then((res) => {
            const value:FormFieldData = res.data as FormFieldData
            addSectionToDocument(value.section)
            dispatch({type:PdfCtx.AddCompleted, payload:field.field.id})
            dispatch({type:PdfCtx.CreateUnified, payload:undefined})
            modal.hide()
            notify.success(`A new unified field '${form.get('title')}' was successfully added`)
        })
        .catch((err) => {
            console.log(err)
        })
    }

    return (
        <>
        {unifiedForm &&
            <Dialog open={unifiedForm.showModal} onClose={onCloseUnified}>
                <UnifiedFieldModal 
                    formData={unifiedForm.formData} 
                    pdfId={unifiedForm.pdfId}
                    title={unifiedForm.title} 
                    fields={unifiedForm.fields} 
                    submitFn={submitUnifiedFn}
                    page={pageNo}
                />
            </Dialog>
        }
        </>
    )
}
