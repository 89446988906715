import React from 'react'
import { Stack } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import ReplayIcon from '@mui/icons-material/Replay';
import { UIForm } from '../components/unified-editor/types';
import { UnifiedContextProvider } from '../providers/UnifiedContext';
import { useLoadUnified } from '../components/unified-editor/utils';
import { LoadingAnimation } from '../components/shared/LoadingAnimation';
import { UnifiedFields } from '../components/unified-editor/UnifiedFields';
import { useSearchParams } from 'react-router-dom'


export const UnifiedEditor = () => {

    const initial:UIForm = {sections:[], unified_types:[],selected_type:'', disabled:false}

  return (
    <UnifiedContextProvider value={initial}>
      <UnifiedLoadData/>
    </UnifiedContextProvider>
  )
}


const UnifiedLoadData = () => {

  const { loading, error, loadData } = useLoadUnified()
  const [searchParams, setSearchParams] = useSearchParams();

  const getData = async () => {
    const data = await loadData()
    setSearchParams({tab:data.selected_type??""})
  }

  React.useEffect(() => {
    const val = searchParams.get("tab")
    if(val){
      loadData(val)
    } else {
      getData()
    }
  }, [])
  

  return (
    <>
    {error
      ? <Stack mt={5} width={'100%'} height={'100%'} justifyContent={'center'} alignItems={'center'} direction={'row'}>
        <LoadingButton loading={error&&loading} size={'large'} variant={'outlined'} startIcon={ <ReplayIcon/> } onClick={()=>loadData()}>Reload</LoadingButton>
      </Stack>
      : loading
        ? <LoadingAnimation/>
        : <UnifiedFields/>
    }
    </>
  )
}
