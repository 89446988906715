import { Autocomplete, Box, FormControl, IconButton, InputLabel, MenuItem, Select, SelectChangeEvent, Stack, TextField, Typography, styled } from '@mui/material'
import React, { useMemo } from 'react'
import LoadingButton from '@mui/lab/LoadingButton';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import SubdirectoryArrowRightIcon from '@mui/icons-material/SubdirectoryArrowRight';
import classNames from 'classnames';
import { UIGroupOption } from '../../unified-editor/types';
import { useEditGroup } from '../../unified-editor/utils';

export const Dropdown = (
    {groupId, options, label, placeholder, sectionIndex, subsectionIndex, rowIndex, index, conditionalId}:{
        groupId:string, 
        options:UIGroupOption[], 
        label:string, 
        placeholder:string,
        sectionIndex:number,
        subsectionIndex?:number|undefined,
        rowIndex:number,
        index:number,
        conditionalId?:string|undefined
    }) => {

    const [option, setOption] = React.useState<string>('')
    const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setOption(event.target.value);
      };
    // const {edit, setEdit, loading, editGroupTitle} = useEditGroup()
    // const [title, setTitle] = React.useState<string>(label)


    // const handleEditField = () => {
    //     editGroupTitle(
    //         {groupId:groupId, sectionIndex:sectionIndex, subsectionIndex:subsectionIndex, rowIndex:rowIndex, fieldIndex:index, title:title}
    //     )
    // }

    const DropdownField = styled(TextField)(({theme}) => ({
        '& legend': { display: 'none' },
        '& fieldset': { top: 0 },
        '&.conditional fieldset':{
            borderColor:theme.palette.info.main
        }
    })) 


  return (
    <FormControl sx={{px:1}} fullWidth >
        {
        // edit
        //     ? <Stack direction={'row'} mb={0.5} alignItems={'center'}>
        //     {loading
        //         ? <LoadingButton loading={true} sx={{minWidth:0, mx:1}}/>
        //         : <Box>
        //         <IconButton disabled={title===label} onClick={handleEditField} size={'small'}>
        //             <CheckCircleOutlinedIcon color={title===label ? 'disabled' : 'success'} />
        //         </IconButton>
        //         </Box>
        //     }
        //     <Box>
        //         <IconButton disabled={loading} onClick={()=>{setEdit(false);setTitle(label)}} size={'small'} sx={{mr:1}}>
        //         <CancelOutlinedIcon color={loading?'disabled':'error'} />
        //         </IconButton>
        //     </Box>
        //     <TextField fullWidth multiline size={'small'} sx={{
        //         '& legend': { display: 'none' },
        //         '& fieldset': { top: 0 },
        //         }} value={title} onChange={(event) => {setTitle(event.target.value)}}/>
        //     </Stack>
        //     : 
            <Stack direction={'row'} alignItems={'center'}>
                {conditionalId && <SubdirectoryArrowRightIcon fontSize={'small'} color={'info'} sx={{mr:0.25}} />}
                <Typography sx={{color:(theme) => conditionalId && theme.palette.info.main}} width={'100%'} mb={0.25}>{label}</Typography>
            </Stack>
        }
        <DropdownField
            className={classNames({conditional:conditionalId})}
            select
            size={'small'}
            value={option}
            onChange={(e) => handleChange(e)}
            inputProps={{ 'aria-label': 'Without label'}}
        >
            {options.map((o) => (
                <MenuItem key={o.id} value={o.id}>{o.label}</MenuItem>
            ))
            }
        </DropdownField>
    </FormControl>
  )
}
