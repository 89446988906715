import React from 'react'
import { ButtonGroup } from './fields/ButtonGroup';
import { SelectCustom } from './fields/SelectCustom';
import { SelectField } from './fields/SelectField';
import { SelectNested } from './fields/SelectNested';
import { TextField } from './fields/TextField';
import { DynamicFieldDataWithContext } from './form-control-types'

export const DynamicFormControl = ({...props}:DynamicFieldDataWithContext) => {

    const { inputType, label, fieldName, helperText, options = [], config, required, optionsLabel, formContext, disabled } = props;

    const { setValue, watch } = formContext

    switch (inputType) {
        case 'text':
            return (
                <TextField 
                    disabled={disabled}
                    name={fieldName} 
                    label={label} 
                    helperText={helperText} 
                    config={config}
                    required={required}/>
            )
        case 'toggle':
            return (
                <ButtonGroup
                    disabled={disabled}
                    name={fieldName}
                    label={label}
                    helperText={helperText}
                    options={options}
                    required={required}
                    config={config}/>
            )
        case 'select':
            return (
                <SelectField 
                    disabled={disabled}
                    name={fieldName}
                    label={label}
                    config={config}
                    helperText={helperText}
                    required={required}
                    options={options}/>
            )
        case 'select-custom':
            return (
                <SelectCustom
                    disabled={disabled}
                    name={fieldName}
                    label={label}
                    helperText={helperText}
                    required={required}
                    options={options}
                    setValue={setValue}
                    optionLabel={optionsLabel}/>
            )
        case 'select-nested':
            return (
                <SelectNested
                    disabled={disabled}
                    name={fieldName}
                    label={label}
                    helperText={helperText}
                    required={required}
                    options={options}
                    config={config}
                    formContext={formContext}/>
            )
        default:
            return <input type="text" />;
    }
}
