import React, { useEffect } from 'react'
import { ArrowBack } from '@mui/icons-material'
import { Alert, Badge, Box, Button, Chip, IconButton, Stack, Typography } from '@mui/material'
import { motion } from 'framer-motion'
import { FieldModel } from '../../types'
import { FieldOption } from './FieldOption'
import { EditBarForm } from '../form/EditBarForm';
import { useEffectOnce } from '../../hooks/useEffectOnce';
import { PdfCtx, usePdfContext } from '../../providers/PdfContext';
import { SkeletonLoadingFields } from '../shared/SkeletonLoadingFields';
import useStore from '../../providers/EditFormState';
import { HighlightCtx, useHighlightContext } from '../../providers/HighlightContext'
import { useModalContext } from '../../providers/ModalContext'
import { EditRowModal } from './edit-row/EditRowModal'
import { useNotifier } from '../../providers'


export const EditBar = ({field, handleClose}:{field:FieldModel, handleClose: () => void}) => {

  const { state:{pageNo, document}, dispatch } = usePdfContext()
  const { dispatch:hDispatch } = useHighlightContext()
  const loading = useStore((state) => state.loading);
  const setFormTitle = useStore((state) => state.setFormTitle);
  const res = useStore((state) => state.res)
  const error = useStore((state) => state.error)
  const fetch = useStore((state) => state.fetch)
  const modal = useModalContext()
  const notify = useNotifier()
  

  const PageButton = ({pageNo, currentPageNo}:{pageNo:number, currentPageNo:number}) => (
    <Button onClick={()=>{dispatch({type:PdfCtx.Page,payload: pageNo});duplicateField();}} variant="contained" size="small" sx={{marginLeft:"2px", marginRight:"2px", marginTop:"1em", alignSelf:"center"}}>
      {pageNo == currentPageNo ? 'current page' : `page ${field.groupPage}`}
    </Button>
  )

  function isField(f:FieldModel){
    return f.id === field.groupFieldId;
  }

  const resetHighlights = () => {
    const pageFields = document.pages[pageNo-1].fields;
    hDispatch({type:HighlightCtx.Initial, payload:pageFields})
  }

  const duplicateField = () =>{
    if(field.isGroup && field.groupFieldId && field.groupPage){
      const page:FieldModel[] = document.pages[field.groupPage-1].fields;
      const originalField:FieldModel|undefined = page.find(isField)
      if(originalField){
        hDispatch({type: HighlightCtx.SelectedField, payload: {field:originalField}})
        useStore.setState({loading:true})
        setTimeout(() => {
          fetch(originalField, document.id, pageNo)
        }, 250);
      } else {
        resetHighlights()
      }
    }else{
      resetHighlights()
    }
  }

  useEffectOnce(() => {
    if(!field.groupFieldId){
      setTimeout(() => {
        fetch(field, document.id, pageNo)
      }, 250);
    } else {
      useStore.setState({loading:false})
    }
  })


  // const handleRowClick = (row:number, width:number) => {
  //   modal.show({
  //     title: 'Edit Field Row',
  //     text: <EditRowModal id={document.id} field={field.id} row={row} width={width} onSubmission={handleRowSubmit} />
  //   })
  // }

  // const handleRowSubmit = () => {
  //   modal.hide()
  //   notify.success('Row and Width Successfully Edited')
  // }

  const handleFieldName = () => {
    if(res && res.fields.length>1){
      setFormTitle(field.name)
    }
  }


  return (
    <motion.div key="editbar" transition={{type:"just"}} initial={{x:"-100%"}} animate={{x:0}} exit={{x:"-100%"}} layout style={{height:"100%", width:"98%",backgroundColor:"rgb(239, 239, 239)", position:"absolute", boxShadow:"10px 0px 10px -5px rgba(0,0,0,0.06)", zIndex:"2"}}>
        <Stack py={3} height={'100%'}>
            <Stack mb={2} px={2} direction="row"><IconButton onClick={handleClose}><ArrowBack/></IconButton></Stack>
            <Stack px={2} direction="row" alignItems="center">
              <Chip onClick={handleFieldName} label={field.name} style={{marginRight:"0.5em", width:'300px', flex: '1 0 auto', wordWrap:'break-word'}} />
              <FieldOption field={field.type} />
            </Stack>
            {field.isGroup && !field.groupFieldId && (
              <Alert sx={{mx:2,mt:3,mb:-1}} severity={'info'}>
                This field is part of a duplicate field group, and any mappings here will be reflected on all duplicate fields
              </Alert>
            )}
            {field.isGroup && field.groupPage && (
              <>
              <Alert severity={'warning'} sx={{mx:2,mt:3,mb:1}} >
                This field is part of a duplicate field group, and cannot be edited here, click below to get to the first duplicate field
              </Alert>
              <PageButton pageNo={field.groupPage} currentPageNo={pageNo}/> 
              </>
            )}
            {loading && (
              <Box px={2}>
                <SkeletonLoadingFields repeatN={4} btn={true} form={true} />
              </Box>
            )}
            {error && (
              <Typography mt={4} textAlign="center">`Unfortunately there was an error - {error.message}`</Typography>
            )}
            {res && (
              <>
                {res.fields.length<1
                  ? <Typography mt={4} textAlign="center">No Fields Found</Typography>
                  : <EditBarForm formData={res} selectedField={field} />
                }
              </>
            )}
        </Stack>
    </motion.div>
  )
}
