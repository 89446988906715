import React, { useEffect } from 'react'
import { Backdrop, Box, Button, CircularProgress, Fab, Stack, Tab, Tabs, Typography } from '@mui/material'
import { UnifiedCtx, useUnifiedContext } from '../../providers/UnifiedContext'
import { Section } from './Section'
import './Editor.css'
import { useGetUnified, useSubmitUnified } from './utils'
import ReplayIcon from '@mui/icons-material/Replay';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import DashboardIcon from '@mui/icons-material/Dashboard';
import { Link as RouterLink, useSearchParams } from 'react-router-dom'


export const UnifiedFields = () => {

    const {state:{sections, unified_types, selected_type, reload, disabled}, dispatch} = useUnifiedContext()
    const {submitUnified, loading} = useSubmitUnified()
    const {getData, loading:unifiedLoading} = useGetUnified()
    const [_, setSearchParams] = useSearchParams();


    const moveSectionDown = React.useCallback((id:string,atIndex:number) => {
        dispatch({type: UnifiedCtx.MoveSectionDown, payload:{id:id, index:atIndex}})
    },[sections])

    const moveSectionUp = React.useCallback((id:string,atIndex:number) => {
        dispatch({type: UnifiedCtx.MoveSectionUp, payload:{id:id, index:atIndex}})
    },[sections])

    const handleSubmit = () => {
        window.scrollTo(0, 0)
        submitUnified(selected_type)
    }

    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        getData(newValue)
        setSearchParams({tab:newValue})
    };

    const handleReload = () => {
        if(selected_type){
            getData(selected_type)
        }
    }

    const handleScrollTop = () => {
        window.scrollTo(0, 0)
    }


    return (
        <>
        {(loading || unifiedLoading ) &&
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={true}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        }
        {unified_types &&
            <Box mt={2} position={'relative'}>
                <Tabs value={selected_type} centered onChange={handleChange}>
                    {unified_types.map((t) => (
                        <Tab value={t} key={t} label={t} />
                    ))}
                </Tabs>
                {reload && 
                <Button onClick={handleReload} variant={'outlined'} startIcon={<ReplayIcon/>} sx={{position:'absolute', right:0, top:0, mr:4, mt:1.5}} aria-label={'reload'}>
                    Reload
                </Button>}
            </Box>
        }
        {disabled
            ? <Stack mt={8} justifyContent={'center'} alignItems={'center'}>
                <Typography variant={'h6'} textAlign={'center'} maxWidth={'450px'} mb={4}>Submission is currently in progress, please check back later</Typography>
                <Box><Button component={RouterLink} to={'/dashboard'} startIcon={<DashboardIcon/>} variant={'outlined'} >Dashboard</Button></Box>
            </Stack>
            : <><Stack flexWrap={'wrap'} mt={2} mb={4} p={4}>
                {sections.map((s, i) => (
                    <Section 
                        key={s.id}
                        index={i} 
                        section={s} 
                        moveSectionDown={moveSectionDown} 
                        moveSectionUp={moveSectionUp} 
                        isFirst={i==0} 
                        isLast={i==sections.length-1} />
                ))}
            </Stack>
            <Box role="presentation" sx={{position: "fixed", bottom: 36, left: 32, zIndex: 1}}>
                <Fab size={'small'} onClick={handleScrollTop} color="primary" aria-label="Scroll back to top">
                    <KeyboardArrowUpIcon/>
                </Fab>
            </Box>
            <Box role="presentation" sx={{position: "fixed", bottom: 32, right: 32, zIndex: 1}}>
                <Fab variant={'extended'} onClick={handleSubmit} color="primary" aria-label="Submit form">
                    Submit
                </Fab>
            </Box>
            </>
        }
        </>
    )
}
