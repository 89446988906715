import * as React from 'react';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import SegmentIcon from '@mui/icons-material/Segment';
import LabelOutlinedIcon from '@mui/icons-material/LabelOutlined';

export const DropdownOptions = ({list}:{list:string[]}) => {
    const [open, setOpen] = React.useState(false);

    const handleClick = () => {
        setOpen(!open);
    };

    return (
        <List
        sx={{ width: '100%'}}
        component="nav"
        aria-labelledby="nested-list-subheader">
            <ListItemButton onClick={handleClick}>
                <ListItemIcon><SegmentIcon /></ListItemIcon>
                <ListItemText primary="Options in this field" />
                {open ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={open} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                {list.map((item,i) => (
                    <ListItemButton key={i} sx={{ pl: 4 }}>
                        <ListItemIcon><LabelOutlinedIcon sx={{fontSize:"1em", minWidth:"40px"}} /></ListItemIcon>
                        <ListItemText primary={item} />
                    </ListItemButton>
                ))}
                </List>
            </Collapse>
        </List>
    );
}
