import { Box, Button, CircularProgress, Dialog, DialogContent, DialogContentText, DialogTitle, Divider, IconButton, Stack, Tab, Typography } from '@mui/material'
import React, { useMemo } from 'react'
import { useUnifiedData } from './utils'
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { DynamicFormControl } from '../form/DynamicFormControl';
import { DynamicFieldData, UserFormData } from '../form/form-control-types';
import { FormContainer, useForm } from 'react-hook-form-mui';
import LoadingButton from '@mui/lab/LoadingButton';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { AllMatchedUserFields } from './types';
import { useNotifier } from '../../providers/NotifyContext';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

export const FieldInfo = (
    {fieldId, fieldName, handleDelete}:
    {fieldId:string, fieldName:string, handleDelete:() => void}
    ) => {

    const [value, setValue] = React.useState('1');
    const {getUnifiedFieldData, loading, error, data, unlinkFieldFromUnified} = useUnifiedData()

    React.useEffect(() => {
        getUnifiedFieldData(fieldId)
    }, [])

    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setValue(newValue);
    };

    return (
        <DialogContent sx={{mb:2, minWidth:'500px', maxHeight:'80vh', width:'100%'}}>
            {loading
                ? <Stack direction={'row'} justifyContent={'center'}><CircularProgress/></Stack>
                : data && 
                <TabContext value={value}>
                    <Box sx={{ width: '100%' }}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <TabList onChange={handleChange} aria-label="Unified Field Info tabs">
                                <Tab label="Edit Field" value={'1'} />
                                <Tab label="Connected Fields" value={'2'} />
                            </TabList>
                        </Box>
                        <TabPanel sx={{p:0}} value={'1'}>
                            {
                                data.formData
                                ? <UnifiedFormData 
                                    formData={data.formData} 
                                    fieldId={fieldId} 
                                    handleDelete={handleDelete}/>
                                : <Box mt={2}>
                                    <Box component={'span'}>
                                        This field is not a unified field and can be edited directly on the form, which can be found in the
                                    </Box>
                                    <Box ml={0.5} sx={{cursor:'pointer'}} color={'primary.main'} component={'span'} onClick={(e:React.MouseEvent<HTMLSpanElement, MouseEvent>)=>handleChange(e,'2')}>
                                        next tab
                                    </Box>
                                </Box>
                            }
                        </TabPanel>
                        <TabPanel sx={{py:2, px:0}} value={'2'}>
                            <UnifiedConnectedFields data={data.matchedFields} unlinkField={unlinkFieldFromUnified}/>
                        </TabPanel>
                    </Box>
                </TabContext>
            }
        </DialogContent>
    )
}

const UnifiedConnectedFields = ({data, unlinkField}:{data:AllMatchedUserFields[], unlinkField:(pdfId:string, fieldId:string, page:number)=>void}) => {

    const handleNavigate = (id:string,page:number,fieldId:string) => {
        window.open(`/edit/${id}?page=${page}&id=${fieldId}`)
    }


    return (
        <>
        <DialogContentText pb={2} id="alert-dialog-description">
            The following fields are connected to the unified field:
        </DialogContentText>
        {data.length 
            ? data.map((p,i) => (
            <Stack key={i} my={2} >
                <Divider sx={{mb:2, borderColor:'black'}} />
                <Typography variant={'body2'} fontWeight={'bold'} sx={{wordWrap:'break-word'}} >{p.name}</Typography>
                {p.fields.map((f,index) => (
                    <Stack direction={'row'} mt={1} key={f.id} alignItems={'center'} justifyContent={'space-between'}>
                        <Stack direction={'row'} alignItems={'center'}>
                            <Typography variant={'body2'}>{`Page ${f.page}`}</Typography>
                            <IconButton size={'small'} sx={{ml:0.5}} onClick={() => handleNavigate(p.id, f.page, f.id)}>
                                <OpenInNewIcon color={'primary'} fontSize={'small'}/>
                            </IconButton>
                        </Stack>
                        {/* <IconButton onClick={() => unlinkField(p.id, f.id, f.page)}>
                            <HighlightOffIcon color={'error'}/>
                        </IconButton> */}
                    </Stack>
                ))}
            </Stack>))
            : <Box>
                <Divider sx={{mb:2, borderColor:'black'}} />
                <Typography variant={'button'}>No Mapped Fields</Typography>
            </Box>
        }
        <Divider sx={{mt:2, borderColor:'black'}}/>
        </>
    )
}


const UnifiedFormData = (
    {formData, fieldId, handleDelete}:
    {formData:UserFormData, fieldId:string, handleDelete:()=>void}
    ) => {

    const {submitUnifiedData, error, submitLoading, deleteUnifiedField} = useUnifiedData()
    const {success, close, error:notifyError, warning} = useNotifier()
    const [loading, setLoading] = React.useState<boolean>(false);
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleCloseConfirm = () => {
        setOpen(false);
        deleteUnified()
    };

    const deleteUnified = () => {
        close()
        setLoading(true)
        deleteUnifiedField(fieldId)
        .then((res) => {
            if(res){
                success(`The unified field was successfully deleted`)
                handleDelete()
            } else {
                warning('This unified field still has connected fields, please review and remove them first')
            }
        })
        .catch((err) => {
            notifyError('There was a problem deleting this field, please try again')
            console.log(err)
        })
        .finally(() => {
            setLoading(false)
        })
    }

    const defaultValuesItems = useMemo(() => formData.fields.filter((v) => v.defaultValue ), [])
    const defaultValuesNested = useMemo(() => checkNested(formData.fields),[])

    const defaultValues = useMemo(() => {
        const obj:{[key:string]:any} = {};
    
        if(formData.title){
          obj['title'] = formData.title
        }
    
        for (let index = 0; index < defaultValuesItems.length; index++) {
          const name = defaultValuesItems[index].fieldName
          const value = defaultValuesItems[index].defaultValue
          obj[name] = value;
        }
    
        for (let index = 0; index < defaultValuesNested.length; index++) {
          const name = defaultValuesNested[index].fieldName;
          const value = defaultValuesNested[index].defaultValue;
          obj[name] = value
        }
    
        return obj;
    }, [])

    function checkNested(fields:DynamicFieldData[]):DynamicFieldData[]{
        const defaultValuesNestedItems:DynamicFieldData[] = []
        for (let index = 0; index < fields.length; index++) {
          const element = fields[index];
          if(element.options){
            const y = element.options
            for (let index = 0; index < y.length; index++) {
              const o = y[index];
              if(o.nested){
                const h = o.nested
                if(h.defaultValue){
                  defaultValuesNestedItems.push(h)
                }
              }
            }
          }
        }
        return defaultValuesNestedItems
    } 
    
    const formContext = useForm({defaultValues:defaultValues});
    const {formState:{isDirty}} = formContext
    
    const handleSubmit = (data:{[key: string]: any;}) => {
        const form = new FormData()
        form.append('fieldId', fieldId)
        formatFormFields(data, form)
        submitUnifiedData(form)
    }


    const formatFormFields = (data:{[key: string]: any;},form:FormData) => {
        for (const key in data) {
          if(data[key]){
            if(typeof data[key] === 'object' && data[key] !== null &&!Array.isArray(data[key])){
              const id:string = data[key]['id']
              form.append(key, id)
            } else {
              form.append(key, data[key])
            }
          }
        }
    }

    return (
        <>
        <FormContainer onSuccess={handleSubmit} formContext={formContext}>
            <Stack spacing={4} mt={2} position={'relative'}>
                {formData.fields.map((f,i) => (
                    <DynamicFormControl key={i} {...f} formContext={formContext} />
                ))}
                <Stack pt={2} direction={'row'} spacing={1}>
                    <LoadingButton sx={{width:'75%'}} disabled={!isDirty} loading={submitLoading} variant='outlined' type='submit'>
                        Submit
                    </LoadingButton>
                    <LoadingButton onClick={handleClickOpen} loading={loading} variant={'outlined'} color={'error'} sx={{width:'25%'}}>
                        Delete
                    </LoadingButton>
                </Stack>
            </Stack>
        </FormContainer>
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            >
            <DialogTitle id="alert-dialog-title">Delete Field?</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    Are you sure you would like to delete this Unified Field?
                </DialogContentText>
                <Stack mt={4} spacing={1} direction={'row'} justifyContent={'flex-end'}>
                    <Button variant={'outlined'} onClick={handleClose}>No</Button>
                    <Button variant={'outlined'} color={'error'} onClick={handleCloseConfirm} autoFocus>Yes</Button>
                </Stack>
            </DialogContent>
        </Dialog>
        </>
    )

}