import React, { ForwardedRef, useMemo } from 'react'
import { alpha, Box, styled, Tooltip, useTheme } from '@mui/material';
import { HighlightType, ZPosition } from '../../providers/HighlightContext';
import { FieldModel, FieldType } from '../../types';
import classNames from 'classnames';
import { motion } from 'framer-motion';

interface HighlightPdfFieldProps {
    fieldData: FieldModel;
    handleClick: (f: FieldModel) => void;
    highlightType: HighlightType;
    highlightColor?: string|undefined;
    highlightZPos?: ZPosition;
    showFieldLabels: boolean;
    selectedFieldId: string | undefined;
}

export const HighlightPdfField = (props:HighlightPdfFieldProps) => {

    const { fieldData, handleClick, highlightType, highlightColor, highlightZPos=ZPosition.Below, showFieldLabels, selectedFieldId } = props;
    const theme = useTheme()

    const fieldName:string = fieldData.name;
    const label:string|undefined = fieldData.customLabel
    const group:boolean|null|undefined = fieldData.isGroup && fieldData.groupFieldId!==null;
    const leftPos = useMemo(() => `${fieldData.positionPercentage[0] + (highlightType === HighlightType.Text ? -0.5 : 0) }%`, [highlightType])
    const bottomPos = useMemo(() => `${fieldData.positionPercentage[1] + (highlightType === HighlightType.Text ? -0.25 : 0) }%`, [highlightType])
    const widthAmount = useMemo(() => `${fieldData.sizePercentage[0] + (highlightType === HighlightType.Text ? 1 : 0) }%`, [highlightType])
    const heightAmount = useMemo(() => `${fieldData.sizePercentage[1]+ (highlightType === HighlightType.Text ? 0.5 : 0) }%`, [highlightType])
    const bgColor = useMemo(() => highlightColor 
        ? highlightColor 
        : fieldData.completed ? alpha(theme.palette.success.light,0.5) : alpha(theme.palette.error.light,0.5), [highlightColor])

    const HighlightBox = styled('button')(({theme})=>({
        // left: leftPos,
        // bottom: bottomPos,
        // width: widthAmount,
        // height: heightAmount,
        left: `${fieldData.positionPercentage[0] + (highlightType === HighlightType.Text ? -0.5 : 0) }%`,
        bottom: `${fieldData.positionPercentage[1] + (highlightType === HighlightType.Text ? -0.25 : 0) }%`,
        width:`${fieldData.sizePercentage[0] + (highlightType === HighlightType.Text ? 1 : 0) }%`,
        height: `${fieldData.sizePercentage[1]+ (highlightType === HighlightType.Text ? 0.5 : 0) }%`,
        backgroundColor: highlightColor 
            ? highlightColor 
            : fieldData.completed ? alpha(theme.palette.success.light,0.5) : alpha(theme.palette.error.light,0.5),
        '&:hover':{
            backgroundColor: alpha(theme.palette.info.light,0.7),
        },
        '&.group':{
            backgroundColor: theme.palette.grey[300]
        },
        '&.text:hover':{
            backgroundColor: alpha(theme.palette.success.light,0.3)
        },
        '&.active':{
            zIndex:'0',
            backgroundColor: alpha(theme.palette.success.light,0.5)
        }
    }))

    const HighlightElement = React.forwardRef((props,ref:ForwardedRef<any>) => {
        return (
            <HighlightBox
                ref={ref}
                {...props}
                onClick={()=>handleClick(fieldData)} 
                className={classNames(
                    `highlight-box
                    ${highlightZPos === ZPosition.Below ? "z-below" : "z-above"}`,
                    {
                        field: highlightType === HighlightType.Field,
                        text: highlightType === HighlightType.Text,
                        group: group,
                        completed: fieldData.completed && !highlightColor,
                        type_Btn: fieldData.type === FieldType.Button,
                        type_Tx: fieldData.type === FieldType.Text,
                        type_List: fieldData.type === FieldType.List,
                        type_Sig: fieldData.type === FieldType.Signature,
                        selected: selectedFieldId === fieldData.id,
                        active: fieldData.active
                    }
                )}>
                <Box component='span' className={classNames('hide_text', {show_label: showFieldLabels})} >
                    <Box>{label ?? fieldName}</Box>
                </Box>
            </HighlightBox>
        )
    })
    HighlightElement.displayName = "HighlightElement";


    return (
        <>
        {group && ( 
            <Tooltip arrow title="This is a duplicate field">
                <HighlightElement />
            </Tooltip>)}
        
        {highlightType === HighlightType.Text && (
            <Tooltip arrow title="Click to use this text as a field title">
                <HighlightElement />
            </Tooltip>)}

        {highlightType === HighlightType.Field && (<HighlightElement />)}
        </>
    )
}