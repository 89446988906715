import { alpha, ListItem, styled } from '@mui/material'
import React from 'react'

export const GroupItemStyled = styled(ListItem)(({theme})=>({
    position: 'relative',
    width:'100%',
    marginBottom: '1em',
    borderRadius: '4px',
    cursor: 'pointer',
    fontSize: '0.875rem',
    color:`${theme.palette.primary.main}`,
    border: `solid 1px ${alpha(theme.palette.primary.main, 0.5)}`,
    '&.enabled':{
        border: `solid 1px ${theme.palette.primary.main}`,
        backgroundColor:`${alpha(theme.palette.primary.main, 0.04)}`,
    },
    '&.selected':{
        border: `solid 1px ${theme.palette.success.light}`,
        backgroundColor: `${theme.palette.success.light}`,
        color: `${theme.palette.success.contrastText}`
    },
    '&.selected svg':{
        fill: `${theme.palette.success.contrastText}`
    },
    '&.enabled.selected':{
        backgroundColor: `${theme.palette.success.main}`
    }
}))