import React, { forwardRef } from 'react';
import { Box, Button, CircularProgress, Divider, Grid, IconButton, ListItemIcon, ListItemText, MenuItem, Stack, TextField, Tooltip, Typography } from '@mui/material';
import { motion } from 'framer-motion';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import { ItemTypes, PdfImageModel, PdfImageStatus } from '../../types';
import { LoadingButton } from '@mui/lab';
import logo from '../../bf_form_logo_sml.png'
import ProgressiveImage from 'react-progressive-graceful-image';
import loading_image from '../../loading_image.png'
import { useDrag } from 'react-dnd';
import {  useEditPdf } from './utils';
import { LinearProgressWithLabel } from '../shared/LinearProgressWithLabel';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import { useContextContext } from '../../providers/ContextContext';
import BFIcon from '../shared/BFIcon';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import DriveFileMoveOutlinedIcon from '@mui/icons-material/DriveFileMoveOutlined';
import { SlimDivider } from '../shared/SlimDivider';
import PublishIcon from '@mui/icons-material/Publish';
import { TooltipWithWidth } from '../shared/TooltipWithWidth';
import FileDownloadDoneIcon from '@mui/icons-material/FileDownloadDone';

interface PdfItemProps{
    img: PdfImageModel;
    clickHandler: (id: string) => void;
    setOpenId: (id: string, name: string) => void;
    addLogoHandler: (id: string) => void;
    handleDrop: (folderId: string, pdfId: string) => void;
    moveTo: (pdfId:string) => void;
    disabled: boolean;
}

interface DropResult {
    id: string
}

export const PdfItem = forwardRef((props:PdfItemProps, ref: React.ForwardedRef<unknown>) => {
    const {img, clickHandler, setOpenId, addLogoHandler, handleDrop, moveTo, disabled:dis} = props;
    const {changeNameHandler, submitHandler , disabled, loading} = useEditPdf()
    const [editName, setEditName] = React.useState<string>(img.name)
    const [enableEdit, setEnableEdit] = React.useState<boolean>(false);
    const {show, hide} = useContextContext()
    const textRef = React.useRef<any>(null)

    const [{isDragging}, drag] = useDrag(() => ({
        type: ItemTypes.FILE,
        item: { img },
        end: (item, monitor) => {
            const dropResult = monitor.getDropResult<DropResult>()
            if(item && dropResult){
                handleDrop(dropResult.id, item.img.id)
            }
        },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
            handlerId: monitor.getHandlerId(),
        })
    }))

    React.useEffect(() => {
        if (enableEdit && textRef.current) {
          textRef.current.select()
        }
      }, [enableEdit]);

    const opacity = isDragging ? 0.05 : 1

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEditName(event.target.value)
    };

    const handleEditName = () => {
        setEnableEdit(false)
        changeNameHandler({id:img.id, name:editName})
    }

    const submitFile = () => {
        submitHandler(img.id)
    }

    const editPdfName = () => {
        hide()
        setEnableEdit(true)
    }

    const Menu = () => {
        return (
            <>
            <MenuItem dense onClick={()=>{hide();addLogoHandler(img.id)}}>
                <ListItemIcon>
                    <BFIcon />
                </ListItemIcon>
                <ListItemText>Add Logo</ListItemText>
            </MenuItem>
            <SlimDivider />
            <MenuItem dense onClick={editPdfName}>
                <ListItemIcon>
                    <DriveFileRenameOutlineIcon />
                </ListItemIcon>
                <ListItemText>Rename</ListItemText>
            </MenuItem>
            <MenuItem dense onClick={()=>{hide();setOpenId(img.id, img.name)}}>
                <ListItemIcon>
                    <DeleteOutlineIcon/>
                </ListItemIcon>
                <ListItemText>Delete</ListItemText>
            </MenuItem>
            <MenuItem dense onClick={()=>{hide();moveTo(img.id)}}>
                <ListItemIcon>
                    <DriveFileMoveOutlinedIcon/>
                </ListItemIcon>
                <ListItemText>Move To</ListItemText>
            </MenuItem>
            </>
        )
    }


    const handleContextMenu = (e: React.MouseEvent<HTMLButtonElement|HTMLDivElement>) => {
        show({
            menu: <Menu/>, 
            element: e
        })
    }


    return (
        <Grid item xs={12} sm={2.4} mb={2}>
            <Stack height={'100%'}>
                <motion.div
                    layout
                    initial={{scale: 1, opacity:0}}
                    animate={{ scale: 1, opacity: 1 }}
                    exit={{ scale:0.5, opacity: 0 }}
                    transition={{ type: "keyframes" }}
                    style={{position:"relative"}}
                    className="parent_pdf"
                    >
                    <div style={{position:'relative'}} onContextMenu={(e)=>handleContextMenu(e)}>
                        <Box zIndex={1} position={'absolute'} left={'50%'} top={'50%'} sx={{transform:'translate(-50%,-50%)'}} >
                            <Button disabled={disabled} sx={{marginBottom:-4, borderRadius:'100vh', position:'relative'}} onClick={() => addLogoHandler(img.id)}>
                                <Tooltip title={img.logo ? 'Click to change PDF Logo' : 'Click here to add a PDF Logo'} placement={'bottom'} arrow>
                                    <img src={img.logo ?? logo} width={'56px'} height={'56px'} style={{borderRadius:'100%', boxShadow: 'rgba(0, 0, 0, 0.2) 0px 2px 6px', objectFit:'cover'}}/>
                                </Tooltip>
                            </Button>
                        </Box>
                        <Button onDoubleClick={()=>clickHandler(img.id)} sx={{position:"relative"}} disabled={disabled}>
                            <Box ref={drag} border={'solid 1px #ebebeb'} width={'100%'} sx={{opacity:opacity}} >
                                <ProgressiveImage src={img.url} placeholder={loading_image}>
                                    {(src,loading) => <img width={'100%'} src={src} style={{ opacity: loading ? 0.5 : 1, transition: 'opacity 1s' }} alt={img.name} />}
                                </ProgressiveImage>
                            </Box>
                        </Button>
                    </div>
                    {img.completed_fields/img.total_fields * 100 > 0 && img.completed_fields/img.total_fields * 100 < 100 &&
                        <Stack direction={'row'} height={'20px'} mx={1} my={1.25} alignItems={'center'} >
                            <Box width={'100%'}><LinearProgressWithLabel value={img.completed_fields/img.total_fields * 100} /></Box>
                            <Divider sx={{borderColor:'rgba(0,0,0,0.5)'}} orientation={'vertical'} />
                            <TooltipWithWidth tooltipwidth={175} title={img.status === PdfImageStatus.loading ? 'Submission in progress' : 'Submit partially completed form for processing'}>
                                <Box bgcolor={'#f7f7f7'} borderRadius={'100%'} ml={0.75} position={'relative'}>
                                    <IconButton disabled={dis} onClick={submitFile}>
                                        {img.status === PdfImageStatus.complete 
                                            ? <FileDownloadDoneIcon fontSize={'inherit'} color={dis ? 'disabled' : 'success'} />
                                            : <PublishIcon fontSize={'inherit'} color={dis ? 'disabled' : 'info'}/> 
                                        }
                                    </IconButton>
                                    {img.status === PdfImageStatus.loading && (
                                        <CircularProgress
                                            size={40}
                                            sx={{
                                            position: 'absolute',
                                            top: 0,
                                            left: 0,
                                            zIndex: 1,
                                            }}
                                        />
                                    )}
                                </Box>
                            </TooltipWithWidth>
                        </Stack>
                    }
                    {img.completed_fields/img.total_fields * 100 === 100 &&
                        <Stack direction={'row'} height={'20px'} mx={1} my={1.25} alignItems={'center'} >
                        <Box width={'100%'}><LinearProgressWithLabel color={'success'} value={100} /></Box>
                        <Divider sx={{borderColor:'rgba(0,0,0,0.5)'}} orientation={'vertical'} />
                        <Tooltip title={img.status === PdfImageStatus.loading ? 'Submission in progress' : 'Submit for processing'}><Box bgcolor={'#f7f7f7'} borderRadius={'100%'} ml={0.75} position={'relative'}>
                            <IconButton disabled={dis} onClick={submitFile}> 
                                {img.status === PdfImageStatus.complete 
                                    ? <FileDownloadDoneIcon fontSize={'inherit'} color={dis ? 'disabled' :'success'} />
                                    : <PublishIcon fontSize={'inherit'} color={dis ? 'disabled' : 'info'}/> 
                                }
                            </IconButton>
                            {img.status === PdfImageStatus.loading && (
                                <CircularProgress
                                    color={'success'}
                                    size={40}
                                    sx={{
                                    position: 'absolute',
                                    top: 0,
                                    left: 0,
                                    zIndex: 1,
                                    }}
                                />
                            )}
                        </Box></Tooltip>
                    </Stack>
                    }
                </motion.div>
                <motion.div
                    // layout
                    initial={{opacity:0}}
                    animate={{opacity:1}}
                    exit={{opacity:0}}
                    transition={{ type: "keyframes" }}
                    style={{position:"relative"}}
                    >
                    {enableEdit
                        ? <TextField
                            inputRef={textRef}
                            multiline 
                            value={editName}
                            disabled={disabled}
                            size={"small"} 
                            onChange={handleChange}
                            InputProps={{disableUnderline:true}} 
                            inputProps={{
                                style:{textAlign:'center', 
                                fontWeight:500, 
                                lineHeight:1.43, 
                                padding:"0 5px", 
                                wordBreak:"break-word", 
                                fontSize:'0.875rem'}
                            }} 
                            variant={'standard'}/>
                        : <Tooltip title={'Double-click here to edit name'} arrow>
                            <Typography
                            sx={{wordBreak:'break-word'}}
                            padding={'1px 5px 5px'}
                            fontSize={'0.875rem'}
                            textAlign={'center'} 
                            fontWeight={500} 
                            lineHeight={1.43} 
                            onDoubleClick={()=>{if(!disabled){setEnableEdit(true)}}} >
                                {editName}
                            </Typography>
                        </Tooltip>
                    }
                    {loading
                        ? <Stack direction='row' sx={{position:"absolute", width:"100%", justifyContent:"center"}} >
                            <LoadingButton loading={true} sx={{minWidth:0, p:2}}/>
                        </Stack>
                        : <>
                            {enableEdit && 
                                <Stack direction='row' sx={{position:"absolute", width:"100%", justifyContent:"center"}} >
                                    {editName !== img.name &&
                                        <IconButton onClick={handleEditName}>
                                            <CheckCircleOutlinedIcon color='success' sx={{opacity: loading ? "0" : "1" }} />
                                        </IconButton>
                                    }
                                    <IconButton onClick={()=>{setEnableEdit(false);setEditName(img.name)}}><CancelOutlinedIcon color='error' /></IconButton>
                                </Stack>
                            }
                        </>
                    }
                </motion.div>
                {img.completed_fields>0 &&
                    <Box mt={1}></Box>
                }
            </Stack>
        </Grid>
    )
});

PdfItem.displayName = "PdfItem";
