import { LoadingButton } from '@mui/lab';
import { Stack } from '@mui/material';
import React from 'react'
import { useParams } from 'react-router-dom'
import ReplayIcon from '@mui/icons-material/Replay';
import { useLoadItems } from '../components/dashboard/utils';
import { LoadingNoText } from '../components/shared/LoadingAnimation';
import { ListNoPagination } from '../components/dashboard/ListNoPagination';
import { ResultType } from '../types';

export const Folders = () => {

    const {id} = useParams();
    const { loading, error, getPdfFoldersList } = useLoadItems()

    React.useEffect(() => {
        getPdfFoldersList(id??'')
    }, [id])

    const reloadData = () => {
        getPdfFoldersList(id??'')
    }

    return (
        <>
        {error
        ? <Stack mt={5} width={'100%'} height={'100%'} justifyContent={'center'} alignItems={'center'} direction={'row'}>
            <LoadingButton loading={error&&loading} size={'large'} variant={'outlined'} startIcon={ <ReplayIcon/> } onClick={reloadData}>Reload</LoadingButton>
        </Stack>
        : loading 
            ? <LoadingNoText/>
            : <ListNoPagination type={ResultType.Folders} />
        }
        </>
    )
}