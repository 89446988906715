import { Button, Grid, ListItemIcon, ListItemText, MenuItem, Stack, Typography } from '@mui/material'
import React, { forwardRef } from 'react'
import FolderIcon from '@mui/icons-material/Folder';
import { useParentSize } from '@cutting/use-get-parent-size';
import { useNavigate } from 'react-router-dom';
import { useDrop } from 'react-dnd';
import { ItemTypes, PdfFolderModel } from '../../types';
import { useContextContext } from '../../providers/ContextContext';
import CreateNewFolderOutlinedIcon from '@mui/icons-material/CreateNewFolderOutlined';

interface UpFolderProps{
    goToFiles:() => void
    goToFolder:(folderId:string) => void
    folder: PdfFolderModel
}

export const UpFolder = forwardRef((props:UpFolderProps,ref:React.ForwardedRef<unknown>) => {

    const {goToFiles, goToFolder, folder} = props

    const iconRef = React.useRef<HTMLDivElement>(null);
    const { width } = useParentSize(iconRef);

    const [{canDrop, isOver}, drop] = useDrop(() => ({
        accept: ItemTypes.FILE,
        drop: () => (folder.folder?{id:folder.folder}:{}),
        collect: (monitor) => ({
            isOver: monitor.isOver(),
            canDrop: monitor.canDrop(),
        }),
    }))

    const isActive = canDrop && isOver
    let backgroundColor = '#e3e3e3'
    let border = 'dashed 3px transparent'
    if(isActive){
        backgroundColor = '#c8f7ec'
    } else if(canDrop){
        backgroundColor = '#c8e5f7'
        border = 'dashed 3px #c8e5f7'
    }

    const handleGoUp = () => {
        if(folder.folder){
            goToFolder(folder.folder)
        } else {
            goToFiles()
        }
    }


    return (
        <Grid item xs={12} sm={2.4} mb={4}>
            <Stack ref={iconRef} direction={'column'} alignItems={'center'} justifyContent={'center'} height={'100%'} width={'100%'} >
                <Button ref={drop} onDoubleClick={handleGoUp} sx={{border:border}}>
                    <FolderIcon style={{fontSize:`${width*0.7}px`}} htmlColor={backgroundColor} />
                </Button>
                <Typography variant={'button'} >...</Typography>
            </Stack>
        </Grid>
    )
})

UpFolder.displayName = "UpFolder"