import React from 'react'
import { motion } from 'framer-motion'
import { BoxItemModel } from '../../types'
import { alpha, Box, Typography } from '@mui/material'

export const OutlineBox = ({item}:{item:BoxItemModel}) => {    

  return (
    <motion.div 
        initial={{opacity:0}} 
        animate={{opacity:1}}>
      <Box
        data-box={item.id}
        sx={{
          position: 'absolute',
          pointerEvents:'none',
          left:`${item.box.positionPercentage[0]}%`,
          bottom: `${item.box.positionPercentage[1] + 0.5}%`,
          width: `${item.box.sizePercentage[0] + 2}%`,
          height: `${item.box.sizePercentage[1] + 2}%`,
          zIndex: 1,
        }}
      >
        <Typography sx={{
          backgroundColor:(theme)=>alpha(theme.palette.common.white, 0.8), 
          borderRadius:1,
          px:0.65, 
          fontSize:'8px', 
          display:'inline-block', 
          textTransform:'uppercase', 
          fontWeight:'bold'}} >
            {item.name}
          </Typography>
      </Box>
      <Box
        sx={{
            position:'absolute',
            backgroundColor:(theme)=>alpha(theme.palette.info.light,0.05),
            pointerEvents: 'none',
            borderRadius:1,
            border:(theme) => `solid 2px ${theme.palette.info.dark}`,
            left:`${item.box.positionPercentage[0] - 1}%`,
            bottom: `${item.box.positionPercentage[1] - 1}%`,
            width: `${item.box.sizePercentage[0] + 2}%`,
            height: `${item.box.sizePercentage[1] + 2}%`
        }}
      />
    </motion.div>
        
  )
}
