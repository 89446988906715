import { DialogContent, DialogContentText, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Stack, Typography } from '@mui/material'
import React from 'react'
import { PdfFolderModel } from '../../types';
import FolderIcon from '@mui/icons-material/Folder';

export const MoveFileModal = (
    {folders, folder, handleMove}:
    {folders:PdfFolderModel[], folder: PdfFolderModel | null, handleMove:(folderId?:string|undefined)=>void}) => {

  return (
    <DialogContent>
        <DialogContentText id="alert-dialog-description">
            Which folder would you like to move the file to?
        </DialogContentText>
        {!folder
            ? <List>
                {folders.map((f) => (
                    <ListItem key={f.id} disablePadding>
                        <ListItemButton onClick={()=>handleMove(f.id)} >
                            <ListItemIcon><FolderIcon/></ListItemIcon>
                            <ListItemText primary={f.name} />
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>
            : <List>
                <ListItem disablePadding>
                    <ListItemButton onClick={()=>handleMove()}>
                        <ListItemIcon><FolderIcon/></ListItemIcon>
                        <ListItemText primary={'...'} />
                    </ListItemButton>
                </ListItem>
            </List>
        }
    </DialogContent>
  )
}
