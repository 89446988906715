import { Button, DialogContent, DialogContentText, Stack, Typography } from '@mui/material';
import React, { useMemo } from 'react'
import { useDropzone } from 'react-dropzone';
import UploadFileIcon from "@mui/icons-material/UploadFile";
import ErrorIcon from "@mui/icons-material/Error";
import { useNavigate } from 'react-router-dom';
import { DocumentModel } from '../../types';
import { useNotifier } from '../../providers';
import { useModalContext } from '../../providers/ModalContext';
import { useInstance } from '../../utils/axiosConfig';

const baseStyle:React.CSSProperties = {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    borderRadius: 6,
    borderWidth: 2,
    borderStyle: 'solid',
    borderColor: 'rgba(25, 118, 210, 0.25)',
    transition: 'background-color color .25s ease-in-out',
    flexDirection:"column",
    padding: '35px 0',
    width: '450px'
};

const acceptStyle:React.CSSProperties = {
    color: '#fff',
    fontWeight: 'bold',
    backgroundColor: '#2196f3',
    borderColor: '#2196f3'
};

const rejectStyle:React.CSSProperties = {
    borderColor: '#ff1744',
    color: '#fff',
    backgroundColor: '#ff1744',
    fontWeight: 'bold'
};


const DropzoneText = ({text, icon}:{text:string, icon:React.ReactElement }) => {
    return (
        <Stack direction="row" alignItems="center">
            {icon}<Typography variant="h6" sx={{ml:1.5}}>{text}</Typography>
        </Stack>
    )
}

export const Dropzone = ({setLoading}:{setLoading: React.Dispatch<React.SetStateAction<boolean>>}) => {

    const navigate = useNavigate();
    const { error } = useNotifier()
    const modal = useModalContext();
    const {instance} = useInstance()


    const processFile = (file: File) => {
        setLoading(true);
        if(file){
            const formData = new FormData();
            formData.append("file", file, file.name);
            instance.post(`/api/pdf/check_name`,{"name":file.name})
            .then((res) => {
                const isAvailable:boolean = res.data
                if (isAvailable){
                    submitFile(file,formData)
                } else {
                    setLoading(false)
                    modal.show({
                        title:'Duplicate PDF?',
                        text: modalContent(),
                        actions: modalAction(file, formData)
                    })
                }
            })
            .catch(err => {
                setLoading(false)
                console.log(err)
            })
        }
    }

    const modalContent = () => (
        <DialogContent>
            <DialogContentText id="alert-dialog-description">
                A pdf with this name already exists, is this a duplcate PDF? You can still upload this pdf, but it will be given a random name
            </DialogContentText>
        </DialogContent>
    )

    const modalAction = (file:File, formData:FormData):JSX.Element => (
        <Button color="error" sx={{mt:1}} onClick={() => submitFile(file,formData)}>
            Submit Anyway
        </Button> 
    )

    const submitFile = (file:File, formData:FormData) => {
        setLoading(true)
        instance.post(`/api/pdf/upload`, formData)
            .then((res) => 
            {
                setLoading(false);
                modal.hide()
                const doc:DocumentModel = res.data;
                navigate(`/edit/${doc.id}`, {state: {file: file, res: doc}});
            })
            .catch(err => {
                setLoading(false);
                console.log(err);
            });
    }


    const {
        getRootProps,
        getInputProps,
        isDragActive,
        isDragAccept,
        isDragReject
    } = useDropzone({
        accept: {
        '	application/pdf': ['.pdf']
        },
        multiple: false,
        onDropAccepted(files) {
            processFile(files[0])
        },
        onDropRejected(){
            error('Please only select a PDF file and only a single file')

        }
    });

    const style = useMemo(() => ({
        ...baseStyle,
        ...(isDragAccept ? acceptStyle : {}),
        ...(isDragReject ? rejectStyle : {})
    }), [
        isDragAccept,
        isDragReject
    ]);


    return (
        <>
        <Button sx={{textTransform:"none", mt:3 }} size="large">
            <div {...getRootProps({style})}>
                <input {...getInputProps()} />
                {isDragAccept && (<DropzoneText text="Drop file to start processing the PDF" icon={<UploadFileIcon/>} />)}
                {isDragReject && (<DropzoneText text="Please add only a single file of type PDF" icon={<ErrorIcon/>} />)}
                {!isDragActive && (<DropzoneText text="Click or Drag a PDF here to Start" icon={<UploadFileIcon/>} />)}
            </div>
        </Button>
        </>
    )
}