import { ArrowBack, ArrowForward, ZoomIn, ZoomOut, ZoomOutMap } from '@mui/icons-material';
import { IconButton, Slider, Stack, Typography,  Box, Tooltip, Switch, FormGroup, FormControlLabel } from '@mui/material'
import React from 'react'
import { CustomLabelProps, HighlightCtx, useHighlightContext } from '../../providers/HighlightContext';
import { PdfCtx, usePdfContext } from '../../providers/PdfContext';
import { FieldModel } from '../../types';
import { LoadingSwitch } from '../dashboard/LoadingSwitch';
import { useSearchParams } from 'react-router-dom';
import { useInstance } from '../../utils/axiosConfig';

export interface SidebarProps {
    scale:number;
    numPages: number | null;
    setScale: React.Dispatch<React.SetStateAction<number>>;
    isRendered: boolean | undefined;
    showFieldLabels: boolean;
    setShowFieldLabels: React.Dispatch<React.SetStateAction<boolean>>;
}

export const Sidebar = (props:SidebarProps) => {

    const {scale, numPages, setScale, isRendered, showFieldLabels, setShowFieldLabels} = props;
    const { state:{pageNo, document}, dispatch } = usePdfContext()
    const {dispatch:hDispatch, state:{showGroups, showSections, showTitles, showPosition}} = useHighlightContext()
    const {instance} = useInstance()
    const [_, setSearchParams] = useSearchParams();
    const pageIndex = pageNo - 1


    function previousPage() {
        changePage(-1);
    }

    function nextPage() {
        changePage(1);
    }

    function changePage(offset:number) {
        dispatch({type:PdfCtx.Page, payload: pageNo + offset })
        setSearchParams([['page',`${pageNo+offset}`]])
    }

    function decreaseScale() {
        changeScale(-0.1);
    }

    function increaseScale() {
        changeScale(0.1);
    }

    function resetScale() {
        setScale(1);
    }

    function changeScale(offset:number) {
        setScale((prevScale) => prevScale + offset);
    }

    function handleChange(event:Event, value:number|number[]){
        setScale(Number(value))
    }

    function handleSwitchChange (event: React.ChangeEvent<HTMLInputElement>) {
        setShowFieldLabels(event.target.checked)
    }

    function handleGroupChange (event:React.ChangeEvent<HTMLInputElement>){
        if(event.target.checked){
            const groups = document.pages[pageIndex].groups
            if(groups){
                hDispatch({type:HighlightCtx.OnlyBox, payload:groups})
            }
        } else {
            hDispatch({type:HighlightCtx.HideBox})
        }
    }

    function handleSectionChange (event:React.ChangeEvent<HTMLInputElement>){
        if(event.target.checked){
            const sections = document.pages[pageIndex].sections
            if(sections){
                hDispatch({type:HighlightCtx.HighlightSections, payload:sections})
            }
        } else {
            const fields:FieldModel[] = document.pages[pageIndex].fields;
            hDispatch({type:HighlightCtx.Initial, payload:fields})
        }
    }

    const handleFieldMappedNames = async (event:React.ChangeEvent<HTMLInputElement>) => {
        if(event.target.checked){
            hDispatch({type: HighlightCtx.ToggleTitle, payload:true})
            const mappedNames = new Promise(function(resolve:(value:void) => void, reject){
                instance.get(`/api/pdf/user_field_names/${document.id}`)
                .then((res) => {
                    const data:CustomLabelProps[] = res.data
                    hDispatch({type: HighlightCtx.EditLabel, payload:data}) 
                    dispatch({type:PdfCtx.EditLabel, payload:data})
                    resolve()
                })
                .catch((err) => {
                    reject(err)
                })
            })
            try {
                const value = await mappedNames
                return value
            } catch (error) {
                console.log(error)
            }
        } else {
            hDispatch({type: HighlightCtx.EditLabel, payload:undefined}) 
            hDispatch({type: HighlightCtx.ToggleTitle, payload:false})
            dispatch({type:PdfCtx.EditLabel, payload:undefined})
        }
    }


    return (
        <Stack px={4} mt={3} width="100%" >
            <Tooltip title={`${Math.round(document.completed_fields/document.total_fields * 100)}% completed`} placement="top" arrow>
                <Box width="100%" borderRadius="2em" mx={{paddingTop:"0.4em", paddingBottom:"0.4em", color:"white" , background: `linear-gradient(to right, #1976d2 ${document.completed_fields/document.total_fields * 100}%, rgb(167, 202, 237) ${document.completed_fields/document.total_fields * 100}%)`}} >
                    <Typography px="1em" whiteSpace="nowrap" overflow="hidden" textOverflow="ellipsis" textAlign="center" fontWeight="bold" variant="body2">{document.name}</Typography>
                </Box>
            </Tooltip>
            <Typography fontWeight="bold" mt={1} variant="body2" textAlign="center">{`${document.completed_fields}/${document.total_fields}`}</Typography>
            <FormGroup sx={{alignSelf:'center', mt:3}}>
                <FormControlLabel control={ 
                    <Switch
                        disabled={showTitles||showPosition}
                        checked={showFieldLabels}
                        onChange={handleSwitchChange}
                        inputProps={{ 'aria-label': 'show-fields' }}/> } 
                        label={ showFieldLabels ? <b>Hide Field Labels</b> : <b>Show Field Labels</b> }
                    />
            </FormGroup>
            <FormGroup sx={{alignSelf:'center'}}>
                <FormControlLabel control={ 
                    <Switch
                        disabled={showSections}
                        checked={showGroups}
                        onChange={handleGroupChange}
                        inputProps={{ 'aria-label': 'show-group' }}/> } 
                        label={ showGroups ? <b>Hide Groups</b> : <b>Show Groups</b> }
                    />
            </FormGroup>
            <FormGroup sx={{alignSelf:'center'}}>
                <FormControlLabel control={ 
                    <Switch
                        disabled={showGroups}
                        checked={showSections}
                        onChange={handleSectionChange}
                        inputProps={{ 'aria-label': 'show-section' }}/> } 
                        label={ showSections ? <b>Hide Sections</b> : <b>Show Sections</b> }
                    />
            </FormGroup>
            <FormGroup sx={{alignSelf:'center'}}>
                <LoadingSwitch disabled={showPosition||showFieldLabels} loadingFn={handleFieldMappedNames} showLabel={'Show Mapped Titles'} hideLabel={'Hide Mapped Titles'} />
            </FormGroup>
            {/* <FormGroup sx={{alignSelf:'center'}}>
                <LoadingSwitch disabled={showTitles||showFieldLabels} loadingFn={handleFieldPositioned} showLabel={'Show Form Positions'} hideLabel={'Hide Form Positions'} />
            </FormGroup> */}
            {isRendered && numPages && numPages > 1 && (
                <Stack direction="column" alignItems="center" justifyContent="flex-end" mt="auto" flexGrow={1}>
                    <Stack width="100%" spacing={2} direction="row" mb={2} mt={2} alignItems="center">
                        <Tooltip title="zoom out" arrow><span><IconButton disabled={scale <= 0.5} onClick={decreaseScale}><ZoomOut /></IconButton></span></Tooltip>
                        <Slider aria-label="Volume" min={0.5} max={2} step={0.1} value={scale} onChange={handleChange} />
                        <Tooltip title="zoom in" arrow><span><IconButton disabled={scale >= 2} onClick={increaseScale}><ZoomIn /></IconButton></span></Tooltip>
                        <Tooltip title="reset" arrow><span><IconButton disabled={scale == 1} onClick={resetScale} ><ZoomOutMap/></IconButton></span></Tooltip>
                    </Stack>
                    <Stack mb={3} direction="row" alignItems="center" >
                        <IconButton type="button" disabled={pageNo <= 1} onClick={previousPage} size="small">
                            <ArrowBack/>
                        </IconButton>
                        <Typography mx={1} variant="body2" fontWeight={700} >Page {pageNo || (numPages ? 1 : '--')} of {numPages || '--'}</Typography>
                        <IconButton type="button" disabled={pageNo >= numPages} onClick={nextPage} size="small">
                            <ArrowForward/>
                        </IconButton>
                    </Stack>
                </Stack>
            )}
        </Stack>
    )
}