import React, { useState } from 'react'
import { Box, Chip, Stack } from '@mui/material'
import { GroupHighlightModel } from '../../../types'
import {useDebouncedCallback} from 'use-debounce'
import { HighlightCtx, useHighlightContext } from '../../../providers/HighlightContext'
import LinearProgressStyled from './LinearProgressStyled'
import { usePdfContext } from '../../../providers/PdfContext'
import useStore from '../../../providers/EditFormState'
import { useCombinedState } from '../../../providers/combinedStateFn'
import VisibilityIcon from '@mui/icons-material/Visibility';
import SubdirectoryArrowLeftIcon from '@mui/icons-material/SubdirectoryArrowLeft';

export const GroupChip = ({group, disabled}:{group:GroupHighlightModel, disabled?:boolean|undefined}) => {

    const { dispatch } = useHighlightContext();
    const [enabled, setEnabled] = React.useState<boolean>(false);
    const setTextHighfieldIcon = useStore((state) => state.setShowTextHighlight);
    const combined = useCombinedState()


    const onPreview = () => {
        if(enabled){
            setEnabled(false)
            setTextHighfieldIcon(true)
            combined.removeHighlightedTextFn()
        } else {
            setEnabled(true)
            setTextHighfieldIcon(false)
            dispatch({type:HighlightCtx.OutlineBox, payload:[group]})
        }
    }

    return (
        <Stack direction={"row"} mt={0.5} mb={3.5} width={"100%"} overflow={'hidden'}>
            <SubdirectoryArrowLeftIcon sx={{transform:'scaleX(-1)', ml:2, mr: 0.25}}/>
            <Chip sx={{minWidth:0}} label={group.name} color={enabled ? 'primary' : 'default'} variant={enabled ? 'filled' : 'outlined'} disabled={disabled} clickable onClick={onPreview} />
        </Stack>
    )
}