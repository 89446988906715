import React from 'react'
import { Button, styled, alpha, Box } from '@mui/material';
import { HighlightType, SelectedFieldOpts, ZPosition } from '../../providers/HighlightContext'
import { motion } from 'framer-motion';
import classNames from 'classnames';


export const HighlightSelectedField = ({selectedField,highlightType}:{selectedField: SelectedFieldOpts, highlightType:HighlightType}) => {

    const {field, position} = selectedField

    const HighlightSelected = styled(Button)(({theme})=>({
        border: `solid 1px ${theme.palette.info.main}`,
        backgroundColor: `${alpha(theme.palette.info.light,0.5)}`,
        zIndex: `${position === ZPosition.Above ? 2 : 0}`,
        left: `${field.positionPercentage[0] - (highlightType === HighlightType.Text ? -0.5 : 0) }%`,
        bottom: `${field.positionPercentage[1] - (highlightType === HighlightType.Text ? -0.25 : 0) }%`,
        width: `${field.sizePercentage[0] - (highlightType === HighlightType.Text ? 1 : 0) }%`,
        height: `${field.sizePercentage[1] - (highlightType === HighlightType.Text ? 0.5 : 0) }%`,
        '&:hover':{
          backgroundColor: `${alpha(theme.palette.info.light,0.7)}`
        },
        '& span':{
          color:`${theme.palette.common.black}`
        }
    }))

    
  return (
    <motion.div initial={{opacity:0}} animate={{opacity:1}}>
    <HighlightSelected className={classNames(
      'highlight-selected', 
      {
        completed: field.completed
      }
    )}>
        <Box component='span'>
          <Box>
            {field.name}
          </Box>
        </Box>
    </HighlightSelected>
    </motion.div>
  )
}
