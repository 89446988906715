import { Box, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Paper, PaperProps, Stack, styled, SxProps, Theme } from "@mui/material";
import React, {useContext, useRef, useState} from "react";
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import Draggable from "react-draggable";

type UseModalShowReturnType = {
    show: boolean;
    setShow: (value: boolean) => void;
    onHide: () => void;
}

const useModalShow = (): UseModalShowReturnType => {
    const [show, setShow] = useState(false);

    const handleOnHide = () => {
        setShow(false);
    };

    return {
        show,
        setShow,
        onHide: handleOnHide,
    }
};

type ModalContextType = {
    show: (
        {title,text,actions,sx,hideBackdrop}:
        {title: string, text: string | JSX.Element, actions?:JSX.Element, sx?:SxProps<Theme>, hideBackdrop?:boolean}
    ) => Promise<boolean>;
    hide: () => void;
};


const ModalContext = React.createContext<ModalContextType>({} as ModalContextType);

function ModalContextProvider({children}:{children:React.ReactNode}){
    const {setShow, show, onHide} = useModalShow();
    const [content, setContent] = useState<{ 
        title: string, 
        text: string | JSX.Element, 
        actions?:JSX.Element, 
        sx?:SxProps<Theme>, 
        hideBackdrop?:boolean
    } | null>();
    const resolver = useRef<any>();
    const nodeRef = useRef<any>(null);

    const handleShow = (
        {title,text,actions,sx,hideBackdrop}:
        {title: string, text: string | JSX.Element, actions?:JSX.Element, sx?:SxProps<Theme>, hideBackdrop?:boolean}): Promise<boolean> => 
    {
        setContent({
            title,
            text,
            actions,
            sx,
            hideBackdrop
        });
        setShow(true);
        return new Promise(function (resolve) {
            resolver.current = resolve;
        });
    };

    const hideModal = () => {
        setContent({
            title:'',
            text:'',
            actions: undefined,
            sx: undefined,
            hideBackdrop: undefined
        });
        resolver.current && resolver.current(false);
        onHide();
    }

    const modalContext: ModalContextType = {
        show: handleShow,
        hide: hideModal
    };

    function PaperComponent(props: PaperProps) {
        return (
            <Draggable
                nodeRef={nodeRef}
                handle="#draggable-dialog-title"
                cancel={'[class*="MuiDialogContent-root"]'}
            >
                <Box ref={nodeRef}>
                    <Paper {...props} />
                </Box>
            </Draggable>
        );
      }

    const ContextDialog = styled(Dialog)(({ theme }) => ({
        height:'100%',
        overflow:'scroll',
        '.MuiDialog-paper': {
            minWidth: '500px'
        }
    }));

    return (
        <ModalContext.Provider value={modalContext}>
            {children}

            {content &&
                <ContextDialog
                    sx={content.sx}
                    open={show}
                    onClose={onHide}
                    PaperComponent={PaperComponent}
                    hideBackdrop={content.hideBackdrop}
                    aria-labelledby="draggable-dialog-title"
                    aria-describedby="dialog-description"
                >
                    <DialogTitle id="draggable-dialog-title">
                        <Stack direction={'row'} justifyContent={'space-between'} sx={{cursor:'move'}}>
                            {content.title}
                            <DragIndicatorIcon/>
                        </Stack>
                    </DialogTitle>
                    {
                        typeof content.text === 'string'
                            ? <DialogContent>
                                <DialogContentText id="dialog-description">
                                    {content.text}
                                </DialogContentText>
                            </DialogContent>
                            : content.text
                    }
                    {
                        content.actions &&
                            <DialogActions sx={{px:'1em'}} >
                                {content.actions}
                            </DialogActions>
                    }
                </ContextDialog>

            }
        </ModalContext.Provider>
    )
}

const useModalContext = (): ModalContextType => useContext(ModalContext);

export {
    useModalShow,
    useModalContext,
}

export default ModalContextProvider;