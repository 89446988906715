import React, { useEffect, useMemo, useState } from 'react'
import { Box, FormHelperText, IconButton, Stack, Tooltip } from '@mui/material'
import AddIcon from '@mui/icons-material/Add';
import { useModalContext } from '../../../providers/ModalContext';
import { PdfCtx, usePdfContext } from '../../../providers/PdfContext';
import { SectionModalFields } from './SectionModalFields';
import { AutocompleteElement, UseFormGetValues, UseFormSetValue } from 'react-hook-form-mui';
import useStore from '../../../providers/EditFormState';
import { SectionListItem } from '../../form/form-control-types';
import { HighlightModel } from '../../../types';
import { useNotifier } from '../../../providers';
import { SubsectionField } from '../create-subsection/SubsectionField';
import { CompletedSectionHeader } from './CompletedSectionHeader';
import { CompletedSection } from './CompletedSection';
import { FormCtx, useFormContext } from '../../../providers/FormContext';


export const SectionField = ({setValue, getValues, watchSection, disabled}:{
    setValue: UseFormSetValue<{[key: string]: any;}>,
    getValues: UseFormGetValues<{[key: string]: any;}>,
    watchSection: any,
    disabled?:boolean|undefined;}) => {

    const modal = useModalContext();
    const notify = useNotifier()
    const { state:{document}, dispatch } = usePdfContext();
    const { state:{section}, dispatch:fDispatch } = useFormContext()
    const formValues = useStore((state) => state.res)
    const formData = useStore((state) => state.setFormData)
    const setIsEdited = useStore((state) => state.setIsEdited)
    const [sectionList, setSectionList] = useState<SectionListItem[]>([]);
    const sectionData = formValues?.sectionList
    const sectionValue = getValues('section')

    useEffect(() => {
        const existingSectionList:SectionListItem[] = sectionData ?? []
        setSectionList(existingSectionList)
    }, [])
    
    
    

    const handleAddSection = (section:HighlightModel) => {
        dispatch({type:PdfCtx.EditSections, payload:[section]})
        const sectionItem:SectionListItem = {id: section.id, label: section.name}
        const exitingSectionList:SectionListItem[] = formValues?.sectionList ?? []
        if(formValues)formData({...formValues, sectionList:[...exitingSectionList, sectionItem] })
        setSectionList((existing) => ([...existing, sectionItem]))
        setValue('section', {id:section.id, label:section.name})
        setIsEdited(true)
        modal.hide()
        notify.success(`Successfully created a new section '${section.name}'`)
    }

    const handleClick = () => {
        modal.show({
            title:'Create new section',
            text: <SectionModalFields id={document.id} handleAddSection={handleAddSection}/>,
        })
    }

    const AddSection = () => (
        <>
        <Stack direction={'row'} mt={2}>
            <AutocompleteElement
                required
                autocompleteProps={{size:'small', fullWidth:true, disabled:disabled, isOptionEqualToValue:()=>{return true}}} 
                name={'section'} 
                label={'Add to Section'} 
                options={sectionList} />
            <Tooltip title={'Click to add a new Section'}>
                <Box component={'span'}>
                    <IconButton sx={{ml:1}} onClick={handleClick} disabled={disabled} >
                        <AddIcon/>
                    </IconButton>
                </Box>
            </Tooltip>
        </Stack>
        <FormHelperText sx={{ml:1.75, mr:3}}>Include this field in a section group to seperate which fields appear together</FormHelperText>
        {
            watchSection && <SubsectionField sectionData={sectionValue} setValue={setValue} getValues={getValues} disabled={disabled} />
        }
        </>
    )


    return (
        <>
        {section 
            ? <CompletedSectionHeader section={section} setValue={setValue} setSectionList={setSectionList} disabled={disabled} />
            // ? <CompletedSection sectionName={existingSection.name} existingSub={existingSub?.name} />
            : <AddSection />
        }
        </>
    )
}
