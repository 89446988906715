import { CircularProgress, Stack, Typography } from '@mui/material'
import React from 'react'

export const LoadingAnimation = ({text, height}:{text?:string, height?:string}) => {
    return (
        <Stack alignItems="center" justifyContent="center" height={height ? height : "70vh"} >
            <CircularProgress />
            <Typography variant="h5" mt={1}>{text ? text : "Loading..."}</Typography>
        </Stack>
    )
}

export const LoadingNoText = ({height}:{height?:string}) => {
    return (
        <Stack alignItems="center" justifyContent="center" height={height ? height : "70vh"} >
            <CircularProgress />
        </Stack>
    )
}
