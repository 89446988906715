import React from 'react'
import TopNav from '../components/shared/TopNav'
import { ErrorBoundary } from 'react-error-boundary'
import { Container } from '@mui/material'
import { Outlet } from 'react-router-dom'
import ErrorFallback from './ErrorFallback'

export const Layout = () => {
  return (
    <>
      <TopNav />
      <ErrorBoundary FallbackComponent={ErrorFallback}>
          <Container maxWidth="lg" component="main">
            <Outlet />
          </Container>
      </ErrorBoundary>
    </>
  )
}
