import axios, { AxiosError } from "axios";
import create from "zustand"
import { UserFormData } from "../components/form/form-control-types";
import { FieldModel } from "../types";
import { useInstance } from "../utils/axiosConfig";

interface EditFormState {
    loading:boolean;
    error: AxiosError<any, any> | null;
    res: UserFormData | undefined;
    fieldTitle: string | undefined;
    btnGroup: FieldModel[];
    isEdited: boolean;
    showTextHighlightSearch: boolean;
    fetch: (field: FieldModel, docId:string, pageNo:number) => void;
    initial: () => void;
    setFormTitle: (title: string) => void;
    setIsEdited: (value: boolean) => void;
    setShowTextHighlight: (value: boolean) => void;
    setHighlightFn?: (field: FieldModel) => void;
    setBtnGroupItem: (field: FieldModel) => void;
    setFormData: (data:UserFormData) => void;
}

// const {instance} = useInstance()

const useStore = create<EditFormState>()((set) => ({
        loading:true,
        error: null,
        res: undefined,
        fieldTitle: undefined,
        btnGroup: [],
        isEdited: false,
        showTextHighlightSearch:false,
        fetch: (f,docId,no)=>{
            let typeEncode = f.type as string;
            typeEncode = typeEncode.replace(/\//g, "");
            const jsonSessionToken = window.sessionStorage.getItem("token")
            const jsonLocalToken = localStorage.getItem("token")
            if(jsonSessionToken || jsonLocalToken){
                let token = ''
                if(jsonSessionToken){
                    token = JSON.parse(jsonSessionToken)
                } else if(jsonLocalToken){
                    token = JSON.parse(jsonLocalToken)
                }
                axios.get(`${process.env.REACT_APP_DB_HOST_URI}/api/pdf/form_fields/${docId}/${f.id}/${typeEncode}/${no}`,{headers:{Authorization:"Bearer "+token}})
                    .then((r) => set((state) => ( 
                        {...state, res: r.data as UserFormData, loading:false, showTextHighlightSearch: true}
                    )))
                    .catch((err) => set((state) => (
                        {...state, error:err, loading:false}
                    )))
                }
            },
        initial: () => set((state) => (
            {...state, 
            error:null, 
            res:undefined, 
            loading:true, 
            fieldTitle: undefined, 
            btnGroup: [],
            isEdited: false, 
            showTextHighlightSearch: false}
        )),
        setFormTitle: (t) => set((state) => ({...state, fieldTitle: t})),
        setIsEdited: (v) => set((state) => ({...state, isEdited: v })),
        setShowTextHighlight: (v) => set((state) => ({...state, showTextHighlightSearch:v})),
        setBtnGroupItem: (v) => set((state) => {
            let newArr = state.btnGroup;
            if(!newArr.some(i => i.id === v.id)){
                newArr.push(v)
            } else {
                newArr = state.btnGroup.filter(i=>i.id!==v.id)
            }
            return {...state, btnGroup: newArr}
        }),
        setFormData: (d) => set((state) => ({...state, res: d}))
    }))

export default useStore;