import axios, { AxiosError, AxiosResponse } from "axios";
import React from "react";
import { AuthCtx, useAuthContext } from "../providers/AuthContext";
import { useLocalStorage } from "../auth/useLocalStorage";
import { useNavigate } from "react-router-dom";


export const useInstance = () => {

  const { state:{user} } = useAuthContext()

  const instance = axios.create({
        baseURL:`${process.env.REACT_APP_DB_HOST_URI}`,
        timeout: 20000,
        headers: {
          Authorization: "Bearer "+user?.token
        }
    })
  
    return { instance }
}


const AxiosInterceptor = ({ children }:{children:JSX.Element}) => {

    const { dispatch } = useAuthContext()
    const { setItem } = useLocalStorage();
    const { instance } = useInstance()
    const navigate = useNavigate();
  
    React.useEffect(() => {
  
      const resInterceptor = (response:AxiosResponse) => {
        return response;
      };
  
      const errInterceptor = (error:AxiosError) => {
        if (error.response?.status === 401) {
          setItem("token", "");
          dispatch({type:AuthCtx.Logout})
          navigate('/login')
        }
  
        return Promise.reject();
      };
  
      const interceptor = instance.interceptors.response.use(
        resInterceptor,
        errInterceptor
      );

  
      return () => instance.interceptors.response.eject(interceptor);
    }, []);
  
    return children;
  };

export { AxiosInterceptor }